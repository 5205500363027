// DEPENDENCIES
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CSS
import "./TipsAndTricksPreviewCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// REACT COMPONENT
const TipsAndTricksPreviewCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {}, []);

	// NAVIGATION
	const navigate = useNavigate(); // Initialize useNavigate

	// INPUT HANDLERS

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const handleCardClick = () => {
		if (props.record.data_type === "url") {
			// urls open in new tab automatically
			window.open(props.record.data, "_blank");
		} else {
			navigate(`/tips_and_tricks/${props.record.id}`); // Navigate to tips_and_tricks/:id
		}
	};

	// RENDER
	return (
		<div className="TipsAndTricksPreviewCard" onClick={handleCardClick}>
			<div className="TipsAndTricksPreviewHeader">
				<div className="leftSideCardHeader">
					<div className="cardTitles">
						<h2>{props.record.title}</h2>
						<h3>{props.record.description}</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

// EXPORT
export default TipsAndTricksPreviewCard;
