// DEPENDENCIES
import React, { useState, useContext } from "react";

// CSS
import "./NewDetailDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	postAPICall,
	postFormDataAPICall,
} from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	TextField,
} from "@mui/material";

import { Close, CloudUpload } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

// CUSTOM COMPONENTS

// CONSTANTS
import { getDateTime } from "../../../constants";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

// REACT COMPONENT
const NewDetailDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Form data
	const [title, setTitle] = useState("");

	const [selectedAssemblies, setSelectedAssemblies] = useState([]);

	const [selectedGroupings, setSelectedGroupings] = useState([]);

	const [bwPDFfile, setBWPDFfile] = useState(null);
	const [colorPDFfile, setColorPDFfile] = useState(null);
	const [dwgFile, setDWGfile] = useState(null); // CAD file

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const [groupingsLoading, setGroupingsLoading] = useState(false);

	// Misc
	const [availableGroupings, setAvailableGroupings] = useState([]);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS
	// Form data handlers
	const titleChanged = (event) => {
		setTitle(event.target.value);
	};

	const assemblySelected = (event, newValue) => {
		setSelectedAssemblies([...newValue]);
		filterGroupings(newValue);
	};

	const groupingSelected = (event, newValue) => {
		setSelectedGroupings([...newValue]);
	};

	// File handlers
	const handleBWPDFChange = (event) => {
		if (event.target.files.length === 0) return;

		if (event.target.files[0].type !== "application/pdf") {
			openAlert("Please select a PDF file");
			return;
		}

		if (event.target.files[0].name.includes(".png")) {
			openAlert(
				'Your B&W PDF file cannot contain ".png" in the file name'
			);
			return;
		}

		setBWPDFfile(event.target.files[0]);
	};

	const handleColorPDFChange = (event) => {
		if (event.target.files.length === 0) return;

		if (event.target.files[0].type !== "application/pdf") {
			openAlert("Please select a PDF file");
			return;
		}

		if (event.target.files[0].name.includes(".png")) {
			openAlert(
				'Your color PDF file cannot contain ".png" in the file name'
			);
			return;
		}

		setColorPDFfile(event.target.files[0]);
	};

	const handleDWGChange = (event) => {
		if (event.target.files.length === 0) return;

		// if (event.target.files[0].type !== 'image/vnd.dwg') {
		//     openAlert('Please select a .dwg file');
		//     return;
		// }

		setDWGfile(event.target.files[0]);
	};

	// File removers
	const removeBWPDFFile = () => {
		setBWPDFfile(null);
	};

	const removeColorPDFFile = () => {
		setColorPDFfile(null);
	};

	const removeDWGFile = () => {
		setDWGfile(null);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertMessage(message);
		setAlertOpen(true);
	};

	const closeAlert = () => {
		setAlertMessage("");
		setAlertOpen(false);
	};

	// BUTTON HANDLERS
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		try {
			// Create detail record & retrieve ID
			const detailRes = await addDetail();
			const newDetailID = detailRes.data.insertId;

			// Add assemblies
			await addAssemblies(newDetailID);

			// Add groupings
			await addGroupings(newDetailID);

			// Add files
			await addBWPDFFile(newDetailID);
			await addColorPDFFile(newDetailID);
			await addDWGFile(newDetailID);

			// Refresh the detail library
			props.fetchDetailLibraryRecords();
		} catch (error) {
			openAlert("Error submitting form data");
			console.error(error);
		}

		setIsLoading(false);
		formClosed();
	};

	// API FUNCTIONS
	const filterGroupings = async (newValue) => {
		setGroupingsLoading(true);

		let groupingRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/groupings/byAssembly",
			{
				assemblyIDs: newValue.map((a) => a.id),
			}
		);
		setAvailableGroupings(groupingRes.data);

		setGroupingsLoading(false);
	};

	// Detail
	const addDetail = async () => {
		const res = await postAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/add",
			{
				title: title,
				createdDateTime: getDateTime(),
				createdByID: employeeID,
			},
			employeeID
		);

		return res;
	};

	// Assemblies
	const addAssemblies = async (detailID) => {
		if (selectedAssemblies.length === 0) return null;

		for (let i = 0; i < selectedAssemblies.length; i++) {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/encompass/assemblies/add",
				{
					detailID: detailID,
					assemblyID: selectedAssemblies[i].id,
				},
				employeeID
			);
		}
	};

	// Groupings
	const addGroupings = async (detailID) => {
		if (selectedGroupings.length === 0) return null;

		for (let i = 0; i < selectedGroupings.length; i++) {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/encompass/groupings/add",
				{
					detailID: detailID,
					groupingID: selectedGroupings[i].id,
				},
				employeeID
			);
		}
	};

	// Files
	const addBWPDFFile = async (detailID) => {
		if (!bwPDFfile) return null;

		const bwPDFformData = new FormData();
		bwPDFformData.append("pdf", bwPDFfile);
		bwPDFformData.append("detailID", detailID);

		const res = await postFormDataAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/bwPDF/add",
			bwPDFformData
		);

		return res;
	};

	const addColorPDFFile = async (detailID) => {
		if (!colorPDFfile) return null;

		const colorPDFformData = new FormData();
		colorPDFformData.append("pdf", colorPDFfile);
		colorPDFformData.append("detailID", detailID);

		const res = await postFormDataAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/colorPDF/add",
			colorPDFformData
		);

		return res;
	};

	const addDWGFile = async (detailID) => {
		if (!dwgFile) return null;

		const dwgFormData = new FormData();
		dwgFormData.append("dwg", dwgFile);
		dwgFormData.append("detailID", detailID);

		const res = await postFormDataAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/dwg/add",
			dwgFormData
		);

		return res;
	};

	// HELPER FUNCTIONS
	const validateInputFields = () => {
		if (!title) {
			openAlert("Please enter a title");
			return false;
		}

		if (selectedAssemblies.length === 0) {
			openAlert("Please select at least one assembly");
			return false;
		}

		if (!bwPDFfile) {
			openAlert("Please select at black and white (BW) pdf.");
			return false;
		}

		if (!colorPDFfile) {
			openAlert("Please select a color pdf.");
			return false;
		}

		if (!dwgFile) {
			openAlert("Please select a CAD (.dwg) file");
			return false;
		}

		return true;
	};

	const resetForm = () => {
		setTitle("");
		setSelectedAssemblies([]);
		setSelectedGroupings([]);
		setBWPDFfile(null);
		setColorPDFfile(null);
		setDWGfile(null);

		setAlertOpen(false);
		setAlertMessage("");

		setIsLoading(false);
		setGroupingsLoading(false);
	};

	const formClosed = () => {
		resetForm();
		props.newDetailDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="newDetailDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="newDetailDialogTitle">New Detail</DialogTitle>
			<DialogContent id="newDetailDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="newDetailDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="newDetailDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="newDetailAlert"
									severity="error"
									action={
										<IconButton
											id="newDetailCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<Close
												id="newDetailCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="newDetailDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newDetailTitle"
								label="Title"
								value={title}
								onChange={titleChanged}
							/>
						</div>
						<div className="newDetailDialogRow">
							<Autocomplete
								sx={{ flex: 1 }}
								limitTags={2}
								onChange={assemblySelected}
								multiple
								id="assemblySelect"
								options={props.assemblies}
								getOptionLabel={(option) => option.name}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField
										{...params}
										label="Assemblies"
										placeholder="Assemblies"
									/>
								)}
							/>
						</div>
						<div className="newDetailDialogRow">
							{groupingsLoading && (
								<LinearProgress
									sx={{
										width: "100%",
										marginTop: "1em",
										marginBottom: "1em",
									}}
									color="primary"
								/>
							)}
							{!groupingsLoading && (
								<Autocomplete
									sx={{ flex: 1 }}
									limitTags={2}
									onChange={groupingSelected}
									multiple
									id="groupingSelect"
									options={availableGroupings}
									getOptionLabel={(option) => option.name}
									filterSelectedOptions
									renderInput={(params) => (
										<TextField
											{...params}
											label="Groupings"
											placeholder="Groupings"
										/>
									)}
								/>
							)}
						</div>
						<div className="newDetailDialogRow">
							<h2 className="newDetailDialogLabel">Files</h2>
						</div>
						<div className="newDetailDialogRow" id="fileUploadRow">
							<Button
								component="label"
								variant="contained"
								startIcon={<CloudUpload />}
							>
								B&W PDF
								<VisuallyHiddenInput
									type="file"
									id="newDetailFileInput"
									onChange={handleBWPDFChange}
								/>
							</Button>
							<h2 className="fileLabel">
								{bwPDFfile
									? bwPDFfile.name
									: "No file selected"}
							</h2>
							{bwPDFfile && (
								<IconButton
									id="newDetailCloseIconButtonFile"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={removeBWPDFFile}
								>
									<Close
										id="newDetailCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							)}
						</div>
						<div className="newDetailDialogRow" id="fileUploadRow">
							<Button
								component="label"
								variant="contained"
								startIcon={<CloudUpload />}
							>
								Color PDF
								<VisuallyHiddenInput
									type="file"
									id="newDetailFileInput"
									onChange={handleColorPDFChange}
								/>
							</Button>
							<h2 className="fileLabel">
								{colorPDFfile
									? colorPDFfile.name
									: "No file selected"}
							</h2>
							{colorPDFfile && (
								<IconButton
									id="newDetailCloseIconButtonFile"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={removeColorPDFFile}
								>
									<Close
										id="newDetailCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							)}
						</div>
						<div className="newDetailDialogRow" id="fileUploadRow">
							<Button
								component="label"
								variant="contained"
								startIcon={<CloudUpload />}
							>
								CAD File
								<VisuallyHiddenInput
									type="file"
									id="newDetailFileInput"
									onChange={handleDWGChange}
								/>
							</Button>
							<h2 className="fileLabel">
								{dwgFile ? dwgFile.name : "No file selected"}
							</h2>
							{dwgFile && (
								<IconButton
									id="newDetailCloseIconButtonFile"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={removeDWGFile}
								>
									<Close
										id="newDetailCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							)}
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default NewDetailDialog;
