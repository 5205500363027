// DEPENDENCIES
import React, { useState, useContext } from "react";

// CSS
import "./SpecificationsListCard.css";

// MUI COMPONENTS
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// CUSTOM COMPONENTS
import SpecificationList from "./SpecificationList/SpecificationList";
import J2NoResultsFound from "../../../../Misc/J2NoResultsFound/J2NoResultsFound";
import DocumentGenerationOptionsDialog from "../DocumentGenerationOptionsDialog/DocumentGenerationOptionsDialog";
import SpecificationCommentsDialog from "./SpecificationCommentsDialog/SpecificationCommentsDialog";

// CONSTANTS
import { defaultBlue } from "../../../../../constants";
import { UserContext } from "../../../../../context/UserContext";
import { useMsal } from "@azure/msal-react";

// FUNCTIONAL COMPONENT
const SpecificationsListCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS

	// CONTEXT
	const { employeeID } = useContext(UserContext);

	// STATES
	const [specCommentsDialogOpen, setSpecCommentsDialogOpen] = useState(false);
	const [specCommentsRecord, setSpecCommentsRecord] = useState({});

	const [
		generateSingleSpecificationDialogOpen,
		setGenerateSingleSpecificationDialogOpen,
	] = useState(false);
	const [specificationToDownload, setSpecificationToDownload] = useState({});

	// INPUT HANDLERS
	const openEditSpecificationTextDialog = (specRecord) => {
		props.handleSpecificationEditClicked(specRecord);
	};

	const handleGenerateSingleSpecificationClicked = (spec) => {
		setSpecificationToDownload(spec);
		setGenerateSingleSpecificationDialogOpen(true);
	};

	const openSpecificationComments = (spec) => {
		setSpecCommentsRecord(spec);
		setSpecCommentsDialogOpen(true);
	};

	// RETURN
	return (
		<div className="specificationsListCard">
			<div className="specificationsListCardHeader">
				<div className="specificationsListCardHeaderIconTitleContainer">
					<IconButton sx={{ color: defaultBlue }}>
						<MenuBookIcon sx={{ fontSize: 32 }} />
					</IconButton>
					<h2 className="specificationsListCardHeaderTitle">
						Specifications
					</h2>
				</div>
				<div className="specificationsListCardHeaderButtons">
					<Button
						className="specificationsListCardHeaderButton"
						onClick={props.addSpecifications}
						color="primary"
						variant="outlined"
						disabled={props.isJobLocked}
					>
						Add Spec
					</Button>
					<Button
						className="specificationsListCardHeaderButton"
						onClick={props.toggleLock}
						color="secondary"
						variant={props.isJobLocked ? "contained" : "outlined"}
						startIcon={
							props.isJobLocked ? <LockIcon /> : <LockOpenIcon />
						}
					>
						{props.isJobLocked ? "Unlock" : "Lock"}
					</Button>
					<Button
						className="specificationsListCardHeaderButton"
						onClick={props.saveButtonClicked}
						color="primary"
						variant="contained"
						disabled={!props.unsavedChanges}
					>
						Save
					</Button>
				</div>
			</div>
			<div className="specificationsListCardContentRow">
				<Collapse
					sx={{ flex: 1 }}
					id="specificationsListCardCollapse"
					in={props.unsavedChanges}
				>
					<div className="alertContainer">
						<Alert
							id="specificationsListCardCollapseAlert"
							severity="warning"
						>
							You have unsaved changes. Please save before leaving
							the page.
						</Alert>
					</div>
				</Collapse>
			</div>
			<div className="specificationsListCardContent">
				{props.selectedSpecifications.length === 0 && (
					<J2NoResultsFound />
				)}
				{props.selectedSpecifications.length > 0 && (
					<>
						<SpecificationList
							selectedSpecifications={
								props.selectedSpecifications
							}
							selectedSpecificationsChanged={(newList) =>
								props.selectedSpecificationsChanged(newList)
							}
							specificationRemoved={(specID) =>
								props.specificationRemoved(specID)
							}
							editSpecificationText={(recordToEdit) =>
								openEditSpecificationTextDialog(recordToEdit)
							}
							handleGenerateSingleSpecificationClicked={
								handleGenerateSingleSpecificationClicked
							}
							openSpecificationComments={(spec) =>
								openSpecificationComments(spec)
							}
							unsavedChanges={props.unsavedChanges}
							isJobLocked={props.isJobLocked}
						/>
						<div className="specificationsListCardButtons">
							<Button
								className="specificationsListCardButton"
								onClick={props.generateDocumentClicked}
								color="primary"
								variant="contained"
								disabled={props.unsavedChanges}
							>
								Generate Document
							</Button>
						</div>
					</>
				)}
			</div>
			<DocumentGenerationOptionsDialog
				open={generateSingleSpecificationDialogOpen}
				documentGenerationOptionsDialogClosed={() =>
					setGenerateSingleSpecificationDialogOpen(false)
				}
				jobID={props.jobID}
				selectedSpecifications={[specificationToDownload]}
				job={props.job}
				propertyDetails={props.propertyDetails}
				divisionCodes={""} // none because not generating a table of contents
				fetchDesignJobRecord={() => console.log("fetchDesignJobRecord")}
				generateTableOfContents={false}
				generateCoverPage={false}
				saveToS3={false}
				singleSpec={true}
			/>
			<SpecificationCommentsDialog
				open={specCommentsDialogOpen}
				onClose={() => setSpecCommentsDialogOpen(false)}
				spec={specCommentsRecord}
			/>
		</div>
	);
};

export default SpecificationsListCard;
