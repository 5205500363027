// REACT IMPORTS
import React, { useState } from "react";
import dayjs from "dayjs";

// CSS
import "./J2ReportIssueDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import {
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	CircularProgress,
} from "@mui/material";

// FUNCTIONAL COMPONENT
const J2ReportIssueDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [issueBulletValue, setIssueBulletValue] = useState("");
	const [issueFormDescription, setIssueFormDescription] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// INPUT HANDLERS
	const issueBulletPointClicked = (event) => {
		setIssueBulletValue(event.target.value);
	};

	const issueFormDescriptionChanged = (event) => {
		setIssueFormDescription(event.target.value);
	};

	const reportIssueSubmitted = async () => {
		setIsLoading(true);
		let reportRes = await postAPICall(
			instance,
			accounts[0],
			"/api/logs/reportLog/add",
			{
				reportName: issueBulletValue,
				description: issueFormDescription,
				submittedBy: "leifj@j2consultants.com", // TODO: Change this to the user's email
				submittedTime: dayjs().format(
					"MMMM, D YYYY h:mm:ss A [(America/Seattle)]"
				),
				tipID: props.tipID,
			}
		);
		setIsLoading(false);
		props.reportIssueClosed();
	};

	// RENDER
	return (
		<Dialog
			id="reportIssueDialog"
			open={props.open}
			fullWidth
			onClose={props.reportIssueClosed}
		>
			<DialogTitle>Report an Issue</DialogTitle>
			<DialogContent id="reportIssueDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<DialogContentText>
							Describe the issue you encountered
						</DialogContentText>
						<FormControl>
							<RadioGroup
								value={issueBulletValue}
								onChange={issueBulletPointClicked}
							>
								<FormControlLabel
									value="videoNotPlaying"
									control={<Radio />}
									label="Video not playing"
								/>
								<FormControlLabel
									value="audioNotWorking"
									control={<Radio />}
									label="Audio not working"
								/>
								<FormControlLabel
									value="noContent"
									control={<Radio />}
									label="No content is being displayed"
								/>
								<FormControlLabel
									value="infoOutdated"
									control={<Radio />}
									label="The information is outdated"
								/>
								<FormControlLabel
									value="other"
									control={<Radio />}
									label="Other"
								/>
							</RadioGroup>
						</FormControl>
						<TextField
							label="Description"
							value={issueFormDescription}
							onChange={issueFormDescriptionChanged}
						>
							Additional Notes
						</TextField>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={props.reportIssueClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={reportIssueSubmitted} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default J2ReportIssueDialog;
