// DEPENDENCIES
import React from "react";

// CSS
import "./UnableToDeleteSpecDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// MUI COMPONENTS
import { Dialog, DialogContent, DialogTitle, Button } from "@mui/material";

// REACT COMPONENT
const UnableToDeleteSpecDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Content

	// Feedback

	// CONTEXT
	// const employeeID = useContext(UserContext);

	// USE EFFECT

	// INPUT HANDLERS
	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<Dialog
			id="unableToDeleteSpecDialog"
			open={props.open}
			fullWidth
			onClose={props.formClosed}
		>
			<DialogTitle id="unableToDeleteSpecDialogTitle">
				Unable to Delete Specification
			</DialogTitle>
			<DialogContent id="unableToDeleteSpecDialogContent">
				<div className="unableToDeleteSpecDialogRow">
					This spec cannot be deleted because it is currently being
					used in these jobs:
				</div>
				<div className="unableToDeleteSpecDialogRow">
					<div className="unableToDeleteSpecDialogColumn">
						{props.jobsUsingSpec.map((job) => (
							<div className="unableToDeleteSpecDialogRow">
								{job.job_name}
							</div>
						))}
					</div>
				</div>
				<div className="dialogButtons">
					<Button
						onClick={props.formClosed}
						variant="outlined"
						color="primary"
					>
						Close
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default UnableToDeleteSpecDialog;
