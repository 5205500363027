// DEPENDENCIES
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

// CSS
import "./AdminTicketCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, deleteAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { Button, ButtonGroup, Collapse, IconButton } from "@mui/material";
import {
	Add as AddIcon,
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import TicketStatusIcon from "../TicketCard/TicketStatusIcon/TicketStatusIcon";
import TicketComment from "../TicketComment/TicketComment";

// CONSTANTS
import { toTitleCase } from "../../../constants";

const requestTypeOptions = {
	bugIssue: "Bug/Issue",
	change: "Change",
	newFeature: "New Feature",
	other: "Other",
};

// RENDER
const AdminTicketCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [comments, setComments] = useState([]);
	const [adminTicketCardOpen, setAdminTicketCardOpen] = useState(false);

	// USE EFFECT
	useEffect(() => {
		fetchTicketData();
	}, []);

	// INPUT HANDLERS
	const handleAdminTicketCardOpen = () => {
		setAdminTicketCardOpen(!adminTicketCardOpen);
	};

	// API HELPER FUNCTIONS
	const fetchTicketData = async () => {
		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/tickets/comments",
				{
					ticketID: props.ticket.id,
				}
			);

			if (response.data.error) {
				console.log("Error in fetchComments: ", response.data.error);
				return;
			}

			setComments(response.data);
		} catch (error) {
			console.error("Error fetching statuses: ", error);
		}
	};

	const deleteComment = async (commentID) => {
		try {
			const response = await deleteAPICall(
				instance,
				accounts[0],
				"/api/tickets/comments/delete",
				{
					commentID: commentID,
				}
			);

			if (response.data.error) {
				console.log("Error in deleteComment: ", response.data.error);
				return;
			}

			const newComments = comments.filter(
				(comment) => comment.id !== commentID
			);
			setComments(newComments);
		} catch (error) {
			console.error("Error deleting comment: ", error);
		}
	};

	// RENDER
	return (
		<div className="AdminTicketCard">
			<div
				className="adminTicketCardHeader"
				onClick={handleAdminTicketCardOpen}
			>
				<div className="adminTicketCardHeaderTitles">
					<h2 className="adminTicketCardHeaderTitle">
						{props.ticket.name}
						<IconButton
							sx={{
								color: "black",
								minHeight: 0,
								minWidth: 0,
								padding: 0,
							}}
						>
							{adminTicketCardOpen ? (
								<ArrowDropUpIcon fontSize="small" />
							) : (
								<ArrowDropDownIcon fontSize="small" />
							)}
						</IconButton>
					</h2>
					<h3 className="adminTicketCardHeaderSubtitle">
						{props.ticket.submitted_by_first_name}{" "}
						{props.ticket.submitted_by_last_name}
					</h3>
				</div>
				<div className="adminTicketCardHeaderButtons">
					{adminTicketCardOpen && (
						<ButtonGroup>
							<Button
								variant="outlined"
								color="primary"
								onClick={(e) => {
									e.stopPropagation(); // Prevents the card from closing when the button is clicked
									props.addCommentClicked(props.ticket.id);
								}}
								startIcon={<AddIcon />}
							>
								Comment
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={(e) => {
									e.stopPropagation(); // Prevents the card from closing when the button is clicked
									props.editTicketClicked(props.ticket);
								}}
							>
								Edit
							</Button>
						</ButtonGroup>
					)}
					<TicketStatusIcon
						statusTitle={props.ticket.status_name}
						hexColor={props.ticket.hex_color}
					/>
				</div>
			</div>
			<Collapse in={adminTicketCardOpen} className="adminTicketCardBody">
				<p className="adminTicketCardBodyDescription">
					{props.ticket.description}
				</p>
				<div className="adminTicketCardBodyRow">
					<div className="adminTicketCardBodyRowItem">
						<p className="adminTicketCardBodyRowItemTitle">
							Request Type
						</p>
						<p className="adminTicketCardBodyRowItemValue">
							{requestTypeOptions[props.ticket.request_type]}
						</p>
					</div>
					<div className="adminTicketCardBodyRowItem">
						<p className="adminTicketCardBodyRowItemTitle">
							Priority
						</p>
						<p className="adminTicketCardBodyRowItemValue">
							{toTitleCase(props.ticket.priority)}
						</p>
					</div>
					<div className="adminTicketCardBodyRowItem">
						<p className="adminTicketCardBodyRowItemTitle">
							Category
						</p>
						<p className="adminTicketCardBodyRowItemValue">
							{props.ticket.category_name
								? toTitleCase(props.ticket.category_name)
								: "N/A"}
						</p>
					</div>
				</div>
				<div className="adminTicketCardBodyRow">
					<div className="adminTicketCardBodyRowItem">
						<p className="adminTicketCardBodyRowItemTitle">
							Application Name
						</p>
						<p className="adminTicketCardBodyRowItemValue">
							{props.ticket.application_name}
						</p>
					</div>
					<div className="adminTicketCardBodyRowItem">
						<p className="adminTicketCardBodyRowItemTitle">
							Task Owner
						</p>
						<p className="adminTicketCardBodyRowItemValue">
							{props.ticket.assignee_first_name
								? props.ticket.assignee_first_name +
								  " " +
								  props.ticket.assignee_last_name
								: "Unassigned"}
						</p>
					</div>
					<div className="adminTicketCardBodyRowItem">
						<p className="adminTicketCardBodyRowItemTitle">
							Submitted Date
						</p>
						<p className="adminTicketCardBodyRowItemValue">
							{dayjs(props.ticket.created).format("MMM DD, YYYY")}
						</p>
					</div>
				</div>
				{props.ticket.image_url && (
					<div className="screenshotContainer">
						<p className="adminTicketCardBodyRowItemTitle">
							Screenshot
						</p>
						<img
							src={props.ticket.image_url}
							alt="Ticket Screenshot"
						/>
					</div>
				)}
				{comments.length > 0 && (
					<div className="commentsContainer">
						<p className="ticketCardBodyRowItemTitle">Comments</p>
						{comments.map((comment, index) => (
							<TicketComment
								key={index}
								comment={comment}
								showBottomBorder={index !== comments.length - 1}
								showDeleteButton={true}
								deleteComment={(id) => deleteComment(id)}
							/>
						))}
					</div>
				)}
			</Collapse>
		</div>
	);
};

export default AdminTicketCard;
