// REACT
import { useEffect, useState } from "react";

// CSS
import "./SpecTextEditor.css";
import "ckeditor5/ckeditor5.css";

// CKEDITOR
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
	ClassicEditor,
	Bold,
	Heading,
	Essentials,
	Italic,
	Mention,
	Paragraph,
	GeneralHtmlSupport,
	List,
	Undo,
	CloudServices,
	Table,
	TableToolbar,
	Style,
	AutoLink,
	Link,
	TableColumnResize,
	RemoveFormat,
	Indent,
	IndentBlock,
} from "ckeditor5";

const SpecTextEditor = (props) => {
	const isDisabled = props.disabled;

	const [data, setData] = useState(props.data);

	useEffect(() => {
		setData(props.data);
	}, [props.data]);

	return (
		<>
			<CKEditor
				disabled={isDisabled}
				editor={ClassicEditor}
				config={{
					toolbar: isDisabled
						? []
						: {
								items: [
									"style",
									"removeFormat",
									"|",
									"heading",
									"|",
									"numberedList",
									"outdent",
									"indent",
									"|",
									"insertTable",
								],
						  },
					plugins: [
						Bold,
						Heading,
						Essentials,
						GeneralHtmlSupport,
						List,
						Italic,
						Mention,
						Paragraph,
						CloudServices,
						Undo,
						Table,
						TableToolbar,
						Style,
						AutoLink,
						Link,
						TableColumnResize,
						RemoveFormat,
						Indent,
						IndentBlock,
					],
					initialData: data,
					table: {
						contentToolbar: [
							"tableColumn",
							"tableRow",
							"mergeTableCells",
						],
						defaultHeadings: { rows: 1 },
					},
					style: {
						definitions: [
							{
								name: "Bold Text",
								element: "span",
								classes: ["bold"],
							},
							{
								name: "Red Text",
								element: "span",
								classes: ["red"],
							},
							{
								name: "Yellow Highlight",
								element: "span",
								classes: ["yellow-highlight"],
							},
							{
								name: "Gray Highlight",
								element: "span",
								classes: ["gray-highlight"],
							},
							{
								name: "Blue Highlight",
								element: "span",
								classes: ["blue-highlight"],
							},
							{
								name: "Green Highlight",
								element: "span",
								classes: ["green-highlight"],
							},
							{
								name: "Pink Highlight",
								element: "span",
								classes: ["pink-highlight"],
							},
							{
								name: "Uppercase Text",
								element: "span",
								classes: ["uppercase"],
							},
							{
								name: "Italic Text",
								element: "span",
								classes: ["italic"],
							},
							{
								name: "Underline Text",
								element: "span",
								classes: ["underline"],
							},
						],
					},
					heading: {
						options: [
							{
								model: "paragraph",
								title: "Paragraph",
								class: "document-normal",
							},
							{
								model: "heading1",
								view: "h1",
								title: "Title",
								class: "document-title",
							},
							{
								model: "heading2",
								view: "h2",
								title: "Subtitle",
								class: "document-subtitle",
							},
							{
								model: "heading3",
								view: "h3",
								title: "End Of Section",
								class: "document-end-of-section",
							},
						],
					},
				}}
				onChange={(event, editor) => {
					const newData = editor.getData();
					setData(newData);
					props.onDataChange(newData);
					// console.log("editor html: ", editor.getData());
				}}
			/>
		</>
	);
};

export default SpecTextEditor;
