import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./EmailSignature.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
} from "@mui/material";

// CONSTANTS
import smallJ2Logo from "../../../images/j2Logo80px.png";

// REACT COMPONENT
const EmailSignature = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API Res
	const [employeeRecord, setEmployeeRecord] = useState({});
	const [linkOptions, setLinkOptions] = useState([]);

	// Form
	const [selectedLink, setSelectedLink] = useState("");
	const [selectedLinkType, setSelectedLinkType] = useState("");

	const [phoneTypeOptions, setPhoneTypeOptions] = useState([]);
	const [phoneType, setPhoneType] = useState("");
	const [phoneValue, setPhoneValue] = useState("");

	// Loading
	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Email Signature",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: `Accessed the Email Signature screen.`,
		});

		fetchProfileRecord(employeeID);
	}, []);

	// INPUT HANDLERS
	const handleLinkChange = (e) => {
		setSelectedLinkType(e.target.value);

		switch (e.target.value) {
			case "CONTACT ME":
				setSelectedLink(employeeRecord.contact_card_url);
				break;
			case "MY BIO":
				setSelectedLink(employeeRecord.bio_url);
				break;
			case "SCHEDULE A MEETING":
				setSelectedLink(employeeRecord.schedule_meeting_url);
				break;
			default:
				setSelectedLink("");
				break;
		}
	};

	const handlePhoneTypeChange = (e) => {
		setPhoneType(e.target.value);

		if (e.target.value === "Cell") {
			setPhoneValue(employeeRecord.phone_number);
		} else if (e.target.value === "Desk") {
			setPhoneValue(employeeRecord.desk_phone);
		}
	};

	// API FUNCTIONS
	const fetchProfileRecord = async (id) => {
		setIsLoading(true);

		try {
			// Fetch record
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/profile/emailSignature",
				{
					employeeID: id,
				}
			);

			setEmployeeRecord(response.data[0]);

			// Set link options
			let tempLinkOptions = ["None"];

			if (response.data[0].contact_card_url) {
				tempLinkOptions.push("CONTACT ME");
			}

			if (response.data[0].bio_url) {
				tempLinkOptions.push("MY BIO");
			}

			if (response.data[0].schedule_meeting_url) {
				tempLinkOptions.push("SCHEDULE A MEETING");
			}

			setLinkOptions(tempLinkOptions);

			// Set selected link
			setSelectedLink("");
			setSelectedLinkType("None");

			// Set phone type options
			let tempPhoneTypeOptions = [];

			if (response.data[0].phone_number) {
				tempPhoneTypeOptions.push("Cell");
			}

			if (response.data[0].desk_phone) {
				tempPhoneTypeOptions.push("Desk");
			}

			setPhoneTypeOptions(tempPhoneTypeOptions);
			setPhoneType("Cell");
			setPhoneValue(response.data[0].phone_number);
		} catch (err) {
			console.log(err);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="EmailSignature">
			<div className="emailSignatureTitle">Email Signature</div>
			<div className="emailSignatureFormCard">
				<div className="emailSignatureCardHeader">
					<h2>Signature Details</h2>
				</div>
				<div className="emailSignatureFormCardRow">
					<FormControl variant="outlined" style={{ flex: 1 }}>
						<InputLabel id="linkTypeLabel">Link Type</InputLabel>
						<Select
							labelId="linkTypeLabel"
							id="linkType"
							value={selectedLinkType}
							onChange={handleLinkChange}
							label="Link Type"
						>
							{linkOptions.map((value) => (
								<MenuItem key={value} value={value}>
									{value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="outlined" style={{ flex: 1 }}>
						<InputLabel id="phoneTypeLabel">Phone Type</InputLabel>
						<Select
							labelId="phoneTypeLabel"
							id="phoneType"
							value={phoneType}
							onChange={handlePhoneTypeChange}
							label="Phone Type"
						>
							{phoneTypeOptions.map((type) => (
								<MenuItem key={type} value={type}>
									{type}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</div>
			{isLoading && (
				<div className="loadingSpinner">
					<CircularProgress />
				</div>
			)}
			{employeeRecord && employeeRecord.id && (
				<>
					<div className="emailSignaturePreviewCard">
						<div className="emailSignatureCardHeader">
							<h2>Signature Preview</h2>
						</div>
						<div className="signaturePreview">
							<table
								className="signaturePreviewTable"
								style={{
									fontFamily: "Open Sans, sans-serif",
									fontWeight: "400",
									fontStyle: "normal",
								}}
							>
								<tr className="signaturePreviewTableRow">
									<td
										className="signaturePreviewTableData"
										id="signaturePreviewLogo"
										style={{
											paddingLeft: "0px",
											paddingRight: "0px",
										}}
									>
										<img
											src={smallJ2Logo}
											alt="J2 Logo"
											style={{
												height: "80px",
												aspectRatio: "1/1",
											}}
										/>
									</td>
									<td
										className="signaturePreviewTableData"
										id="signaturePreviewNameContainer"
										style={{
											paddingLeft: "8px",
											paddingRight: "16px",
										}}
									>
										<div
											className="signaturePreviewName"
											style={{
												fontFamily:
													"PT Sans Narrow, sans-serif",
												fontWeight: "700",
												fontStyle: "normal",
												fontSize: "24px",
												color: "black",
											}}
										>
											{employeeRecord.full_name.toUpperCase()}
											{employeeRecord.accreditation &&
												`, ${employeeRecord.accreditation}`}
										</div>
										<div
											className="signaturePreviewJobTitle"
											style={{ fontSize: "14px" }}
										>
											{employeeRecord.job_title}
										</div>
										{selectedLink !== "" && (
											<div className="signaturePreviewLink">
												<a
													href={selectedLink}
													style={{
														fontSize: "12px",
														color: "#8697A6",
														fontWeight: "400",
														textDecoration:
															"underline",
													}}
												>
													{selectedLinkType}
												</a>
											</div>
										)}
									</td>
									<td
										className="signaturePreviewTableData"
										id="signaturePreviewDetails"
										style={{
											fontSize: "12px",
											color: "#000000",
											fontWeight: "300",
											paddingLeft: "16px",
											paddingRight: "8px",
											borderLeft: "0.5px solid #8697A6",
										}}
									>
										<div
											className="signaturePreviewJ2Link"
											style={{
												marginTop: "5px",
												marginBottom: "5px",
											}}
										>
											<a
												href="https://j2consultants.com"
												style={{
													fontSize: "12px",
													color: "#000000",
													fontWeight: "300",
													textDecoration: "none",
												}}
											>
												J2 Building Consultants, Inc.
											</a>
										</div>
										<div
											className="signaturePreviewEmail"
											style={{
												marginTop: "5px",
												marginBottom: "5px",
											}}
										>
											<a
												href={`mailto:${employeeRecord.email}`}
												style={{
													fontSize: "12px",
													color: "#000000",
													fontWeight: "300",
													textDecoration: "none",
												}}
											>
												{employeeRecord.email}
											</a>
										</div>
										<div
											className="signaturePreviewPhone"
											style={{
												marginTop: "5px",
												marginBottom: "5px",
											}}
										>
											{phoneValue}
										</div>
										<div
											className="signaturePreviewHiring"
											style={{
												marginTop: "5px",
												marginBottom: "5px",
											}}
										>
											<a
												href="https://bit.ly/3LieVmn"
												style={{
													fontSize: "12px",
													color: "#000000",
													fontWeight: "300",
													textDecoration: "none",
												}}
											>
												<i>We're hiring!</i>
											</a>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div className="emailSignatureDescription">
						<h3>
							<i>
								Please copy and paste the signature above into
								your signature editor on Outlook ONLINE (the
								desktop app signature editor results in a blurry
								logo). Here's how to add a signature in Outlook
								Online:{" "}
								<a href="https://youtu.be/tFQCEozOwRU?si=k48u_X1EVicGswxF&t=35">
									Video
								</a>{" "}
								|{" "}
								<a href="https://support.microsoft.com/en-us/office/create-and-add-an-email-signature-in-outlook-com-or-outlook-on-the-web-776d9006-abdf-444e-b5b7-a61821dff034">
									Article
								</a>{" "}
							</i>
						</h3>
					</div>
				</>
			)}
		</div>
	);
};

export default EmailSignature;
