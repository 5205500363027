// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// CSS
import "./SubmitForReviewDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../../context/UserContext";

// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const SubmitForReviewDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Form Data
	const [
		specificationSubmittalDescription,
		setSpecificationSubmittalDescription,
	] = useState("");

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// NAVIGATE
	const navigate = useNavigate();

	// USE EFFECT

	// INPUT HANDLERS

	// Button Handlers
	const submitButtonClicked = async () => {
		if (!validateInputFields()) {
			return;
		}

		setIsLoading(true);

		await createPendingSpecChangeRecord();

		setIsLoading(false);

		formClosed();
	};

	// Feedback Handlers
	const openAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const closeAlert = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	const specificationSubmittalDescriptionChanged = (event) => {
		setSpecificationSubmittalDescription(event.target.value);
	};

	// API FUNCTIONS
	const createPendingSpecChangeRecord = async () => {
		try {
			if (!props.parentID) {
				openAlert(
					"Error locating the parent document. Please refresh the page and try again. If the issue persists, contact support."
				);
			}

			const parentRes = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/byID",
				{
					id: props.parentID,
				}
			);

			if (parentRes.status !== 200) {
				openAlert(
					"Error locating the parent document. Please refresh the page and try again. If the issue persists, contact support."
				);
				return;
			}

			const oldContent = parentRes.data[0].content;

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/specifications/pendingChanges/add",
				{
					specID: props.parentID ?? props.specID,
					description: specificationSubmittalDescription,
					oldContent: oldContent,
					newContent: props.newData,
					modifiedBy: employeeID,
				}
			);

			if (res.status !== 200) {
				openAlert("Error submitting for review. Please try again.");
				return;
			}

			navigate(`/specs/design_jobs/${props.jobID}`);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		closeAlert();
	};

	const validateInputFields = () => {
		if (specificationSubmittalDescription === "") {
			openAlert("Please describe your changes.");
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.handleClose();
	};

	// RENDER
	return (
		<Dialog
			id="submitForReviewDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="submitForReviewDialogTitle">
				Submit <b>{props.specName}</b> For Review
			</DialogTitle>
			<DialogContent id="submitForReviewDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="submitForReviewDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="submitForReviewDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="submitForReviewDialogAlert"
									severity="error"
									action={
										<IconButton
											id="submitForReviewDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="submitForReviewDialogAlertCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="submitForReviewDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="submitForReviewDialogDescriptionTextField"
								label="Please Describe Your Changes"
								variant="outlined"
								value={specificationSubmittalDescription}
								onChange={
									specificationSubmittalDescriptionChanged
								}
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default SubmitForReviewDialog;
