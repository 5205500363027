// REACT IMPORTS
import React, { useState, useEffect } from "react";

// CSS
import "./AccessLog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	employee: "Employee",
	tool: "Tool",
	time: "Time",
};

const searchDefaultValue = "employee";

// REACT COMPONENT
const AccessLog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// LOGS STATE
	const [accessLogs, setAccessLogs] = useState([]);
	const [filteredAccessLogs, setFilteredAccessLogs] = useState([]);

	const [accessLogsColumns, setAccessLogsColumns] = useState([]);
	const [accessLogsRows, setAccessLogsRows] = useState([]);

	// LOADING STATES
	const [isLoading, setIsLoading] = useState(false);

	// PAGINATION STATES
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// USE EFFECT
	useEffect(() => {
		fetchAccessLogs();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredAccessLogs]);

	// INPUT HANDLERS
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API HELPER FUNCTIONS
	const fetchAccessLogs = async () => {
		setIsLoading(true);

		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/logs/access/all"
			);
			setAccessLogs(response.data);
			setFilteredAccessLogs(response.data);

			setNumberOfPages(
				Math.ceil(response.data.length / RECORDS_PER_PAGE)
			);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	const filterAccessLogs = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredLogs = accessLogs.filter((logObj) => {
			if (searchByParameter === "employee") {
				return (logObj.employee_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "tool") {
				return (logObj.page_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "time") {
				return (logObj.date_time ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredAccessLogs(filteredLogs);
		setNumberOfPages(Math.ceil(filteredLogs.length / RECORDS_PER_PAGE));

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setAccessLogsColumns([
			{ text: "Employee" },
			{ text: "Time" },
			{ text: "Tool" },
			{ text: "Message" },
		]);
	};

	const createRowData = () => {
		setAccessLogsRows(
			filteredAccessLogs.map((log) => [
				{ text: log.employee_name },
				{ text: convertToPST(log.created) },
				{ text: log.page_name },
				{ text: log.notes },
			])
		);
	};

	// RENDER
	return (
		<div className="AccessLog">
			<div className="accessLogHeader">
				<div className="accessLogHeaderTitle">Access Logs</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterAccessLogs(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredAccessLogs.length === 0 && <J2NoResultsFound />}
					{filteredAccessLogs.length > 0 &&
						accessLogsColumns.length > 0 &&
						accessLogsRows.length > 0 && (
							<>
								<GenericTable
									columnData={accessLogsColumns}
									rowData={accessLogsRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
		</div>
	);
};

export default AccessLog;
