import React, { useState } from "react";

// CSS
import "./AssembliesSearchBar.css";

// MUI COMPONENTS
import {
	Autocomplete,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

// CONSTANTS
const searchByParameterMap = {
	assemblies: "Assemblies",
	buildingElement: "Building Element",
	description: "Description",
};

// REACT COMPONENT
const AssembliesSearchBar = (props) => {
	// STATES
	const [selectedAssemblies, setSelectedAssemblies] = useState([]);

	const [searchByParameter, setSearchByParameter] = useState("assemblies");

	// INPUT HANDLERS
	const assemblySelected = (event, newValue) => {
		setSelectedAssemblies([...newValue]);
		props.filterAssemblySearchBar([...newValue]);
	};

	const searchByParameterSelected = (event) => {
		setSearchByParameter(event.target.value);

		if (event.target.value !== "assemblies") {
			setSelectedAssemblies([]);

			setSearchByParameter("assemblies");
			props.assemblySearchBarUnselected();
		}
	};

	// RENDER
	return (
		<div className="AssembliesSearchBar">
			<div className="assembliesSearchBarFieldsContainer">
				<Autocomplete
					sx={{ flex: 1 }}
					limitTags={3}
					onChange={assemblySelected}
					multiple
					value={selectedAssemblies}
					id="assemblySelect"
					options={props.assemblies.filter(
						(assembly) =>
							!selectedAssemblies
								.map((a) => a.id)
								.includes(assembly.id)
					)}
					getOptionLabel={(option) => option.name}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							label="Assemblies"
							placeholder="Assemblies"
						/>
					)}
				/>
				<FormControl sx={{ minWidth: "10vw" }}>
					<InputLabel id="searchByLabel">Search By</InputLabel>
					<Select
						labelId="searchByLabel"
						value={searchByParameter}
						label="Search By"
						onChange={searchByParameterSelected}
					>
						{Object.keys(searchByParameterMap).map((key) => (
							<MenuItem value={key}>
								{searchByParameterMap[key]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};

export default AssembliesSearchBar;
