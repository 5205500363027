// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./CoreRepairScopesEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	deleteAPICall,
	postAPICall,
	putAPICall,
} from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
} from "@mui/material";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const CoreRepairScopeEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [buildingElement, setBuildingElement] = useState("");

	const [originalSelectedAssemblies, setOriginalSelectedAssemblies] =
		useState([]);
	const [selectedAssemblies, setSelectedAssemblies] = useState([]);

	const [description, setDescription] = useState("");
	const [replacementMaterial, setReplacementMaterial] = useState("");
	const [selectedSpecifications, setSelectedSpecifications] = useState([]);
	const [originalSelectedSpecifications, setOriginalSelectedSpecifications] =
		useState([]);

	const [coreRepairScopeSpecifications, setCoreRepairScopeSpecifications] =
		useState([]);
	const [allSpecifications, setAllSpecifications] = useState([]);
	const [coreRepairScopeAssemblies, setCoreRepairScopeAssemblies] = useState(
		[]
	);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			// if record to edit is passed in, set input fields to record values (this means we are editing)
			setBuildingElement(
				props.recordToEdit.building_type
					? props.recordToEdit.building_type
					: ""
			);
			setDescription(
				props.recordToEdit.description
					? props.recordToEdit.description
					: ""
			);
			setReplacementMaterial(
				props.recordToEdit.replacement_material
					? props.recordToEdit.replacement_material
					: ""
			);
			setCoreRepairScopeSpecifications(
				props.repairScopeSpecifications
					? props.repairScopeSpecifications
					: []
			);
			setCoreRepairScopeAssemblies(
				props.repairScopeAssemblies ? props.repairScopeAssemblies : []
			);

			setAllSpecifications(
				props.allSpecifications ? props.allSpecifications : []
			);

			// if editing, not making a new record
			if (props.editing) {
				// search through corerepairscopespecifications for core_repair_scope_id matching recordToEdit.id
				// add those to selectedSpecifications
				let selectedSpecs = [];
				props.repairScopeSpecifications.forEach((spec) => {
					if (spec.core_repair_scopes_id === props.recordToEdit.id) {
						// find spec from spec list with spec id and push
						let specification = props.allSpecifications.find(
							(s) => s.id === spec.spec_id
						);
						selectedSpecs.push(specification);
					}
				});
				setSelectedSpecifications(selectedSpecs);
				setOriginalSelectedSpecifications(selectedSpecs);

				// set original selected assemblies
				let selectedAssemblies = [];
				props.repairScopeAssemblies.forEach((rsa) => {
					if (rsa.core_repair_scopes_id === props.recordToEdit.id) {
						// find assembly from assembly list with assembly id and push
						let selectedAssembly = props.assemblies.find(
							(a) => a.id === rsa.assembly_id
						);
						selectedAssemblies.push(selectedAssembly);
					}
				});

				setOriginalSelectedAssemblies(selectedAssemblies);
				setSelectedAssemblies(selectedAssemblies);
			}
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	const buildingElementChanged = (event, newValue) => {
		setBuildingElement(newValue);
	};

	const assembliesChanged = (event, newValue) => {
		setSelectedAssemblies(newValue);
	};

	const descriptionChanged = (event) => {
		setDescription(event.target.value);
	};

	const replacementMaterialChanged = (event) => {
		setReplacementMaterial(event.target.value);
	};

	const coreRepairScopeSpecificationsChanged = (event, newValue) => {
		setSelectedSpecifications(newValue);
	};

	// API FUNCTIONS

	const handleUpdateRecord = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/update",
				{
					description: description,
					building_element_id:
						convertBuildingElementNameToID(buildingElement),
					replacement_material: replacementMaterial,
					modified_by: employeeID,
					id: props.recordToEdit.id,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
			props.setSnackbarMessage("Error updating core repair scope");
			props.setOpenSnackbar(true);
		}
	};

	const handleAddRecord = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/add",
				{
					description: description,
					building_element_id:
						convertBuildingElementNameToID(buildingElement),
					replacement_material: replacementMaterial,
					modified_by: employeeID,
				}
			);
			return res.data;
		} catch (error) {
			console.error(error);
			props.setSnackbarMessage("Error adding core repair scope");
			props.setOpenSnackbar(true);
		}
	};

	const handleAddCoreRepairScopeSpecification = async (
		specID,
		coreRepairScopeID
	) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/add/repairScopeSpecifications",
				{
					core_repair_scopes_id: coreRepairScopeID,
					spec_id: specID,
					modified_by: employeeID,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
			props.setSnackbarMessage(
				"Error adding core repair scope specification"
			);
			props.setOpenSnackbar(true);
		}
	};

	const handleRemoveCoreRepairScopeSpecification = async (
		repairScopeSpecificationID
	) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/delete/repairScopeSpecifications",
				{
					id: repairScopeSpecificationID,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
			props.setSnackbarMessage(
				"Error removing core repair scope specification"
			);
			props.setOpenSnackbar(true);
		}
	};

	const handleAddCoreRepairScopeAssembly = async (
		assemblyID,
		coreRepairScopeID
	) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/add/repairScopeAssemblies",
				{
					core_repair_scopes_id: coreRepairScopeID,
					assembly_id: assemblyID,
					modified_by: employeeID,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
			props.setSnackbarMessage("Error adding core repair scope assembly");
			props.setOpenSnackbar(true);
		}
	};

	const handleRemoveCoreRepairScopeAssembly = async (
		repairScopeAssemblyID
	) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/delete/repairScopeAssemblies",
				{
					id: repairScopeAssemblyID,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
			props.setSnackbarMessage(
				"Error removing core repair scope assembly"
			);
			props.setOpenSnackbar(true);
		}
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		// if editing, update record
		if (props.editing) {
			await handleUpdateRecord();
			// Remove all original specifications that are no longer selected
			let a = await handleSpecificationChangeOnSubmit();
			let b = await handleAssembliesChangedOnSubmit();
		} else {
			// else if not editing add record
			const newRecord = await handleAddRecord();
			selectedSpecifications.forEach(async (spec) => {
				await handleAddCoreRepairScopeSpecification(
					spec.id,
					newRecord.insertId
				);
			});
			selectedAssemblies.forEach(async (assm) => {
				await handleAddCoreRepairScopeAssembly(
					assm.id,
					newRecord.insertId
				);
			});
		}
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		await props.onClose(true);
	};

	const handleCancelClick = () => {
		resetInputFields();
		props.onClose(false);
	};

	const resetInputFields = () => {
		setBuildingElement("");
		setDescription("");
		setReplacementMaterial("");
		setSelectedSpecifications([]);
		setSelectedAssemblies([]);
	};

	const validateInputFields = () => {
		if (buildingElement === "") {
			setAlertOpen(true);
			setAlertMessage("Building Element is required.");
			return false;
		}
		if (description === "") {
			setAlertOpen(true);
			setAlertMessage("Description is required.");
			return false;
		}
		if (replacementMaterial === "") {
			setAlertOpen(true);
			setAlertMessage("Replacement Material is required.");
			return false;
		}
		// specifications can also be empty
		return true;
	};

	const convertBuildingElementNameToID = (name) => {
		return props.buildingElements.find(
			(element) => element.building_type === name
		).id;
	};

	const convertAssemblyNameToID = (name) => {
		return props.assemblies.find((assembly) => assembly.name === name).id;
	};

	const handleSpecificationChangeOnSubmit = async () => {
		// Remove all original specifications that are no longer selected
		const removePromises = originalSelectedSpecifications.map(
			async (spec) => {
				if (!selectedSpecifications.includes(spec)) {
					let repairScopeSpecification =
						coreRepairScopeSpecifications.find(
							(s) =>
								s.core_repair_scopes_id ===
									props.recordToEdit.id &&
								s.spec_id === spec.id
						);
					await handleRemoveCoreRepairScopeSpecification(
						repairScopeSpecification.id
					);
				}
			}
		);
		await Promise.all(removePromises);

		// Add all new specifications that were not in the original list
		const addPromises = selectedSpecifications.map(async (spec) => {
			if (!originalSelectedSpecifications.includes(spec)) {
				await handleAddCoreRepairScopeSpecification(
					spec.id,
					props.recordToEdit.id
				);
			}
		});
		await Promise.all(addPromises);
	};

	const handleAssembliesChangedOnSubmit = async () => {
		// Remove all original assemblies that are no longer selected
		const removePromises = originalSelectedAssemblies.map(async (assm) => {
			if (!selectedAssemblies.includes(assm)) {
				let repairScopeAssembly = coreRepairScopeAssemblies.find(
					(csa) =>
						csa.core_repair_scopes_id === props.recordToEdit.id &&
						csa.assembly_id === assm.id
				);
				await handleRemoveCoreRepairScopeAssembly(
					repairScopeAssembly.id
				);
			}
		});
		await Promise.all(removePromises);

		// Add all new assemblies that were not in the original list
		const addPromises = selectedAssemblies.map(async (assm) => {
			if (!originalSelectedAssemblies.includes(assm)) {
				await handleAddCoreRepairScopeAssembly(
					assm.id,
					props.recordToEdit.id
				);
			}
		});
		await Promise.all(addPromises);
	};
	// RENDER
	return (
		<Dialog
			id="CoreRepairScopeEditAddDialog"
			open={props.open}
			fullWidth
			onClose={handleCancelClick}
		>
			<DialogTitle id="CoreRepairScopeEditAddDialogTitle">
				{/* if editing, edit, if not, add */}
				{props.editing
					? "Edit Core Repair Scope"
					: "Add New Core Repair Scope"}
			</DialogTitle>
			{loading && (
				<div className="loadingSpinner">
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<DialogContent id="CoreRepairScopeEditAddDialogContent">
					{alertOpen && (
						<Alert
							severity="warning"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setAlertOpen(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					)}

					<FormControl sx={{ flex: 1 }}>
						<Autocomplete
							id="buildingElementSelector"
							options={props.buildingElements.map(
								(obj) => obj.building_type
							)}
							value={buildingElement}
							onChange={buildingElementChanged}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Building Element"
								/>
							)}
						/>
					</FormControl>
					<FormControl sx={{ flex: 1 }}>
						<Autocomplete
							id="assemblySelector"
							options={props.assemblies}
							getOptionLabel={(option) => option.name}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField
									{...params}
									label="Assemblies"
									placeholder="Assemblies"
								/>
							)}
							value={selectedAssemblies}
							onChange={assembliesChanged}
							multiple
							limitTags={2}
						/>
					</FormControl>
					<TextField
						id="description"
						label="Description"
						variant="outlined"
						value={description}
						onChange={descriptionChanged}
						fullWidth
					/>
					<TextField
						id="replacementMaterial"
						label="Replacement Material"
						variant="outlined"
						value={replacementMaterial}
						onChange={replacementMaterialChanged}
						fullWidth
					/>
					<FormControl sx={{ flex: 1 }}>
						<Autocomplete
							multiple
							id="coreRepairScopeSpecificationsSelector"
							autoComplete
							limitTags={2}
							options={allSpecifications}
							getOptionLabel={(option) => option.title}
							filterSelectedOptions
							isOptionEqualToValue={(option, value) => {
								return option.id === value.id;
							}}
							value={selectedSpecifications}
							renderOption={(props, option) => (
								<li {...props} key={option.id}>
									<div>
										<div className="addSpecificationRowOne">
											<span className="addSpecificationDivision">
												{option.division}
											</span>
											<span className="addSpecificationDash">
												{" "}
												-{" "}
											</span>
											<span className="addSpecificgitationTitle">
												{option.title}
											</span>
										</div>
										<div className="addSpecificationDescription">
											{option.description}
										</div>
									</div>
								</li>
							)}
							onChange={coreRepairScopeSpecificationsChanged}
							renderInput={(params) => (
								<TextField {...params} label="Specifications" />
							)}
						/>
					</FormControl>
					<div className="editCoreRepairScopeButtons">
						<Button
							variant="outlined"
							color="error"
							onClick={handleCancelClick}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitClick}
							disabled={loading}
						>
							Submit
						</Button>
					</div>
				</DialogContent>
			)}
		</Dialog>
	);
};

// EXPORT
export default CoreRepairScopeEditAddDialog;
