// REACT IMPORTS
import React, { useState, useEffect } from "react";

// CSS
import "./AutomationLog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { RECORDS_PER_PAGE, convertToPST } from "../../../constants";

const searchByParameterMap = {
	employee: "Employee",
	tool: "Tool",
	time: "Time",
};

const searchDefaultValue = "employee";

// REACT COMPONENT
const AutomationLog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// LOGS STATE
	const [automationLogs, setAutomationLogs] = useState([]);
	const [filteredAutomationLogs, setFilteredAutomationLogs] = useState([]);

	const [automationLogsColumns, setAutomationLogsColumns] = useState([]);
	const [automationLogsRows, setAutomationLogsRows] = useState([]);

	// LOADING STATES
	const [isLoading, setIsLoading] = useState(false);

	// PAGINATION STATES
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// USE EFFECT
	useEffect(() => {
		fetchAutomationLogs();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredAutomationLogs]);

	// INPUT HANDLERS
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API HELPER FUNCTIONS
	const fetchAutomationLogs = async () => {
		setIsLoading(true);

		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/logs/automation/all"
			);
			setAutomationLogs(response.data);
			setFilteredAutomationLogs(response.data);

			setNumberOfPages(
				Math.ceil(response.data.length / RECORDS_PER_PAGE)
			);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	const filterAutomationLogs = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredLogs = automationLogs.filter((logObj) => {
			if (searchByParameter === "employee") {
				return (logObj.employee_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "tool") {
				return (logObj.tool_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "time") {
				return (logObj.date_time ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredAutomationLogs(filteredLogs);
		setNumberOfPages(Math.ceil(filteredLogs.length / RECORDS_PER_PAGE));

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setAutomationLogsColumns([
			{ text: "Employee" },
			{ text: "Time" },
			{ text: "Tool" },
			{ text: "Message" },
		]);
	};

	const createRowData = () => {
		setAutomationLogsRows(
			filteredAutomationLogs.map((log) => [
				{ text: log.employee_name },
				{ text: convertToPST(log.created) },
				{ text: log.tool_name },
				{ text: log.message },
			])
		);
	};

	// RENDER
	return (
		<div className="AutomationLog">
			<div className="automationLogHeader">
				<div className="automationLogHeaderTitle">Automation Logs</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterAutomationLogs(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredAutomationLogs.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredAutomationLogs.length > 0 &&
						automationLogsColumns.length > 0 &&
						automationLogsRows.length > 0 && (
							<>
								<GenericTable
									columnData={automationLogsColumns}
									rowData={automationLogsRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
		</div>
	);
};

export default AutomationLog;
