// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./AddCommentDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	TextField,
	FormControlLabel,
	Checkbox,
	CircularProgress,
} from "@mui/material";

// CONSTANTS

// REACT COMPONENT
const AddCommentDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [ticketID, setTicketID] = useState(0);

	const [notifyUser, setNotifyUser] = useState(false);
	const [comment, setComment] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setTicketID(props.ticketID);
		}
	}, [props.open, props.ticketID]);

	// INPUT HANDLERS
	const notifyUserChanged = (e) => {
		setNotifyUser(e.target.checked);
	};

	const commentChanged = (e) => {
		setComment(e.target.value);
	};

	// BUTTONS
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;
		if (ticketID === 0) return;

		try {
			setIsLoading(true);

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/tickets/comments/add",
				{
					// notifyUser: notifyUser,
					ticketID: ticketID,
					comment: comment,
					submittedByID: employeeID,
					modifiedBy: employeeID,
				}
			);

			// email user that comment has been added
			if (notifyUser) {
				await postAPICall(
					instance,
					accounts[0],
					"/api/tickets/email/comment",
					{
						ticketID: ticketID,
						comment: comment,
					}
				);
			}

			setIsLoading(false);
			resetForm();

			props.fetchTickets();
			props.addCommentDialogClosed();
		} catch (error) {
			console.log("Error in Edit Ticket Dialog submit: ", error);
			setIsLoading(false);
		}
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		setTicketID(0);
		setComment("");
	};

	const validateInputFields = () => {
		if (!comment) {
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.addCommentDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="addCommentDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="addCommentDialogTitle">Add Comment</DialogTitle>
			<DialogContent id="addCommentDialogContent">
				{isLoading && (
					<div className="addCommentDialogRow">
						<CircularProgress />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="addCommentDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="comment"
								label="Comment"
								value={comment}
								onChange={commentChanged}
							/>
						</div>
						<div className="addCommentDialogRow">
							<FormControlLabel
								control={
									<Checkbox
										checked={notifyUser}
										onChange={notifyUserChanged}
									/>
								}
								labelPlacement="right"
								label="Notify User?"
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
						disabled={isLoading}
					>
						Cancel
					</Button>
					<Button
						onClick={submitButtonClicked}
						variant="contained"
						disabled={isLoading}
					>
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default AddCommentDialog;
