// DEPENDENCIES
import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";

// CSS
import "./PendingJobReviewScreen.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	putAPICall,
	postAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	IconButton,
	LinearProgress,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS
import GeneralInformationCard from "../../../Proposals/PendingJobs/GeneralInformationCard/GeneralInformationCard";
import DropboxInformationCard from "../../../Proposals/PendingJobs/DropboxInformationCard/DropboxInformationCard";
import BQEInformationCard from "../../../Proposals/PendingJobs/BQEInformationCard/BQEInformationCard";
import CompanyCamInformationCard from "../../../Proposals/PendingJobs/CompanyCamInformationCard/CompanyCamInformationCard";

// CONSTANTS
import { zipCodeRegex, getYear } from "../../../../constants";

// REACT COMPONENT
const PendingJobReviewScreen = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS
	const successMessageRef = useRef(null);
	const topScreenRef = useRef(null);

	// PARAMS
	const { id } = useParams();

	// STATES
	const [pendingJobRecord, setPendingJobRecord] = useState({});
	const [allPhases, setAllPhases] = useState([]);
	const [allComponents, setAllComponents] = useState([]);
	const [activeEmployees, setActiveEmployees] = useState([]);
	const [selectedComponents, setSelectedComponents] = useState([]);

	const [propertyInfo, setPropertyInfo] = useState({
		name: "",
		addressLine1: "",
		addressLine2: "",
		city: "",
		state: "",
		zip: "",
	});

	const [projectInfo, setProjectInfo] = useState({
		name: "",
		displayName: "",
		oic: "",
	});

	const [phaseBudgets, setPhaseBudgets] = useState({
		phase1: "0",
		phase2: "0",
		phase3: "0",
		phase4: "0",
	});

	const [phaseCostBases, setPhaseCostBases] = useState({
		phase1: "",
		phase2: "",
		phase3: "",
		phase4: "",
	});

	const [numberOfPhases, setNumberOfPhases] = useState(1);

	const [phase1, setPhase1] = useState({});
	const [phase2, setPhase2] = useState({});
	const [phase3, setPhase3] = useState({});
	const [phase4, setPhase4] = useState({});

	const [dealOwner, setDealOwner] = useState({});

	const [filePath, setFilePath] = useState("/Projects");
	const [folderCreationLevel, setFolderCreationLevel] = useState("");

	const [bqePropertyRecord, setBqePropertyRecord] = useState(null);
	const [bqeProjectRecordID, setBqeProjectRecordID] = useState(null);

	const [bqePropertyRecordCreateNew, setBqePropertyRecordCreateNew] =
		useState(false);
	const [bqeProjectRecordCreateNew, setBqeProjectRecordCreateNew] =
		useState(false);

	// Feedback
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [errorAlertOpen, setErrorAlertOpen] = useState(false);
	const [errorAlertMessage, setErrorAlertMessage] = useState("");

	const [successAlertOpen, setSuccessAlertOpen] = useState(false);
	const [successAlertMessage, setSuccessAlertMessage] = useState("");

	const [progressBarOpen, setProgressBarOpen] = useState(false);
	const [progressBarLevel, setProgressBarLevel] = useState(0);
	const [progressBarMessage, setProgressBarMessage] = useState("");

	// Validation
	const [isDropboxInformationValid, setIsDropboxInformationValid] =
		useState(false);
	const [isBqeInformationValid, setIsBqeInformationValid] = useState(false);

	const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchPendingJobRecord();
	}, []);

	// This useEffect is used to determine if there are any unsaved changes
	useEffect(() => {
		if (!pendingJobRecord.id) return;

		if (
			propertyInfo.name != pendingJobRecord.property_name ||
			propertyInfo.addressLine1 !=
				pendingJobRecord.property_address_line1 ||
			propertyInfo.addressLine2 !=
				pendingJobRecord.property_address_line2 ||
			propertyInfo.city != pendingJobRecord.property_city ||
			propertyInfo.state != pendingJobRecord.property_state ||
			propertyInfo.zip != pendingJobRecord.property_zip ||
			projectInfo.oic != pendingJobRecord.oic ||
			projectInfo.name != pendingJobRecord.project_name ||
			phase1.id != pendingJobRecord.phase1_id ||
			phase2.id != pendingJobRecord.phase2_id ||
			phase3.id != pendingJobRecord.phase3_id ||
			phase4.id != pendingJobRecord.phase4_id ||
			phaseBudgets.phase1 != pendingJobRecord.phase1_budget ||
			phaseBudgets.phase2 != pendingJobRecord.phase2_budget ||
			phaseBudgets.phase3 != pendingJobRecord.phase3_budget ||
			phaseBudgets.phase4 != pendingJobRecord.phase4_budget ||
			phaseCostBases.phase1 != pendingJobRecord.phase1_cost_basis ||
			phaseCostBases.phase2 != pendingJobRecord.phase2_cost_basis ||
			phaseCostBases.phase3 != pendingJobRecord.phase3_cost_basis ||
			phaseCostBases.phase4 != pendingJobRecord.phase4_cost_basis ||
			dealOwner.id != pendingJobRecord.deal_owner_id ||
			filePath != pendingJobRecord.dropbox_file_path ||
			(bqePropertyRecord &&
				bqePropertyRecord.id != pendingJobRecord.bqe_property_id) ||
			bqeProjectRecordID != pendingJobRecord.bqe_project_id
		) {
			setIsUnsavedChanges(true);
		} else {
			setIsUnsavedChanges(false);
		}
	}, [
		propertyInfo,
		projectInfo,
		phase1,
		phase2,
		phase3,
		phase4,
		phaseBudgets,
		phaseCostBases,
		dealOwner,
		filePath,
		bqePropertyRecord,
		bqeProjectRecordID,
	]);

	// INPUT HANDLERS
	// Buttons
	const saveButtonPressed = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/jobs/pending/save",
				{
					propertyName: propertyInfo.name.trim(),
					propertyAddressLine1: propertyInfo.addressLine1.trim(),
					propertyAddressLine2: propertyInfo.addressLine2.trim(),
					propertyCity: propertyInfo.city.trim(),
					propertyState: propertyInfo.state,
					propertyZip: propertyInfo.zip,
					oic: projectInfo.oic,
					phase1ID: phase1.id ?? null,
					phase2ID: phase2.id ?? null,
					phase3ID: phase3.id ?? null,
					phase4ID: phase4.id ?? null,
					phase1Budget: phaseBudgets.phase1,
					phase2Budget: phaseBudgets.phase2,
					phase3Budget: phaseBudgets.phase3,
					phase4Budget: phaseBudgets.phase4,
					phase1CostBasis: phaseCostBases.phase1 ?? null,
					phase2CostBasis: phaseCostBases.phase2 ?? null,
					phase3CostBasis: phaseCostBases.phase3 ?? null,
					phase4CostBasis: phaseCostBases.phase4 ?? null,
					dealOwnerID: dealOwner.id ?? null,
					dropboxFilePath: filePath ?? null,
					projectName: projectInfo.name ?? null,
					bqePropertyID:
						bqePropertyRecord && bqePropertyRecord.id
							? bqePropertyRecord.id
							: null,
					bqeProjectID: bqeProjectRecordID ?? null,
					bqePPCreateNew: bqePropertyRecordCreateNew ?? false,
					bqePRCreateNew: bqeProjectRecordCreateNew ?? false,
					id: pendingJobRecord.id,
				},
				employeeID
			);

			if (res.data && res.data.error) {
				openErrorAlert(res.data.error);
				return;
			}

			await fetchPendingJobRecord();

			openSuccessAlert("Pending Job Updated Successfully");
			return;
		} catch (err) {
			console.log(err);
		}
	};

	const submitButtonPressed = async () => {
		setIsSubmitted(true);
		closeSuccessAlert();
		closeErrorAlert();

		// Validate all information
		const generalValid = validateGeneralInformation();
		const dropboxValid = validateDropboxInformation();
		const bqeValid = validateBQEInformation();

		if (!generalValid || !dropboxValid || !bqeValid) {
			setIsSubmitted(false);
			return;
		}

		setProgressBarOpen(true);

		// Create all dropbox folders if necessary
		editProgressBar(0, "Creating Dropbox Folders");
		const dropboxCreated = await createDropboxFolders();
		if (!dropboxCreated) {
			return;
		}

		// Generate phase/project codes and create BQE records
		editProgressBar(40, "Creating BQE Records");
		const propertyCode = await generatePropertyCode();
		const projectCode = await generateProjectCode();
		const phase1Code = await generatePhaseCode();
		const phase2Code = await generatePhaseCode();
		const phase3Code = await generatePhaseCode();
		const phase4Code = await generatePhaseCode();

		const bqeCreated = await createBQERecords(
			propertyCode,
			projectCode,
			phase1Code,
			phase2Code,
			phase3Code,
			phase4Code
		);

		if (!bqeCreated) {
			return;
		}

		// Create Company Cam records
		editProgressBar(80, "Creating Company Cam Records");
		const ccCreated = await createCompanyCamProjects();

		if (!ccCreated) {
			return;
		}

		// Create property record in database
		editProgressBar(90, "Creating property record in database");
		const propertyDBID = await createPropertyRecord(
			bqeCreated.propertyID,
			propertyCode,
			dropboxCreated.propertyPath
		);

		if (!propertyDBID) {
			openErrorAlert("Failed to create property record in database.");
			return;
		}

		// Create project record in database
		editProgressBar(92, "Creating project record in database");
		const projectDBID = await createProjectRecord(
			bqeCreated.projectID,
			projectCode,
			dropboxCreated.projectPath,
			propertyDBID
		);

		if (!projectDBID) {
			openErrorAlert("Failed to create project record in database.");
			return;
		}

		editProgressBar(94, "Creating phase records in database");
		// Create phase records in database
		const phase1DBID = await createPhaseRecord(
			phase1,
			bqeCreated.phase1ID,
			phase1Code,
			dropboxCreated.phase1Path,
			ccCreated.phase1ID,
			propertyDBID,
			projectDBID
		);
		const phase2DBID = await createPhaseRecord(
			phase2,
			bqeCreated.phase2ID,
			phase2Code,
			dropboxCreated.phase2Path,
			ccCreated.phase2ID,
			propertyDBID,
			projectDBID
		);
		const phase3DBID = await createPhaseRecord(
			phase3,
			bqeCreated.phase3ID,
			phase3Code,
			dropboxCreated.phase3Path,
			ccCreated.phase3ID,
			propertyDBID,
			projectDBID
		);
		const phase4DBID = await createPhaseRecord(
			phase4,
			bqeCreated.phase4ID,
			phase4Code,
			dropboxCreated.phase4Path,
			ccCreated.phase4ID,
			propertyDBID,
			projectDBID
		);

		// Send a webhook to notify deal owner
		editProgressBar(95, "Notifying deal owner");
		const dealOwnerRes = await notifyDealOwner(
			propertyDBID,
			projectDBID,
			phase1DBID,
			phase2DBID,
			phase3DBID,
			phase4DBID
		);

		// Update pending job record
		editProgressBar(96, "Updating job record");
		const submittedUpdateRes = await pendingJobSubmitted();

		// Send a webhook to notify project coordinator
		editProgressBar(99, "Notifying project coordinator");
		await notifyProjectCoordinator(
			propertyDBID,
			projectDBID,
			phase1DBID,
			phase2DBID,
			phase3DBID,
			phase4DBID
		);

		openSuccessAlert("Pending Job Submitted Successfully");
	};

	// API FUNCTIONS
	const fetchPendingJobRecord = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/pending/byID",
				{
					id: id,
				}
			);

			setPendingJobRecord(res.data[0]);

			setPropertyInfo({
				name: res.data[0].property_name ?? "",
				addressLine1: res.data[0].property_address_line1 ?? "",
				addressLine2: res.data[0].property_address_line2 ?? "",
				city: res.data[0].property_city ?? "",
				state: res.data[0].property_state ?? "",
				zip: res.data[0].property_zip ?? "",
			});

			setProjectInfo((prevInfo) => ({
				...prevInfo,
				oic: res.data[0].oic ?? "",
			}));

			setFilePath(res.data[0].dropbox_file_path);

			if (res.data[0].bqe_property_id)
				await fetchBQEPropertyRecord(res.data[0].bqe_property_id);
			if (res.data[0].bqe_property_id && res.data[0].bqe_project_id)
				setBqeProjectRecordID(res.data[0].bqe_project_id);

			setBqePropertyRecordCreateNew(res.data[0].bqe_pp_create_new);
			setBqeProjectRecordCreateNew(res.data[0].bqe_pr_create_new);

			await fetchActiveEmployees();
			await fetchEmployeeRecord(res.data[0].deal_owner_id);
			await fetchPhaseRecords(
				res.data[0].phase1_id,
				res.data[0].phase2_id,
				res.data[0].phase3_id,
				res.data[0].phase4_id
			);
			await fetchComponentIDs(res.data[0].project_name);

			setPhaseCostBases({
				phase1: res.data[0].phase1_cost_basis ?? "",
				phase2: res.data[0].phase2_cost_basis ?? "",
				phase3: res.data[0].phase3_cost_basis ?? "",
				phase4: res.data[0].phase4_cost_basis ?? "",
			});

			setPhaseBudgets({
				phase1: res.data[0].phase1_budget ?? "0",
				phase2: res.data[0].phase2_budget ?? "0",
				phase3: res.data[0].phase3_budget ?? "0",
				phase4: res.data[0].phase4_budget ?? "0",
			});
		} catch (err) {
			console.log(err);
		}

		setIsLoading(false);
	};

	const fetchEmployeeRecord = async (id) => {
		if (!id) return;

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/byID",
				{
					id: id,
				}
			);

			setDealOwner(res.data[0]);

			return res.data[0];
		} catch (err) {
			console.log(err);
		}
	};

	const fetchActiveEmployees = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/all/validCompanyCam"
			);
			setActiveEmployees(res.data);

			return;
		} catch (err) {
			console.log(err);
		}
	};

	const fetchPhaseRecords = async (p1ID, p2ID, p3ID, p4ID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/phases/names/all"
			);
			setAllPhases(res.data);

			if (p1ID) {
				const phase1 = res.data.find((phase) => phase.id === p1ID);
				setPhase1(phase1);
				setNumberOfPhases(1);
			}

			if (p2ID) {
				const phase2 = res.data.find((phase) => phase.id === p2ID);
				setPhase2(phase2);
				setNumberOfPhases(2);
			}

			if (p3ID) {
				const phase3 = res.data.find((phase) => phase.id === p3ID);
				setPhase3(phase3);
				setNumberOfPhases(3);
			}

			if (p4ID) {
				const phase4 = res.data.find((phase) => phase.id === p4ID);
				setPhase4(phase4);
				setNumberOfPhases(4);
			}

			return;
		} catch (err) {
			console.log(err);
		}
	};

	const fetchComponentIDs = async (projectName) => {
		try {
			// fetch all components
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/components/all"
			);
			setAllComponents(res.data);

			// split project name into individual components
			const projectNames = projectName.split(", ");

			// find the component records for the project names
			var component1Record = null;
			var component2Record = null;

			var componentList = [];
			var abbreviatedComponentList = [];
			var selectedComponentsList = [];

			if (projectNames.length === 1) {
				component1Record = res.data.find(
					(component) => component.name === projectNames[0]
				);

				if (component1Record && component1Record.name) {
					componentList.push(component1Record.name);
					abbreviatedComponentList.push(
						component1Record.abbreviated_name
					);
					selectedComponentsList.push(component1Record);
				}
			}

			if (projectNames.length === 2) {
				component1Record = res.data.find(
					(component) => component.name === projectNames[0]
				);
				component2Record = res.data.find(
					(component) => component.name === projectNames[1]
				);

				if (component1Record && component1Record.name) {
					componentList.push(component1Record.name);
					abbreviatedComponentList.push(
						component1Record.abbreviated_name
					);
					selectedComponentsList.push(component1Record);
				}

				if (component2Record && component2Record.name) {
					componentList.push(component2Record.name);
					abbreviatedComponentList.push(
						component2Record.abbreviated_name
					);
					selectedComponentsList.push(component2Record);
				}
			}

			// set the component names
			setProjectInfo((prevInfo) => ({
				...prevInfo,
				name: componentList.join(", "),
				displayName: abbreviatedComponentList.join(", "),
			}));

			setSelectedComponents(selectedComponentsList);

			return;
		} catch (err) {
			console.log(err);
		}
	};

	const fetchBQEPropertyRecord = async (propertyID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/bqe/project/byID",
				{
					id: propertyID,
				}
			);

			setBqePropertyRecord(res.data);

			return;
		} catch (err) {
			console.log(err);
		}
	};

	// Dropbox
	const createDropboxFolders = async () => {
		try {
			// each project folder will have the year appended to the back of the name
			let year = getYear();

			switch (folderCreationLevel) {
				case "property":
					// PROPERTY LEVEL
					const propertyRes = await createPropertyFolder(
						`${filePath}/${propertyInfo.name}`
					);

					if (propertyRes.data && propertyRes.data.error) {
						if (propertyRes.data.errorResponse === "Conflict") {
							openErrorAlert(
								"Property folder already exists in Dropbox."
							);
							return false;
						}

						openErrorAlert(
							"Failed to create property folder in Dropbox."
						);
						return false;
					}

					// PROJECT LEVEL
					var projectPath = `${propertyRes.data.metadata.path_display}/${projectInfo.displayName} (${year})`;

					var projectRes = await createProjectFolder(projectPath);

					if (projectRes.data && projectRes.data.error) {
						if (projectRes.data.errorResponse === "Conflict") {
							openErrorAlert(
								"Project folder already exists in Dropbox."
							);
							return false;
						}

						openErrorAlert(
							"Failed to create project folder in Dropbox."
						);
						return false;
					}

					// PHASE LEVEL
					var phaseLevelRes = await createPhaseLevelFolders(
						projectPath
					);
					if (!phaseLevelRes) {
						return false;
					}

					var phaseRes = await createPhaseFolders(projectPath);

					if (phaseRes.data && phaseRes.data.error) {
						openErrorAlert(
							"Failed to create phase folders in Dropbox."
						);
						return false;
					}

					return {
						propertyPath: propertyRes.data.metadata.path_display,
						projectPath: projectRes.data.metadata.path_display,
						...phaseLevelRes,
					};

					break;
				case "project":
					var projectPath = filePath;

					// PROJECT LEVEL
					var projectRes = await createProjectFolder(
						`${projectPath}/${projectInfo.displayName} (${year})`
					);

					if (projectRes.data && projectRes.data.error) {
						if (projectRes.data.errorResponse === "Conflict") {
							openErrorAlert(
								"Project folder already exists in Dropbox."
							);
							return false;
						}

						openErrorAlert(
							"Failed to create project folder in Dropbox."
						);
						return false;
					}

					// PHASE LEVEL
					var phaseLevelRes = await createPhaseLevelFolders(
						`${projectPath}/${projectInfo.displayName} (${year})`
					);
					if (!phaseLevelRes) {
						return false;
					}

					var phaseRes = await createPhaseFolders(
						`${projectPath}/${projectInfo.displayName} (${year})`
					);

					if (phaseRes.data && phaseRes.data.error) {
						openErrorAlert(
							"Failed to create phase folders in Dropbox."
						);
						return false;
					}

					return {
						propertyPath: projectPath,
						projectPath: projectRes.data.metadata.path_display,
						...phaseLevelRes,
					};
				case "phase":
					var projectPath = filePath;

					// PHASE LEVEL
					var phaseLevelRes = await createPhaseLevelFolders(
						projectPath
					);
					if (!phaseLevelRes) {
						return false;
					}

					var phaseRes = await createPhaseFolders(`${projectPath}`);

					if (phaseRes.data && phaseRes.data.error) {
						openErrorAlert(
							"Failed to create phase folders in Dropbox."
						);
						return false;
					}

					return {
						propertyPath: projectPath
							.split("/")
							.slice(0, -1)
							.join("/"),
						projectPath: projectPath,
						...phaseLevelRes,
					};
				default:
					openErrorAlert("Could not determine project file path.");
					return false;
			}
		} catch (err) {
			console.log(err);
		}
	};

	const createPropertyFolder = async (path) => {
		editProgressBar(10, "Creating Property Folder");

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/dropbox/create/folder",
				{
					path: path,
				}
			);

			return res;
		} catch (err) {
			console.log(err);
		}
	};

	const createProjectFolder = async (path) => {
		editProgressBar(20, "Creating Project Folder");

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/dropbox/create/folder",
				{
					path: path,
				}
			);

			return res;
		} catch (err) {
			console.log(err);
		}
	};

	const createPhaseFolders = async (projectPath) => {
		// This uses a "batch create" API call to create all the phase folders at once
		try {
			editProgressBar(25, "Fetching Phase Folder Names");
			const generalNames = await fetchGeneralFolderNames(projectPath);
			const phase1Names = await fetchPhase1FolderNames(projectPath);
			const phase2Names = await fetchPhase2FolderNames(projectPath);
			const phase3Names = await fetchPhase3FolderNames(projectPath);
			const phase4Names = await fetchPhase4FolderNames(projectPath);

			const phaseFolderPaths = [
				...generalNames,
				...phase1Names,
				...phase2Names,
				...phase3Names,
				...phase4Names,
			];

			editProgressBar(30, "Creating Phase Folders");
			const subfolderLevelRes = await postAPICall(
				instance,
				accounts[0],
				"/api/dropbox/create/folder/batch",
				{
					paths: phaseFolderPaths,
				}
			);

			return subfolderLevelRes;
		} catch (err) {
			console.log(err);
		}
	};

	const createPhaseLevelFolders = async (projectPath) => {
		// These are the folders at the "phase level", (i.e. 1. General, 2. Phase 1, 3. Phase 2, etc.)
		editProgressBar(22, "Creating Phase level Folders");
		var phaseFolderNames = [];

		phaseFolderNames.push(`${projectPath}/1. General`);
		if (phase1 && phase1.id)
			phaseFolderNames.push(
				`${projectPath}/${phase1.steve_number}. ${phase1.abbreviated_name}`
			);
		if (phase2 && phase2.id)
			phaseFolderNames.push(
				`${projectPath}/${phase2.steve_number}. ${phase2.abbreviated_name}`
			);
		if (phase3 && phase3.id)
			phaseFolderNames.push(
				`${projectPath}/${phase3.steve_number}. ${phase3.abbreviated_name}`
			);
		if (phase4 && phase4.id)
			phaseFolderNames.push(
				`${projectPath}/${phase4.steve_number}. ${phase4.abbreviated_name}`
			);

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/dropbox/create/folder/batch",
				{
					paths: phaseFolderNames,
				}
			);

			if (res.data && res.data.error) {
				openErrorAlert(
					"Failed to create phase level folders in Dropbox."
				);
				return false;
			}

			return {
				phase1Path:
					phase1 && phase1.id
						? `${projectPath}/${phase1.steve_number}. ${phase1.abbreviated_name}`
						: null,
				phase2Path:
					phase2 && phase2.id
						? `${projectPath}/${phase2.steve_number}. ${phase2.abbreviated_name}`
						: null,
				phase3Path:
					phase3 && phase3.id
						? `${projectPath}/${phase3.steve_number}. ${phase3.abbreviated_name}`
						: null,
				phase4Path:
					phase4 && phase4.id
						? `${projectPath}/${phase4.steve_number}. ${phase4.abbreviated_name}`
						: null,
			};
		} catch (err) {
			console.log(err);
		}
	};

	const fetchGeneralFolderNames = async (projectPath) => {
		return [
			`${projectPath}/1. General/1.0 Photos`,
			`${projectPath}/1. General/1.1 Admin`,
			`${projectPath}/1. General/1.2 Docs In`,
		];
	};

	const fetchPhase1FolderNames = async (projectPath) => {
		if (!phase1 || !phase1.phase_type_id) return [];

		try {
			const folderListRes = await getAPICall(
				instance,
				accounts[0],
				"/api/dropbox/folders/byPhaseType",
				{
					phaseTypeID: phase1.phase_type_id,
				}
			);

			return folderListRes.data.map(
				(folder) =>
					`${projectPath}/${phase1.steve_number}. ${phase1.abbreviated_name}/${folder.folder_name}`
			);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchPhase2FolderNames = async (projectPath) => {
		if (!phase2 || !phase2.phase_type_id) return [];

		try {
			const folderListRes = await getAPICall(
				instance,
				accounts[0],
				"/api/dropbox/folders/byPhaseType",
				{
					phaseTypeID: phase2.phase_type_id,
				}
			);

			return folderListRes.data.map(
				(folder) =>
					`${projectPath}/${phase2.steve_number}. ${phase2.abbreviated_name}/${folder.folder_name}`
			);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchPhase3FolderNames = async (projectPath) => {
		if (!phase3 || !phase3.phase_type_id) return [];

		try {
			const folderListRes = await getAPICall(
				instance,
				accounts[0],
				"/api/dropbox/folders/byPhaseType",
				{
					phaseTypeID: phase3.phase_type_id,
				}
			);

			return folderListRes.data.map(
				(folder) =>
					`${projectPath}/${phase3.steve_number}. ${phase3.abbreviated_name}/${folder.folder_name}`
			);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchPhase4FolderNames = async (projectPath) => {
		if (!phase4 || !phase4.phase_type_id) return [];

		try {
			const folderListRes = await getAPICall(
				instance,
				accounts[0],
				"/api/dropbox/folders/byPhaseType",
				{
					phaseTypeID: phase4.phase_type_id,
				}
			);

			return folderListRes.data.map(
				(folder) =>
					`${projectPath}/${phase4.steve_number}. ${phase4.abbreviated_name}/${folder.folder_name}`
			);
		} catch (err) {
			console.log(err);
		}
	};

	// BQE
	const createBQERecords = async (
		propertyCode,
		projectCode,
		phase1Code,
		phase2Code,
		phase3Code,
		phase4Code
	) => {
		try {
			// BQE API requires a client to create a property, project, or phase
			const clientID = await determineBQEClientID();

			if (!clientID) {
				return false;
			}

			// Create property and store it's ID for project and phase creation
			const propertyID = await createBQEPropertyRecord(
				clientID,
				propertyCode
			);

			if (!propertyID) {
				openErrorAlert("Failed to create BQE property.");
				return false;
			}

			// Create project and store it's ID for phase creation
			const projectID = await createBQEProjectRecord(
				clientID,
				propertyID,
				projectCode
			);

			if (!projectID) {
				openErrorAlert("Failed to create BQE project.");
				return false;
			}

			// Create phase records
			const phaseRecordsCreated = await createBQEPhaseRecords(
				clientID,
				propertyID,
				projectID,
				phase1Code,
				phase2Code,
				phase3Code,
				phase4Code
			);

			if (!phaseRecordsCreated) {
				openErrorAlert("Failed to create BQE phases.");
				return false;
			}

			// Return all the IDs
			return {
				propertyID: propertyID,
				projectID: projectID,
				...phaseRecordsCreated,
			};
		} catch (err) {
			console.log(err);
		}
	};

	const searchBQEClient = async () => {
		// Check to see if there's already a client tied to the company name from the HS deal
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/bqe/client/byName",
				{
					clientName: pendingJobRecord.hs_company_name,
				}
			);

			return res.data.length > 0 ? res.data[0].id : null;
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEClient = async () => {
		// Create a client record in BQE
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/client/create",
				{
					clientName: pendingJobRecord.hs_company_name,
					addressLine1: pendingJobRecord.hs_company_address_line1,
					addressLine2: pendingJobRecord.hs_company_address_line2,
					city: pendingJobRecord.hs_company_city,
					state: pendingJobRecord.hs_company_state,
					zip: pendingJobRecord.hs_company_zip,
				}
			);

			if (!res.data || !res.data.id) return null;

			return res.data.id;
		} catch (err) {
			console.log(err);
		}
	};

	const determineBQEClientID = async () => {
		// Search for BQE client, if it doesn't exist, create it and return the ID
		try {
			editProgressBar(42, "Searching for BQE client");
			const clientExistsID = await searchBQEClient();

			if (clientExistsID) {
				return clientExistsID;
			}

			editProgressBar(45, "Creating BQE client");
			const newClientID = await createBQEClient();

			if (!newClientID) {
				openErrorAlert("Failed to create BQE client.");
				return null;
			}

			return newClientID;
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEPropertyRecord = async (clientID, propertyCode) => {
		// Create a new BQE property record (if it doesn't already exist) and return it's ID
		try {
			if (bqePropertyRecord && bqePropertyRecord.id)
				return bqePropertyRecord.id;

			editProgressBar(55, "Creating BQE property record");
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/property/create",
				{
					clientId: clientID,
					propertyCode: propertyCode,
					managerId: dealOwner.bqe_id,
					propertyName: propertyInfo.name,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					oic: pendingJobRecord.oic,
					classId: pendingJobRecord.bqe_state_class_code,
				}
			);

			return res.data && res.data.id ? res.data.id : null;
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEProjectRecord = async (
		clientID,
		propertyID,
		projectCode
	) => {
		// Create a new BQE project record (if it doesn't already exist) and return it's ID
		try {
			if (bqeProjectRecordID != null) return bqeProjectRecordID;

			editProgressBar(65, "Creating BQE project record");
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/project/create",
				{
					clientId: clientID,
					projectCode: projectCode,
					managerId: dealOwner.bqe_id,
					projectName: projectInfo.displayName,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					oic: pendingJobRecord.oic,
					classId: pendingJobRecord.bqe_state_class_code,
					parentId: propertyID,
					rootId: propertyID,
				}
			);

			return res.data && res.data.id ? res.data.id : null;
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEPhaseRecords = async (
		clientID,
		propertyID,
		projectID,
		phase1Code,
		phase2Code,
		phase3Code,
		phase4Code
	) => {
		// Create a new BQE phase record for each phase (if applicable) and return their IDs
		try {
			editProgressBar(70, "Creating BQE phase records");
			const phase1ID = await createBQEPhase1Record(
				clientID,
				propertyID,
				projectID,
				phase1Code
			);
			const phase2ID = await createBQEPhase2Record(
				clientID,
				propertyID,
				projectID,
				phase2Code
			);
			const phase3ID = await createBQEPhase3Record(
				clientID,
				propertyID,
				projectID,
				phase3Code
			);
			const phase4ID = await createBQEPhase4Record(
				clientID,
				propertyID,
				projectID,
				phase4Code
			);

			if (!phase1ID || !phase2ID || !phase3ID || !phase4ID) return false;

			return {
				phase1ID: phase1ID,
				phase2ID: phase2ID,
				phase3ID: phase3ID,
				phase4ID: phase4ID,
			};
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEPhase1Record = async (
		clientID,
		propertyID,
		projectID,
		phaseCode
	) => {
		// Create a new BQE phase record (if necessary), return it's ID, and associate an activity group. If phase 1 isn't requested, return 1.
		try {
			if (!phase1 || !phase1.id) return 1;

			editProgressBar(72, "Creating BQE phase 1 record");

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/phase/create",
				{
					clientId: clientID,
					phaseCode: phaseCode,
					managerId: dealOwner.bqe_id,
					phaseName:
						phaseCostBases.phase1 === "flatFee"
							? `FF ${phase1.abbreviated_name}`
							: phase1.abbreviated_name,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					oic: pendingJobRecord.oic,
					classId: pendingJobRecord.bqe_state_class_code,
					parentId: projectID,
					rootId: propertyID,
					contractAmount: phaseBudgets.phase1,
					contractType: phaseCostBases.phase1 === "flatFee" ? 1 : 0,
				}
			);

			if (res.data && res.data.id) {
				await associateActivityGroup(
					res.data.id,
					phase1.activity_group_id
				);
			}

			return res.data && res.data.id ? res.data.id : null;
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEPhase2Record = async (
		clientID,
		propertyID,
		projectID,
		phaseCode
	) => {
		// Create a new BQE phase record (if necessary), return it's ID, and associate an activity group. If phase 2 isn't requested, return 1.
		try {
			if (!phase2 || !phase2.id) return 1;

			editProgressBar(74, "Creating BQE phase 2 record");

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/phase/create",
				{
					clientId: clientID,
					phaseCode: phaseCode,
					managerId: dealOwner.bqe_id,
					phaseName:
						phaseCostBases.phase2 === "flatFee"
							? `FF ${phase2.abbreviated_name}`
							: phase2.abbreviated_name,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					oic: pendingJobRecord.oic,
					classId: pendingJobRecord.bqe_state_class_code,
					parentId: projectID,
					rootId: propertyID,
					contractAmount: phaseBudgets.phase2,
					contractType: phaseCostBases.phase2 === "flatFee" ? 1 : 0,
				}
			);

			if (res.data && res.data.id) {
				await associateActivityGroup(
					res.data.id,
					phase2.activity_group_id
				);
			}

			return res.data && res.data.id ? res.data.id : null;
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEPhase3Record = async (
		clientID,
		propertyID,
		projectID,
		phaseCode
	) => {
		// Create a new BQE phase record (if necessary), return it's ID, and associate an activity group. If phase 3 isn't requested
		try {
			if (!phase3 || !phase3.id) return 1;

			editProgressBar(76, "Creating BQE phase 3 record");

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/phase/create",
				{
					clientId: clientID,
					phaseCode: phaseCode,
					managerId: dealOwner.bqe_id,
					phaseName:
						phaseCostBases.phase3 === "flatFee"
							? `FF ${phase3.abbreviated_name}`
							: phase3.abbreviated_name,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					oic: pendingJobRecord.oic,
					classId: pendingJobRecord.bqe_state_class_code,
					parentId: projectID,
					rootId: propertyID,
					contractAmount: phaseBudgets.phase3,
					contractType: phaseCostBases.phase3 === "flatFee" ? 1 : 0,
				}
			);

			if (res.data && res.data.id) {
				await associateActivityGroup(
					res.data.id,
					phase3.activity_group_id
				);
			}

			return res.data && res.data.id ? res.data.id : null;
		} catch (err) {
			console.log(err);
		}
	};

	const createBQEPhase4Record = async (
		clientID,
		propertyID,
		projectID,
		phaseCode
	) => {
		// Create a new BQE phase record (if necessary), return it's ID, and associate an activity group. If phase 4 isn't requested
		try {
			if (!phase4 || !phase4.id) return 1;

			editProgressBar(78, "Creating BQE phase 4 record");

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/phase/create",
				{
					clientId: clientID,
					phaseCode: phaseCode,
					managerId: dealOwner.bqe_id,
					phaseName:
						phaseCostBases.phase4 === "flatFee"
							? `FF ${phase4.abbreviated_name}`
							: phase4.abbreviated_name,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					oic: pendingJobRecord.oic,
					classId: pendingJobRecord.bqe_state_class_code,
					parentId: projectID,
					rootId: propertyID,
					contractAmount: phaseBudgets.phase4,
					contractType: phaseCostBases.phase4 === "flatFee" ? 1 : 0,
				}
			);

			if (res.data && res.data.id) {
				await associateActivityGroup(
					res.data.id,
					phase4.activity_group_id
				);
			}

			return res.data && res.data.id ? res.data.id : null;
		} catch (err) {
			console.log(err);
		}
	};

	const associateActivityGroup = async (phaseID, groupID) => {
		// Associate an activity group with a phase
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/bqe/phase/assignActivityGroup",
				{
					phaseID: phaseID,
					activityGroupID: groupID,
				}
			);

			return res;
		} catch (err) {
			console.log(err);
		}
	};

	// Company Cam
	const createCompanyCamProjects = async () => {
		// Create a Company Cam project for each phase and return their IDs

		editProgressBar(82, "Creating Phase 1 Company Cam Project");
		const phase1Res = await createCompanyCamProject(phase1);
		if (!phase1Res || phase1Res.error) {
			openErrorAlert("Failed to create Company Cam project for Phase 1.");
			return false;
		}

		editProgressBar(84, "Creating Phase 2 Company Cam Project");
		const phase2Res = await createCompanyCamProject(phase2);
		if (!phase2Res || phase2Res.error) {
			openErrorAlert("Failed to create Company Cam project for Phase 2.");
			return false;
		}

		editProgressBar(86, "Creating Phase 3 Company Cam Project");
		const phase3Res = await createCompanyCamProject(phase3);
		if (!phase3Res || phase3Res.error) {
			openErrorAlert("Failed to create Company Cam project for Phase 3.");
			return false;
		}

		editProgressBar(88, "Creating Phase 4 Company Cam Project");
		const phase4Res = await createCompanyCamProject(phase4);
		if (!phase4Res || phase4Res.error) {
			openErrorAlert("Failed to create Company Cam project for Phase 4.");
			return false;
		}

		return {
			phase1ID: phase1Res !== 1 ? phase1Res.data.company_cam_id : null,
			phase2ID: phase2Res !== 1 ? phase2Res.data.company_cam_id : null,
			phase3ID: phase3Res !== 1 ? phase3Res.data.company_cam_id : null,
			phase4ID: phase4Res !== 1 ? phase4Res.data.company_cam_id : null,
		};
	};

	const createCompanyCamProject = async (phase) => {
		// Create a new Company Cam project record (if necessary) and return it's ID
		if (!phase || !phase.id) return 1;

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/companyCam/project/create",
				{
					ccProjectName: `${propertyInfo.name}: ${projectInfo.displayName}: ${phase.abbreviated_name}`,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					contactName: pendingJobRecord.contact_name,
					contactEmail: pendingJobRecord.contact_email,
					dealOwnerCCID: dealOwner.company_cam_id,
					phaseType: phase.phase_type,
					phaseName: phase.abbreviated_name,
					oic: projectInfo.oic,
				}
			);

			return res;
		} catch (err) {
			console.log(err);
			return;
		}
	};

	// MySQL
	const createPropertyRecord = async (bqeID, bqeCode, filePath) => {
		// Create a new property record in the database (if it doesn't already exist) and return it's ID
		try {
			// SEARCH EXISTING
			const searchRes = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/property/byName",
				{
					name: propertyInfo.name,
				}
			);

			if (searchRes.data && searchRes.data.length > 0) {
				console.log("Property already exists");
				return searchRes.data[0].id;
			}

			// CREATE
			const creationRes = await postAPICall(
				instance,
				accounts[0],
				"/api/jobs/property/create",
				{
					name: propertyInfo.name,
					addressLine1: propertyInfo.addressLine1,
					addressLine2: propertyInfo.addressLine2,
					city: propertyInfo.city,
					state: propertyInfo.state,
					zip: propertyInfo.zip,
					dropboxFilePath: filePath,
					bqeID: bqeID,
					bqeCode: bqeCode,
					oic: projectInfo.oic,
				},
				employeeID
			);

			return creationRes.data && creationRes.data.insertId
				? creationRes.data.insertId
				: null;
		} catch (err) {
			console.log(err);
		}
	};

	const createProjectRecord = async (
		bqeID,
		bqeCode,
		filePath,
		propertyID
	) => {
		// Create a new project record in the database (if it doesn't already exist) and return it's ID
		try {
			// SEARCH EXISTING
			const searchRes = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/project/byName",
				{
					name: projectInfo.name,
					propertyID: propertyID,
				}
			);

			if (searchRes.data && searchRes.data.length > 0) {
				console.log("Project already exists");
				return searchRes.data[0].id;
			}

			// CREATE
			const creationRes = await postAPICall(
				instance,
				accounts[0],
				"/api/jobs/project/create",
				{
					name: projectInfo.name,
					displayName: projectInfo.displayName,
					dropboxPath: filePath,
					bqeID: bqeID,
					bqeCode: bqeCode,
					hubspotID: pendingJobRecord.hs_deal_id,
					quoteID: pendingJobRecord.hs_quote_id,
					propertyID: propertyID,
					ownerID: dealOwner.id,
				},
				employeeID
			);

			return creationRes.data && creationRes.data.insertId
				? creationRes.data.insertId
				: null;
		} catch (err) {
			console.log(err);
		}
	};

	const createPhaseRecord = async (
		phase,
		bqeID,
		bqeCode,
		filePath,
		companyCamID,
		propertyID,
		projectID
	) => {
		// Create a new phase record in the database (if it doesn't already exist) and return it's ID
		if (!phase || !phase.id) return null;

		try {
			// SEARCH EXISTING
			const searchRes = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/phase/byName",
				{
					name: phase.name,
					projectID: projectID,
				}
			);

			if (searchRes.data && searchRes.data.length > 0) {
				console.log("Phase already exists");
				return searchRes.data[0].id;
			}

			// CREATE
			const creationRes = await postAPICall(
				instance,
				accounts[0],
				"/api/jobs/phase/create",
				{
					name: phase.name,
					dropboxPath: filePath,
					bqeID: bqeID,
					bqeCode: bqeCode,
					hubspotID: pendingJobRecord.hs_deal_id,
					companyCamID: companyCamID,
					propertyID: propertyID,
					projectID: projectID,
					ownerID: dealOwner.id,
					phaseTypeID: phase.phase_type_id,
				},
				employeeID
			);

			if (!creationRes.data || creationRes.data.error) {
				openErrorAlert(
					`Failed to create phase record for ${phase.name}.`
				);
				return null;
			}

			return creationRes.data.insertId;
		} catch (err) {
			console.log(err);
		}
	};

	// Various
	const notifyDealOwner = async (
		propertyID,
		projectID,
		phase1ID,
		phase2ID,
		phase3ID,
		phase4ID
	) => {
		// Send a webhook to a Make Scenario which notifies the deal owner
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/outlook/pendingJob/emailDealOwner",
				{
					propertyID: propertyID,
					projectID: projectID,
					phase1ID: phase1ID,
					phase2ID: phase2ID,
					phase3ID: phase3ID,
					phase4ID: phase4ID,
				}
			);

			return res;
		} catch (err) {
			console.log(err);
		}
	};

	const pendingJobSubmitted = async () => {
		// Update the pending job record to submitted
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/jobs/pending/submitted",
				{
					submittedByID: employeeID,
					id: pendingJobRecord.id,
				},
				employeeID
			);

			return res;
		} catch (err) {
			console.log(err);
		}
	};

	const notifyProjectCoordinator = async (
		propertyID,
		projectID,
		phase1ID,
		phase2ID,
		phase3ID,
		phase4ID
	) => {
		// This function notifies the project coordinator to manually complete any tasks that the automation failed on
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/outlook/pendingJob/emailProjectCoordinator",
				{
					propertyID: propertyID,
					projectID: projectID,
					phase1ID: phase1ID,
					phase2ID: phase2ID,
					phase3ID: phase3ID,
					phase4ID: phase4ID,
					submittedBy: employeeID,
				}
			);

			return res;
		} catch (err) {
			console.log(err);
		}
	};

	// HELPER FUNCTIONS
	// Validate Information
	const validateGeneralInformation = () => {
		// Property Information
		if (
			!propertyInfo.name ||
			!propertyInfo.addressLine1 ||
			!propertyInfo.city ||
			!propertyInfo.state ||
			!propertyInfo.zip.match(zipCodeRegex)
		) {
			openErrorAlert(
				"Please ensure all property information is filled out correctly."
			);
			return false;
		}

		// Project Information
		if (
			!projectInfo.name ||
			!projectInfo.displayName ||
			!projectInfo.oic ||
			!selectedComponents.length
		) {
			openErrorAlert(
				"Please ensure all project information is filled out correctly."
			);
			return false;
		}

		// Phase Information
		if (!phase1 || !phaseBudgets.phase1 || !phaseCostBases.phase1) {
			openErrorAlert(
				"Please ensure all phase information for Phase 1 is filled out correctly."
			);
			return false;
		}

		// Checks to see if phase 2 info needs to be filled out
		if (
			numberOfPhases >= 2 &&
			(!phase2 || !phaseBudgets.phase2 || !phaseCostBases.phase2)
		) {
			openErrorAlert(
				"Please ensure all phase information for Phase 2 is filled out correctly."
			);
			return false;
		}

		// Checks to see if phase 3 info needs to be filled out
		if (
			numberOfPhases >= 3 &&
			(!phase3 || !phaseBudgets.phase3 || !phaseCostBases.phase3)
		) {
			openErrorAlert(
				"Please ensure all phase information for Phase 3 is filled out correctly."
			);
			return false;
		}

		// Checks to see if phase 4 info needs to be filled out
		if (
			numberOfPhases >= 4 &&
			(!phase4 || !phaseBudgets.phase4 || !phaseCostBases.phase4)
		) {
			openErrorAlert(
				"Please ensure all phase information for Phase 4 is filled out correctly."
			);
			return false;
		}

		// Deal Owner Information
		if (!dealOwner.id) {
			openErrorAlert("Please ensure the deal owner is selected.");
			return false;
		}

		return true;
	};

	const validateDropboxInformation = () => {
		if (!isDropboxInformationValid) {
			openErrorAlert(
				"Please ensure the Dropbox information is filled out correctly."
			);
			return false;
		}

		return true;
	};

	const validateBQEInformation = () => {
		if (!isBqeInformationValid) {
			openErrorAlert(
				"Please ensure the BQE information is filled out correctly."
			);
			return false;
		}

		return true;
	};

	// Feedback methods
	const openErrorAlert = (message) => {
		setErrorAlertMessage(message);
		setErrorAlertOpen(true);

		// scroll to top
		topScreenRef.current.scrollIntoView({ behavior: "smooth" });

		setProgressBarLevel(0);
		setProgressBarMessage("");
		setProgressBarOpen(false);
	};

	const closeErrorAlert = () => {
		setErrorAlertMessage("");
		setErrorAlertOpen(false);
	};

	const openSuccessAlert = (message) => {
		setSuccessAlertMessage(message);
		setSuccessAlertOpen(true);

		// scroll to top
		topScreenRef.current.scrollIntoView({ behavior: "smooth" });

		setProgressBarLevel(0);
		setProgressBarMessage("");
		setProgressBarOpen(false);
	};

	const closeSuccessAlert = () => {
		setSuccessAlertMessage("");
		setSuccessAlertOpen(false);
	};

	const editProgressBar = (level, message) => {
		setProgressBarLevel(level);
		setProgressBarMessage(message);
	};

	// RNG methods
	const generatePropertyCode = async () => {
		// This generates a unique property code (for BQE), if it fails to generate a unique code after 10 tries, it will return null
		// Code format: PP-XXXXX (5 uppercase pseudorandom alphanumeric characters)
		try {
			var code = generateAlphanumericCode();
			var propertyCode = `PP-${code}`;

			var count = 0;
			while (count < 10) {
				// Try 10 times to generate a unique property code
				const res = await getAPICall(
					instance,
					accounts[0],
					"/api/jobs/property/byBQECode",
					{
						bqeCode: propertyCode,
					}
				);

				if (res.data.length === 0) {
					return propertyCode;
				}

				code = generateAlphanumericCode();
				propertyCode = `PP-${code}`;
				count++;
			}

			openErrorAlert("Failed to generate a unique property code.");
			return null;
		} catch (err) {
			openErrorAlert("Failed to generate a unique property code.");
			console.log(err);
		}
	};

	const generateProjectCode = async () => {
		// This generates a unique project code (for BQE), if it fails to generate a unique code after 10 tries, it will return null
		// Code format: PJ-XXXXX (5 uppercase pseudorandom alphanumeric characters)
		try {
			var code = generateAlphanumericCode();
			var projectCode = `PJ-${code}`;

			var count = 0;
			while (count < 10) {
				// Try 10 times to generate a unique project code
				const res = await getAPICall(
					instance,
					accounts[0],
					"/api/jobs/project/byBQECode",
					{
						bqeCode: projectCode,
					}
				);

				if (res.data.length === 0) {
					return projectCode;
				}

				code = generateAlphanumericCode();
				projectCode = `PJ-${code}`;
				count++;
			}

			openErrorAlert("Failed to generate a unique project code.");
			return null;
		} catch (err) {
			openErrorAlert("Failed to generate a unique project code.");
			console.log(err);
		}
	};

	const generatePhaseCode = async () => {
		// This generates a unique phase code (for BQE), if it fails to generate a unique code after 10 tries, it will return null
		// Code format: PH-XXXXX (5 uppercase pseudorandom alphanumeric characters)
		try {
			var code = generateAlphanumericCode();
			var phaseCode = `PH-${code}`;

			var count = 0;
			while (count < 10) {
				// Try 10 times to generate a unique phase code
				const res = await getAPICall(
					instance,
					accounts[0],
					"/api/jobs/phase/byBQECode",
					{
						bqeCode: phaseCode,
					}
				);

				if (res.data.length === 0) {
					return phaseCode;
				}

				code = generateAlphanumericCode();
				phaseCode = `PH-${code}`;
				count++;
			}

			openErrorAlert("Failed to generate a unique phase code.");
			return null;
		} catch (err) {
			openErrorAlert("Failed to generate a unique phase code.");
			console.log(err);
		}
	};

	const generateAlphanumericCode = () => {
		// Generates a 5-character alphanumeric code, generates an integer then converts to ASCII
		var code = "";

		for (let i = 0; i < 5; i++) {
			// Randomly choose between a letter or a number for the next character
			const letterOrNumber = Math.random();

			if (letterOrNumber < 0.5) {
				// Letter
				// A-Z : 65-90 (ASCII)
				const min = 65;
				const max = 90 + 1;

				code += String.fromCharCode(
					Math.floor(Math.random() * (max - min) + min)
				);
			} else {
				// Number
				// 0-9 : 48-57 (ASCII)
				const min = 48;
				const max = 57 + 1;

				code += String.fromCharCode(
					Math.floor(Math.random() * (max - min) + min)
				);
			}
		}

		return code;
	};

	// RENDER (ALREADY SUBMITTED)
	if (!isLoading && pendingJobRecord.is_submitted) {
		return (
			<div className="PendingJobReviewScreen" ref={topScreenRef}>
				<div className="pendingJobReviewScreenHeader">
					{pendingJobRecord.property_name}
				</div>
				<div className="pendingJobAlreadySubmittedMessage">
					This job has already been submitted.
				</div>
			</div>
		);
	}

	// RENDER (NOT SUBMITTED)
	return (
		<div className="PendingJobReviewScreen" ref={topScreenRef}>
			<div className="pendingJobReviewScreenHeader">
				{pendingJobRecord.property_name}
			</div>
			{isLoading && (
				<div className="pendingJobLoadingSpinner">
					<CircularProgress />
				</div>
			)}
			{!isLoading && (
				<>
					<Collapse
						sx={{ width: "stretch" }}
						id="pendingJobAlertCollapse"
						in={successAlertOpen}
					>
						<div
							className="pendingJobAlertCollapseContainer"
							ref={successMessageRef}
						>
							<Alert
								sx={{ width: "stretch" }}
								id="pendingJobSuccessAlert"
								severity="success"
								action={
									<IconButton
										id="pendingJobSuccessAlertCloseIconButton"
										aria-label="close"
										color="inherit"
										size="small"
										onClick={closeSuccessAlert}
									>
										<CloseIcon
											id="pendingJobSuccessAlertCloseIcon"
											fontSize="inherit"
										/>
									</IconButton>
								}
							>
								{successAlertMessage}
							</Alert>
						</div>
					</Collapse>
					<Collapse
						sx={{ width: "stretch" }}
						id="pendingJobAlertCollapse"
						in={errorAlertOpen}
					>
						<div className="pendingJobAlertCollapseContainer">
							<Alert
								sx={{ width: "stretch" }}
								id="pendingJobErrorAlert"
								severity="error"
								action={
									<IconButton
										id="pendingJobErrorAlertCloseIconButton"
										aria-label="close"
										color="inherit"
										size="small"
										onClick={closeErrorAlert}
									>
										<CloseIcon
											id="pendingJobErrorAlertCloseIcon"
											fontSize="inherit"
										/>
									</IconButton>
								}
							>
								{errorAlertMessage}
							</Alert>
						</div>
					</Collapse>
					<div className="jobCardContainer">
						<GeneralInformationCard
							allComponents={allComponents}
							allPhases={allPhases}
							activeEmployees={activeEmployees}
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
							projectInfo={projectInfo}
							setProjectInfo={setProjectInfo}
							dealOwner={dealOwner}
							setDealOwner={setDealOwner}
							selectedComponents={selectedComponents}
							setSelectedComponents={setSelectedComponents}
							numberOfPhases={numberOfPhases}
							setNumberOfPhases={setNumberOfPhases}
							phase1={phase1}
							phase2={phase2}
							phase3={phase3}
							phase4={phase4}
							setPhase1={setPhase1}
							setPhase2={setPhase2}
							setPhase3={setPhase3}
							setPhase4={setPhase4}
							phaseCostBases={phaseCostBases}
							setPhaseCostBases={setPhaseCostBases}
							phaseBudgets={phaseBudgets}
							setPhaseBudgets={setPhaseBudgets}
						/>
						<DropboxInformationCard
							filePath={filePath}
							setFilePath={setFilePath}
							propertyInfo={propertyInfo}
							projectInfo={projectInfo}
							folderCreationLevel={folderCreationLevel}
							setFolderCreationLevel={setFolderCreationLevel}
							setIsDropboxInformationValid={
								setIsDropboxInformationValid
							}
						/>
						<BQEInformationCard
							propertyInfo={propertyInfo}
							projectInfo={projectInfo}
							bqePropertyRecord={bqePropertyRecord}
							setBqePropertyRecord={setBqePropertyRecord}
							bqeProjectRecordID={bqeProjectRecordID}
							setBqeProjectRecordID={setBqeProjectRecordID}
							dealOwner={
								dealOwner &&
								dealOwner.first_name &&
								dealOwner.last_name
									? `${dealOwner.first_name} ${dealOwner.last_name}`
									: ""
							}
							isBqeInformationValid={isBqeInformationValid}
							setIsBqeInformationValid={setIsBqeInformationValid}
							bqePropertyRecordCreateNew={
								bqePropertyRecordCreateNew
							}
							setBqePropertyRecordCreateNew={
								setBqePropertyRecordCreateNew
							}
							bqeProjectRecordCreateNew={
								bqeProjectRecordCreateNew
							}
							setBqeProjectRecordCreateNew={
								setBqeProjectRecordCreateNew
							}
						/>
						<CompanyCamInformationCard
							propertyName={propertyInfo.name}
							projectDisplayName={projectInfo.displayName}
							phase1={phase1.abbreviated_name ?? ""}
							phase2={phase2.abbreviated_name ?? ""}
							phase3={phase3.abbreviated_name ?? ""}
							phase4={phase4.abbreviated_name ?? ""}
						/>
					</div>
					<Collapse
						sx={{ width: "stretch" }}
						id="progressBarCollapse"
						in={progressBarOpen}
					>
						<div className="progressBarCollapseContainer">
							<h2>{progressBarMessage}</h2>
							<LinearProgress
								sx={{ height: 10, borderRadius: 5 }}
								id="pendingJobProgressBar"
								variant="determinate"
								value={progressBarLevel}
							/>
						</div>
					</Collapse>
					<Collapse
						sx={{ width: "stretch" }}
						id="pendingJobAlertCollapse"
						in={isUnsavedChanges}
					>
						<div className="pendingJobAlertCollapseContainer">
							<Alert
								sx={{ width: "stretch" }}
								id="pendingJobWarningAlert"
								severity="warning"
							>
								You have unsaved changes. Please save before
								submitting.
							</Alert>
						</div>
					</Collapse>
					<div className="pendingJobsButtons">
						<Button
							variant="outlined"
							color="primary"
							onClick={saveButtonPressed}
						>
							Save
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={submitButtonPressed}
							disabled={isUnsavedChanges || isSubmitted}
						>
							Submit
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default PendingJobReviewScreen;
