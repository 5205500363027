// DEPENDENCIES
import React, { useState } from "react";

// CSS
import "./InformationCard.css";

// MUI COMPONENTS
import { IconButton, Button, Collapse, Chip, Tooltip } from "@mui/material";

import { Info as InfoIcon, Edit as EditIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS
import SpecComment from "../CommentsCard/SpecComment/SpecComment";
import EditSpecInformationDialog from "./EditSpecInformationDialog/EditSpecInformationDialog";

// CONSTANTS
import { defaultBlue, convertToPST } from "../../../../../constants";

// FUNCTIONAL COMPONENT
const InformationCard = (props) => {
	// STATES
	const [editInformationOpen, setEditInformationOpen] = useState(false);

	// INPUT HANDLERS
	const editInformationCardClicked = () => {
		setEditInformationOpen(true);
	};

	// RETURN
	return (
		<div className="specDetailScreenListCard">
			<div className="specDetailScreenListCardHeader">
				<div
					className="specDetailScreenListCardHeaderIconTitleContainer"
					onClick={props.informationDropdownClicked}
				>
					<IconButton sx={{ color: defaultBlue }}>
						<InfoIcon sx={{ fontSize: 32 }} />
					</IconButton>
					<h2 className="specDetailScreenListCardHeaderTitle">
						Information
					</h2>
				</div>
				{props.informationOpen ? (
					props.isAdmin ? (
						<div className="specDetailScreenListCardHeaderEditButtonContainer">
							<Button
								variant="contained"
								color="primary"
								startIcon={<EditIcon />}
								onClick={editInformationCardClicked}
							>
								Edit
							</Button>
						</div>
					) : (
						<div className="SpecDetailScreenInfoCardButtonGroup">
							<Tooltip title="Only Admin users can edit standard specs.">
								<Chip label="View Only" />
							</Tooltip>
						</div>
					)
				) : null}
				{/* {props.informationOpen && props.isAdmin ? (
					<div className="specDetailScreenListCardHeaderEditButtonContainer">
						<Button
							variant="contained"
							color="primary"
							startIcon={<EditIcon />}
							onClick={editInformationCardClicked}
						>
							Edit
						</Button>
					</div>
				) : (
					<div className="SpecDetailScreenInfoCardButtonGroup">
						<Tooltip title="Only Admin users can edit standard specs.">
							<Chip label="View Only" />
						</Tooltip>
					</div>
				)} */}
			</div>
			<Collapse
				in={props.informationOpen}
				id="informationDropdownCollapse"
				timeout="auto"
				unmountOnExit
			>
				<div className="informationDropdownContent">
					<div className="informationDropdownContentRow">
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Title
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.specRecord.title}
							</div>
						</div>
					</div>
					<div className="informationDropdownContentRow">
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Description
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.specRecord.description !== "" ? (
									props.specRecord.description
								) : (
									<i>No description</i>
								)}
							</div>
						</div>
					</div>
					<div className="informationDropdownContentRow">
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Division
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.specRecord.division}
							</div>
						</div>
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Created Date
							</div>
							<div className="informationDropdownContentRowItemValue">
								{convertToPST(props.specRecord.created)}
							</div>
						</div>
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Status
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.specRecord.status_name}
							</div>
						</div>
					</div>
					<div className="informationDropdownContentRow">
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Recent Comments
							</div>
							<div className="recentCommentsContainer">
								{props.comments.length === 0 && (
									<div className="noCommentsText">
										<i>No comments found</i>
									</div>
								)}
								{props.comments.map((comment, index) => {
									return (
										<SpecComment
											key={index}
											comment={comment}
											showBottomBorder={
												index !==
												props.comments.length - 1
											}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</Collapse>
			<EditSpecInformationDialog
				open={editInformationOpen}
				editSpecInformationDialogClosed={() =>
					setEditInformationOpen(false)
				}
				specRecord={props.specRecord}
				fetchSpecRecord={props.fetchSpecRecord}
				statuses={props.statuses}
			/>
		</div>
	);
};

export default InformationCard;
