// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./EditJobTitlesDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { deleteAPICall, putAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@mui/material";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const EditJobTitlesDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	const [isLoading, setIsLoading] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);

	const [department, setDepartment] = useState("");
	const [jobTitleName, setJobTitleName] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setDepartment(props.recordToEdit.department_name || "");
			setJobTitleName(props.recordToEdit.name || "");
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	const jobTitleChanged = (event) => {
		setJobTitleName(event.target.value);
	};

	const departmentChanged = (event) => {
		setDepartment(event);
	};

	const formClosed = () => {
		resetForm();
		setAlertOpen(false);
		props.editRecordClosed();
	};

	// SUBMIT
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		let res = await putAPICall(
			instance,
			accounts[0],
			`/api/jobTitles/${props.recordToEdit.id}`,
			{
				name: jobTitleName,
				department_id: getDepartmentIdFromName(department),
				modified_by: employeeID,
			}
		);

		setIsLoading(false);

		formClosed();
		props.fetchJobTitles();
	};

	const deleteButtonClicked = async () => {
		setIsLoading(true);

		let res = await deleteAPICall(
			instance,
			accounts[0],
			`/api/jobTitles/${props.recordToEdit.id}`
		);

		setIsLoading(false);

		formClosed();
		props.fetchJobTitles();
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const validateInputFields = () => {
		if (!department || !jobTitleName) {
			setAlertOpen(true);
			return false;
		}

		return true;
	};

	const resetForm = () => {
		setJobTitleName("");
		setDepartment("");
	};

	const getDepartmentIdFromName = (name) => {
		let department = props.departments.find(
			(department) => department.name === name
		);
		return department.id;
	};

	// RENDER
	return (
		<Dialog
			id="EditJobTitlesDialog"
			open={props.open}
			fullWidth
			maxWidth="lg"
			onClose={formClosed}
		>
			<DialogTitle id="EditJobTitlesDialogTitle">
				Edit Job Title{" "}
				<Button
					onClick={deleteButtonClicked}
					variant="contained"
					color="error"
				>
					Delete
				</Button>
			</DialogTitle>
			<DialogContent id="EditJobTitlesDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="EditJobTitlesDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="EditJobTitlesDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="newEmployeeAlert"
									severity="error"
									action={
										<IconButton
											id="EditJobTitlesDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={() => {
												setAlertOpen(false);
											}}
										>
											<CloseIcon
												id="EditJobTitlesDialogCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									Please fill out all fields
								</Alert>
							</Collapse>
						</div>
						<div className="EditJobTitlesDialogRow">
							<TextField
								sx={{ flex: 3 }}
								id="EditJobTitlesDialogName"
								label="Name"
								value={jobTitleName}
								onChange={jobTitleChanged}
							/>
						</div>
						<div className="EditJobTitlesDialogRow">
							<Autocomplete
								sx={{ flex: 1 }}
								id="EditJobTitlesDepartment"
								options={props.departments.map(
									(departments) => departments.name
								)}
								renderInput={(params) => (
									<TextField {...params} label="Department" />
								)}
								onChange={(event, value) => {
									departmentChanged(value);
								}}
								value={department}
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default EditJobTitlesDialog;
