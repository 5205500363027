// DEPENDENCIES
import React from "react";

// CSS
import "./ThumbnailBlowupDialog.css";

// MUI COMPONENTS
import { Dialog, DialogContent } from "@mui/material";

// FUNCTIONAL COMPONENT
const ThumbnailBlowupDialog = (props) => {
	// RENDER
	return (
		<Dialog
			id="thumbnailBlowupDialog"
			open={props.open}
			onClose={props.closeThumbnailBlowupDialog}
			fullWidth
			maxWidth="lg"
		>
			<DialogContent id="thumbnailBlowupDialogContent">
				<img src={props.thumbnailURL} alt="thumbnail blowup" />
				<h2>Click outside preview to close.</h2>
			</DialogContent>
		</Dialog>
	);
};

export default ThumbnailBlowupDialog;
