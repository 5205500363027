// REACT
import React from "react";
import { PatternFormat } from "react-number-format";

// MUI
import { TextField } from "@mui/material";

// COMPONENT
const DivisionTextField = (props) => {
	return (
		<PatternFormat
			customInput={TextField}
			format="## ## ##"
			mask="_"
			{...props}
		/>
	);
};

export default DivisionTextField;
