// DEPENDENCIES
import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";

// CSS
import "./TipsAndTricksDetails.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// MUI
import { CircularProgress } from "@mui/material";

// CUSTOM COMPONENTS
import VideoCard from "../VideoCard/VideoCard";
import IFrameCard from "../IFrameCard/IFrameCard";
import J2ReportIssueDialog from "../J2ReportIssueDialog/J2ReportIssueDialog";

// REACT COMPONENT
const TipsAndTricksDetails = () => {
	const { id } = useParams();

	// STATES
	const [isLoading, setIsLoading] = useState(false);
	const [tipsAndTricksDetail, setTipsAndTricksDetail] = useState({}); // { id, title, description, data, category_id, category_name }
	const [reportIssueID, setReportIssueID] = useState("");
	const [reportIssueOpen, setReportIssueOpen] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE NAVIGATE
	const navigate = useNavigate();

	// MSAL
	const { instance, accounts } = useMsal();

	// USE EFFECT
	useEffect(() => {
		fetchTipsAndTricksDetail();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: `Tips and Tricks`,
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: `Accessed the Tips and Tricks page with ID of ${id}`,
		});
	}, []);

	// FUNCTIONS

	// HELPER FUNCTIONS
	const fetchTipsAndTricksDetail = async () => {
		try {
			setIsLoading(true);

			let dataRes = await getAPICall(
				instance,
				accounts[0],
				`/api/tipsAndTricks/${id}`
			);

			if (dataRes.data.length === 0) {
				navigate("/tip_not_found");
			}

			setTipsAndTricksDetail(dataRes.data[0]);

			setIsLoading(false);
		} catch (error) {
			console.log(
				"Error in TipsandTricks - fetchTipsAndTricks(): ",
				error
			);
		}
	};

	const reportIssueClicked = (id) => {
		setReportIssueID(id);
		setReportIssueOpen(true);
	};

	const reportIssueClosed = () => {
		setReportIssueOpen(false);
	};

	return (
		<div className="TipsAndTricksDetails">
			<div className="tipsAndTricksDetailsHeader">Tips & Tricks</div>
			{isLoading && (
				<div className="loadingSpinnerContainer">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tipsAndTricksDetailsContainer">
					{tipsAndTricksDetail.data_type === "video" ? (
						<VideoCard
							key={id}
							id={tipsAndTricksDetail.id}
							cardTitle={tipsAndTricksDetail.title}
							description={tipsAndTricksDetail.description}
							reportIssueClicked={(id) => reportIssueClicked(id)}
							videoSrc={tipsAndTricksDetail.data}
						/>
					) : (
						<IFrameCard
							key={id}
							id={tipsAndTricksDetail.id}
							cardTitle={tipsAndTricksDetail.title}
							description={tipsAndTricksDetail.description}
							reportIssueClicked={(id) => reportIssueClicked(id)}
							link={tipsAndTricksDetail.data}
						/>
					)}
				</div>
			)}
			<J2ReportIssueDialog
				open={reportIssueOpen}
				reportIssueClosed={reportIssueClosed}
				tipID={reportIssueID}
			/>
		</div>
	);
};

export default TipsAndTricksDetails;
