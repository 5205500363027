// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./AssembliesConsoleEditAdd.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	deleteAPICall,
	postAPICall,
	putAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
} from "@mui/material";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const AssembliesConsoleEditAdd = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [assemblyName, setAssemblyName] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.editing) {
			setAssemblyName(props.recordToEdit.name);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	const assemblyNameChanged = (event) => {
		setAssemblyName(event.target.value);
	};

	// API FUNCTIONS
	const handleUpdateRecord = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/encompass/assemblies/update",
				{
					id: props.recordToEdit.id,
					name: assemblyName,
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddRecord = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/encompass/assemblies/addAssembly",
				{
					name: assemblyName,
					modifiedBy: employeeID,
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDeleteRecord = async () => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/encompass/assemblies/deleteAssembly",
				{
					id: props.recordToEdit.id,
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		if (props.editing) {
			await handleUpdateRecord();
		} else {
			await handleAddRecord();
		}
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		props.onClose(true);
	};

	const handleCancelClick = () => {
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		props.onClose(false);
	};

	const handleDeleteClick = async () => {
		setLoading(true);
		await handleDeleteRecord();
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		props.onClose(true);
	};

	const resetInputFields = () => {
		setAssemblyName("");
	};

	const validateInputFields = () => {
		if (assemblyName === "") {
			setAlertMessage("Assembly name is required");
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	// RENDER
	return (
		<Dialog
			id="AssembliesEditAddDialog"
			open={props.open}
			fullWidth
			onClose={handleCancelClick}
		>
			<DialogTitle id="AssembliesEditAddDialogTitle">
				{props.editing ? "Edit Assembly" : "Add Assembly"}
				{props.editing ? (
					<Button
						color="error"
						variant="contained"
						onClick={handleDeleteClick}
					>
						Delete
					</Button>
				) : null}
			</DialogTitle>
			{loading && (
				<div className="loadingSpinner">
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<DialogContent id="AssembliesEditAddDialogContent">
					{alertOpen && (
						<Alert
							severity="warning"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setAlertOpen(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					)}

					<FormControl sx={{ flex: 1 }}>
						<TextField
							id="assemblyName"
							label="Assembly Name"
							variant="outlined"
							value={assemblyName}
							onChange={assemblyNameChanged}
							fullWidth
						/>
					</FormControl>
					<div className="editAssembliesButtons">
						<Button
							variant="outlined"
							color="error"
							onClick={handleCancelClick}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitClick}
							disabled={loading}
						>
							Submit
						</Button>
					</div>
				</DialogContent>
			)}
		</Dialog>
	);
};

// EXPORT
export default AssembliesConsoleEditAdd;
