// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./SpecificationCommentsDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../../context/UserContext";

// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import TextField from "@mui/material/TextField";

import Alert from "@mui/material/Alert";

import Collapse from "@mui/material/Collapse";

import CircularProgress from "@mui/material/CircularProgress";

// MUI ICONS
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS
import SpecComment from "../../../../SpecLibrary/SpecDetailScreen/CommentsCard/SpecComment/SpecComment";
import J2NoResultsFound from "../../../../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS

// OTHER

// REACT COMPONENT
const SpecificationCommentsDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Content
	const [comments, setComments] = useState([]);

	const [newCommentOpen, setNewCommentOpen] = useState(false);

	const [newCommentText, setNewCommentText] = useState("");

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			fetchComments();
		}
	}, [props.open]);

	// INPUT HANDLERS
	const newCommentClosed = () => {
		setNewCommentOpen(false);
		setNewCommentText("");
	};

	const newCommentTextChanged = (event) => {
		setNewCommentText(event.target.value);
	};

	// API FUNCTIONS
	const fetchComments = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/comments/bySpec`,
				{
					id: props.spec.id,
				}
			);

			setComments(res.data);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	const newCommentAdded = async () => {
		setIsLoading(true);

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/specifications/comments/add",
				{
					specID: props.spec.id,
					comment: newCommentText,
					createdByID: employeeID,
				}
			);

			await fetchComments();

			setNewCommentOpen(false);
			setNewCommentText("");
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	const formClosed = () => {
		props.onClose();
	};

	const submitButtonClicked = () => {
		console.log("submitButtonClicked");
	};

	// RENDER
	return (
		<Dialog
			id="specificationCommentsDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="specificationCommentsDialogTitle">
				{props.spec.title}
			</DialogTitle>
			<DialogContent id="specificationCommentsDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						{!props.spec.parent_id && (
							<div className="specificationCommentsDialogAlertContainer">
								<Alert
									severity="info"
									sx={{ width: "stretch" }}
								>
									This is a J2 standard specification. Please
									go{" "}
									<a
										href={`${process.env.REACT_APP_BASE_URL}/specs/library/${props.spec.id}`}
									>
										here
									</a>{" "}
									if you would like to add comments.
								</Alert>
							</div>
						)}
						{props.spec.parent_id && (
							<div className="specificationCommentsDialogAlertContainer">
								<Alert
									severity="info"
									sx={{ width: "stretch" }}
								>
									This is a local copy of a J2 standard
									specification. Please go{" "}
									<a
										href={`${process.env.REACT_APP_BASE_URL}/specs/library/${props.spec.parent_id}`}
									>
										here
									</a>{" "}
									if you would view and edit comments on the
									original specification.
								</Alert>
							</div>
						)}
						{props.spec.parent_id && (
							<>
								<div className="newSpecCommentButtonContainer">
									<Button
										variant="outlined"
										color="primary"
										startIcon={<AddIcon />}
										onClick={() => setNewCommentOpen(true)}
									>
										New
									</Button>
								</div>
								<Collapse
									in={newCommentOpen}
									id="newSpecCommentDropdownCollapse"
									timeout="auto"
									unmountOnExit
								>
									<div className="newSpecCommentInputContainer">
										<div className="newSpecCommentRow">
											<TextField
												sx={{ flex: 1 }}
												id="newCommentTextField"
												label="Comment"
												value={newCommentText}
												onChange={newCommentTextChanged}
											/>
										</div>
										<div className="newSpecCommentRow">
											<Button
												variant="outlined"
												color="error"
												onClick={newCommentClosed}
											>
												Cancel
											</Button>
											<Button
												variant="contained"
												color="primary"
												onClick={newCommentAdded}
											>
												Post
											</Button>
										</div>
									</div>
								</Collapse>
							</>
						)}
						<div className="specificationCommentsDialogCommentContainer">
							{comments.length === 0 && <J2NoResultsFound />}
							{comments.length > 0 && (
								<>
									{comments.map((comment, index) => (
										<SpecComment
											key={comment.id}
											comment={comment}
											showBottomBorder={
												index !== comments.length - 1
											}
										/>
									))}
								</>
							)}
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="contained"
						color="primary"
					>
						Close
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default SpecificationCommentsDialog;
