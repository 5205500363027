// DEPENDENCIES
import React, { useState, useEffect, useContext, useRef, useMemo } from "react";

// CSS
import "./ProjectConsoleEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	postAPICall,
	putAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	debounce,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS
import DropboxFileSelector from "../../../Misc/DropboxFileSelector/DropboxFileSelector";

// CONSTANTS

// OTHER

// REACT COMPONENT
const ProjectConsoleEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS
	const hubSpotDealNameRef = useRef(null);

	// STATES
	const [loading, setLoading] = useState(true);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// General inputs
	const [projectDropboxPath, setProjectDropboxPath] = useState("");
	const [projectProperty, setProjectProperty] = useState("");
	const [projectBuildingComponents, setProjectBuildingComponents] = useState(
		[]
	);

	// BQE Project
	const [bqeProjectOptions, setBqeProjectOptions] = useState([]);
	const [bqeProjectSearchLoading, setBqeProjectSearchLoading] =
		useState(false);

	const [bqeProjectRecord, setBqeProjectRecord] = useState({});

	// HUBSPOT DEAL
	const [hubSpotDealOptions, setHubSpotDealOptions] = useState([]);
	const [hubSpotDealRecord, setHubSpotDealRecord] = useState({});
	const [hubSpotDealName, setHubSpotDealName] = useState("");
	const [hubSpotDealSearchLoading, setHubSpotDealSearchLoading] =
		useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		// if editing, populate fields
		if (props.editing) {
			handlePopulateFieldsOnEdit();
		} else {
			setLoading(false);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	const hubSpotDealNameSelected = (event, newValue) => {
		if (newValue && newValue.properties) {
			// if a deal name is selected and the user didn't just clear the autocomplete
			setHubSpotDealRecord(newValue);
			setHubSpotDealName(newValue.properties.dealname);
		} else {
			// if the user cleared the autocomplete
			setHubSpotDealRecord({});
			setHubSpotDealName("");
			setHubSpotDealOptions([]);
		}
	};

	const bqeProjectNameSelected = (event) => {
		const selectedId = event.target.value;
		const selectedProject = bqeProjectOptions.find(
			(option) => option.id === selectedId
		);

		if (selectedProject) {
			setBqeProjectRecord(selectedProject);
		} else {
			setBqeProjectRecord({});
			setBqeProjectOptions([]);
		}
	};

	// GENERAL INPUTS
	const handleProjectPropertyChange = (event, newValue) => {
		setProjectProperty(newValue);
		// if newValue is null, clear bqe project options
		if (!newValue) {
			setBqeProjectRecord({});
			setBqeProjectOptions([]);
			// clear dropbox path
			setProjectDropboxPath("");
		} else {
			// get bqe projects
			fetchBqeProjectRecords(newValue);
			// set dropbox path
			setProjectDropboxPath(newValue.dropbox_file_path);
		}
	};

	const handleProjectBuildingComponentsChange = (event, newValue) => {
		setProjectBuildingComponents(newValue);
	};

	// API FUNCTIONS

	// populate fields when editing
	const handlePopulateFieldsOnEdit = async () => {
		setLoading(true);
		// search though props.properties to find the property that matches the recordToEdit.propertyID
		const property = props.properties.find(
			(property) => property.id === props.recordToEdit.property_id
		);
		setProjectProperty(property);
		// search existing hubspot deals
		await fetchHubSpotDealRecord(props.recordToEdit.hubspot_id);
		// reference display_name to get the building components, split by ', ', buildingcomponent.abbreviated_name
		const buildingComponents = props.recordToEdit.display_name.split(", ");
		const buildingComponentsArray = buildingComponents.map((component) => {
			const componentObject = props.buildingComponents.find(
				(buildingComponent) =>
					buildingComponent.abbreviated_name === component
			);
			return componentObject;
		});
		setProjectBuildingComponents(buildingComponentsArray);
		// set bqe project options
		const bqeRecordsRes = await fetchBqeProjectRecords(property);
		// set specific bqe project record
		const bqeProject = bqeRecordsRes.find(
			(option) => option.id === props.recordToEdit.bqe_id
		);
		setBqeProjectRecord(bqeProject);
		// set dropbox path
		setProjectDropboxPath(props.recordToEdit.dropbox_path);
		setLoading(false);
	};

	const fetchHubSpotDealRecord = async (hubspotID) => {
		setHubSpotDealSearchLoading(true);
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hubspot/getDealFromDealID",
				{
					dealID: hubspotID,
				}
			);
			if (res.data) {
				hubSpotDealNameChanged({
					target: { value: res.data.properties.dealname },
				});
				hubSpotDealNameSelected(null, res.data);
			} else {
				setAlertMessage(
					"Error fetching HubSpot deal record. Record may not be associated with a HubSpot deal."
				);
				setAlertOpen(true);
			}
		} catch (error) {
			console.log("Error in fetchHubSpotDealRecord: ", error);
		}
		setHubSpotDealSearchLoading(false);
	};

	const hubSpotDealNameChanged = useMemo(
		() =>
			debounce(async (event) => {
				setHubSpotDealSearchLoading(true);
				const textLength = event.target.value.length;

				// if the current text length is less than 3, we will get too many results, so return
				if (textLength < 3) {
					setHubSpotDealSearchLoading(false);
					setHubSpotDealOptions([]);
					return;
				}

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/hubspot/search/deals",
						{
							dealName: event.target.value,
						}
					);

					setHubSpotDealOptions(res.data);
				} catch (error) {
					console.log("Error in hubspot dealNameChanged: ", error);
				}
				setHubSpotDealSearchLoading(false);
			}, 500),
		[]
	);

	const fetchBqeProjectRecords = async (newValue) => {
		setBqeProjectSearchLoading(true);
		// Return early if no bqe_id is present
		if (!newValue?.bqe_id) {
			setBqeProjectSearchLoading(false);
			return;
		}
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/bqe/project/byProperty",
				{
					propertyID: newValue.bqe_id,
				}
			);
			setBqeProjectOptions(
				res.data == ""
					? []
					: res.data.filter(
							(option) =>
								!option.displayName.endsWith("(deleted)")
					  )
			);
			setBqeProjectSearchLoading(false);
			return res.data.filter(
				(option) => !option.displayName.endsWith("(deleted)")
			);
		} catch (error) {
			console.log("Error in fetch project records: ", error);
			setBqeProjectOptions([]);
		}
	};

	const handleAddRecord = async () => {
		setLoading(true);

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/jobs/project/create",
				{
					name: projectBuildingComponents
						.map((component) => component.name)
						.join(", "),
					displayName: projectBuildingComponents
						.map((component) => component.abbreviated_name)
						.join(", "),
					dropboxPath: projectDropboxPath,
					bqeID: bqeProjectRecord.id,
					bqeCode: bqeProjectRecord.code,
					hubspotID: hubSpotDealRecord.properties.hs_object_id,
					quoteID: hubSpotDealRecord.properties.hs_quote_id
						? hubSpotDealRecord.properties.hs_quote_id
						: "",
					propertyID: projectProperty.id,
					ownerID: getEmployeeIDFromHubSpotID(
						hubSpotDealRecord.properties.hubspot_owner_id
					),
					modifiedBy: employeeID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error adding record");
			setAlertOpen(true);
		}

		setLoading(false);
	};

	const handleEditRecord = async () => {
		setLoading(true);
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/jobs/project/update",
				{
					id: props.recordToEdit.id,
					name: projectBuildingComponents
						.map((component) => component.name)
						.join(", "),
					displayName: projectBuildingComponents
						.map((component) => component.abbreviated_name)
						.join(", "),
					dropboxPath: projectDropboxPath,
					bqeID: bqeProjectRecord.id,
					bqeCode: bqeProjectRecord.code,
					hubspotID: hubSpotDealRecord.properties.hs_object_id,
					quoteID: hubSpotDealRecord.properties.hs_quote_id
						? hubSpotDealRecord.properties.hs_quote_id
						: "",
					propertyID: projectProperty.id,
					ownerID: getEmployeeIDFromHubSpotID(
						hubSpotDealRecord.properties.hubspot_owner_id
					),
					modifiedBy: employeeID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error editing record");
			setAlertOpen(true);
		}
		setLoading(false);
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		// if editing, update record, else add record
		if (props.editing) {
			await handleEditRecord();
		} else {
			await handleAddRecord();
		}
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		props.onClose();
	};

	const handleCancelClick = () => {
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		props.onCloseNoRefresh();
	};

	const resetInputFields = () => {
		setLoading(false);
		setAlertMessage("");
		setAlertOpen(false);

		setBqeProjectOptions([]);
		setBqeProjectSearchLoading(false);
		setBqeProjectRecord({});

		setHubSpotDealOptions([]);
		setHubSpotDealRecord({});
		setHubSpotDealName("");
		setHubSpotDealSearchLoading(false);

		setProjectDropboxPath("");
		setProjectProperty("");

		setProjectBuildingComponents([]);
	};

	const validateInputFields = () => {
		if (
			!projectBuildingComponents ||
			projectBuildingComponents.length === 0
		) {
			setAlertMessage("Please select building components");
			setAlertOpen(true);
			return false;
		}
		if (!projectProperty) {
			setAlertMessage("Please select a property");
			setAlertOpen(true);
			return false;
		}
		if (!bqeProjectRecord.id) {
			setAlertMessage("Please select a BQE project");
			setAlertOpen(true);
			return false;
		}
		if (!hubSpotDealRecord.properties) {
			setAlertMessage("Please select a HubSpot deal");
			setAlertOpen(true);
			return false;
		}
		if (projectDropboxPath === "") {
			setAlertMessage("Please enter a Dropbox path");
			setAlertOpen(true);
			return false;
		}

		return true;
	};

	const getEmployeeNameFromHubSpotID = (hubspotID) => {
		if (!hubspotID) return "";
		const employee = props.employees.find(
			(employee) => employee.hubspot_id == hubspotID
		);
		return employee ? employee.first_name + " " + employee.last_name : "";
	};

	const getEmployeeIDFromHubSpotID = (hubspotID) => {
		if (!hubspotID) return "";
		const employee = props.employees.find(
			(employee) => employee.hubspot_id == hubspotID
		);
		return employee ? employee.id : "";
	};

	// RENDER
	return (
		<Dialog
			id="ProjectConsoleEditAddDialog"
			open={props.open}
			fullWidth
			maxWidth="xl"
			onClose={handleCancelClick}
		>
			<DialogTitle id="ProjectConsoleEditAddDialogTitle">
				{/* edit / add */}
				{props.editing ? "Edit Project" : "Add Project"}
			</DialogTitle>
			{loading && (
				<div className="projectConsoleLoadingSpinner">
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<DialogContent id="ProjectConsoleEditAddDialogContent">
					{alertOpen && (
						<Alert
							severity="warning"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setAlertOpen(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					)}

					<div className="dialogSubtitle">Tools Property Record</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									id="propertyID"
									options={props.properties}
									getOptionLabel={(option) =>
										option ? option.name : ""
									}
									value={projectProperty}
									onChange={handleProjectPropertyChange}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Property"
											variant="outlined"
										/>
									)}
								/>
							</div>
						</div>
					</FormControl>

					<Divider />
					<div className="dialogSubtitle">
						Search Existing HubSpot Deals
					</div>

					{/* HUBSPOT */}
					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									options={hubSpotDealOptions}
									ref={hubSpotDealNameRef}
									getOptionLabel={(option) =>
										option.properties
											? option.properties.dealname
											: option
									}
									value={
										hubSpotDealOptions.find(
											(option) =>
												option.properties?.dealname ===
												hubSpotDealName
										) || null
									}
									filterSelectedOptions
									onChange={hubSpotDealNameSelected}
									clearOnBlur={false}
									renderInput={(params) => (
										<TextField
											{...params}
											label="HubSpot Deal Name"
											placeholder="HubSpot Deal Name"
											value={hubSpotDealName || ""}
											onKeyUp={hubSpotDealNameChanged}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{hubSpotDealSearchLoading && (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														)}
													</>
												),
											}}
										/>
									)}
								/>
							</div>
							<div className="FormRow">
								<TextField
									sx={{ flexGrow: 1 }}
									label="Owner"
									variant="outlined"
									value={
										hubSpotDealRecord.properties
											?.hubspot_owner_id
											? getEmployeeNameFromHubSpotID(
													hubSpotDealRecord.properties
														.hubspot_owner_id
											  )
											: ""
									}
									disabled
									helperText={
										hubSpotDealName &&
										!hubSpotDealRecord.properties
											.hubspot_owner_id
											? "No employee associated with deal"
											: "This field is auto-populated from HubSpot"
									}
									error={
										hubSpotDealName &&
										!hubSpotDealRecord.properties
											.hubspot_owner_id
									}
								/>
							</div>
						</div>
					</FormControl>

					<Divider />
					<div className="dialogSubtitle">Building Components</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									multiple
									filterSelectedOptions
									id="buildingComponents"
									options={props.buildingComponents}
									getOptionLabel={(option) => option.name}
									value={projectBuildingComponents}
									onChange={(event, newValue) => {
										// Allow only up to 2 selections
										if (newValue.length <= 2) {
											handleProjectBuildingComponentsChange(
												event,
												newValue
											);
										} else {
											setAlertMessage(
												"Please select up to 2 building components"
											);
											setAlertOpen(true);
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Building Components"
											variant="outlined"
										/>
									)}
								/>
							</div>
						</div>
					</FormControl>

					{/* BQE Project */}
					{/* render only when property has been selected */}
					<Divider />
					<div className="dialogSubtitle">
						Search Matching BQE Projects
					</div>
					<FormControl
						sx={{ flex: 1 }}
						error={
							bqeProjectOptions.length === 0 &&
							!bqeProjectSearchLoading &&
							projectProperty
						}
					>
						<div className="FormBlock">
							<div className="FormRow">
								<FormControl sx={{ flexGrow: 1 }}>
									<InputLabel id="projectRecordLabel">
										BQE Project
									</InputLabel>
									<Select
										labelId="projectRecordLabel"
										value={bqeProjectRecord.id || ""}
										label="BQE Project"
										onChange={bqeProjectNameSelected}
										disabled={bqeProjectSearchLoading}
										startAdornment={
											<InputAdornment position="start">
												{bqeProjectSearchLoading && (
													<CircularProgress
														size={20}
													/>
												)}
											</InputAdornment>
										}
									>
										{bqeProjectOptions.map((option) => (
											<MenuItem
												key={option.id}
												value={option.id}
											>
												{option.displayName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</div>

						{bqeProjectOptions.length === 0 &&
							!bqeProjectSearchLoading &&
							projectProperty && (
								<FormHelperText>
									No Matching BQE Projects
								</FormHelperText>
							)}

						{!projectProperty && (
							<FormHelperText>
								Please select a property to search for BQE
								projects
							</FormHelperText>
						)}
					</FormControl>

					<Divider />
					<div className="dialogSubtitle">Dropbox Information</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<DropboxFileSelector
									filePath={projectDropboxPath}
									setFilePath={setProjectDropboxPath}
								/>
							</div>
						</div>
					</FormControl>

					<div className="editProjectConsoleButtons">
						<Button
							variant="outlined"
							color="error"
							onClick={handleCancelClick}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitClick}
							disabled={loading}
						>
							Submit
						</Button>
					</div>
				</DialogContent>
			)}
		</Dialog>
	);
};

// EXPORT
export default ProjectConsoleEditAddDialog;
