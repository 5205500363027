// DEPENDENCIES
import React, { useEffect, useRef, useState } from "react";

// CSS
import "./GenericTable.css";

// MSAL

// API IMPORTS

// CONTEXT

// MUI COMPONENTS
import {
	TableBody,
	TableContainer,
	TableHead,
	Table,
	TableRow,
	TableCell,
	IconButton,
	Tooltip,
	CircularProgress,
} from "@mui/material";

// MUI ICONS
import {
	Edit as EditIcon,
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	UnfoldMore as UnfoldMoreIcon,
	Link as LinkIcon,
	Download as DownloadIcon,
	Restore as RestoreIcon,
	RecentActors as RecentActorsIcon,
	ContentCopy as ContentCopyIcon,
	Delete as DeleteIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS
import { defaultBlue, selectedBlue } from "../../../constants";

// add more icons here when needed
const iconMap = {
	edit: <EditIcon fontSize="small" />,
	link: <LinkIcon fontSize="small" />,
	download: <DownloadIcon fontSize="small" />,
	restore: <RestoreIcon fontSize="small" />,
	recentActors: <RecentActorsIcon fontSize="small" />,
	copy: <ContentCopyIcon fontSize="small" />,
	delete: <DeleteIcon fontSize="small" />,
};

// OTHER

// REACT COMPONENT
const GenericTable = (props) => {
	// Ref
	const tableContainerRef = useRef();
	const previousRowDataRef = useRef(props.rowData);

	useEffect(() => {
		// Logic to scroll to the top of the table container on pagination
		const currentRowDataString = JSON.stringify(props.rowData); // deep compare, not comparing the reference
		if (previousRowDataRef.current !== currentRowDataString) {
			if (tableContainerRef?.current) {
				tableContainerRef.current.scrollTo({
					top: 0,
					behavior: "smooth", // smooth scrolling
				});
			}
			previousRowDataRef.current = JSON.stringify(props.rowData); // Update the deep compare reference
		}
	}, [props.rowData, props.noAutoScroll]);

	// RENDER
	return (
		<TableContainer id="tableContainer" ref={tableContainerRef}>
			<Table id="table" stickyHeader>
				<GenericTableHeader columnData={props.columnData} />
				<GenericTableRow rowData={props.rowData} />
			</Table>
		</TableContainer>
	);
};

const GenericTableHeader = (props) => {
	const [loadingColumn, setLoadingColumn] = useState(null);

	useEffect(() => {
		// Reset loading state when new rowData is received
		setLoadingColumn(null);
	}, [props.columnData]);

	const returnSortIcon = (column, index) => {
		if (loadingColumn === index) {
			return (
				<span style={{ marginLeft: "3px" }}>
					<CircularProgress size={20} color="white" />
				</span>
			);
		} else {
			if (column.sortDirection === "ASC") {
				return <ExpandLessIcon />;
			} else if (column.sortDirection === "DESC") {
				return <ExpandMoreIcon />;
			} else {
				return <UnfoldMoreIcon />;
			}
		}
	};

	const handleSortFunction = (column, index) => {
		setLoadingColumn(index);
		column.sortFunction(column);
	};

	const returnColumnCell = (column, index) => {
		return (
			<TableCell
				id="tableHeaderCell"
				key={index}
				onClick={
					// check if column is sortable
					column.sortFunction
						? () => handleSortFunction(column, index)
						: null
				}
				sx={{
					backgroundColor:
						column.sortDirection !== "none" && column.sortFunction
							? selectedBlue
							: defaultBlue,
				}}
			>
				<div id="tableCellContents">
					{column.icon ? (
						<IconButton
							sx={{
								minHeight: 0,
								minWidth: 0,
								padding: 0,
							}}
							disableRipple={true}
						>
							<span style={{ color: "white" }}>
								{iconMap[column.icon]}
							</span>
						</IconButton>
					) : null}
					{column.text ? column.text : null}
					{column.sortFunction ? returnSortIcon(column, index) : null}
				</div>
			</TableCell>
		);
	};

	// RENDER
	return (
		<TableHead>
			<TableRow>
				{props.columnData.map((column, index) =>
					returnColumnCell(column, index)
				)}
			</TableRow>
		</TableHead>
	);
};

const GenericTableRow = (props) => {
	// returns an iconbutton if the cell has an icon, uses the iconMap to find the icon, applies appropriate styling to the icon
	const returnRowIcon = (cell) => {
		return (
			<IconButton
				sx={{ minHeight: 0, minWidth: 0, padding: 0 }} // minHeight and minWidth are set to 0 to remove "padding" (MUI quirk)
			>
				<span style={{ color: "#3C4142" }}>{iconMap[cell.icon]}</span>
			</IconButton>
		);
	};

	// return the text with the appropriate class style
	const returnRowText = (cell, rowIndex, cellIndex) => {
		let className;
		if (cell.notApplicable) {
			className = "notApplicable";
		} else if (cell.link) {
			className = "link";
		} else {
			className = "default";
		}
		// dynamic text styling, overrides classes
		const style = {
			...(cell.textColor && { color: cell.textColor }),
			...(cell.bold && { fontWeight: "bold" }),
			...(cell.noWrap && { whiteSpace: "nowrap" }),
		};

		return (
			<span
				className={className}
				style={style}
				key={`row${rowIndex}cell${cellIndex}`}
			>
				{cell.text}
			</span>
		);
	};

	const returnTableCellContents = (cell, rowIndex, cellIndex) => {
		return (
			<div id="tableCellContents">
				{/* not applicable overrides all other provided fields */}
				{cell.notApplicable ? (
					<div className="notApplicable">N/A</div>
				) : (
					<React.Fragment key={`row${rowIndex}cell${cellIndex}`}>
						{/* return icon and text if provided */}
						{cell.icon && returnRowIcon(cell, rowIndex, cellIndex)}
						{cell.text && returnRowText(cell, rowIndex, cellIndex)}
					</React.Fragment>
				)}
			</div>
		);
	};

	const returnRowCell = (cell, rowIndex, cellIndex) => {
		return (
			<TableCell
				id="tableRowCell"
				key={`row-${rowIndex}-cell-${cellIndex}`}
				align="left"
				onClick={cell.onClick ? () => cell.onClick() : null}
			>
				{/* if hovertext is applied return the contents wrapped in tooltip */}
				{cell.hoverText ? (
					<Tooltip title={cell.hoverText} placement="bottom-start">
						{returnTableCellContents(cell, rowIndex, cellIndex)}
					</Tooltip>
				) : (
					returnTableCellContents(cell, rowIndex, cellIndex)
				)}
			</TableCell>
		);
	};

	// **** cell and row indexes are very important for react to keep track of table, do not delete ****
	return (
		<TableBody>
			{props.rowData.map((row, rowIndex) => (
				<TableRow hover={true} key={`row-${rowIndex}`}>
					{row.map((cell, cellIndex) =>
						returnRowCell(cell, rowIndex, cellIndex)
					)}
				</TableRow>
			))}
		</TableBody>
	);
};

// EXPORT
export default GenericTable;
