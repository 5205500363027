import React, { useState, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

// CSS
import "./Navigation.css";
import j2logo from "../../images/j2_logo_white.png";

// MSAL
import { useMsal } from "@azure/msal-react";

// MUI COMPONENTS
import {
	Collapse,
	ImageListItem,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";

// MUI ICONS
import {
	AdminPanelSettings as AdminPanelSettingsIcon,
	Article as ArticleIcon,
	AssignmentInd as AssignmentIndIcon,
	AttachEmail as AttachEmailIcon,
	AutoStories as AutoStoriesIcon,
	Badge as BadgeIcon,
	Build as BuildIcon,
	Category as CategoryIcon,
	CollectionsBookmark as CollectionsBookmarkIcon,
	Computer as ComputerIcon,
	ConfirmationNumber as ConfirmationNumberIcon,
	Construction as ConstructionIcon,
	CreateNewFolder as CreateNewFolderIcon,
	Dashboard as DashboardIcon,
	DensitySmall as DensitySmallIcon,
	DriveFileRenameOutline as DriveFileRenameOutlineIcon,
	ExpandLess,
	ExpandMore,
	GridView as GridViewIcon,
	Hardware as HardwareIcon,
	HelpOutline as HelpOutlineIcon,
	HistoryEdu as HistoryEduIcon,
	HomeWork as HomeWorkIcon,
	HouseSiding as HouseSidingIcon,
	ImportantDevices as ImportantDevicesIcon,
	Info as InfoIcon,
	InfoOutlined as InfoOutlinedIcon,
	Inventory as InventoryIcon,
	Key as KeyIcon,
	LaptopMac as LaptopMacIcon,
	LocalActivity as LocalActivityIcon,
	LockOpen as LockOpenIcon,
	LockOutlined as LockOutlinedIcon,
	Logout as LogoutIcon,
	LowPriority as LowPriorityIcon,
	ManageAccounts as ManageAccountsIcon,
	MenuBook as MenuBookIcon,
	NewReleases as NewReleasesIcon,
	NoEncryptionGmailerrorred as NoEncryptionGmailerrorredIcon,
	People as PeopleIcon,
	PublishedWithChanges as PublishedWithChangesIcon,
	QuestionMark as QuestionMarkIcon,
	Reviews as ReviewsIcon,
	ScatterPlot as ScatterPlotIcon,
	SmartToy as SmartToyIcon,
	Work as WorkIcon,
} from "@mui/icons-material";

// CONSTANTS

// REACT COMPONENT
const Navigation = (props) => {
	// MSAL
	const { instance } = useMsal();

	// STATES
	// Nav Collapse
	const [navExpanded, setNavExpanded] = useState(false);
	const [navListID, setNavListID] = useState("nav-list-compressed");

	const [delayHandler, setDelayHandler] = useState(null);

	// Dropdowns
	const [proposalsOpen, setProposalsOpen] = useState(false);
	const [logsOpen, setLogsOpen] = useState(false);
	const [adminOpen, setAdminOpen] = useState(false);
	const [ticketsOpen, setTicketsOpen] = useState(false);
	const [specificationsOpen, setSpecificationsOpen] = useState(false);
	const [profileOpen, setProfileOpen] = useState(false);
	const [dashboardsOpen, setDashboardsOpen] = useState(false);
	const [hardwareInventoryOpen, setHardwareInventoryOpen] = useState(false);

	// INPUT HANDLERS
	const proposalDropdownClicked = () => {
		setProposalsOpen(!proposalsOpen);
	};

	const logsDropdownClicked = () => {
		setLogsOpen(!logsOpen);
	};

	const adminDropdownClicked = () => {
		setAdminOpen(!adminOpen);
	};

	const ticketsDropdownClicked = () => {
		setTicketsOpen(!ticketsOpen);
	};

	const specificationsDropdownClicked = () => {
		setSpecificationsOpen(!specificationsOpen);
	};

	const profileDropdownClicked = () => {
		setProfileOpen(!profileOpen);
	};

	const dashboardsDropdownClicked = () => {
		setDashboardsOpen(!dashboardsOpen);
	};

	const hardwareInventoryDropdownClicked = () => {
		setHardwareInventoryOpen(!hardwareInventoryOpen);
	};

	const signOutPressed = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: "/",
			onRedirectNavigate: (url) => {
				// Return false if you would like to stop navigation after local logout
				return false;
			},
		});
	};

	// Nav Collapse Handlers
	const mouseLeaveNav = () => {
		clearTimeout(delayHandler);

		setNavExpanded(false);
		setNavListID("nav-list-compressed");
	};

	const mouseEnterNav = () => {
		setDelayHandler(
			setTimeout(() => {
				setNavExpanded(true);
				setNavListID("nav-list");
			}, 250) // Only expand after mouse has been over the nav for 250ms
		);
	};

	// RETURN
	return (
		<Collapse
			in={navExpanded}
			id="nav-collapse"
			orientation="horizontal"
			collapsedSize="5vw"
			onMouseEnter={mouseEnterNav}
			onMouseLeave={mouseLeaveNav}
			timeout={250}
		>
			<div className="Navigation">
				<List id={navListID} component="nav">
					<div id="nav-list-items">
						{/* NAV LOGO */}
						<ImageListItem id="nav-logo-container">
							<img alt="j2 logo" src={j2logo} id="nav-logo" />
						</ImageListItem>

						{/* PROPOSAL DROPDOWN */}
						<ListItemButton
							onClick={proposalDropdownClicked}
							id="nav-list-item-button-rfp"
						>
							<ListItemIcon className="nav-list-item-icon">
								<ArticleIcon
									id="nav-list-item-icon-image"
									style={{ color: "white" }}
								/>
							</ListItemIcon>
							<ListItemText
								primary="Proposals"
								id="proposalListItemText"
								className="nav-list-item-text"
								disableTypography
							/>
							<div className="dropdownIconContainer">
								{proposalsOpen ? (
									<ExpandLess style={{ color: "white" }} />
								) : (
									<ExpandMore style={{ color: "white" }} />
								)}
							</div>
						</ListItemButton>
						{/* PROPOSAL LINKS */}
						<Collapse
							in={proposalsOpen}
							timeout="auto"
							unmountOnExit
							id="nav-dropdown-collapse-rfp"
							className="rfp-related"
						>
							<ListItemButton
								component={RouterLink}
								to="/"
								id="nav-list-item-button-rfp"
							>
								<ListItemIcon
									id="rfpListIcon"
									className="nav-list-item-icon"
								>
									<CreateNewFolderIcon
										style={{ color: "white" }}
									/>
								</ListItemIcon>
								<ListItemText
									primary="RFP"
									id="rfpListItemText"
									className="nav-list-item-text"
									disableTypography
								/>
							</ListItemButton>
							<ListItemButton
								component={RouterLink}
								to="/rfa"
								id="nav-list-item-button"
							>
								<ListItemIcon
									id="rfaListIcon"
									className="nav-list-item-icon"
								>
									<ConstructionIcon
										style={{ color: "white" }}
									/>
								</ListItemIcon>
								<ListItemText
									primary="RFA"
									id="rfaListItemText"
									className="nav-list-item-text"
									disableTypography
								/>
							</ListItemButton>
							{props.accessibleTools
								.map((t) => t.name)
								.includes("Pending Jobs") && (
								<>
									<ListItemButton
										component={RouterLink}
										to="/jobs/pending"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="activeTicketsIcon"
											className="nav-list-item-icon"
										>
											<NewReleasesIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Pending Jobs"
											id="pendingJobsText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
								</>
							)}
						</Collapse>

						{/* TIPS & TRICKS */}
						<ListItemButton
							component={RouterLink}
							to="/tips_and_tricks"
							id="nav-list-item-button"
						>
							<ListItemIcon
								id="tipsAndTricksIcon"
								className="nav-list-item-icon"
							>
								<QuestionMarkIcon style={{ color: "white" }} />
							</ListItemIcon>
							<ListItemText
								primary="Tips & Tricks"
								id="tipsAndTricksText"
								className="nav-list-item-text"
								disableTypography
							/>
						</ListItemButton>

						{/* INFO */}
						<ListItemButton
							component={RouterLink}
							to="/info"
							id="nav-list-item-button"
						>
							<ListItemIcon
								id="infoIcon"
								className="nav-list-item-icon"
							>
								<InfoIcon style={{ color: "white" }} />
							</ListItemIcon>
							<ListItemText
								primary="Info"
								id="infoText"
								className="nav-list-item-text"
								disableTypography
							/>
						</ListItemButton>

						{/* TICKETS */}
						<ListItemButton
							onClick={ticketsDropdownClicked}
							id="nav-list-item-button"
						>
							<ListItemIcon className="nav-list-item-icon">
								<ConfirmationNumberIcon
									style={{ color: "white" }}
								/>
							</ListItemIcon>
							<ListItemText
								primary="Tickets"
								id="ticketsListItemText"
								className="nav-list-item-text"
								disableTypography
							/>
							<div className="dropdownIconContainer">
								{ticketsOpen ? (
									<ExpandLess style={{ color: "white" }} />
								) : (
									<ExpandMore style={{ color: "white" }} />
								)}
							</div>
						</ListItemButton>
						<Collapse
							in={ticketsOpen}
							timeout="auto"
							unmountOnExit
							id="nav-dropdown-collapse"
						>
							{/* IT REQUEST */}
							<ListItemButton
								component={RouterLink}
								to="/tickets/it_request"
								id="nav-list-item-button"
							>
								<ListItemIcon
									id="itRequestIcon"
									className="nav-list-item-icon"
								>
									<ComputerIcon style={{ color: "white" }} />
								</ListItemIcon>
								<ListItemText
									primary="IT Request"
									id="itRequestText"
									className="nav-list-item-text"
									disableTypography
								/>
							</ListItemButton>
							{/* YOUR TICKETS */}
							<ListItemButton
								component={RouterLink}
								to="/tickets/submitted"
								id="nav-list-item-button"
							>
								<ListItemIcon
									id="yourTicketsIcon"
									className="nav-list-item-icon"
								>
									<LocalActivityIcon
										style={{ color: "white" }}
									/>
								</ListItemIcon>
								<ListItemText
									primary="Your Tickets"
									id="yourTicketsText"
									className="nav-list-item-text"
									disableTypography
								/>
							</ListItemButton>
							{/* ACTIVE & INACTIVE TICKETS */}
							{Boolean(
								props.accessibleTools?.find(
									(t) => t.name === "Tickets"
								)?.admin_access
							) && (
								<>
									<ListItemButton
										component={RouterLink}
										to="/tickets/active"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="activeTicketsIcon"
											className="nav-list-item-icon"
										>
											<LockOpenIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Active Tickets"
											id="activeTicketsText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/tickets/inactive"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="inactiveTicketsIcon"
											className="nav-list-item-icon"
										>
											<LockOutlinedIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Inactive Tickets"
											id="inactiveTicketsText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
								</>
							)}
						</Collapse>

						{/* Detail Library */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Detail Library")
						) && (
							<>
								<ListItemButton
									component={RouterLink}
									to="/encompass/detail_library"
									id="nav-list-item-button"
								>
									<ListItemIcon
										id="detailLibraryIcon"
										className="nav-list-item-icon"
									>
										<CollectionsBookmarkIcon
											style={{ color: "white" }}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Detail Library"
										id="detailLibraryText"
										className="nav-list-item-text"
										disableTypography
									/>
								</ListItemButton>
							</>
						)}

						{/* Spec Library */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Spec Library")
						) && (
							<>
								<ListItemButton
									onClick={specificationsDropdownClicked}
									id="nav-list-item-button"
								>
									<ListItemIcon id="nav-list-item-icon">
										<HistoryEduIcon
											style={{ color: "white" }}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Specifications"
										id="specificationsListItemText"
										className="nav-list-item-text"
									/>
									<div className="dropdownIconContainer">
										{specificationsOpen ? (
											<ExpandLess
												style={{ color: "white" }}
											/>
										) : (
											<ExpandMore
												style={{ color: "white" }}
											/>
										)}
									</div>
								</ListItemButton>
								{/* SPECIFICATIONS LINKS */}
								<Collapse
									in={specificationsOpen}
									timeout="auto"
									unmountOnExit
									sx={{ pl: "1em" }}
								>
									<ListItemButton
										component={RouterLink}
										to="/specs/design_jobs"
										id="nav-list-item-button"
									>
										<ListItemIcon id="nav-list-item-icon">
											<HouseSidingIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Design Jobs"
											id="designJobsText"
											className="nav-list-item-text"
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/specs/library"
										id="nav-list-item-button"
									>
										<ListItemIcon id="nav-list-item-icon">
											<MenuBookIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Spec Library"
											id="specLibraryText"
											className="nav-list-item-text"
										/>
									</ListItemButton>
									{Boolean(
										props.accessibleTools?.find(
											(t) => t.name === "Spec Library"
										)?.admin_access
									) && (
										<>
											<ListItemButton
												component={RouterLink}
												to="/specs/pending_changes"
												id="nav-list-item-button"
											>
												<ListItemIcon id="nav-list-item-icon">
													<ReviewsIcon
														style={{
															color: "white",
														}}
													/>
												</ListItemIcon>
												<ListItemText
													primary="Pending Changes"
													id="pendingChangesText"
													className="nav-list-item-text"
												/>
											</ListItemButton>
											<ListItemButton
												component={RouterLink}
												to="/specs/spec_change_log"
												id="nav-list-item-button"
											>
												<ListItemIcon id="nav-list-item-icon">
													<AutoStoriesIcon
														style={{
															color: "white",
														}}
													/>
												</ListItemIcon>
												<ListItemText
													primary="Spec Change Log"
													id="specChangeLogText"
													className="nav-list-item-text"
												/>
											</ListItemButton>
											<ListItemButton
												component={RouterLink}
												to="/specs/design_job_change_log"
												id="nav-list-item-button"
											>
												<ListItemIcon id="nav-list-item-icon">
													<PublishedWithChangesIcon
														style={{
															color: "white",
														}}
													/>
												</ListItemIcon>
												<ListItemText
													primary="Design Job Change Log"
													id="designJobChangeLogText"
													className="nav-list-item-text"
												/>
											</ListItemButton>
										</>
									)}
								</Collapse>
							</>
						)}

						{/* CORE REPAIR SCOPES */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Core Repair Scopes")
						) && (
							<>
								{/* CORE REPAIR SCOPES */}
								<ListItemButton
									component={RouterLink}
									to="/encompass/core_repair_scopes"
									id="nav-list-item-button"
								>
									<ListItemIcon
										id="crsIcon"
										className="nav-list-item-icon"
									>
										<BuildIcon style={{ color: "white" }} />
									</ListItemIcon>
									<ListItemText
										primary="Core Repair Scopes"
										id="crsText"
										className="nav-list-item-text"
										disableTypography
									/>
								</ListItemButton>
							</>
						)}

						{/* JOBS */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Jobs")
						) && (
							<>
								{/* JOBS LINKS */}
								<ListItemButton
									component={RouterLink}
									to="/jobs"
									id="nav-list-item-button"
								>
									<ListItemIcon
										id="jobsIcon"
										className="nav-list-item-icon"
									>
										<WorkIcon style={{ color: "white" }} />
									</ListItemIcon>
									<ListItemText
										primary="Jobs"
										id="jobsText"
										className="nav-list-item-text"
										disableTypography
									/>
								</ListItemButton>
							</>
						)}

						{/* HARDWARE INVENTORY */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Hardware Inventory")
						) && (
							<>
								<ListItemButton
									onClick={hardwareInventoryDropdownClicked}
									id="nav-list-item-button"
								>
									<ListItemIcon id="nav-list-item-icon">
										<InventoryIcon
											style={{ color: "white" }}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Hardware Inventory"
										id="hardwareInventoryListItemText"
										className="nav-list-item-text"
									/>
									<div className="dropdownIconContainer">
										{hardwareInventoryOpen ? (
											<ExpandLess
												style={{ color: "white" }}
											/>
										) : (
											<ExpandMore
												style={{ color: "white" }}
											/>
										)}
									</div>
								</ListItemButton>
								{/* HARDWARE INVENTORY LINKS */}
								<Collapse
									in={hardwareInventoryOpen}
									timeout="auto"
									unmountOnExit
									sx={{ pl: "1em" }}
								>
									<ListItemButton
										component={RouterLink}
										to="/hardware_inventory/devices"
										id="nav-list-item-button"
									>
										<ListItemIcon id="nav-list-item-icon">
											<LaptopMacIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Devices"
											id="DevicesInventoryText"
											className="nav-list-item-text"
										/>
									</ListItemButton>

									<ListItemButton
										component={RouterLink}
										to="/hardware_inventory/leases"
										id="nav-list-item-button"
									>
										<ListItemIcon id="nav-list-item-icon">
											<LowPriorityIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Leases"
											id="LeasesInventoryText"
											className="nav-list-item-text"
										/>
									</ListItemButton>

									<ListItemButton
										component={RouterLink}
										to="/hardware_inventory/service_history"
										id="nav-list-item-button"
									>
										<ListItemIcon id="nav-list-item-icon">
											<HardwareIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Service History"
											id="ServiceHistoryInventoryText"
											className="nav-list-item-text"
										/>
									</ListItemButton>
								</Collapse>
							</>
						)}

						{/* DASHBOARDS */}
						<ListItemButton
							onClick={dashboardsDropdownClicked}
							id="nav-list-item-button"
						>
							<ListItemIcon className="nav-list-item-icon">
								<DashboardIcon style={{ color: "white" }} />
							</ListItemIcon>
							<ListItemText
								primary="Dashboards"
								id="dashboardsListItemText"
								className="nav-list-item-text"
								disableTypography
							/>
							<div className="dropdownIconContainer">
								{dashboardsOpen ? (
									<ExpandLess style={{ color: "white" }} />
								) : (
									<ExpandMore style={{ color: "white" }} />
								)}
							</div>
						</ListItemButton>
						<Collapse
							in={dashboardsOpen}
							timeout="auto"
							unmountOnExit
							id="nav-dropdown-collapse"
						>
							{/* ALL */}
							<ListItemButton
								component={RouterLink}
								to="/dashboards/all"
								id="nav-list-item-button"
							>
								<ListItemIcon
									id="allDashboardsIcon"
									className="nav-list-item-icon"
								>
									<GridViewIcon style={{ color: "white" }} />
								</ListItemIcon>
								<ListItemText
									primary="All Dashboards"
									id="allDashboardsText"
									className="nav-list-item-text"
									disableTypography
								/>
							</ListItemButton>
							{/* BY USER */}
							<ListItemButton
								component={RouterLink}
								to="/dashboards/mine"
								id="nav-list-item-button"
							>
								<ListItemIcon
									id="myDashboardsIcon"
									className="nav-list-item-icon"
								>
									<AssignmentIndIcon
										style={{ color: "white" }}
									/>
								</ListItemIcon>
								<ListItemText
									primary="My Dashboards"
									id="myDashboardsText"
									className="nav-list-item-text"
									disableTypography
								/>
							</ListItemButton>
						</Collapse>

						{/* LOGS */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Logs")
						) && (
							<>
								{/* LOGS DROPDOWN */}
								<ListItemButton
									onClick={logsDropdownClicked}
									id="nav-list-item-button"
								>
									<ListItemIcon className="nav-list-item-icon">
										<DensitySmallIcon
											style={{ color: "white" }}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Logs"
										id="logsListItemText"
										className="nav-list-item-text"
										disableTypography
									/>
									<div className="dropdownIconContainer">
										{logsOpen ? (
											<ExpandLess
												style={{ color: "white" }}
											/>
										) : (
											<ExpandMore
												style={{ color: "white" }}
											/>
										)}
									</div>
								</ListItemButton>
								{/* LOGS LINKS */}
								<Collapse
									in={logsOpen}
									timeout="auto"
									unmountOnExit
									id="nav-dropdown-collapse"
								>
									<ListItemButton
										component={RouterLink}
										to="/logs/access"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="accessLogIcon"
											className="nav-list-item-icon"
										>
											<BadgeIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Access Logs"
											id="accessLogText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/logs/automation"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="automationLogIcon"
											className="nav-list-item-icon"
										>
											<SmartToyIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Automation Logs"
											id="automationLogText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
								</Collapse>
							</>
						)}

						{/* ADMIN */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Admin")
						) && (
							<>
								{/* ADMIN DROPDOWN */}
								<ListItemButton
									onClick={adminDropdownClicked}
									id="nav-list-item-button"
								>
									<ListItemIcon className="nav-list-item-icon">
										<AdminPanelSettingsIcon
											style={{ color: "white" }}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Admin"
										id="adminListItemText"
										className="nav-list-item-text"
										disableTypography
									/>
									<div className="dropdownIconContainer">
										{adminOpen ? (
											<ExpandLess
												style={{ color: "white" }}
											/>
										) : (
											<ExpandMore
												style={{ color: "white" }}
											/>
										)}
									</div>
								</ListItemButton>
								{/* ADMIN LINKS */}
								<Collapse
									in={adminOpen}
									timeout="auto"
									unmountOnExit
									id="nav-dropdown-collapse"
								>
									<ListItemButton
										component={RouterLink}
										to="/admin/info_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="infoConsoleIcon"
											className="nav-list-item-icon"
										>
											<InfoIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Info Console"
											id="infoConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/info_access_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="infoAccessConsoleIcon"
											className="nav-list-item-icon"
										>
											<InfoOutlinedIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Info Access Console"
											id="infoAccessConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/tips_and_tricks_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="tipsAndTricksConsoleIcon"
											className="nav-list-item-icon"
										>
											<HelpOutlineIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Tips & Tricks Console"
											id="tipsAndTricksConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/category_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="categoryConsoleIcon"
											className="nav-list-item-icon"
										>
											<CategoryIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Category Console"
											id="categoryConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/employee_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="employeeConsoleIcon"
											className="nav-list-item-icon"
										>
											<PeopleIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Employee Console"
											id="employeeConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/permissions"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="permissionsConsoleIcon"
											className="nav-list-item-icon"
										>
											<KeyIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Permissions Console"
											id="permissionsConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/role_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="roleConsoleIcon"
											className="nav-list-item-icon"
										>
											<NoEncryptionGmailerrorredIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Role Console"
											id="roleConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/dashboard_access_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="dashboardAccessConsoleIcon"
											className="nav-list-item-icon"
										>
											<DashboardIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Dashboard Access Console"
											id="dashboardAccessConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/job_titles_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="jobTitlesConsoleIcon"
											className="nav-list-item-icon"
										>
											<WorkIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Job Titles Console"
											id="jobTitlesConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/assemblies_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="assembliesConsoleIcon"
											className="nav-list-item-icon"
										>
											<BuildIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Assemblies Console"
											id="assembliesConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/property_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="propertyConsoleIcon"
											className="nav-list-item-icon"
										>
											<HomeWorkIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Property Console"
											id="propertyConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/project_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="projectConsoleIcon"
											className="nav-list-item-icon"
										>
											<DriveFileRenameOutlineIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Project Console"
											id="projectConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/phase_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="phaseConsoleIcon"
											className="nav-list-item-icon"
										>
											<ScatterPlotIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Phase Console"
											id="phaseConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
									<ListItemButton
										component={RouterLink}
										to="/admin/device_status_console"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="deviceStatusConsoleIcon"
											className="nav-list-item-icon"
										>
											<ImportantDevicesIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Device Status Console"
											id="deviceStatusConsoleText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
								</Collapse>
							</>
						)}

						{/* Profile */}
						{Boolean(
							props.accessibleTools
								.map((t) => t.name)
								.includes("Email Signature")
						) && (
							<>
								{/* PROFILE DROPDOWN */}
								<ListItemButton
									onClick={profileDropdownClicked}
									id="nav-list-item-button"
								>
									<ListItemIcon className="nav-list-item-icon">
										<ManageAccountsIcon
											style={{ color: "white" }}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Profile"
										id="profileListItemText"
										className="nav-list-item-text"
										disableTypography
									/>
									<div className="dropdownIconContainer">
										{profileOpen ? (
											<ExpandLess
												style={{ color: "white" }}
											/>
										) : (
											<ExpandMore
												style={{ color: "white" }}
											/>
										)}
									</div>
								</ListItemButton>
								{/* PROFILE LINKS */}
								<Collapse
									in={profileOpen}
									timeout="auto"
									unmountOnExit
									id="nav-dropdown-collapse"
								>
									<ListItemButton
										component={RouterLink}
										to="/profile/signature"
										id="nav-list-item-button"
									>
										<ListItemIcon
											id="mailSignatureIcon"
											className="nav-list-item-icon"
										>
											<AttachEmailIcon
												style={{ color: "white" }}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Email Signature"
											id="emailSignatureText"
											className="nav-list-item-text"
											disableTypography
										/>
									</ListItemButton>
								</Collapse>
							</>
						)}
					</div>
					<div id="nav-footer">
						<ListItemButton
							onClick={signOutPressed}
							id="nav-list-item-button"
						>
							<ListItemIcon className="nav-list-item-icon">
								<LogoutIcon style={{ color: "white" }} />
							</ListItemIcon>
							<ListItemText
								primary="Sign Out"
								primaryTypographyProps={{
									style: { fontWeight: 500 },
								}}
								className="nav-list-item-text"
								disableTypography
								id="signOutListItemText"
							/>
						</ListItemButton>
					</div>
				</List>
			</div>
		</Collapse>
	);
};

export default Navigation;
