// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./AssembliesConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import Button from "@mui/material/Button";
import { CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import AssembliesConsoleEditAdd from "./AssembliesConsoleEditAdd/AssembliesConsoleEditAdd";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

// OTHER
import { convertToPST } from "../../../constants";

const searchByParameterMap = {
	name: "Name",
};

const searchDefaultValue = "name";

// REACT COMPONENT
const AssembliesConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [isLoading, setIsLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);

	const [assemblies, setAssemblies] = useState([]);
	const [assembliesRows, setAssembliesRows] = useState([]);
	const [assembliesColumns, setAssembliesColumns] = useState([]);
	const [filteredAssemblies, setFilteredAssemblies] = useState([]);
	const [recordToEdit, setRecordToEdit] = useState({});

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);
	const [numberOfPages, setNumberOfPages] = useState(5);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredAssemblies]);

	// INPUT HANDLERS
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = async (doRefresh) => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({}); // clear record

		if (doRefresh) {
			await handleAPICalls(); // refresh data
		}
	};

	// API FUNCTIONS
	const handleAPICalls = async () => {
		setIsLoading(true);
		await fetchAssemblies();
		setIsLoading(false);
	};

	const fetchAssemblies = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/encompass/assemblies/all"
			);
			setAssemblies(res.data);
			setFilteredAssemblies(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
		} catch (error) {
			console.log(error);
		}
	};

	const filterAssemblies = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredAssemblies = assemblies.filter((assemblyObj) => {
			if (searchByParameter === "name") {
				return (assemblyObj.name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredAssemblies(filteredAssemblies);
		setNumberOfPages(
			Math.ceil(filteredAssemblies.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const orderAssembliesByDateCreated = (direction) => {
		let orderedAssemblies = [...filteredAssemblies];
		if (direction === "asc") {
			orderedAssemblies.sort(
				(a, b) => new Date(a.created) - new Date(b.created)
			);
		} else {
			orderedAssemblies.sort(
				(a, b) => new Date(b.created) - new Date(a.created)
			);
		}
		setFilteredAssemblies(orderedAssemblies);
	};

	const orderAssembliesByNames = (direction) => {
		let orderedAssemblies = [...filteredAssemblies];
		orderedAssemblies.sort((a, b) => {
			if (direction === "asc") {
				return a.name.localeCompare(b.name);
			} else {
				return b.name.localeCompare(a.name);
			}
		});
		setFilteredAssemblies(orderedAssemblies);
	};

	// HELPER FUNCTIONS
	const createColumnData = () => {
		setAssembliesColumns([{ text: "Name" }, { text: "Created" }, {}]);
	};

	const createRowData = () => {
		setAssembliesRows(
			filteredAssemblies.map((a) => [
				{ text: a.name },
				{ text: convertToPST(a.created) },
				{
					onClick: () => handleEditClick(a),
					icon: "edit",
				},
			])
		);
	};
	// RENDER
	return (
		<div className="AssembliesConsole">
			<div className="AssembliesConsoleHeader">
				<div className="AssembliesConsoleTitle">
					Assemblies Console
					<div className="AssembliesConsoleContainer">
						<Button
							variant="contained"
							color="primary"
							onClick={handleAddClick}
							disabled={isLoading}
						>
							New Record
						</Button>
					</div>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={filterAssemblies}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredAssemblies.length === 0 && <J2NoResultsFound />}
					{filteredAssemblies.length > 0 &&
						assembliesRows.length > 0 &&
						assembliesColumns.length > 0 && (
							<>
								<GenericTable
									columnData={assembliesColumns}
									rowData={assembliesRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<AssembliesConsoleEditAdd
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				recordToEdit={recordToEdit}
				editing={editing}
			/>
		</div>
	);
};

// EXPORT
export default AssembliesConsole;
