// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./DropboxInformationCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import { TextField, CircularProgress, IconButton } from "@mui/material";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS
import FolderCard from "./FolderCard/FolderCard";

// CONSTANTS
const oldPhaseFolders = [
	"1. General",
	"2. Diagnose",
	"3. Prescribe",
	"4. Treat",
	"5. Support",
];

// REACT COMPONENT
const DropboxInformationCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [folderSearchText, setFolderSearchText] = useState("");

	// API
	const [folders, setFolders] = useState([]);
	const [filteredFolders, setFilteredFolders] = useState([]);

	// This shows the user what folders will be created (and where) when they submit the pending job form
	const [helperMessage, setHelperMessage] = useState(
		"Please select a state where the project will live."
	);

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT

	// USE EFFECT
	useEffect(() => {
		fetchFolders(props.filePath);
	}, []);

	useEffect(() => {
		// Every time the filePath changes, we need to determine the helper message
		determineHelperMessage();
	}, [props.filePath]);

	useEffect(() => {
		// Every time the propertyInfo or projectInfo changes, we need to determine the helper message. It will stay the same besides the names of the folders changing
		determineHelperMessage();
	}, [props.propertyInfo.name, props.projectInfo.displayName]);

	// INPUT HANDLERS
	const folderClicked = async (folder) => {
		// If the file path is already at the max level, don't do anything
		if (props.filePath.split("/").length >= 5 || isLoading) {
			return;
		}

		// This prevents users from created new phase folders inside of folders such as "2. Diagnose" or "3. Prescribe"
		if (oldPhaseFolders.includes(folder)) {
			return;
		}

		let newFilePath = `${props.filePath}/${folder}`;

		setIsLoading(true);

		// Fetch the new folders and update the file path
		try {
			await fetchFolders(newFilePath);
			props.setFilePath(newFilePath);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	const folderSearchTextChanged = (e) => {
		setFolderSearchText(e.target.value);

		if (e.target.value === "") {
			setFilteredFolders(folders);
		} else {
			setFilteredFolders(
				folders.filter((folder) =>
					folder.name
						.toLowerCase()
						.includes(e.target.value.toLowerCase())
				)
			);
		}
	};

	const backButtonClicked = async () => {
		// If the file path is already at the min level ("/Projects"), don't do anything
		if (props.filePath.split("/").length <= 2 || isLoading) {
			return;
		}

		setIsLoading(true);

		let newPath = props.filePath
			.split("/")
			.slice(0, props.filePath.split("/").length - 1);

		await fetchFolders(newPath.join("/"));

		props.setFilePath(newPath.join("/"));

		setIsLoading(false);
	};

	// API FUNCTIONS
	const fetchFolders = async (path) => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/dropbox/listFolders`,
				{
					path: path,
				}
			);

			// entry[".tag"] === "folder" is used to filter out files and other non-folder entities
			setFolders(
				res.data.entries.filter((entry) => entry[".tag"] === "folder")
			);
			setFilteredFolders(
				res.data.entries.filter((entry) => entry[".tag"] === "folder")
			);

			setFolderSearchText("");
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	const determineHelperMessage = () => {
		if (props.filePath.split("/").length === 2) {
			// If the file path is at the min level ("/Projects"), show the default message and set as invalid
			setHelperMessage(
				"Please select a state folder where the project will live."
			);
			props.setIsDropboxInformationValid(false);
		} else if (props.filePath.split("/").length === 3) {
			// If the file path is at the property level ("/Projects/WA-SEA"), show the explanatory message and set as valid
			setHelperMessage(
				`This will create a property folder titled "${props.propertyInfo.name}" under "${props.filePath}" with corresponding project and phase folder(s).`
			);
			props.setIsDropboxInformationValid(true);
			props.setFolderCreationLevel("property");
		} else if (props.filePath.split("/").length === 4) {
			// If the file path is at the project level ("/Projects/WA-SEA/Property X"), show the explanatory message and set as valid
			setHelperMessage(
				`This will create a project folder titled "${props.projectInfo.displayName}" under "${props.filePath}" with corresponding phase folder(s).`
			);
			props.setIsDropboxInformationValid(true);
			props.setFolderCreationLevel("project");
		} else if (props.filePath.split("/").length === 5) {
			// If the file path is at the phase level ("/Projects/WA-SEA/Property X/Project Y"), show the explanatory message and set as valid
			setHelperMessage(
				`This will create phase folder(s) under "${props.filePath}".`
			);
			props.setIsDropboxInformationValid(true);
			props.setFolderCreationLevel("phase");
		}
	};

	// RENDER
	return (
		<div className="DropboxInformationCard">
			<div className="dropboxCardHeader">Dropbox Information</div>
			<div className="dropboxCardSubheader">File Path</div>
			<div className="filePathContainer">
				<IconButton onClick={backButtonClicked}>
					<ArrowBackIcon />
				</IconButton>
				<h1 style={{ flexGrow: 1 }}>{props.filePath}</h1>
			</div>
			<div className="filterDropboxFolderTextFieldContainer">
				<TextField
					sx={{ flex: 1 }}
					label="Search"
					value={folderSearchText}
					onChange={folderSearchTextChanged}
				/>
			</div>
			{isLoading && (
				<div className="folderLoadingContainer">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="resultsContainer">
					{filteredFolders.length === 0 && (
						<div className="noResultsFoundContainer">
							Nothing to see here!
						</div>
					)}
					{filteredFolders.length > 0 && (
						<div className="folderContainer">
							{filteredFolders.map((folder) => (
								<FolderCard
									key={folder.id}
									folder={folder}
									folderClicked={folderClicked}
								/>
							))}
						</div>
					)}
				</div>
			)}
			<div className="dropboxFileMessageContainer">{helperMessage}</div>
		</div>
	);
};

export default DropboxInformationCard;
