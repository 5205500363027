// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./Jobs.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../context/UserContext";

// MUI COMPONENTS
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const Jobs = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS

	// API FUNCTIONS
	// const callAPI = async () => {
	// 	const response = await getAPICall(
	// 		instance,
	// 		accounts[0],
	// 		"/api/jobs/test"
	// 	);

	// 	console.log(response);
	// };

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="Jobs">
			{/* <Button onClick={callAPI}>Call API</Button> */}
		</div>
	);
};

// EXPORT
export default Jobs;
