// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./SpecLibrary.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

import NewSpecDialog from "./NewSpecDialog/NewSpecDialog";

// CONSTANTS
import { SPEC_LIBRARY_RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	title: "Title",
	division: "Division",
	description: "Description",
};

const searchDefaultValue = "title";

// OTHER

// REACT COMPONENT
const SpecLibrary = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [specLibraryRecords, setSpecLibraryRecords] = useState([]);
	const [filteredSpecLibraryRecords, setFilteredSpecLibraryRecords] =
		useState([]);
	const [specLibraryRecordRows, setSpecLibraryRecordRows] = useState([]);
	const [specLibraryRecordColumns, setSpecLibraryRecordColumns] = useState(
		[]
	);

	const [divisionSortDirection, setDivisionSortDirection] = useState("none");
	const [titleSortDirection, setTitleSortDirection] = useState("none");

	const [statuses, setStatuses] = useState([]);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] = useState(
		SPEC_LIBRARY_RECORDS_PER_PAGE
	);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// Dialog
	const [newSpecDialogOpen, setNewSpecDialogOpen] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		// Fetch Spec Library Records
		fetchSpecLibraryRecords();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Spec Library",
			employeeID: employeeID,
			notes: "Accessed the Spec Library page",
		});
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredSpecLibraryRecords]);

	// INPUT HANDLERS
	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex(
			(currentPage - 1) * SPEC_LIBRARY_RECORDS_PER_PAGE
		);
		setPaginationEndIndex(currentPage * SPEC_LIBRARY_RECORDS_PER_PAGE);
	};

	// Dialog
	const newSpecDialogOpened = () => {
		setNewSpecDialogOpen(true);
	};

	const newSpecDialogClosed = () => {
		setNewSpecDialogOpen(false);
	};

	// API FUNCTIONS

	const fetchSpecLibraryRecords = async () => {
		setIsLoading(true);
		await getSpecificationRecords();
		await fetchStatuses();

		setIsLoading(false);
	};

	const getSpecificationRecords = async (sortBy, sortDirection) => {
		let queryParams;
		if (sortBy && sortDirection) {
			queryParams = `sortBy=${sortBy}&sortDirection=${sortDirection}`;
		}

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/allStandardSpecifications?${queryParams}`
			);

			setSpecLibraryRecords(res.data);
			setFilteredSpecLibraryRecords(res.data);
			setNumberOfPages(
				Math.ceil(res.data.length / SPEC_LIBRARY_RECORDS_PER_PAGE)
			);
		} catch (error) {
			console.log("Error in fetchSpecLibraryRecords: ", error);
		}
	};

	const fetchStatuses = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/statuses/all"
			);
			setStatuses(res.data);
		} catch (error) {
			console.log("Error in fetchStatuses: ", error);
		}
	};

	// HELPER FUNCTIONS
	// Filter records
	const filterSpecLibraryRecords = (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredRecords = specLibraryRecords.filter((record) => {
			if (searchByParameter === "title") {
				return (record.title ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "division") {
				let endIndex = searchValue.indexOf("_");

				if (endIndex !== -1) {
					return (
						record.division.slice(0, endIndex) ===
						searchValue.slice(0, endIndex)
					);
				} else {
					return (
						record.division.slice(0, searchValue.length) ===
						searchValue.slice(0, searchValue.length)
					);
				}
			} else if (searchByParameter === "description") {
				return (record.description ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredSpecLibraryRecords(filteredRecords);
		setNumberOfPages(
			Math.ceil(filteredRecords.length / SPEC_LIBRARY_RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(SPEC_LIBRARY_RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const sortAlpha = async (column) => {
		let direction;
		let columnToSort = column.text.toLowerCase();

		// set direction based on columntext
		switch (columnToSort) {
			case "division":
				direction = divisionSortDirection;
				break;
			case "title":
				direction = titleSortDirection;
				break;
			default:
				break;
		}

		// switch direction to new direction
		switch (direction) {
			case "none":
				direction = "ASC";
				break;
			case "ASC":
				direction = "DESC";
				break;
			case "DESC":
				direction = "ASC";
				break;
			default:
				break;
		}

		// all other columns are set to none
		if (columnToSort !== "division") {
			setDivisionSortDirection("none");
		}
		if (columnToSort !== "title") {
			setTitleSortDirection("none");
		}

		// set new direction
		switch (columnToSort) {
			case "division":
				setDivisionSortDirection(direction);
				break;
			case "title":
				setTitleSortDirection(direction);
				break;
			default:
				break;
		}

		if (direction === "none") {
			// default direction and column
			direction = "ASC";
			columnToSort = "division";
		}

		await getSpecificationRecords(columnToSort, direction);
	};

	const createColumnData = () => {
		setSpecLibraryRecordColumns([
			{
				text: "Division",
				sortFunction: sortAlpha,
				sortDirection: divisionSortDirection,
			},
			{
				text: "Title",
				sortFunction: sortAlpha,
				sortDirection: titleSortDirection,
			},
			{ text: "Description" },
			{ text: "Status" },
		]);
	};

	const createRowData = () => {
		setSpecLibraryRecordRows(
			filteredSpecLibraryRecords.map((record) => [
				{ text: record.division, noWrap: true },
				{
					text: (
						// note: using <a> tag instead of link and onClick props so on ctrl+click it opens in new tab
						<a href={`/specs/library/${record.id}`}>
							{record.title}
						</a>
					),
				},
				{
					text: record.description,
					notApplicable: !record.description,
				},
				{ text: record.status_name, textColor: record.status_color },
			])
		);
	};

	// RENDER
	return (
		<div className="SpecLibrary">
			<div className="specLibraryHeader">
				<div className="specLibraryHeaderTitle">
					Spec Library
					{props.isAdmin ? (
						<Button
							variant="contained"
							color="primary"
							onClick={newSpecDialogOpened}
							disabled={isLoading}
						>
							New Spec
						</Button>
					) : null}
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterSpecLibraryRecords(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="specLibraryTableContainerDiv">
					{filteredSpecLibraryRecords.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredSpecLibraryRecords.length > 0 &&
						specLibraryRecordRows.length > 0 &&
						specLibraryRecordColumns.length > 0 && (
							<>
								<GenericTable
									columnData={specLibraryRecordColumns}
									rowData={specLibraryRecordRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<NewSpecDialog
				open={newSpecDialogOpen}
				newSpecDialogClosed={newSpecDialogClosed}
				statuses={statuses}
			/>
		</div>
	);
};

// EXPORT
export default SpecLibrary;
