// REACT IMPORTS
import React, { useState, useContext } from "react";

// CSS
import "./VideoCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button } from "@mui/material";

// FUNCTIONAL COMPONENT
const VideoCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS

	// RENDER
	return (
		<div className="VideoCard">
			<div className="cardHeader">
				<div className="leftSideCardHeader">
					<div className="cardTitles">
						<h2>{props.cardTitle}</h2>
						<h3>{props.description}</h3>
					</div>
				</div>
				<Button
					sx={{ padding: "0px" }}
					onClick={() => props.reportIssueClicked(props.id)}
				>
					Report an Issue
				</Button>
			</div>
			<div className="videoContainer">
				<video
					id="videoPlayer"
					width="90%"
					height="auto"
					title=""
					poster=""
					controls
				>
					<source src={props.videoSrc} type="video/mp4" />
				</video>
			</div>
		</div>
	);
};

export default VideoCard;
