// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./ActiveTickets.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// CUSTOM COMPONENTS
import AdminTicketCard from "../AdminTicketCard/AdminTicketCard";
import EditTicketDialog from "../AdminTicketCard/EditTicketDialog/EditTicketDialog";
import AddCommentDialog from "../AdminTicketCard/AddCommentDialog/AddCommentDialog";

import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS

const searchByParameterMap = {
	title: "Title",
	category: "Category",
	applicationName: "Application Name",
	status: "Status",
	employee: "Employee",
};

const searchDefaultValue = "title";

// FUNCTIONAL COMPONENT
const ActiveTickets = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API Results
	const [tickets, setTickets] = useState([]);
	const [filteredTickets, setFilteredTickets] = useState([]);

	const [itMembers, setItMembers] = useState([]);
	const [ticketStatuses, setTicketStatuses] = useState([]);
	const [employees, setEmployees] = useState([]);

	// API States
	const [isLoading, setIsLoading] = useState(false);

	// Dialog States
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState({});

	const [addCommentDialogOpen, setAddCommentDialogOpen] = useState(false);
	const [ticketIDToAddComment, setTicketIDToAddComment] = useState(0);

	// USE EFFECT
	useEffect(() => {
		fetchActiveTickets();
	}, []);

	// INPUT HANDLERS
	const editTicketClicked = (record) => {
		setRecordToEdit(record);
		setEditDialogOpen(true);
	};

	const closeEditDialog = () => {
		setEditDialogOpen(false);
	};

	const addCommentClicked = (ticketID) => {
		setTicketIDToAddComment(ticketID);
		setAddCommentDialogOpen(true);
	};

	const closeAddCommentDialog = () => {
		setAddCommentDialogOpen(false);
	};

	// API HELPER FUNCTIONS
	const fetchActiveTickets = async () => {
		try {
			setIsLoading(true);

			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/tickets/active"
			);
			// Get the image URLs
			for (let i = 0; i < res.data.length; i++) {
				let imageName = res.data[i].s3_name;
				if (!imageName) continue;

				const imageUrl = await getAPICall(
					instance,
					accounts[0],
					"/api/tickets/screenshot",
					{
						imageName: imageName,
					}
				);

				res.data[i].image_url = imageUrl.data;
			}
			setTickets(res.data);
			setFilteredTickets(res.data);

			const itRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/it/all"
			);
			setItMembers(itRes.data);

			const statusRes = await getAPICall(
				instance,
				accounts[0],
				"/api/tickets/statuses"
			);
			setTicketStatuses(statusRes.data);

			const employeeRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/all"
			);
			setEmployees(employeeRes.data);

			setIsLoading(false);
		} catch (error) {
			console.error("Error fetching Active Tickets: ", error);
			setIsLoading(false);
		}
	};

	// HELPER FUNCTIONS
	const filterTickets = (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredTickets = tickets.filter((ticket) => {
			if (searchByParameter === "title") {
				return (ticket.name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "category") {
				return (ticket.category_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "applicationName") {
				return (ticket.application_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "status") {
				return (ticket.status_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "employee") {
				return (
					(ticket.submitted_by_first_name ?? "")
						.toLowerCase()
						.includes(searchValue.toLowerCase()) ||
					(ticket.submitted_by_last_name ?? "")
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				);
			}
		});

		setFilteredTickets(filteredTickets);
		setIsLoading(false);
	};

	// RENDER
	return (
		<div className="ActiveTickets">
			<div className="activeTicketsHeader">Active Tickets</div>
			<SearchBar
				searchByParameterMap={searchByParameterMap}
				searchDefaultValue={searchDefaultValue}
				filterResults={(searchValue, searchByParameter) =>
					filterTickets(searchValue, searchByParameter)
				}
			/>
			{isLoading && (
				<div className="loadingContainer">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="activeTicketsBody">
					{filteredTickets.length === 0 && <J2NoResultsFound />}
					{filteredTickets.map((ticket, index) => (
						<AdminTicketCard
							key={index}
							ticket={ticket}
							editTicketClicked={(record) =>
								editTicketClicked(record)
							}
							addCommentClicked={(ticketID) =>
								addCommentClicked(ticketID)
							}
						/>
					))}
				</div>
			)}
			<EditTicketDialog
				open={editDialogOpen}
				ticket={recordToEdit}
				editTicketDialogClosed={closeEditDialog}
				itMembers={itMembers}
				ticketStatuses={ticketStatuses}
				employees={employees}
				fetchTickets={fetchActiveTickets}
			/>
			<AddCommentDialog
				open={addCommentDialogOpen}
				ticketID={ticketIDToAddComment}
				addCommentDialogClosed={closeAddCommentDialog}
				fetchTickets={fetchActiveTickets}
			/>
		</div>
	);
};

export default ActiveTickets;
