// DEPENDENCIES
import React, { useState } from "react";

// CSS
import "./AssembliesAndGroupingsCard.css";

// MUI COMPONENTS
import { Button, Collapse, IconButton } from "@mui/material";

import {
	Edit as EditIcon,
	Diversity2 as Diversity2Icon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import EditAssembliesAndGroupingsCardDialog from "./EditAssembliesAndGroupingsCardDialog/EditAssembliesAndGroupingsCardDialog";

// CONSTANTS
import { defaultOrange } from "../../../../constants";

// FUNCTIONAL COMPONENT
const AssembliesAndGroupingsCard = (props) => {
	// STATES
	const [editAssembliesAndGroupingsOpen, setEditAssembliesAndGroupingsOpen] =
		useState(false);

	// INPUT HANDLERS
	const editAssembliesAndGroupingsClicked = () => {
		setEditAssembliesAndGroupingsOpen(true);
	};

	// API FUNCS
	const fetchDetailRecord = async () => {
		await props.fetchDetailRecord();
	};

	// RENDER
	return (
		<div className="AssembliesAndGroupingsCard">
			<div className="assembliesAndGroupingsCardHeader">
				<div
					className="assembliesAndGroupingsCardHeaderIconTitleContainer"
					onClick={props.assembliesAndGroupingsDropdownClicked}
				>
					<IconButton sx={{ color: defaultOrange }}>
						<Diversity2Icon sx={{ fontSize: 32 }} />
					</IconButton>
					<h2 className="assembliesAndGroupingsCardHeaderTitle">
						Assemblies & Groupings
					</h2>
				</div>
				{props.assembliesAndGroupingsOpen && (
					<div className="assembliesAndGroupingsCardHeaderEditButtonContainer">
						<Button
							variant="contained"
							color="secondary"
							startIcon={<EditIcon />}
							onClick={editAssembliesAndGroupingsClicked}
						>
							Edit
						</Button>
					</div>
				)}
			</div>
			<Collapse
				in={props.assembliesAndGroupingsOpen}
				id="assembliesAndGroupingsDropdownCollapse"
				timeout="auto"
				unmountOnExit
			>
				<div className="assembliesAndGroupingsDropdownContent">
					<div className="agContainer">
						<div className="agTitle">Assemblies</div>
						{(props.detailRecord.split_assemblies ?? "")
							.split("_SPLIT_")
							.map((assembly, index) => (
								<div className="agItem" key={index}>
									{assembly}
								</div>
							))}
					</div>
					<div className="agContainer">
						<div className="agTitle">Groupings</div>
						{(props.detailRecord.split_groupings ?? "")
							.split("_SPLIT_")
							.map((grouping, index) => (
								<div className="agItem" key={index}>
									{grouping}
								</div>
							))}
					</div>
				</div>
			</Collapse>
			<EditAssembliesAndGroupingsCardDialog
				open={editAssembliesAndGroupingsOpen}
				assemblies={props.assemblies}
				groupings={props.groupings}
				selectedAssemblies={props.selectedAssemblies}
				selectedGroupings={props.selectedGroupings}
				editAssembliesAndGroupingsCardDialogClosed={() =>
					setEditAssembliesAndGroupingsOpen(false)
				}
				recordToEdit={props.detailRecord}
				fetchDetailRecord={fetchDetailRecord}
			/>
		</div>
	);
};

export default AssembliesAndGroupingsCard;
