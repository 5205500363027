// DEPENDENCIES
import React from 'react';


// CSS
import './ProposalSummaryHelpDialog.css';


// MUI COMPONENTS
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Button from '@mui/material/Button';


// FUNCTIONAL COMPONENT
const ProposalSummaryHelpDialog = (props) => {
    // INPUT HANDLERS
    const formClosed = () => {
        props.onClose();
    }


    // RENDER
    return (
        <Dialog
            id='proposalSummaryHelpDialog'
            open={props.open}
            fullWidth
            onClose={formClosed}
        >
            <DialogTitle id='proposalSummaryHelpDialogTitle'>Proposal Summary</DialogTitle>
            <DialogContent
                id="proposalSummaryHelpDialogContent"
            >
                <div className='helpParagraph'>
                    <h2>Selecting a Service</h2>
                    <p>
                        This is the service we will perform during the project, also known as the "Phase". Once you select a service, a dropdown will expand 
                        below with the cost basis for the service. Time & Cost is selected by default.
                    </p>
                    <p>
                        Select up to four services. If you need to select more than four services, please contact the Sales team for assistance.
                    </p>
                    <p>
                        Please note: If you select "Invasive" as one of your services, you will be prompted to enter the number of openings at the property.
                    </p>
                    <h2 id='paragraphHeader'>Selecting a Component</h2>
                    <p>
                        This is the component we will be working on during the project. The list of components (i.e. "Building Envelope, Site") is also known
                        as the "Project". Select all components that apply. If there is a component that is not listed, please select the closest option and
                        leave a note in the "Additional Notes" field.
                    </p>
                    <h2 id='paragraphHeader'>Scope and Background</h2>
                    <p>
                        This is the paragraph under the "Background Section" of the proposal. <b>Please pay extra attention to spelling, grammar, punctuation, etc. 
                        as this will be presented to the client.</b> This should contain background information about what we are performing.
                    </p>
                    <h2 id='paragraphHeader'>Additional Notes</h2>
                    <p>
                        This is for anything you'd like to add that doesn't fit in the other fields. This is a good place to add notes about the project that would
                        be useful to the Sales team. Spelling and grammar aren't as important here as this is only internal, but please ensure the information is accurate.
                    </p>
                    <h2 id='paragraphHeader'>OIC</h2>
                    <p>
                        This is the office (OIC = Office in Charge) that will be responsible for the project.
                    </p>
                    <h2 id='paragraphHeader'>Proposal Due Date</h2>
                    <p>
                        This is the date the Sales team needs to submit the proposal to the client. Please ensure this date is accurate.
                    </p>
                </div>
                <div className='dialogButtons'>
                    <Button
                        id='closeProposalSummaryHelpDialogButton'
                        onClick={formClosed}
                        color='primary'
                        variant='contained'
                    >
                        Close
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}


export default ProposalSummaryHelpDialog;