// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./CoreRepairScopeDeleteDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { deleteAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const CoreRepairScopeDeleteDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS
	const handleDelete = async () => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/CoreRepairScopes/delete",
				{
					id: props.recordToDelete.id,
				}
			);
		} catch (error) {
			console.log(error);
			props.setSnackbarMessage("Error deleting core repair scope");
			props.setOpenSnackbar(true);
		}
	};

	const handleDeleteCoreRepairScopeSpecification = async (
		repairScopeSpecificationID
	) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/delete/repairScopeSpecifications",
				{
					id: repairScopeSpecificationID,
				}
			);
		} catch (error) {
			console.log(error);
			props.setSnackbarMessage(
				"Error deleting core repair scope specification"
			);
			props.setOpenSnackbar(true);
		}
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const handleDeleteClick = async () => {
		setLoading(true);
		// go through each repair scope specification core_repair_scopes_id that matches props.recordToDelete.id and delete
		props.repairScopeSpecifications.forEach(async (spec) => {
			if (spec.core_repair_scopes_id === props.recordToDelete.id) {
				await handleDeleteCoreRepairScopeSpecification(spec.id);
			}
		});
		await handleDelete();
		setLoading(false);
		props.onClose(true);
	};

	// RENDER
	return (
		<Dialog
			id="CoreRepairScopeDeleteDialog"
			open={props.open}
			onClose={props.onClose}
		>
			<DialogTitle id="CoreRepairScopeDeleteDialogTitle">
				Confirm Delete Core Repair Scope
			</DialogTitle>
			<DialogContent id="CoreRepairScopeDeleteDialogContent">
				{loading ? (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				) : (
					<>
						Are you sure you want to delete this core repair scope?
						<div id="CoreRepairScopeDeleteDialogButtons">
							<Button
								color="error"
								variant="outlined"
								onClick={() => props.onClose(false)}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								onClick={handleDeleteClick}
							>
								Delete
							</Button>
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default CoreRepairScopeDeleteDialog;
