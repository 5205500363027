// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./YourTickets.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// CUSTOM COMPONENTS
import TicketCard from "../TicketCard/TicketCard";

import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
const searchByParameterMap = {
	title: "Title",
	category: "Category",
	applicationName: "Application Name",
	status: "Status",
};

const searchDefaultValue = "title";

// RENDER
const YourTickets = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API Results
	const [tickets, setTickets] = useState([]);
	const [filteredTickets, setFilteredTickets] = useState([]);

	// API States
	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchTickets();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Tickets",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the Submitted Tickets page",
		});
	}, []);

	// API HANDLER FUNCTIONS
	const fetchTickets = async () => {
		try {
			setIsLoading(true);

			// Get tickets submitted by the current user
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/tickets/submitted",
				{
					submittedByID: employeeID,
				}
			);

			// Get the image URL for each ticket's screenshot
			for (let i = 0; i < res.data.length; i++) {
				let imageName = res.data[i].s3_name;
				if (!imageName) continue;

				const imageUrl = await getAPICall(
					instance,
					accounts[0],
					"/api/tickets/screenshot",
					{
						imageName: imageName,
					}
				);

				res.data[i].image_url = imageUrl.data;
			}

			setTickets(res.data);
			setFilteredTickets(res.data);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log("Error in fetchTickets: ", error);
		}
	};

	// HELPER FUNCTIONS
	const filterTickets = (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredTickets = tickets.filter((ticket) => {
			if (searchByParameter === "title") {
				return (ticket.name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "category") {
				return (ticket.category_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "applicationName") {
				return (ticket.application_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "status") {
				return (ticket.status_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredTickets(filteredTickets);
		setIsLoading(false);
	};

	// RENDER
	return (
		<div className="YourTickets">
			<div className="yourTicketsHeader">Your Submitted Tickets</div>
			<SearchBar
				searchByParameterMap={searchByParameterMap}
				searchDefaultValue={searchDefaultValue}
				filterResults={(searchValue, searchByParameter) =>
					filterTickets(searchValue, searchByParameter)
				}
			/>
			{isLoading && (
				<div className="loadingContainer">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="yourTicketsBody">
					{filteredTickets.length === 0 && <J2NoResultsFound />}
					{filteredTickets.map((ticket, index) => {
						return (
							<TicketCard
								key={index}
								id={ticket.id}
								title={ticket.name}
								description={ticket.description}
								requestType={ticket.request_type}
								priority={ticket.priority}
								category={ticket.category_name}
								appName={ticket.application_name}
								taskOwner={
									ticket.assignee_first_name +
									" " +
									ticket.assignee_last_name
								}
								submittedDate={dayjs(ticket.created).format(
									"MMM DD, YYYY"
								)}
								statusTitle={ticket.status_name}
								hexColor={ticket.hex_color}
								screenshotLink={ticket.image_url}
								fetchTickets={fetchTickets} // This is passed to TicketCard so that it can call fetchTickets after a ticket is marked as complete
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default YourTickets;
