// DEPENDENCIES
import React, { useEffect } from "react";

// CSS
import "./SpecificationList.css";

// MUI COMPONENTS

// MUI ICONS

// CUSTOM COMPONENTS
import SpecificationListItem from "./SpecificationListItem/SpecificationListItem";
// CONSTANTS

// OTHER

// REACT COMPONENT
const SpecificationList = (props) => {
	// STATES

	// CONTEXT

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS

	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="SpecificationList">
			{props.selectedSpecifications.map((spec) => (
				<SpecificationListItem
					key={spec.id}
					spec={spec}
					specificationRemoved={props.specificationRemoved}
					editSpecificationText={props.editSpecificationText}
					handleGenerateSingleSpecificationClicked={
						props.handleGenerateSingleSpecificationClicked
					}
					openSpecificationComments={(spec) =>
						props.openSpecificationComments(spec)
					}
					unsavedChanges={props.unsavedChanges}
					isJobLocked={props.isJobLocked}
				/>
			))}
		</div>
	);
};

// EXPORT
export default SpecificationList;
