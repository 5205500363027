// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./JobTitlesConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import NewJobTitlesDialog from "./NewJobTitlesDialog/NewJobTitlesDialog";
import EditJobTitlesDialog from "./EditJobTitlesDialog/EditJobTitlesDialog";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	name: "Name",
	department_name: "Department",
};

const searchDefaultValue = "name";

// OTHER

// REACT COMPONENT
const JobTitlesConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [isLoading, setIsLoading] = useState(false);
	const [newRecordOpen, setNewRecordOpen] = useState(false);
	const [editRecordOpen, setEditRecordOpen] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState(null);

	const [jobTitles, setJobTitles] = useState([]);
	const [filteredJobTitles, setFilteredJobTitles] = useState([]);
	const [jobTitlesRows, setJobTitlesRows] = useState([]);
	const [jobTitlesColumns, setJobTitlesColumns] = useState([]);
	const [departments, setDepartments] = useState([]);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);
	const [numberOfPages, setNumberOfPages] = useState(5);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchJobTitles();
		fetchDepartments();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredJobTitles]);

	// INPUT HANDLERS
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	const newRecordClicked = () => {
		setNewRecordOpen(!newRecordOpen);
	};

	const newJobTitlesDialogClosed = () => {
		setNewRecordOpen(false);
	};

	const editRecordClicked = (record) => {
		setEditRecordOpen(true);
		setRecordToEdit(record);
	};

	const editRecordClosed = () => {
		setEditRecordOpen(false);
	};

	// API FUNCTIONS
	// Fetches all job titles
	const fetchJobTitles = async () => {
		try {
			setIsLoading(true);

			const jobTitlesRes = await getAPICall(
				instance,
				accounts[0],
				"/api/jobTitles/all"
			);
			setJobTitles(jobTitlesRes.data);
			setFilteredJobTitles(jobTitlesRes.data);
			setNumberOfPages(
				Math.ceil(jobTitlesRes.data.length / RECORDS_PER_PAGE)
			);

			setIsLoading(false);
		} catch (error) {
			console.error(
				"JobTitlesConsole.js - fetchJobTitles() - Error fetching Job Titles Data: ",
				error
			);
		}
	};

	const fetchDepartments = async () => {
		try {
			setIsLoading(true);

			const departmentsRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/departments/all"
			);
			setDepartments(departmentsRes.data);

			setIsLoading(false);
		} catch (error) {
			console.error(
				"JobTitlesConsole.js - fetchDepartments() - Error fetching Departments Data: ",
				error
			);
		}
	};

	const filterJobTitles = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredJobTitles = jobTitles.filter((jobTitleObj) => {
			if (searchByParameter === "name") {
				return (jobTitleObj.name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "department_name") {
				return (jobTitleObj.department_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredJobTitles(filteredJobTitles);
		setNumberOfPages(
			Math.ceil(filteredJobTitles.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	const createColumnData = () => {
		setJobTitlesColumns([
			{ text: "Name" },
			{ text: "Department" },
			{}, //edit
		]);
	};

	const createRowData = () => {
		setJobTitlesRows(
			filteredJobTitles.map((jobTitle) => [
				{ text: jobTitle.name },
				{ text: jobTitle.department_name },
				{ icon: "edit", onClick: () => editRecordClicked(jobTitle) },
			])
		);
	};

	// RENDER
	return (
		<div className="JobTitlesConsole">
			<div className="JobTitlesConsoleHeader">
				<div className="JobTitlesConsoleTitle">
					Job Titles Console
					<div className="JobTitlesConsoleContainer">
						<Button
							variant="contained"
							color="primary"
							onClick={newRecordClicked}
							disabled={isLoading}
						>
							New Record
						</Button>
					</div>
				</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					searchByParameterMap={searchByParameterMap}
					filterResults={(searchValue, searchByParameter) =>
						filterJobTitles(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredJobTitles.length === 0 && <J2NoResultsFound />}
					{filteredJobTitles.length > 0 &&
						jobTitlesColumns.length > 0 &&
						jobTitlesRows.length > 0 && (
							<>
								<GenericTable
									columnData={jobTitlesColumns}
									rowData={jobTitlesRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<NewJobTitlesDialog
				open={newRecordOpen}
				departments={departments}
				newJobTitlesDialogClosed={newJobTitlesDialogClosed}
				fetchJobTitles={fetchJobTitles}
			/>
			<EditJobTitlesDialog
				open={editRecordOpen}
				departments={departments}
				fetchJobTitles={fetchJobTitles}
				editRecordClosed={editRecordClosed}
				recordToEdit={recordToEdit}
			/>
		</div>
	);
};

// EXPORT
export default JobTitlesConsole;
