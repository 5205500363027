// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DeviceStatusesConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import DeviceStatusesConsoleEditAddDialog from "./DeviceStatusesConsoleEditAddDialog/DeviceStatusesConsoleEditAddDialog";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import GenericTable from "../../Misc/Table/GenericTable";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	name: "Name",
	display_name: "Display Name",
};

const searchDefaultValue = "name";

// OTHER

// REACT COMPONENT
const DeviceStatusesConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [deviceStatuses, setDeviceStatuses] = useState([]);
	const [filteredDeviceStatuses, setFilteredDeviceStatuses] = useState([]);
	const [deviceStatusesRows, setDeviceStatusesRows] = useState([]);
	const [deviceStatusesColumns, setDeviceStatusesColumns] = useState([]);

	const [recordToEdit, setRecordToEdit] = useState({});
	const [loading, setLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);

	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredDeviceStatuses]);

	// INPUT HANDLERS
	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
		handleAPICalls();
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await getDeviceStatuses();
		setLoading(false);
	};

	const getDeviceStatuses = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/deviceStatuses/all`
			);
			setDeviceStatuses(res.data);
			setFilteredDeviceStatuses(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	// pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// search
	const filterDeviceStatusRecords = (searchValue, searchByParameter) => {
		let filteredRecords = deviceStatuses.filter((record) => {
			if (searchByParameter === "name") {
				return record.name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "display_name") {
				return record.display_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredDeviceStatuses(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const createRowData = () => {
		setDeviceStatusesRows(
			filteredDeviceStatuses.map((record) => [
				{ text: record.name },
				{ text: record.display_name },
				{ icon: "edit", onClick: () => handleEditClick(record) },
			])
		);
	};

	const createColumnData = () => {
		setDeviceStatusesColumns([
			{ text: "Name" },
			{ text: "Display Name" },
			{}, // blank column for edit icon
		]);
	};

	// RENDER
	return (
		<div className="DeviceStatusesConsole">
			<div className="DeviceStatusesConsoleHeader">
				<div className="DeviceStatusesConsoleHeaderTitle">
					Device Status Console
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddClick}
						disabled={loading}
					>
						New Device Status
					</Button>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterDeviceStatusRecords(
							searchValue,
							searchByParameter
						)
					}
				/>
			</div>
			{loading && (
				<div className="loadingDiv">
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<div className="tableContainerDiv">
					{filteredDeviceStatuses.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredDeviceStatuses.length > 0 &&
						deviceStatusesRows.length > 0 &&
						deviceStatusesColumns.length > 0 && (
							<>
								<GenericTable
									columnData={deviceStatusesColumns}
									rowData={deviceStatusesRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<DeviceStatusesConsoleEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				recordToEdit={recordToEdit}
				editing={editing}
			/>
		</div>
	);
};

// EXPORT
export default DeviceStatusesConsole;
