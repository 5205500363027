// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./SpecBookLinkItem.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../../../../config/apiCalls";

// CONTEXT

// MUI COMPONENTS
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS
import { convertToPST, defaultOrange } from "../../../../../../constants";

// OTHER

// REACT COMPONENT
const SpecBookLinkItem = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	// CONTEXT

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS

	// API FUNCTIONS
	const downloadClickHandler = async (specBook) => {
		const date = new Date(props.specBook.created); // Convert the created date to a Date object

		let title = `${props.specBook.title.replace(/ /g, "_")}_${String(
			date.getUTCMonth() + 1
		).padStart(2, "0")}_${String(date.getUTCDate()).padStart(
			2,
			"0"
		)}_${date.getUTCFullYear()}.pdf`;

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/specBooks/byS3Name",
				{
					s3Name: props.specBook.s3_name,
					title: title,
				}
			);

			window.open(res.data, "_blank");
		} catch (error) {
			console.log("Error in downloadClickHandler: ", error);
		}
	};

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div
			className="SpecBookLinkItem"
			style={
				props.showBottomBorder
					? {
							borderBottom: "1px solid #e0e0e0",
							paddingBottom: "1em",
					  }
					: { borderBottom: "none" }
			}
		>
			<div className="specBookLinkItemText">
				<div className="specBookLinkItemTitle">
					{props.specBook.title}
				</div>
				<div className="specBookLinkItemSubtitle">
					{props.specBook.subtitle}
				</div>
				<div className="specBookLinkItemUserContainer">
					<div className="specBookLinkItemUserPFPContainer">
						<img
							src={props.specBook.photo_url}
							alt={`${props.specBook.employee_name} Headshot`}
						/>
					</div>
					<div className="specBookLinkItemUser">
						<h2 className="specBookLinkItemSubmitter">
							{props.specBook.employee_name}
						</h2>
						<h3 className="specBookLinkItemDate">
							{convertToPST(props.specBook.created)}
						</h3>
					</div>
				</div>
			</div>
			<div className="specBookLinkItemIcons">
				<IconButton
					size="small"
					color="secondary"
					onClick={downloadClickHandler}
				>
					<OpenInNewIcon />
				</IconButton>
			</div>
		</div>
	);
};

// EXPORT
export default SpecBookLinkItem;
