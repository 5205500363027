// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./DeviceLeasesEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall, putAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// MUI ICONS
import { Close as CloseIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const DeviceLeasesEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(true);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [leaseNumber, setLeaseNumber] = useState("");
	const [originDate, setOriginDate] = useState(dayjs());
	const [termDate, setTermDate] = useState(dayjs());
	const [leaseLength, setLeaseLength] = useState(0);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.editing) {
			handlePopulateFieldsOnEdit();
		} else {
			setLoading(false);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	const handleLeaseNumberChange = (event) => {
		setLeaseNumber(event.target.value);
	};

	const handleOriginDateChange = (event) => {
		if (leaseLength !== 0) {
			setTermDate(event.add(Number(leaseLength), "months"));
		}
		setOriginDate(event);
	};

	const handleTermDateChange = (event) => {
		setTermDate(event);
	};

	const handleLeaseLengthChange = (event) => {
		const value = event.target.value;

		// Allow blank entries or whole numbers
		if (value !== "" && !/^\d+$/.test(value)) {
			return;
		}

		// Update term date only if the value is not blank
		if (value === "") {
			setTermDate(dayjs()); // Reset term date if the input is blank
		} else {
			setTermDate(originDate.add(Number(value), "months")); // Convert value to a number
		}

		setLeaseLength(value);
	};

	// API FUNCTIONS

	// handles population of fields when editing
	const handlePopulateFieldsOnEdit = () => {
		setLoading(true);

		setLeaseNumber(props.recordToEdit.lease_number);
		setOriginDate(dayjs(props.recordToEdit.origin_date));
		setTermDate(dayjs(props.recordToEdit.term_date));
		setLeaseLength(props.recordToEdit.lease_length);
		setLoading(false);
	};

	const addLease = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/addLease",
				{
					lease_number: leaseNumber,
					origin_date: originDate,
					term_date: termDate,
					lease_length: leaseLength,
					modified_by: employeeID,
				}
			);
			console.log(res.data);
			setAlertMessage("Lease added successfully");
			setAlertOpen(true);
		} catch (error) {
			console.error(error);
		}
	};

	const updateLease = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/updateLease",
				{
					id: props.recordToEdit.id,
					lease_number: leaseNumber,
					origin_date: originDate,
					term_date: termDate,
					lease_length: leaseLength,
					modified_by: employeeID,
				}
			);
			setAlertMessage("Lease updated successfully");
			setAlertOpen(true);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		if (props.editing) {
			await updateLease();
		} else {
			await addLease();
		}
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		props.onClose();
	};

	const handleCancelClick = () => {
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		props.onClose();
	};

	const resetInputFields = () => {
		setLeaseNumber("");
		setOriginDate(dayjs());
		setTermDate(dayjs());
		setLeaseLength(0);
	};

	const validateInputFields = () => {
		if (!leaseNumber) {
			setAlertMessage("Please enter a lease number");
			setAlertOpen(true);
			return false;
		}
		if (!originDate) {
			setAlertMessage("Please enter an origin date");
			setAlertOpen(true);
			return false;
		}
		if (leaseLength === 0 || !leaseLength) {
			setAlertMessage("Please enter a lease length");
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	// RENDER
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Dialog
				id="DeviceLeasesEditAddDialog"
				open={props.open}
				maxWidth="xl"
				fullWidth
				onClose={handleCancelClick}
			>
				<DialogTitle id="DeviceLeasesEditAddDialogTitle">
					{/* edit / add */}
					{props.editing ? "Edit Lease Record" : "Add Lease Record"}
				</DialogTitle>
				{loading && (
					<div className="deviceLeasesLoadingSpinner">
						<CircularProgress />
					</div>
				)}
				{!loading && (
					<DialogContent id="DeviceLeasesEditAddDialogContent">
						{alertOpen && (
							<Alert
								severity="warning"
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setAlertOpen(false);
										}}
									>
										<CloseIcon />
									</IconButton>
								}
							>
								{alertMessage}
							</Alert>
						)}

						<FormControl sx={{ flex: 1 }}>
							<div className="FormBlock">
								<div className="FormRow">
									<TextField
										sx={{ flexGrow: 1 }}
										label="Lease Number"
										variant="outlined"
										value={leaseNumber}
										onChange={handleLeaseNumberChange}
									/>
								</div>
								<div className="FormRow">
									<DatePicker
										sx={{ flexGrow: 1 }}
										label="Origin Date"
										value={originDate}
										onChange={handleOriginDateChange}
									/>
									<TextField
										sx={{ flexGrow: 1 }}
										label="Lease Length (Months)"
										variant="outlined"
										value={leaseLength}
										onChange={handleLeaseLengthChange}
									/>
									<DatePicker
										sx={{ flexGrow: 1 }}
										label="Term Date"
										value={termDate}
										onChange={handleTermDateChange}
										disabled
									/>
								</div>
							</div>
						</FormControl>

						<div className="editDeviceLeasesButtons">
							<Button
								variant="outlined"
								color="error"
								onClick={handleCancelClick}
								disabled={loading}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmitClick}
								disabled={loading}
							>
								Submit
							</Button>
						</div>
					</DialogContent>
				)}
			</Dialog>
		</LocalizationProvider>
	);
};

// EXPORT
export default DeviceLeasesEditAddDialog;
