import React from "react";

// MSAL
import { useMsal } from "@azure/msal-react";

// CSS
import "./Login.css";
import j2logo from "../../images/j2_logo_full.png";

// MUI COMPONENTS
import { Button } from "@mui/material";

// MUI ICONS
import { Microsoft as MicrosoftIcon } from "@mui/icons-material";

const Login = () => {
	const { instance } = useMsal();

	const handleLogin = async () => {
		await instance.handleRedirectPromise();

		const accounts = instance.getAllAccounts();
		if (accounts.length === 0) {
			// No user signed in
			instance.loginRedirect();
		}
	};

	return (
		<div className="Login">
			<div className="loginCard">
				<img src={j2logo} alt="j2 logo" id="loginLogo" />
				<Button
					variant="contained"
					startIcon={<MicrosoftIcon />}
					size="large"
					onClick={handleLogin}
					id="loginButton"
				>
					Sign in with Microsoft
				</Button>
			</div>
		</div>
	);
};

export default Login;
