// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DevicesServiceHistoryDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { convertToPST } from "../../../../constants";
import { postAPICall } from "../../../../config/apiCalls";

// OTHER

// REACT COMPONENT
const DevicesServiceHistoryDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(true);
	const [serviceHistory, setServiceHistory] = useState([]);
	const [ticketNumber, setTicketNumber] = useState("");
	const [incidentType, setIncidentType] = useState("");
	const [notes, setNotes] = useState("");

	const [alertMessage, setAlertMessage] = useState("");
	const [alertOpen, setAlertOpen] = useState(false);
	const [
		createNewServiceHistoryRecordOpen,
		setCreateNewServiceHistoryRecordOpen,
	] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		setLoading(true);
		const filteredServiceHistories = props.serviceHistories.filter(
			(record) => record.device_id === props.recordToShowServiceHistory.id
		);
		if (filteredServiceHistories.length > 0) {
			setServiceHistory(filteredServiceHistories);
		} else {
			setServiceHistory([]);
		}
		setLoading(false);
	}, [props.open, props.recordToShowServiceHistory, props.serviceHistories]);

	// INPUT HANDLERS

	const handleTicketNumberChange = (event) => {
		setTicketNumber(event.target.value);
	};

	const handleIncidentTypeChange = (event, newValue) => {
		setIncidentType(newValue);
	};

	const handleNotesChange = (event) => {
		setNotes(event.target.value);
	};

	// API FUNCTIONS

	const addServiceHistory = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/addServiceHistory",
				{
					device_id: props.recordToShowServiceHistory.id,
					ticket_number: ticketNumber,
					incident_type_id: incidentType.id,
					notes: notes,
					modified_by: employeeID,
				}
			);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		await addServiceHistory();
		resetInputFields();
		props.getServiceHistories();
	};

	const handleClose = () => {
		resetInputFields();
		setServiceHistory({});
		props.onClose();
	};

	const resetInputFields = () => {
		setTicketNumber("");
		setIncidentType("");
		setNotes("");
		setAlertMessage("");
		setAlertOpen(false);
		setCreateNewServiceHistoryRecordOpen(false);
	};

	const validateInputFields = () => {
		if (ticketNumber === "") {
			setAlertMessage("Ticket Number is required.");
			setAlertOpen(true);
			return false;
		}
		if (incidentType === "") {
			setAlertMessage("Incident Type is required.");
			setAlertOpen(true);
			return false;
		}
		if (notes === "") {
			setAlertMessage("Notes are required.");
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	const returnServiceHistoryTable = () => {
		return (
			<>
				<TableContainer>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Created</TableCell>
								<TableCell>Ticket Number</TableCell>
								<TableCell>Incident Type</TableCell>
								<TableCell>Notes</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{serviceHistory.map((record, index) => (
								<TableRow key={index}>
									<TableCell>
										{convertToPST(record.created)}
									</TableCell>
									<TableCell>
										{record.ticket_number}
									</TableCell>
									<TableCell>
										{
											props.incidentTypes.find(
												(incidentType) =>
													incidentType.id ===
													record.incident_type_id
											).display_name
										}
									</TableCell>
									<TableCell>{record.notes}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	// RENDER
	return (
		<Dialog
			id="DevicesServiceHistoryDialog"
			open={props.open}
			fullWidth
			maxWidth="xl"
			onClose={handleClose}
		>
			<DialogTitle id="DevicesServiceHistoryDialogTitle">
				Device Service History
			</DialogTitle>
			<DialogContent id="DevicesServiceHistoryDialogContent">
				{loading && (
					<div className="DevicesServiceHistoryLoadingSpinner">
						<CircularProgress />
					</div>
				)}
				{!loading && (
					<>
						{serviceHistory.length > 0 ? (
							returnServiceHistoryTable()
						) : (
							<J2NoResultsFound />
						)}

						{createNewServiceHistoryRecordOpen ? (
							<>
								{alertOpen && (
									<>
										<Alert severity="error">
											{alertMessage}
										</Alert>
									</>
								)}
								<div className="DevicesServiceHistoryFields">
									<TextField
										sx={{ flexGrow: 1 }}
										id="ticketNumber"
										label="Ticket Number"
										variant="outlined"
										value={ticketNumber}
										onChange={handleTicketNumberChange}
									/>
									<Autocomplete
										sx={{ flexGrow: 1 }}
										id="incidentType"
										options={props.incidentTypes}
										getOptionLabel={(option) =>
											option.display_name
										}
										onChange={handleIncidentTypeChange}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Incident Type"
												variant="outlined"
											/>
										)}
									/>
									<TextField
										sx={{ flexGrow: 1 }}
										id="notes"
										label="Notes"
										variant="outlined"
										value={notes}
										onChange={handleNotesChange}
									/>
								</div>
							</>
						) : (
							<Button
								variant="contained"
								color="primary"
								onClick={() =>
									setCreateNewServiceHistoryRecordOpen(true)
								}
							>
								Add New Service History Record
							</Button>
						)}
					</>
				)}
				<div className="DevicesServiceHistoryCloseButtons">
					<Button
						variant="outlined"
						color="error"
						onClick={handleClose}
					>
						Close
					</Button>
					{createNewServiceHistoryRecordOpen && (
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitClick}
							disabled={loading}
						>
							Submit
						</Button>
					)}
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default DevicesServiceHistoryDialog;
