// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./EditContentCardDialogSubmit.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// MUI COMPONENTS
import {
	Button,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Alert,
	CircularProgress,
	Collapse,
	IconButton,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// REACT COMPONENT
const EditContentCardDialogSubmit = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	const [comment, setComment] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS
	const handleCommentChange = (event) => {
		setComment(event.target.value);
	};

	const handleCancel = () => {
		setComment("");
		props.handleClose();
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			await props.createNewComment(comment);
			await props.handleSubmit(comment);
		} catch (error) {
			console.error(error);
			setError("An error occurred. Please try again.");
		}
		setError("");
		setLoading(false);
	};

	const closeAlert = () => {
		setError("");
	};

	// RENDER
	return (
		<Dialog
			id="EditContentCardDialog"
			open={props.open}
			onClose={handleCancel}
			fullWidth
		>
			<DialogTitle id="EditContentCardDialogTitle">
				Add a Comment Describing Your Changes
			</DialogTitle>
			<DialogContent id="EditContentCardDialogContent">
				{loading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!loading && (
					<>
						<Collapse
							sx={{ flex: 1 }}
							id="EditContentCardDialogAlert"
							in={error !== ""}
						>
							<Alert
								severity="error"
								action={
									<IconButton
										id="newSpecDialogCloseIconButton"
										aria-label="close"
										color="inherit"
										size="small"
										onClick={closeAlert}
									>
										<CloseIcon
											id="newDetailCloseIcon"
											fontSize="inherit"
										/>
									</IconButton>
								}
							>
								{error}
							</Alert>
						</Collapse>
						<Typography variant="body1">
							Please provide some details on the changes you made.
						</Typography>
						<TextField
							label="Add Comment"
							variant="outlined"
							fullWidth
							multiline
							rows={4}
							value={comment}
							onChange={handleCommentChange}
							className="comment-input"
							margin="normal"
						/>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="error" onClick={handleCancel}>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
					disabled={comment === ""}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

// EXPORT
export default EditContentCardDialogSubmit;
