// DEPENDENCIES
import React, { useState, useContext } from "react";

// CSS
import "./RejectPendingSpecChangeDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	TextField,
	Alert,
	Collapse,
	IconButton,
	CircularProgress,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS

// FUNCTIONAL COMPONENT
const RejectPendingSpecChangeDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Form data
	const [reasonForRejection, setReasonForRejection] = useState("");

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// INPUT HANDLERS
	// Form data handlers
	const reasonForRejectionChanged = (event) => {
		setReasonForRejection(event.target.value);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertMessage(message);
		setAlertOpen(true);
	};

	const closeAlert = () => {
		setAlertMessage("");
		setAlertOpen(false);
	};

	// BUTTON HANDLERS
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		await props.rejectPendingChangeRecord();

		setIsLoading(false);
		formClosed();
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const validateInputFields = () => {
		if (reasonForRejection === "") {
			openAlert("Please provide a reason for rejecting the change.");
			return false;
		}

		return true;
	};

	const resetForm = () => {
		setAlertMessage("");
		setAlertOpen(false);
		setIsLoading(false);
	};

	const formClosed = () => {
		resetForm();
		props.handleRejectDialogClose();
	};

	// RENDER
	return (
		<Dialog
			id="rejectPendingSpecChangeDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="rejectPendingSpecChangeDialogTitle">
				Reject Change
			</DialogTitle>
			<DialogContent id="rejectPendingSpecChangeDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="rejectPendingSpecChangeDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="rejectPendingSpecChangeDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="rejectPendingSpecChangeDialogAlert"
									severity="error"
									action={
										<IconButton
											id="rejectPendingSpecChangeDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="rejectPendingSpecChangeDialogCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="rejectPendingSpecChangeDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="rejectPendingSpecChangeDialogReasonForRejection"
								label="Reason for Rejection"
								value={reasonForRejection}
								onChange={reasonForRejectionChanged}
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default RejectPendingSpecChangeDialog;
