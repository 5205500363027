// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./AllDashboards.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

import DashboardCard from "../DashboardCard/DashboardCard";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
const searchByParameterMap = {
	title: "Title",
	category: "Category",
};

const searchDefaultValue = "title";

// REACT COMPONENT
const AllDashboards = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API results
	const [dashboards, setDashboards] = useState([]);
	const [filteredDashboards, setFilteredDashboards] = useState([]);

	// Feedback
	const [isLoading, setIsLoading] = useState(true);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchDashboards();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Dashboards",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: `Accessed the All Dashboards page.`,
		});
	}, []);

	// API FUNCTIONS
	const fetchDashboards = async () => {
		setIsLoading(true);

		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/dashboards/byUser",
				{
					userID: employeeID,
					isIndividual: 0, // False
				}
			);

			setDashboards(response.data);
			setFilteredDashboards(response.data);
		} catch (error) {
			console.log("Error in fetchDashboards: ", error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	const filterDashboards = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredDashboardRecords = dashboards.filter((dashboardObj) => {
			if (searchByParameter === "title") {
				return (dashboardObj.name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "category") {
				return (dashboardObj.dashboard_category_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredDashboards(filteredDashboardRecords);

		setIsLoading(false);
	};

	// RENDER
	return (
		<div className="AllDashboards">
			<div className="allDashboardsHeader">
				<div className="allDashboardsHeaderTitle">All Dashboards</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterDashboards(searchValue, searchByParameter)
					}
					searchByParameterMap={searchByParameterMap}
				/>
			</div>
			{isLoading && (
				<div className="loadingSpinnerContainer">
					<CircularProgress />
				</div>
			)}
			{!isLoading && (
				<div className="dashboardsContainer">
					{filteredDashboards.length === 0 && <J2NoResultsFound />}
					{filteredDashboards.map((dashboard, index) => (
						<DashboardCard key={index} dashboard={dashboard} />
					))}
				</div>
			)}
		</div>
	);
};

export default AllDashboards;
