// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./AddSpecificationsDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS

// CONTEXT

// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const AddSpecificationsDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Form Data
	const [selectedSpecifications, setSelectedSpecifications] = useState([]);

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setSelectedSpecifications(props.selectedSpecifications);
		}
	}, [props.open]);

	// INPUT HANDLERS
	const specificationSelected = (event, newValue) => {
		setSelectedSpecifications([...newValue]);
	};

	const specificationChecked = (event) => {
		let checkedSpecID = parseInt(event.target.name);
		let checked = event.target.checked;

		if (checked) {
			setSelectedSpecifications([
				...selectedSpecifications,
				props.allSpecifications.find(
					(spec) => spec.id === checkedSpecID
				),
			]);
		} else {
			setSelectedSpecifications(
				selectedSpecifications.filter(
					(spec) =>
						spec.id !== checkedSpecID &&
						spec.parent_id !== checkedSpecID
				)
			);
		}
	};

	// Button Handlers
	const submitButtonClicked = async () => {
		if (!validateInputFields()) {
			return;
		}

		setIsLoading(true);

		props.selectedSpecificationsChanged(selectedSpecifications);

		setIsLoading(false);

		formClosed();
	};

	// Feedback Handlers
	const openAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const closeAlert = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const resetForm = () => {
		closeAlert();
		setSelectedSpecifications([]);
	};

	const validateInputFields = () => {
		if (selectedSpecifications.length === 0) {
			openAlert("Please select at least one specification.");
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.addSpecificationsDialogClosed();
	};

	const isSpecChecked = (spec) => {
		if (selectedSpecifications.map((s) => s.id).includes(spec.id)) {
			// if the spec is already selected
			return true;
		}

		if (selectedSpecifications.map((s) => s.id).includes(spec.parent_id)) {
			// if the parent of the spec is already selected
			return true;
		}

		if (selectedSpecifications.map((s) => s.parent_id).includes(spec.id)) {
			// if the spec is a parent of a selected spec
			return true;
		}

		return false;
	};

	const isSpecDisabled = (spec) => {
		if (
			// functionality removed to allow for matching divisions
			// selectedSpecifications
			// 	.map((s) => s.division)
			// 	.includes(spec.division) &&
			selectedSpecifications.map((s) => s.id).includes(spec.id) ||
			selectedSpecifications.map((s) => s.parent_id).includes(spec.id)
		) {
			return true;
		}

		return false;
	};

	// RENDER
	return (
		<Dialog
			id="addSpecDialog"
			open={props.open}
			maxWidth="xl"
			disableEscapeKeyDown
		>
			<DialogTitle id="addSpecDialogTitle">Add Spec(s)</DialogTitle>
			<DialogContent id="addSpecDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="addSpecDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="addSpecDialogCollapse"
								in={alertOpen}
							>
								<Alert
									sx={{
										marginBottom: "1em",
									}}
									id="addSpecDialogAlert"
									severity="error"
									action={
										<IconButton
											id="addSpecDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="addSpecDialogAlertCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="addSpecDialogRow">
							<div className="addSpecDialogCheckboxContainer">
								<FormGroup
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "1em",
										width: "stretch",
									}}
								>
									{props.allSpecifications
										.filter((s) => s.status_id == 1)
										.map((spec) => (
											<FormControlLabel
												control={
													<Checkbox
														checked={isSpecChecked(
															spec
														)}
														name={spec.id}
														onChange={
															specificationChecked
														}
														disabled={isSpecDisabled(
															spec
														)}
													/>
												}
												label={
													<SpecificationCheckboxLabel
														spec={spec}
													/>
												}
											/>
										))}
								</FormGroup>
							</div>
						</div>
					</>
				)}
				<div className="dialogButtons" style={{ marginTop: "1em" }}>
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

const SpecificationCheckboxLabel = (props) => {
	return (
		<div>
			<div className="addSpecificationRowOne">
				<span className="addSpecificationDivision">
					{props.spec.division}
				</span>
				<span> - </span>
				<span className="addSpecificationTitle">
					{props.spec.title}
				</span>
			</div>
			<div className="addSpecificationDescription">
				{props.spec.description}
			</div>
		</div>
	);
};

// EXPORT
export default AddSpecificationsDialog;
