// DEPENDENCIES
import React, { useEffect } from "react";

// CSS
import "./FolderCard.css";

// MUI COMPONENTS
import { Folder as FolderIcon } from "@mui/icons-material";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const FolderCard = (props) => {
	// STATES

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS

	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div
			className="FolderCard"
			onClick={() => props.folderClicked(props.folder.name)}
		>
			<FolderIcon fontSize="small" color="white" />
			<h1>{props.folder.name}</h1>
		</div>
	);
};

// EXPORT
export default FolderCard;
