// DEPENDENCIES
import React, { useState, useContext } from "react";

// CSS
import "./PDFsCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// API IMPORTS
import { putAPICall } from "../../../../config/apiCalls";

// MUI COMPONENTS
import { Button, Collapse, IconButton } from "@mui/material";

import {
	PictureAsPdf as PictureAsPdfIcon,
	Download as DownloadIcon,
	Edit as EditIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import EditPDFsCardDialog from "./EditPDFsCardDialog/EditPDFsCardDialog";

// CONSTANTS
import { defaultOrange } from "../../../../constants";

// FUNCTIONAL COMPONENT
const PDFsCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// CONTEXT
	const employeeID = useContext(UserContext);

	// STATES
	const [editPDFsOpen, setEditPDFsOpen] = useState(false);

	// INPUT HANDLERS
	const editPDFsCardClicked = () => {
		setEditPDFsOpen(true);
	};

	const incrementDownloadCount = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/encompass/details/update/downloadCount/increment",
				{
					downloadCount: props.detailRecord.download_count,
					detailID: props.detailRecord.id,
				}
			);
		} catch (error) {
			console.error("Error incrementing download count: ", error);
		}
	};

	// RENDER
	return (
		<div className="pdfsCard">
			<div className="pdfsCardHeader">
				<div
					className="pdfsCardHeaderIconTitleContainer"
					onClick={props.pdfsDropdownClicked}
				>
					<IconButton sx={{ color: defaultOrange }}>
						<PictureAsPdfIcon sx={{ fontSize: 32 }} />
					</IconButton>
					<h2 className="pdfsCardHeaderTitle">Files</h2>
				</div>
				{props.pdfsOpen && (
					<div className="pdfsCardHeaderEditButtonContainer">
						<Button
							variant="contained"
							color="secondary"
							startIcon={<EditIcon />}
							onClick={editPDFsCardClicked}
						>
							Edit
						</Button>
					</div>
				)}
			</div>
			<Collapse
				in={props.pdfsOpen}
				id="pdfsDropdownCollapse"
				timeout="auto"
				unmountOnExit
			>
				<div className="pdfsDropdownContent">
					<div className="pdfDropdownRow">
						<div className="pdfContainer">
							<div className="pdfTitle">Black & White</div>
							<div className="pdfPreview">
								<img
									src={props.detailRecord.bw_png_url}
									alt="BW Thumbnail"
								/>
							</div>
							<div className="pdfDownloadButtonContainer">
								<Button
									sx={{ width: "stretch" }}
									variant="contained"
									color="black"
									startIcon={<DownloadIcon />}
									href={props.bwPDFFileLink}
									download
								>
									Download
								</Button>
							</div>
						</div>
						<div className="pdfContainer">
							<div
								className="pdfTitle"
								style={{ color: defaultOrange }}
							>
								Color
							</div>
							<div className="pdfPreview">
								<img
									src={props.detailRecord.color_png_url}
									alt="Color Thumbnail"
								/>
							</div>
							<div className="pdfDownloadButtonContainer">
								<Button
									sx={{ width: "stretch" }}
									variant="contained"
									color="secondary"
									startIcon={<DownloadIcon />}
									href={props.colorPDFFileLink}
									download
								>
									Download
								</Button>
							</div>
						</div>
					</div>
					<div className="pdfDropdownRow" id="cadFileRow">
						<div className="cadFileContainer">
							<div className="cadFileTitleLabel">CAD File</div>
							<div className="downloadButtonContainer">
								<Button
									variant="contained"
									color="secondary"
									startIcon={<DownloadIcon />}
									href={props.dwgFileLink}
									download
									onClick={incrementDownloadCount}
								>
									Download
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Collapse>
			<EditPDFsCardDialog
				open={editPDFsOpen}
				editPDFsCardDialogClosed={() => setEditPDFsOpen(false)}
				recordToEdit={props.detailRecord}
				fetchDetailRecord={props.fetchDetailRecord}
			/>
		</div>
	);
};

export default PDFsCard;
