// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./EditCategoryDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { putAPICall, deleteAPICall } from "../../../../config/apiCalls";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// CUSTOM COMPONENTS

// CONSTANTS

// REACT COMPONENT
const EditCategoryDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [id, setId] = useState(0);
	const [name, setName] = useState("");

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setId(props.recordToEdit.id || 0);
			setName(props.recordToEdit.name || "");
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	const nameChanged = (event) => {
		setName(event.target.value);
	};

	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;
		if (id === 0) return;

		setIsLoading(true);

		const res = await putAPICall(
			instance,
			accounts[0],
			"/api/tipsAndTricks/categories/update",
			{
				id: id,
				categoryName: name.toLowerCase(),
				modifiedBy: employeeID,
			}
		);

		setIsLoading(false);

		formClosed();
		props.fetchCategories();
	};

	const deleteButtonClicked = () => {
		setConfirmDeleteOpen(true);
	};

	const confirmDeleteButtonClicked = async () => {
		if (id === 0) return;

		const res = await deleteAPICall(
			instance,
			accounts[0],
			"/api/tipsAndTricks/categories/delete",
			{
				id: id,
			}
		);

		formClosed();
		props.fetchCategories();
	};

	// HELPER FUNCTIONS
	const alertOpened = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const alertClosed = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	const resetForm = () => {
		setId(0);
		setName("");
		alertClosed();
		setConfirmDeleteOpen(false);
	};

	const validateInputFields = () => {
		if (!name || !name.trim()) {
			alertOpened("Please enter a name.");
			return false;
		}

		// Check if category already exists
		for (let i = 0; i < props.categories.length; i++) {
			if (
				(props.categories[i].name ?? "").toLowerCase() ===
				name.toLowerCase()
			) {
				alertOpened("Category already exists.");
				return false;
			}
		}

		return true;
	};

	const formClosed = () => {
		props.editCategoryDialogClosed();
		resetForm();
	};

	// RENDER
	return (
		<Dialog
			id="editCategoryDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editCategoryDialogTitle">
				Edit Category
				<Button
					color="error"
					variant="contained"
					onClick={deleteButtonClicked}
					disabled={isLoading || confirmDeleteOpen}
				>
					Delete
				</Button>
			</DialogTitle>
			<DialogContent id="editCategoryDialogContent">
				<div className="editCategoryDialogAlerts">
					<Collapse id="newCategoryCollapse" in={alertOpen}>
						<Alert
							id="newCategoryAlert"
							severity="error"
							action={
								<IconButton
									id="newCategoryCloseIconButton"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={alertClosed}
								>
									<CloseIcon
										id="newTipCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					</Collapse>
				</div>
				<div className="editCategoryDialogRow">
					<TextField
						sx={{ flex: 1 }}
						id="edotCategoryName"
						label="Name"
						value={name}
						onChange={nameChanged}
					/>
				</div>
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
						disabled={isLoading || confirmDeleteOpen}
					>
						Cancel
					</Button>
					<div className="submitButtonContainer">
						<Button
							onClick={submitButtonClicked}
							variant="contained"
							disabled={isLoading || confirmDeleteOpen}
						>
							Submit
						</Button>
						{isLoading && (
							<CircularProgress
								size={24}
								sx={{
									color: "primary",
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</div>
				</div>
				<div className="editCategoryDialogAlerts">
					<Collapse id="confirmDeleteCollapse" in={confirmDeleteOpen}>
						<div className="confirmDeleteContainer">
							<h2 className="confirmDeleteHeader">
								Are you sure you want to delete this category?
							</h2>
							<div className="confirmDeleteButtonContainer">
								<Button
									variant="outlined"
									color="primary"
									onClick={() => setConfirmDeleteOpen(false)}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="error"
									onClick={confirmDeleteButtonClicked}
								>
									Confirm
								</Button>
							</div>
						</div>
					</Collapse>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EditCategoryDialog;
