// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DeviceLeasesDevices.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// API IMPORTS

// CONTEXT

// MUI COMPONENTS
import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
} from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS

// OTHER

// REACT COMPONENT
const DeviceLeasesDevices = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(true);
	const [devices, setDevices] = useState([]);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open && props.recordToShowDevices) {
			setLoading(true);
			// find all leases to devices records with the lease id from props.recordToShowDevices
			let devices = [];
			props.leasesToDevices.forEach((record) => {
				// find the device record with the device id from the leases to devices record
				if (record.lease_id == props.recordToShowDevices.id) {
					let device = props.devices.find(
						(deviceRecord) => deviceRecord.id === record.device_id
					);
					// add the device record to the devices array
					devices.push(device);
				}
			});
			setDevices(devices);
			setLoading(false);
		}
	}, [props.open, props.recordToShowDevices]);

	// INPUT HANDLERS

	// API FUNCTIONS

	// HELPER FUNCTIONS

	const handleClose = () => {
		setDevices([]);
		props.onClose();
	};

	// RENDER
	return (
		<Dialog
			id="DeviceLeasesDevicesDialog"
			open={props.open}
			fullWidth
			onClose={handleClose}
		>
			<DialogTitle id="DeviceLeasesDevicesDialogTitle">
				Devices
			</DialogTitle>
			{loading && (
				<div className="deviceLeasesDevicesLoadingSpinner">
					<CircularProgress />
				</div>
			)}

			{!loading &&
				(Object.keys(devices).length === 0 ? (
					<div className="deviceLeasesDevicesNoResultsFound">
						<J2NoResultsFound />
					</div>
				) : (
					<DialogContent id="deviceLeasesDevicesDialogContent">
						<div className="FormBlock">
							<Divider />
							{devices.map((record, index) => {
								return (
									<>
										<div className="FormRow">
											<strong>Category: </strong>
											{record.category_display_name}

											<strong>Name: </strong>
											{record.j2_device_name}
										</div>
										<Divider />
									</>
								);
							})}
						</div>
						<div className="deviceLeasesDevicesButtons">
							<Button
								variant="outlined"
								color="error"
								onClick={handleClose}
							>
								Close
							</Button>
						</div>
					</DialogContent>
				))}
		</Dialog>
	);
};

// EXPORT
export default DeviceLeasesDevices;
