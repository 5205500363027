// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./EmployeeConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import NewEmployeeDialog from "./NewEmployeeDialog/NewEmployeeDialog";
import EditEmployeeDialog from "./EditEmployeeDialog/EditEmployeeDialog";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	firstName: "First Name",
	lastName: "Last Name",
	email: "Email",
	office: "Office",
};

const searchDefaultValue = "firstName";

// REACT COMPONENT
const EmployeeConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// USE EFFECT
	useEffect(() => {
		fetchEmployees();
	}, []);

	// STATES
	const [employees, setEmployees] = useState([]);
	const [employeesRows, setEmployeesRows] = useState([]);
	const [employeesColumns, setEmployeesColumns] = useState([]);
	const [filteredEmployees, setFilteredEmployees] = useState([]);

	const [offices, setOffices] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [jobTitles, setJobTitles] = useState([]);

	const [newRecordOpen, setNewRecordOpen] = useState(false);

	// LOADING STATES
	const [isLoading, setIsLoading] = useState(false);

	// PAGINATION STATES
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// EDIT STATES
	const [editRecordOpen, setEditRecordOpen] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState({});

	// USE EFFECT
	useEffect(() => {
		fetchEmployees();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredEmployees]);

	// INPUT HANDLERS
	const newRecordClicked = () => {
		setNewRecordOpen(!newRecordOpen);
	};

	const newEmployeeDialogClosed = () => {
		setNewRecordOpen(false);
	};

	const editEmployeeClicked = (record) => {
		setEditRecordOpen(true);
		setRecordToEdit(record);
	};

	const editEmployeeClosed = () => {
		setEditRecordOpen(false);
	};

	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API HELPER FUNCTIONS
	const fetchEmployees = async () => {
		try {
			setIsLoading(true);

			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/all"
			);
			setEmployees(res.data);
			setFilteredEmployees(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));

			const officeRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/offices/all"
			);
			setOffices(officeRes.data);

			const departmentRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/departments/all"
			);
			setDepartments(departmentRes.data);

			const jobTitleRes = await getAPICall(
				instance,
				accounts[0],
				"/api/jobTitles/all"
			);
			setJobTitles(jobTitleRes.data);

			setIsLoading(false);
		} catch (error) {
			console.error(
				"EmployeeConsole.js - fetchEmployees() - Error fetching tips and tricks: ",
				error
			);
		}
	};

	const filterEmployees = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredEmployeeRecords = employees.filter((employeeObj) => {
			if (searchByParameter === "firstName") {
				return (employeeObj.first_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "lastName") {
				return (employeeObj.last_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "email") {
				return (employeeObj.email ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "office") {
				return (employeeObj.office ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredEmployees(filteredEmployeeRecords);
		setNumberOfPages(
			Math.ceil(filteredEmployeeRecords.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setEmployeesColumns([
			{ text: "First Name" },
			{ text: "Last Name" },
			{ text: "Email" },
			{ text: "Office" },
			{},
		]);
	};

	const createRowData = () => {
		setEmployeesRows(
			filteredEmployees.map((employee) => [
				{ text: employee.first_name },
				{ text: employee.last_name },
				{ text: employee.email, notApplicable: !employee.email },
				{ text: employee.office },
				{ onClick: () => editEmployeeClicked(employee), icon: "edit" },
			])
		);
	};

	// RENDER
	return (
		<div className="EmployeeConsole">
			<div className="employeeConsoleHeader">
				<div className="employeeConsoleHeaderTitle">
					Employee Console
					<div className="newRecordButtonContainer">
						<Button
							variant="contained"
							color="primary"
							onClick={newRecordClicked}
							disabled={isLoading}
						>
							New Record
						</Button>
					</div>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterEmployees(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredEmployees.length === 0 && <J2NoResultsFound />}
					{filteredEmployees.length > 0 &&
						employeesRows.length > 0 &&
						employeesColumns.length > 0 && (
							<>
								<GenericTable
									columnData={employeesColumns}
									rowData={employeesRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<NewEmployeeDialog
				open={newRecordOpen}
				newEmployeeDialogClosed={newEmployeeDialogClosed}
				fetchEmployees={fetchEmployees}
				offices={offices}
				departments={departments}
				jobTitles={jobTitles}
			/>
			<EditEmployeeDialog
				open={editRecordOpen}
				recordToEdit={recordToEdit}
				editEmployeeDialogClosed={editEmployeeClosed}
				fetchEmployees={fetchEmployees}
				offices={offices}
				departments={departments}
				jobTitles={jobTitles}
			/>
		</div>
	);
};

export default EmployeeConsole;
