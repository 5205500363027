// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./ITRequest.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	postAPICall,
	postFormDataAPICall,
} from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Radio,
	RadioGroup,
	FormControlLabel,
	Collapse,
	Alert,
	IconButton,
	CircularProgress,
	styled,
} from "@mui/material";
import {
	Close as CloseIcon,
	CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import J2MultilineTextField from "../../Misc/J2MultilineTextField/J2MultilineTextField";

// CONSTANTS
import { toTitleCase } from "../../../constants";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

// REACT COMPONENTS
const ITRequest = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API result states
	const [categories, setCategories] = useState([]);

	// Form states
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const [requestType, setRequestType] = useState("bugIssue");
	const [urgency, setUrgency] = useState("low");

	const [applicationName, setApplicationName] = useState("");

	const [categoryID, setCategoryID] = useState("");
	const [file, setFile] = useState(null);

	// API states
	const [isLoading, setIsLoading] = useState(false);

	const [successAlertOpen, setSuccessAlertOpen] = useState(false);
	const [errorAlertOpen, setErrorAlertOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchCategories();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Tickets",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the IT Request form",
		});
	}, []);

	// FORM INPUT HANDLERS
	const titleChanged = (event) => {
		setTitle(event.target.value);
	};

	const descriptionChanged = (event) => {
		setDescription(event.target.value);
	};

	const requestTypeChanged = (event) => {
		setRequestType(event.target.value);
	};

	const urgencyChanged = (event) => {
		setUrgency(event.target.value);
	};

	const applicationNameChanged = (event) => {
		setApplicationName(event.target.value);
	};

	const categoryIDChanged = (event) => {
		setCategoryID(event.target.value);
	};

	// FILE HANDLERS
	const handleFileChange = (e) => {
		if (!e.target.files || !e.target.files[0]) return;

		switch (e.target.files[0].type) {
			case "image/jpeg":
			case "image/png":
			case "image/jpg":
				break;
			default:
				errorAlertOpened(
					"Invalid file type. Please upload an image file (.jpeg, .png, .jpg)."
				);
				return;
		}

		errorAlertClosed();
		setFile(e.target.files[0]);
	};

	const removeScreenshotFile = () => {
		setFile(null);
	};

	// ALERT HANDLERS
	const successAlertClosed = () => {
		setSuccessAlertOpen(false);
	};

	const errorAlertOpened = (message) => {
		setErrorMessage(message);
		setErrorAlertOpen(true);
	};

	const errorAlertClosed = () => {
		setErrorAlertOpen(false);
	};

	// BUTTON HANDLERS
	const submitButtonClicked = async () => {
		try {
			setErrorAlertOpen(false);
			setErrorMessage("");
			setSuccessAlertOpen(false);

			setIsLoading(true);

			// Validate input fields
			let inputsValid = validateInputFields();
			if (!inputsValid) {
				setIsLoading(false);
				return;
			}

			// Screenshot upload
			var imageID = null;
			if (file) {
				let imageRes = await uploadImage();
				imageID = imageRes.data.insertId;
				if (!imageID) {
					setIsLoading(false);

					await postAPICall(
						instance,
						accounts[0],
						"/api/logs/automation/add",
						{
							employeeID: employeeID,
							date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
							success: false,
							message: `Failed to upload image for IT request.`,
							tool_name: "Tickets",
						}
					);
					errorAlertOpened(
						"Error uploading screenshot. Please try again."
					);
					return;
				}
			}

			// Get OIC
			let oicRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/office/byEmployeeID",
				{ employeeID: employeeID }
			);

			if (oicRes.data.length === 0 || !oicRes.data[0].state) {
				setIsLoading(false);
				errorAlertOpened(
					"Error getting OIC for your IT request. Please refresh the page and try again. If this problem persists, please contact your system administrator."
				);
				await postAPICall(
					instance,
					accounts[0],
					"/api/logs/automation/add",
					{
						employeeID: employeeID,
						date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
						success: false,
						message: `Error getting OIC for IT request.`,
						tool_name: "Tickets",
					}
				);
				return;
			}

			// Submit ticket to DB
			let res = await postAPICall(
				instance,
				accounts[0],
				"/api/tickets/add",
				{
					name: title || "",
					description: description || "",
					requestType: requestType || "bugIssue",
					priority: urgency || "low",
					applicationName: applicationName || "",
					imageID: imageID,
					taskOwnerID: oicRes.data[0].state === "WA" ? 1 : 2, // Leif: 1, Mick: 2
					categoryID: categoryID || null,
					submittedByID: employeeID,
					modifiedBy: employeeID,
				}
			);

			// Handle ticket submission error
			if (res.data.error) {
				setIsLoading(false);
				await postAPICall(
					instance,
					accounts[0],
					"/api/logs/automation/add",
					{
						employeeID: employeeID,
						date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
						success: false,
						message: `Error submitting IT request: ${res.data.error}`,
						tool_name: "Tickets",
					}
				);
				errorAlertOpened(
					"Error submitting your ticket to the database. Please refresh your browser and try again. If this problem persists, please contact your system administrator."
				);
				return;
			}

			await sendEmailToTaskOwners(employeeID);

			await postAPICall(
				instance,
				accounts[0],
				"/api/logs/automation/add",
				{
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					success: true,
					message: `Submitted IT request: ${title}`,
					tool_name: "Tickets",
				}
			);

			setIsLoading(false);
			setSuccessAlertOpen(true);
			resetForm();
		} catch (error) {
			errorAlertOpened(
				"Unknown error submitting ticket. Please refresh your browser and try again. If this problem persists, please contact your system administrator."
			);
		}
	};

	// API HELPER FUNCTIONS
	const fetchCategories = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/categories/all"
			);
			setCategories(res.data);
		} catch (error) {
			console.log("Error fetching categories: ", error);
		}
	};

	const uploadImage = async () => {
		const formData = new FormData();
		formData.append("image", file);

		try {
			const res = await postFormDataAPICall(
				instance,
				accounts[0],
				"/api/tickets/screenshot/upload",
				formData
			);

			return res;
		} catch (error) {
			console.log("Error uploading image: ", error);
		}
	};

	const sendEmailToTaskOwners = async (employeeID) => {
		try {
			await postAPICall(
				instance,
				accounts[0],
				"/api/tickets/email/submitted",
				{
					name: title,
					description: description,
					requestType: requestType,
					priority: urgency,
					applicationName: applicationName,
					categoryID: categoryID,
					submittedByID: employeeID,
					submittedDate: dayjs().format("MMM D, YYYY"),
				}
			);
		} catch (error) {
			console.log("Error sending email: ", error);
		}
	};

	// HELPER FUNCTIONS
	const validateInputFields = () => {
		if (
			!title ||
			!description ||
			!requestType ||
			!urgency ||
			!applicationName ||
			!categoryID
		) {
			let fields = [];
			if (!title) fields.push("Summary");
			if (!description) fields.push("Description");
			if (!requestType) fields.push("Request Type");
			if (!urgency) fields.push("Urgency");
			if (!applicationName) fields.push("Application Name");
			if (!categoryID) fields.push("Category");

			errorAlertOpened(
				`The following fields need to be filled out: ${fields.join(
					", "
				)}`
			);
			return false;
		}

		return true;
	};

	const resetForm = () => {
		setTitle("");
		setDescription("");
		setRequestType("bugIssue");
		setUrgency("low");
		setApplicationName("");

		setCategoryID(null);

		setErrorAlertOpen(false);
		setErrorMessage("");

		// Clear file input
		setFile(null);
	};

	// RENDER
	return (
		<div className="ITRequest">
			<div className="itRequestHeader">IT Request</div>
			<div className="itRequestCard">
				<div className="itRequestCardRow" id="successAlertRow">
					<Collapse
						sx={{ flex: 1 }}
						id="successMessageCollapse"
						in={successAlertOpen}
					>
						<Alert
							id="successMessageAlert"
							severity="success"
							action={
								<IconButton
									id="successMessagCloseIconButton"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={successAlertClosed}
								>
									<CloseIcon
										id="successMessageCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							}
						>
							Ticket request submitted successfully
						</Alert>
					</Collapse>
				</div>
				<div className="itRequestCardRow">
					<TextField
						sx={{ flex: 1 }}
						id="titleTextField"
						label="Summary"
						value={title}
						onChange={titleChanged}
					/>
				</div>
				<div className="itRequestCardRow">
					<J2MultilineTextField
						id="descriptionTextField"
						placeholder="Description"
						value={description}
						onChange={descriptionChanged}
					/>
				</div>
				<div className="itRequestCardRow">
					<div className="itRequestTypeContainer">
						<h2 className="radioGroupTitle">Request Type</h2>
						<FormControl>
							<RadioGroup
								defaultValue="bugIssue"
								id="formGroup"
								onChange={requestTypeChanged}
							>
								<FormControlLabel
									value="bugIssue"
									control={<Radio />}
									label="Bug/Issue"
								/>
								<FormControlLabel
									value="change"
									control={<Radio />}
									label="Change"
								/>
								<FormControlLabel
									value="newFeature"
									control={<Radio />}
									label="New Feature"
								/>
								<FormControlLabel
									value="other"
									control={<Radio />}
									label="Other"
								/>
							</RadioGroup>
						</FormControl>
					</div>
					<div className="itRequestTypeContainer">
						<h2 className="radioGroupTitle">Urgency</h2>
						<FormControl>
							<RadioGroup
								defaultValue="low"
								id="formGroup"
								onChange={urgencyChanged}
							>
								<FormControlLabel
									value="low"
									control={<Radio />}
									label="Low"
								/>
								<FormControlLabel
									value="medium"
									control={<Radio />}
									label="Medium"
								/>
								<FormControlLabel
									value="high"
									control={<Radio />}
									label="High"
								/>
							</RadioGroup>
						</FormControl>
					</div>
				</div>
				<div className="itRequestCardRow">
					<FormControl sx={{ flex: 1 }}>
						<InputLabel id="categoryLabel">Category</InputLabel>
						<Select
							labelId="categoryLabel"
							value={categoryID}
							label="Category"
							onChange={categoryIDChanged}
						>
							{categories.map((categoryObj) => (
								<MenuItem value={categoryObj.id + ""}>
									{toTitleCase(categoryObj.name)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						sx={{ flex: 1 }}
						id="applicationNameTextField"
						label="App Name"
						value={applicationName}
						onChange={applicationNameChanged}
					/>
				</div>
				<div className="itRequestCardRow">
					<div className="fileUploadContainer">
						<h2 className="fileUploadTitle">Screenshot</h2>
						<div className="fileUploadButtonTitle">
							<Button
								component="label"
								variant="contained"
								startIcon={<CloudUploadIcon />}
							>
								Screenshot
								<VisuallyHiddenInput
									type="file"
									id="itRequestFileInput"
									onChange={handleFileChange}
									accept="image/*"
								/>
							</Button>
							<h2 className="fileLabel">
								{file ? file.name : "No file selected"}
							</h2>
							{file && (
								<IconButton
									id="newDetailCloseIconButtonFile"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={removeScreenshotFile}
								>
									<CloseIcon
										id="newDetailCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							)}
						</div>
					</div>
				</div>
				<div className="itRequestCardRow" id="errorAlertRow">
					<Collapse
						sx={{ flex: 1 }}
						id="errorMessageCollapse"
						in={errorAlertOpen}
					>
						<Alert
							id="errorMessageAlert"
							severity="error"
							action={
								<IconButton
									id="errorMessageCloseIconButton"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={errorAlertClosed}
								>
									<CloseIcon
										id="errorMessageCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							}
						>
							{errorMessage}
						</Alert>
					</Collapse>
				</div>
				<div className="dialogButtons">
					<div className="submitButtonContainer">
						<Button
							onClick={submitButtonClicked}
							variant="contained"
							disabled={isLoading}
						>
							Submit
						</Button>
						{isLoading && (
							<CircularProgress
								size={24}
								sx={{
									color: "primary",
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ITRequest;
