// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./EditPDFsCardDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	postAPICall,
	postFormDataAPICall,
} from "../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@mui/material";

import {
	Close as CloseIcon,
	CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";

import { styled } from "@mui/material/styles";

// CONSTANTS
const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

// REACT COMPONENT
const EditPDFsCardDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Record states
	const [id, setId] = useState(0);

	const [bwPDFOriginalName, setBWPDFOriginalName] = useState("");
	const [colorPDFOriginalName, setColorPDFOriginalName] = useState("");
	const [cadFileOriginalName, setCadFileOriginalName] = useState("");

	// Files
	const [bwPDFFile, setBWPDFFile] = useState(null);
	const [colorPDFFile, setColorPDFFile] = useState(null);
	const [cadFile, setCadFile] = useState(null);

	// Feedback states
	const [isLoading, setIsLoading] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setId(props.recordToEdit.id);
			setBWPDFOriginalName(props.recordToEdit.bw_pdf_original_name);
			setColorPDFOriginalName(props.recordToEdit.color_pdf_original_name);
			setCadFileOriginalName(props.recordToEdit.original_cad_file_name);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	// File input handlers
	const bwPDFFileChanged = (e) => {
		if (e.target.files.length === 0) return;

		if (e.target.files[0].type !== "application/pdf") {
			openAlert("Please select a PDF (.pdf) file");
			return;
		}

		if (e.target.files[0].name.includes(".png")) {
			openAlert(
				'Your B&W PDF file cannot contain ".png" in the file name'
			);
			return;
		}

		setBWPDFFile(e.target.files[0]);
	};

	const colorPDFFileChanged = (e) => {
		if (e.target.files.length === 0) return;

		if (e.target.files[0].type !== "application/pdf") {
			openAlert("Please select a PDF (.pdf) file");
			return;
		}

		if (e.target.files[0].name.includes(".png")) {
			openAlert(
				'Your color PDF file cannot contain ".png" in the file name'
			);
			return;
		}

		setColorPDFFile(e.target.files[0]);
	};

	const cadFileChanged = (e) => {
		if (e.target.files.length === 0) return;

		setCadFile(e.target.files[0]);
	};

	const bwPDFFileRemoved = () => {
		setBWPDFFile(null);
	};

	const colorPDFFileRemoved = (e) => {
		setColorPDFFile(null);
	};

	const cadFileRemoved = () => {
		setCadFile(null);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const closeAlert = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	// Submit button handler
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		try {
			await updateBWPDF();
			await updateColorPDF();
			await updateCADFile();

			await postAPICall(
				instance,
				accounts[0],
				"/api/logs/automation/add",
				{
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					success: true,
					message: `Files edited on detail: ${
						props.recordToEdit.title
					}. New information is as follows: BW PDF: ${
						bwPDFFile ? bwPDFFile.name : "Unchanged"
					}; Color PDF: ${
						colorPDFFile ? colorPDFFile.name : "Unchanged"
					}; CAD File: ${cadFile ? cadFile.name : "Unchanged"}`,
					tool_name: "Detail Library",
				}
			);
		} catch (err) {
			openAlert(err);
		}

		setIsLoading(false);

		props.fetchDetailRecord();
		formClosed();
	};

	// API FUNCTIONS
	const updateBWPDF = async () => {
		if (!bwPDFFile) return null;

		const bwPDFformData = new FormData();
		bwPDFformData.append("pdf", bwPDFFile);
		bwPDFformData.append("detailID", id);

		const res = await postFormDataAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/bwPDF/add",
			bwPDFformData
		);

		return res;
	};

	const updateColorPDF = async () => {
		if (!colorPDFFile) return null;

		const colorPDFformData = new FormData();
		colorPDFformData.append("pdf", colorPDFFile);
		colorPDFformData.append("detailID", id);

		const res = await postFormDataAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/colorPDF/add",
			colorPDFformData
		);

		return res;
	};

	const updateCADFile = async () => {
		if (!cadFile) {
			return null;
		}

		const cadFormData = new FormData();
		cadFormData.append("dwg", cadFile);
		cadFormData.append("detailID", id);

		const res = await postFormDataAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/dwg/add",
			cadFormData
		);

		return res;
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		closeAlert();

		setBWPDFFile(null);
		setColorPDFFile(null);
		setCadFile(null);

		setBWPDFOriginalName("");
		setColorPDFOriginalName("");
		setCadFileOriginalName("");

		setIsLoading(false);
	};

	const validateInputFields = () => {
		return true;
	};

	const formClosed = () => {
		resetForm();
		props.editPDFsCardDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="editPDFsCardDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editPDFsCardDialogTitle">
				{props.recordToEdit.title}
			</DialogTitle>
			<DialogContent id="editPDFsCardDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="editPDFsCardDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="editPDFsCardDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="editPDFsCardDialogAlert"
									severity="error"
									action={
										<IconButton
											id="editPDFsCardDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="editPDFsCardDialogCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="editPDFsCardDialogRow">
							<div className="fileContainer">
								<div className="fileLabel">B&W PDF</div>
								<div className="fileInfo">
									<Button
										component="label"
										variant="contained"
										startIcon={<CloudUploadIcon />}
									>
										Upload
										<VisuallyHiddenInput
											type="file"
											id="fileInput"
											onChange={bwPDFFileChanged}
										/>
									</Button>
									<h2 className="fileNameLabel">
										{bwPDFFile
											? bwPDFFile.name
											: bwPDFOriginalName}
									</h2>
									{bwPDFFile && (
										<IconButton
											id="bwPDFCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={bwPDFFileRemoved}
										>
											<CloseIcon
												id="cadFileCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									)}
								</div>
							</div>
						</div>
						<div className="editPDFsCardDialogRow">
							<div className="fileContainer">
								<div className="fileLabel">Color PDF</div>
								<div className="fileInfo">
									<Button
										component="label"
										variant="contained"
										startIcon={<CloudUploadIcon />}
									>
										Upload
										<VisuallyHiddenInput
											type="file"
											id="fileInput"
											onChange={colorPDFFileChanged}
										/>
									</Button>
									<h2 className="fileNameLabel">
										{colorPDFFile
											? colorPDFFile.name
											: colorPDFOriginalName}
									</h2>
									{colorPDFFile && (
										<IconButton
											id="bwPDFCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={colorPDFFileRemoved}
										>
											<CloseIcon
												id="cadFileCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									)}
								</div>
							</div>
						</div>
						<div className="editPDFsCardDialogRow">
							<div className="fileContainer">
								<div className="fileLabel">CAD File</div>
								<div className="fileInfo">
									<Button
										component="label"
										variant="contained"
										startIcon={<CloudUploadIcon />}
									>
										Upload
										<VisuallyHiddenInput
											type="file"
											id="cadFileInput"
											onChange={cadFileChanged}
										/>
									</Button>
									<h2 className="fileNameLabel">
										{cadFile
											? cadFile.name
											: cadFileOriginalName}
									</h2>
									{cadFile && (
										<IconButton
											id="cadFileCloseIconButtonFile"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={cadFileRemoved}
										>
											<CloseIcon
												id="cadFileCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									)}
								</div>
							</div>
						</div>
						<div className="dialogButtons">
							<Button
								onClick={formClosed}
								variant="outlined"
								color="error"
							>
								Cancel
							</Button>
							<Button
								onClick={submitButtonClicked}
								variant="contained"
							>
								Submit
							</Button>
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default EditPDFsCardDialog;
