// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./EditTipDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// API IMPORTS
import { putAPICall, deleteAPICall } from "../../../../config/apiCalls";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// CONSTANTS
import { toTitleCase, TTdataTypes } from "../../../../constants";

// REACT COMPONENT
const EditTipDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [id, setId] = useState(0);

	const [dataType, setDataType] = useState("iframe");

	const [title, setTitle] = useState("");
	const [categoryID, setCategoryID] = useState(1); // 'it' (information technology) has an ID of 1

	const [description, setDescription] = useState("");

	const [iframeLink, setIframeLink] = useState("");

	const [alertOpen, setAlertOpen] = useState(false);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setId(props.recordToEdit.id || 0);
			setDataType(props.recordToEdit.data_type || "");
			setTitle(props.recordToEdit.title || "");
			setCategoryID(props.recordToEdit.category_id || 1);
			setDescription(props.recordToEdit.description || "");
			setIframeLink(props.recordToEdit.data || "");
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	const dataTypeChanged = (event) => {
		setDataType(event.target.value);
	};

	const titleChanged = (event) => {
		setTitle(event.target.value);
	};

	const categoryIDChanged = (event) => {
		setCategoryID(event.target.value);
	};

	const descriptionChanged = (event) => {
		setDescription(event.target.value);
	};

	const iframeLinkChanged = (event) => {
		setIframeLink(event.target.value);
	};

	// BUTTONS
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;
		if (id === 0) return;

		setIsLoading(true);
		let res = await putAPICall(
			instance,
			accounts[0],
			"/api/tipsAndTricks/update",
			{
				categoryID: categoryID,
				dataType: dataType,
				title: title,
				description: description,
				data: iframeLink,
				visibility: props.recordToEdit.visibility, // keep visibility the same, edit using button on table itself
				id: id,
				modifiedBy: employeeID,
			}
		);
		setIsLoading(false);

		formClosed();
		props.fetchTipsAndTricksOnSubmit();
	};

	const deleteButtonClicked = () => {
		setConfirmDeleteOpen(true);
	};

	const confirmDeleteButtonClicked = async () => {
		if (id === 0) return;

		const res = await deleteAPICall(
			instance,
			accounts[0],
			"/api/tipsAndTricks/delete",
			{
				id: id,
			}
		);

		formClosed();
		props.fetchTipsAndTricks();
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		setDataType("iframe");
		setTitle("");
		setCategoryID(1);
		setDescription("");
		setIframeLink("");
		setAlertOpen(false);
		setConfirmDeleteOpen(false);
	};

	const validateInputFields = () => {
		if (!title || !categoryID || !description || !iframeLink) {
			setAlertOpen(true);
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.editTipDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="editTipDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editTipDialogTitle">
				Edit Tip
				<Button
					color="error"
					variant="contained"
					onClick={deleteButtonClicked}
					disabled={isLoading || confirmDeleteOpen}
				>
					Delete
				</Button>
			</DialogTitle>
			<DialogContent id="editTipDialogContent">
				<div className="editTipDialogAlerts">
					<Collapse id="editTipCollapse" in={alertOpen}>
						<Alert
							id="editTipAlert"
							severity="error"
							action={
								<IconButton
									id="editTipCloseIconButton"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setAlertOpen(false);
									}}
								>
									<CloseIcon
										id="editTipCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							}
						>
							Please fill out all fields
						</Alert>
					</Collapse>
				</div>
				<div className="editTipDialogRow">
					<FormControl sx={{ flex: 1 }}>
						<InputLabel id="dataTypeLabel">Data Type</InputLabel>
						<Select
							sx={{ flexGrow: 1 }}
							id="newTipDataType"
							value={dataType}
							onChange={dataTypeChanged}
							labelId="dataTypeLabel"
							label="Data Type"
						>
							{TTdataTypes.map((categoryObj, index) => (
								<MenuItem key={categoryObj} value={categoryObj}>
									{toTitleCase(categoryObj)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className="editTipDialogRow">
					<TextField
						sx={{ flex: 1 }}
						id="editTipTitle"
						label="Title"
						value={title}
						onChange={titleChanged}
					/>
					<FormControl sx={{ flex: 1 }}>
						<InputLabel id="categoryLabel">Category</InputLabel>
						<Select
							labelId="categoryLabel"
							id="categorySelect"
							value={categoryID}
							label="Category"
							onChange={categoryIDChanged}
						>
							{props.categories.map((categoryObj) => (
								<MenuItem
									key={categoryObj.id}
									value={categoryObj.id}
								>
									{toTitleCase(categoryObj.name)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className="editTipDialogRow">
					<TextField
						sx={{ flex: 1 }}
						id="editTipDescription"
						label="Description"
						value={description}
						onChange={descriptionChanged}
					/>
				</div>
				<div className="editTipDialogRow">
					<TextField
						sx={{ flex: 1 }}
						id="editTipIframeLink"
						label="Data"
						value={iframeLink}
						onChange={iframeLinkChanged}
					/>
				</div>
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
						disabled={isLoading || confirmDeleteOpen}
					>
						Cancel
					</Button>
					<Button
						onClick={submitButtonClicked}
						variant="contained"
						disabled={isLoading || confirmDeleteOpen}
					>
						Submit
					</Button>
					{isLoading && (
						<CircularProgress
							size={24}
							sx={{
								color: "primary",
								position: "absolute",
								top: "50%",
								left: "50%",
								marginTop: "-12px",
								marginLeft: "-12px",
							}}
						/>
					)}
				</div>
				<div className="editTipDialogAlerts">
					<Collapse id="confirmDeleteCollapse" in={confirmDeleteOpen}>
						<div className="confirmDeleteContainer">
							<h2 className="confirmDeleteHeader">
								Are you sure you want to delete this record?
							</h2>
							<div className="confirmDeleteButtonContainer">
								<Button
									variant="outlined"
									color="primary"
									onClick={() => setConfirmDeleteOpen(false)}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="error"
									onClick={confirmDeleteButtonClicked}
								>
									Confirm
								</Button>
							</div>
						</div>
					</Collapse>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EditTipDialog;
