// DEPENDENCIES
import React, { useState } from "react";

// CSS
import "./SpecBookLinksCard.css";

// MUI COMPONENTS
import Button from "@mui/material/Button";

import Collapse from "@mui/material/Collapse";

import IconButton from "@mui/material/IconButton";

// MUI ICONS
import BookmarksIcon from "@mui/icons-material/Bookmarks";

// CUSTOM COMPONENTS
import SpecBookLinkItem from "./SpecBookLinkItem/SpecBookLinkItem";
import J2NoResultsFound from "../../../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { defaultOrange } from "../../../../../constants";

// REACT COMPONENT
const SpecBookLinksCard = (props) => {
	// STATES
	const [specBookCollapseOpen, setSpecBookCollapseOpen] = useState(false);

	// RENDER
	return (
		<div className="SpecBookLinksCard">
			<div className="specBookLinksCardHeader">
				<div className="specBookLinksCardHeaderIconTitleContainer">
					<IconButton sx={{ color: defaultOrange }}>
						<BookmarksIcon sx={{ fontSize: 32 }} />
					</IconButton>
					<h2 className="specBookLinksCardHeaderTitle">
						Generated Spec Books
					</h2>
				</div>
			</div>
			<div className="specBookLinksCardContent">
				{props.specBooks.length === 0 && <J2NoResultsFound />}
				{props.specBooks.length <= 3 && (
					<>
						{props.specBooks.map((specBook, index) => (
							<SpecBookLinkItem
								key={index}
								specBook={specBook}
								showBottomBorder={
									index !== props.specBooks.length - 1
								}
							/>
						))}
					</>
				)}
				{props.specBooks.length > 3 && (
					<>
						<Collapse
							in={specBookCollapseOpen}
							collapsedSize="24.5em" // 3 items * 7.5em (height of each item) + 2em (gap)
						>
							{props.specBooks.map((specBook, index) => (
								<SpecBookLinkItem
									key={index}
									specBook={specBook}
									showBottomBorder={
										index !== props.specBooks.length - 1
									}
								/>
							))}
						</Collapse>
						<Button
							onClick={() =>
								setSpecBookCollapseOpen(!specBookCollapseOpen)
							}
							variant="text"
							color="primary"
						>
							{specBookCollapseOpen ? "Show Less" : "Show More"}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

// EXPORT
export default SpecBookLinksCard;
