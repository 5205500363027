// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./EditDashboardAccessDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { deleteAPICall, postAPICall } from "../../../../config/apiCalls";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// CONSTANTS

// REACT COMPONENT
const EditDashboardAccessDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Record states
	const [id, setId] = useState(0);

	// const [selectedTools, setSelectedTools] = useState([]);
	const [selectedDashboardCategories, setSelectedDashboardCategories] =
		useState([]);

	// Feedback states
	const [isLoading, setIsLoading] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setId(props.recordToEdit.id);
			setSelectedDashboardCategories(props.selectedDashboardCategories);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	// Form input handlers
	const dashboardCategorySelected = (event, newValue) => {
		setSelectedDashboardCategories([...newValue]);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const closeAlert = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	// Submit button handler
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		try {
			await removeDashboardCategories();
			await addDashboardCategories();
		} catch (err) {
			openAlert(err);
		}

		props.fetchRoles();
		formClosed();

		setIsLoading(false);
	};

	// API FUNCTIONS
	const removeDashboardCategories = async () => {
		const res = await deleteAPICall(
			instance,
			accounts[0],
			"/api/roles/dashboardCategories/deleteByRole",
			{
				roleID: id,
			}
		);

		return res;
	};

	const addDashboardCategories = async () => {
		for (let i = 0; i < selectedDashboardCategories.length; i++) {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/roles/dashboardCategories/add",
				{
					roleID: id,
					dashboardCategoryID: selectedDashboardCategories[i].id,
					modifiedBy: employeeID,
				}
			);
		}
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		closeAlert();

		setId(0);
	};

	const validateInputFields = () => {
		if (selectedDashboardCategories.length === 0) {
			openAlert("Please select at least one tool.");
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.editDashboardAccessDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="editDashboardAccessDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editDashboardAccessDialogTitle">
				{props.recordToEdit.role_name}
			</DialogTitle>
			<DialogContent id="editDashboardAccessDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="editDashboardAccessDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="editDashboardAccessDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="editDashboardAccessDialogAlert"
									severity="error"
									action={
										<IconButton
											id="editDashboardAccessDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="editDashboardAccessDialogCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="editDashboardAccessDialogRow">
							<Autocomplete
								sx={{ flex: 1 }}
								limitTags={2}
								onChange={dashboardCategorySelected}
								multiple
								value={selectedDashboardCategories}
								id="dashboardCategoriesSelect"
								options={props.dashboardCategories.filter(
									(category) =>
										!selectedDashboardCategories
											.map((c) => c.id)
											.includes(category.id)
								)}
								getOptionLabel={(option) => option.name}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField
										{...params}
										label="Dashboard Categories"
										placeholder="Dashboard Categories"
									/>
								)}
							/>
						</div>
						<div className="dialogButtons">
							<Button
								onClick={formClosed}
								variant="outlined"
								color="error"
							>
								Cancel
							</Button>
							<Button
								onClick={submitButtonClicked}
								variant="contained"
							>
								Submit
							</Button>
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default EditDashboardAccessDialog;
