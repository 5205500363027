import React from "react";

// CSS
import "./J2PageNotFound.css";
import pageNotFoundImage from "../../../images/page_not_found.png";

// REACT COMPONENT
function J2PageNotFound() {
	return (
		<div className="J2PageNotFound">
			<h1 className="pageNotFoundTitle">Page Not Found (404)</h1>
			<div className="imageContainer">
				<img src={pageNotFoundImage} alt="Page Not Found" />
			</div>
			<p className="notFoundMessage">
				Jerod searched high and low, but he couldn't find the page you
				were looking for. Return to the home page <a href="/">here</a>.
			</p>
		</div>
	);
}

export default J2PageNotFound;
