// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./TipsAndTricksConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// API IMPORTS
import { getAPICall, putAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { Button, Checkbox, CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import NewTipDialog from "./NewTipDialog/NewTipDialog";
import EditTipDialog from "./EditTipDialog/EditTipDialog";

import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { RECORDS_PER_PAGE, toTitleCase } from "../../../constants";

const searchByParameterMap = {
	title: "Title",
	category: "Category",
	description: "Description",
};

const searchDefaultValue = "title";

// REACT COMPONENT
const TipsAndTricksConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// CONTEXT
	const employeeID = useContext(UserContext);

	// STATES
	const [tipsAndTricks, setTipsAndTricks] = useState([]);
	const [filteredTipsAndTricks, setFilteredTipsAndTricks] = useState([]);
	const [tipsAndTricksColumns, setTipsAndTricksColumns] = useState([]);
	const [tipsAndTricksRows, setTipsAndTricksRows] = useState([]);
	const [categories, setCategories] = useState([]);

	const [checkedAllVisibility, setCheckedAllVisibility] = useState(false);

	const [newRecordOpen, setNewRecordOpen] = useState(false);

	const [searchParameter, setSearchParameter] = useState("title");
	const [searchByValue, setSearchByValue] = useState("");

	// LOADING STATES
	const [isLoading, setIsLoading] = useState(false);

	// PAGINATION STATES
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// EDIT STATES
	const [editRecordOpen, setEditRecordOpen] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState({});

	// USE EFFECT
	useEffect(() => {
		fetchTipsAndTricks();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredTipsAndTricks]);

	// INPUT HANDLERS
	const newRecordClicked = () => {
		setNewRecordOpen(!newRecordOpen);
	};

	const newTipDialogClosed = () => {
		setNewRecordOpen(false);
	};

	const editTipClicked = (record) => {
		setEditRecordOpen(true);
		setRecordToEdit(record);
	};

	const editTipClosed = () => {
		setEditRecordOpen(false);
	};

	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	const visibilityClicked = async (record) => {
		let res = await putAPICall(
			instance,
			accounts[0],
			"/api/tipsAndTricks/update",
			{
				categoryID: record.category_id,
				dataType: record.data_type,
				title: record.title,
				description: record.description,
				data: record.data,
				visibility: !record.visibility,
				id: record.id,
				modifiedBy: employeeID,
			}
		);
		if (res && res.status === 200) {
			// update the record from the local tables
			setFilteredTipsAndTricks((prevTipsAndTricks) => {
				return prevTipsAndTricks.map((tip) => {
					if (tip.id === record.id) {
						return {
							...tip,
							visibility: !tip.visibility,
						};
					} else {
						return tip;
					}
				});
			});
			setTipsAndTricks((prevTipsAndTricks) => {
				return prevTipsAndTricks.map((tip) => {
					if (tip.id === record.id) {
						return {
							...tip,
							visibility: !tip.visibility,
						};
					} else {
						return tip;
					}
				});
			});
		}
	};

	const visibilityToggleAllClicked = async (visibility) => {
		filteredTipsAndTricks.forEach(async (record) => {
			try {
				let res = await putAPICall(
					instance,
					accounts[0],
					"/api/tipsAndTricks/update",
					{
						categoryID: record.category_id,
						dataType: record.data_type,
						title: record.title,
						description: record.description,
						data: record.data,
						visibility: visibility,
						id: record.id,
						modifiedBy: employeeID,
					}
				);
			} catch (error) {
				console.error(
					"visibilityToggleAllClicked() - Error updating visibility:",
					error
				);
			}
		});
		setFilteredTipsAndTricks((prevTipsAndTricks) => {
			return prevTipsAndTricks.map((tip) => {
				return {
					...tip,
					visibility: visibility,
				};
			});
		});
		// only update the tipsAndTricks if the record is in filteredTipsAndTricks
		setTipsAndTricks((prevTipsAndTricks) => {
			return prevTipsAndTricks.map((tip) => {
				if (
					filteredTipsAndTricks.find((record) => record.id === tip.id)
				) {
					return {
						...tip,
						visibility: visibility,
					};
				} else {
					return tip;
				}
			});
		});
	};

	// API HELPER FUNCTIONS
	const fetchTipsAndTricks = async () => {
		try {
			setIsLoading(true);

			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/formatted"
			);
			setTipsAndTricks(res.data);
			setFilteredTipsAndTricks(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));

			const categoriesRes = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/categories/all"
			);
			setCategories(categoriesRes.data);

			setIsLoading(false);
		} catch (error) {
			console.error(
				"TipsAndTricksConsole.js - fetchTipsAndTricks() - Error fetching tips and tricks: ",
				error
			);
		}
	};

	const filterTipsAndTricks = async (
		searchValue,
		searchByParameter,
		tipsRecords = tipsAndTricks
	) => {
		setIsLoading(true);

		let filteredTipsAndTricksRecords = tipsRecords.filter((tipObj) => {
			if (searchByParameter === "title") {
				return (tipObj.title ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "category") {
				return (tipObj.category_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "description") {
				return (tipObj.description ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredTipsAndTricks(filteredTipsAndTricksRecords);
		setNumberOfPages(
			Math.ceil(filteredTipsAndTricksRecords.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const fetchTipsAndTricksOnSubmit = async () => {
		try {
			setIsLoading(true);

			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/formatted"
			);
			setTipsAndTricks(res.data);

			if (searchByValue) {
				filterTipsAndTricks(searchByValue, searchParameter, res.data);
			} else {
				setFilteredTipsAndTricks(res.data);
				setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
			}

			const categoriesRes = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/categories/all"
			);
			setCategories(categoriesRes.data);

			setIsLoading(false);
		} catch (error) {
			console.error(
				"TipsAndTricksConsole.js - fetchTipsAndTricksOnSubmit() - Error fetching tips and tricks: ",
				error
			);
		}
	};

	// HELPER FUNCTIONS

	const createColumnData = () => {
		const returnVisibilityCell = () => {
			return (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<div style={{ color: "white" }}>Visibility</div>
					<Checkbox
						onClick={() =>
							visibilityToggleAllClicked(!checkedAllVisibility)
						}
						checked={checkedAllVisibility}
						sx={{
							color: "#FFFFFF",
							"&.Mui-checked": {
								color: "#FFFFFF",
							},
						}}
					/>
				</div>
			);
		};

		setTipsAndTricksColumns([
			{ text: "Title" },
			{ text: "Category" },
			{ text: "Description" },
			{ text: "Data Type" },
			{ text: returnVisibilityCell() },
			{}, // blank for edit column
		]);
	};

	const createRowData = () => {
		const returnVisibilityCell = (record) => {
			return (
				<>
					<Checkbox
						onClick={() => visibilityClicked(record)}
						checked={record.visibility}
					/>
				</>
			);
		};

		setTipsAndTricksRows(
			filteredTipsAndTricks.map((tip) => [
				{ text: tip.title },
				{ text: toTitleCase(tip.category_name) },
				{ text: tip.description },
				{ text: tip.data_type },
				{ text: returnVisibilityCell(tip) },
				{ icon: "edit", onClick: () => editTipClicked(tip) },
			])
		);
	};

	// RENDER
	return (
		<div className="TipsAndTricksConsole">
			<div className="tipsAndTricksConsoleHeader">
				<div className="tipsAndTricksConsoleHeaderTitle">
					Tips & Tricks Console
					<div className="newRecordButtonContainer">
						<Button
							variant="contained"
							color="primary"
							onClick={newRecordClicked}
							disabled={isLoading}
						>
							New Record
						</Button>
					</div>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={filterTipsAndTricks}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredTipsAndTricks.length === 0 && <J2NoResultsFound />}
					{filteredTipsAndTricks.length > 0 &&
						tipsAndTricksColumns.length > 0 &&
						tipsAndTricksRows.length > 0 && (
							<>
								<GenericTable
									columnData={tipsAndTricksColumns}
									rowData={tipsAndTricksRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<NewTipDialog
				open={newRecordOpen}
				newTipDialogClosed={newTipDialogClosed}
				categories={categories}
				fetchTipsAndTricks={fetchTipsAndTricks}
			/>
			<EditTipDialog
				open={editRecordOpen}
				recordToEdit={recordToEdit}
				editTipDialogClosed={editTipClosed}
				categories={categories}
				fetchTipsAndTricksOnSubmit={fetchTipsAndTricksOnSubmit}
			/>
		</div>
	);
};

export default TipsAndTricksConsole;
