import React, { useState } from "react";

// CSS
import "./CoreRepairScopesSearchBar.css";

// MUI COMPONENTS
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

// CONSTANTS
const searchByParameterMap = {
	assemblies: "Assemblies",
	building_type: "Building Element",
	description: "Description",
};

// FUNCTIONAL COMPONENT
const CoreRepairScopesSearchBar = (props) => {
	// STATES
	const [searchValue, setSearchValue] = useState("");
	const [searchByParameter, setSearchByParameter] = useState("building_type");

	// INPUT HANDLERS
	const searchValueChanged = (event) => {
		setSearchValue(event.target.value);
		props.filterResults(event.target.value, searchByParameter);
	};

	const searchByParameterSelected = (event) => {
		setSearchByParameter(event.target.value);

		if (event.target.value !== "assemblies") {
			setSearchValue("");
			props.filterResults("", event.target.value);
		} else {
			setSearchValue("");
			props.assemblySearchBarSelected();
		}
	};

	// RENDER
	return (
		<div className="CoreRepairScopesSearchBar">
			<div className="coreRepairScopesSearchFieldsContainer">
				<TextField
					id="searchField"
					label="Search"
					variant="outlined"
					value={searchValue}
					onChange={searchValueChanged}
				/>
				<FormControl sx={{ minWidth: "10vw" }}>
					<InputLabel id="searchByLabel">Search By</InputLabel>
					<Select
						labelId="searchByLabel"
						value={searchByParameter}
						label="Search By"
						onChange={searchByParameterSelected}
					>
						{Object.keys(searchByParameterMap).map((key) => (
							<MenuItem value={key} key={key}>
								{searchByParameterMap[key]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};

export default CoreRepairScopesSearchBar;
