// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./DetailLibrary.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import DetailLibrarySearchBar from "./DetailLibrarySearchBar/DetailLibrarySearchBar";
import AssembliesGroupingsSearchBar from "./AssembliesGroupingsSearchBar/AssembliesGroupingsSearchBar";
import GenericTable from "../Misc/Table/GenericTable";

import NewDetailDialog from "./NewDetailDialog/NewDetailDialog";
import ThumbnailBlowupDialog from "./ThumbnailBlowupDialog/ThumbnailBlowupDialog";

import J2NoResultsFound from "../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { DETAIL_LIBRARY_RECORDS_PER_PAGE } from "../../constants";

// FUNCTIONAL COMPONENT
const DetailLibrary = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API data
	const [detailLibraryRecords, setDetailLibraryRecords] = useState([]);
	const [filteredDetailLibraryRecords, setFilteredDetailLibraryRecords] =
		useState([]);
	const [detailLibraryRecordsRows, setDetailLibraryRecordsRows] = useState(
		[]
	);
	const [detailLibraryRecordsColumns, setDetailLibraryRecordsColumns] =
		useState([]);

	const [idSortDirection, setIdSortDirection] = useState("none");
	const [titleSortDirection, setTitleSortDirection] = useState("none");

	const [assemblies, setAssemblies] = useState([]);
	const [groupings, setGroupings] = useState([]);
	const [assemblyGroupings, setAssemblyGroupings] = useState([]); // Junction table

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] = useState(
		DETAIL_LIBRARY_RECORDS_PER_PAGE
	);

	// Dialogs
	const [newRecordOpen, setNewRecordOpen] = useState(false);

	const [thumbnailBlowupDialogOpen, setThumbnailBlowupDialogOpen] =
		useState(false);
	const [thumbnailURL, setThumbnailURL] = useState(""); // [TODO] - Set this to the URL of the thumbnail that was clicked on

	// Search bar
	const [showAssemblyGroupingSearchBar, setShowAssemblyGroupingSearchBar] =
		useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchDetailLibraryRecords();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Detail Library",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the Detail Library page",
		});
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredDetailLibraryRecords]);

	// INPUT HANDLERS
	const newRecordClicked = () => {
		setNewRecordOpen(!newRecordOpen);
	};

	const newDetailDialogClosed = () => {
		setNewRecordOpen(false);
	};

	// Thumnail blowup
	const openThumbnailBlowupDialog = (url) => {
		setThumbnailBlowupDialogOpen(true);
		setThumbnailURL(url);
	};

	const closeThumbnailBlowupDialog = () => {
		setThumbnailBlowupDialogOpen(false);
	};

	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex(
			(currentPage - 1) * DETAIL_LIBRARY_RECORDS_PER_PAGE
		);
		setPaginationEndIndex(currentPage * DETAIL_LIBRARY_RECORDS_PER_PAGE);
	};

	// Search bar
	const assembliesGroupingsSelected = () => {
		setShowAssemblyGroupingSearchBar(true);
		setFilteredDetailLibraryRecords(detailLibraryRecords);
		setNumberOfPages(
			Math.ceil(
				detailLibraryRecords.length / DETAIL_LIBRARY_RECORDS_PER_PAGE
			)
		);
	};

	const assembliesGroupingsUnselected = () => {
		setShowAssemblyGroupingSearchBar(false);
		setFilteredDetailLibraryRecords(detailLibraryRecords);
		setNumberOfPages(
			Math.ceil(
				detailLibraryRecords.length / DETAIL_LIBRARY_RECORDS_PER_PAGE
			)
		);
	};

	// API FUNCTIONS
	const fetchDetailLibraryRecords = async () => {
		setIsLoading(true);

		await getDetails();
		await getAssemblies();
		await getGroupings();
		await getAssembliesGroupings();

		setIsLoading(false);
	};

	const getAssembliesGroupings = async () => {
		const assembliesGroupingsRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/assemblies/groupingJunction"
		);
		setAssemblyGroupings(assembliesGroupingsRes.data);
	};

	const getGroupings = async () => {
		const groupingsRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/groupings/all"
		);
		setGroupings(groupingsRes.data);
	};

	const getDetails = async (sortBy, sortDirection) => {
		let queryParams;
		if (sortBy && sortDirection) {
			queryParams = `sortBy=${sortBy}&sortDirection=${sortDirection}`;
		}

		const detailRes = await getAPICall(
			instance,
			accounts[0],
			`/api/encompass/details/all?${queryParams}`
		);
		setDetailLibraryRecords(detailRes.data);
		setFilteredDetailLibraryRecords(detailRes.data);
		setNumberOfPages(
			Math.ceil(detailRes.data.length / DETAIL_LIBRARY_RECORDS_PER_PAGE)
		);
	};

	const getAssemblies = async () => {
		const assembliesRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/assemblies/all"
		);
		setAssemblies(assembliesRes.data);
	};

	const filterDetailLibraryRecords = (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredRecords = detailLibraryRecords.filter((record) => {
			if (searchByParameter === "title") {
				return (record.title ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "id") {
				return record.id == searchValue;
			}
		});

		setFilteredDetailLibraryRecords(filteredRecords);
		setNumberOfPages(
			Math.ceil(filteredRecords.length / DETAIL_LIBRARY_RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(DETAIL_LIBRARY_RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const filterAssemblyGroupingsSearchBar = async (
		selectedAssemblies,
		selectedGroupings
	) => {
		setIsLoading(true);

		let filteredRecords = [];

		let selectedAssemblyIDs = selectedAssemblies.map(
			(assembly) => assembly.id
		);
		let selectedGroupingIDs = selectedGroupings.map(
			(grouping) => grouping.id
		);

		for (let record of detailLibraryRecords) {
			var assemblyIDs = (record.assembly_ids ?? "").split(",");
			var groupingIDs = (record.grouping_ids ?? "").split(",");

			assemblyIDs =
				assemblyIDs.length === 1 && assemblyIDs[0] === ""
					? []
					: assemblyIDs;
			groupingIDs =
				groupingIDs.length === 1 && groupingIDs[0] === ""
					? []
					: groupingIDs;

			assemblyIDs = assemblyIDs.map((id) => parseInt(id));
			groupingIDs = groupingIDs.map((id) => parseInt(id));

			if (
				isSubset(selectedAssemblyIDs, assemblyIDs) &&
				isSubset(selectedGroupingIDs, groupingIDs)
			) {
				filteredRecords.push(record);
			}
		}

		setFilteredDetailLibraryRecords(filteredRecords);
		setNumberOfPages(
			Math.ceil(filteredRecords.length / DETAIL_LIBRARY_RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(DETAIL_LIBRARY_RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	let isSubset = (subsetArray, parentArray) => {
		if (subsetArray.length === 0) {
			return true;
		}

		return subsetArray.every((el) => {
			return parentArray.includes(el);
		});
	};

	const sortAlpha = async (column) => {
		let direction;
		let columnToSort = column.text.toLowerCase();

		// set direction based on columntext
		switch (columnToSort) {
			case "id":
				direction = idSortDirection;
				break;
			case "title":
				direction = titleSortDirection;
				break;
			default:
				direction = "none";
		}

		// switch direction to new direction
		switch (direction) {
			case "none":
				direction = "ASC";
				break;
			case "ASC":
				direction = "DESC";
				break;
			case "DESC":
				direction = "none";
				break;
			default:
				direction = "none";
		}

		// all other columns are set to none
		if (columnToSort !== "id") {
			setIdSortDirection("none");
		}
		if (columnToSort !== "title") {
			setTitleSortDirection("none");
		}

		// set new direction
		switch (columnToSort) {
			case "id":
				setIdSortDirection(direction);
				break;
			case "title":
				setTitleSortDirection(direction);
				break;
			default:
				break;
		}

		if (direction === "none") {
			// default direction and column
			direction = "ASC";
			columnToSort = "id";
		}

		await getDetails(columnToSort, direction);
	};

	const createColumnData = () => {
		setDetailLibraryRecordsColumns([
			{
				text: "ID",
				sortFunction: sortAlpha,
				sortDirection: idSortDirection,
			},
			{
				text: "Title",
				sortFunction: sortAlpha,
				sortDirection: titleSortDirection,
			},
			{ text: "Assemblies" },
			{ text: "Groupings" },
			{ icon: "download" },
			{ text: "Status" },
			{ text: "PDF" },
		]);
	};

	const createRowData = () => {
		const returnPDFThumbnail = (record) => {
			return (
				<div className="pdfThumbnail">
					<img
						src={record.thumbnail_link}
						alt="PDF Thumbnail"
						onMouseEnter={() =>
							openThumbnailBlowupDialog(record.thumbnail_link)
						}
					/>
				</div>
			);
		};

		setDetailLibraryRecordsRows(
			filteredDetailLibraryRecords.map((record) => [
				{ text: record.id },
				{
					text: (
						// note: using <a> tag instead of link and onClick props so on ctrl+click it opens in new tab
						<a href={`/encompass/detail_library/${record.id}`}>
							{record.title}
						</a>
					),
				},
				{
					text: (record.split_assemblies ?? "")
						.split("_SPLIT_")
						.map((assembly) => <div>{assembly}</div>),
				},
				{
					text: (record.split_groupings ?? "")
						.split("_SPLIT_")
						.map((grouping) => <div>{grouping}</div>),
				},
				{ text: record.download_count },
				{
					text: record.detail_status_name,
					textColor: record.detail_status_color,
				},
				{
					text: returnPDFThumbnail(record),
					notApplicable: !record.thumbnail_link,
				},
			])
		);
	};

	// RENDER
	return (
		<div className="DetailLibrary">
			<div className="detailLibraryHeader">
				<div className="detailLibraryHeaderTitle">
					Detail Library
					<Button
						variant="contained"
						color="primary"
						onClick={newRecordClicked}
						disabled={isLoading}
					>
						New Record
					</Button>
				</div>
				{showAssemblyGroupingSearchBar && (
					<AssembliesGroupingsSearchBar
						filterAssemblyGroupingsSearchBar={(
							selectedAssemblies,
							selectedGroupings
						) =>
							filterAssemblyGroupingsSearchBar(
								selectedAssemblies,
								selectedGroupings
							)
						}
						assembliesGroupingsUnselected={
							assembliesGroupingsUnselected
						}
						assemblies={assemblies}
						groupings={groupings}
					/>
				)}
				{!showAssemblyGroupingSearchBar && (
					<DetailLibrarySearchBar
						filterDetailLibraryRecords={(
							searchValue,
							searchByParameter
						) =>
							filterDetailLibraryRecords(
								searchValue,
								searchByParameter
							)
						}
						assembliesGroupingsSelected={
							assembliesGroupingsSelected
						}
					/>
				)}
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredDetailLibraryRecords.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredDetailLibraryRecords.length > 0 &&
						detailLibraryRecordsRows.length > 0 &&
						detailLibraryRecordsColumns.length > 0 && (
							<>
								<GenericTable
									columnData={detailLibraryRecordsColumns}
									rowData={detailLibraryRecordsRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<NewDetailDialog
				open={newRecordOpen}
				newDetailDialogClosed={newDetailDialogClosed}
				assemblies={assemblies}
				groupings={groupings}
				assemblyGroupings={assemblyGroupings}
				fetchDetailLibraryRecords={fetchDetailLibraryRecords}
			/>
			<ThumbnailBlowupDialog
				open={thumbnailBlowupDialogOpen}
				closeThumbnailBlowupDialog={closeThumbnailBlowupDialog}
				thumbnailURL={thumbnailURL}
			/>
		</div>
	);
};

export default DetailLibrary;
