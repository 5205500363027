// DEPENDENCIES
import React, { useState, useContext } from "react";

// CSS
import "./CommentsCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall, deleteAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import { Button, Collapse, IconButton, TextField } from "@mui/material";

import {
	Add as AddIcon,
	ChatBubble as ChatBubbleIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import DetailComment from "./DetailComment/DetailComment";

// CONSTANTS
import { defaultBlue, getDateTime } from "../../../../constants";

// FUNCTIONAL COMPONENT
const CommentsCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [newCommentOpen, setNewCommentOpen] = useState(false);

	const [newCommentText, setNewCommentText] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS
	// Form Handlers
	const newCommentChanged = (event) => {
		setNewCommentText(event.target.value);
	};

	// Buttons
	const submitNewCommentClicked = async () => {
		await createNewComment();

		props.fetchComments();

		newCommentClosed();
	};

	const cancelNewCommentClicked = () => {
		newCommentClosed();
	};

	// API FUNCTIONS
	const createNewComment = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/encompass/details/comments/add",
				{
					detailID: props.detailRecord.id,
					comment: newCommentText,
					createdDateTime: getDateTime(),
					createdByID: employeeID,
				}
			);
		} catch (error) {
			console.error("Error creating new comment: ", error);
		}
	};

	const deleteComment = async (commentID) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/encompass/details/comments/delete",
				{
					commentID: commentID,
				}
			);

			props.fetchComments();
		} catch (error) {
			console.error("Error deleting comment: ", error);
		}
	};

	// HELPER FUNCTIONS
	const newCommentClosed = () => {
		setNewCommentOpen(false);
		setNewCommentText("");
	};

	// RENDER
	return (
		<div className="CommentsCard">
			<div
				className="commentsCardHeader"
				onClick={props.commentsDropdownClicked}
			>
				<IconButton sx={{ color: defaultBlue }}>
					<ChatBubbleIcon sx={{ fontSize: 32 }} />
				</IconButton>
				<h2 className="detailCommentsCardHeaderTitle">Comments</h2>
			</div>
			<Collapse
				in={props.commentsOpen}
				id="commentsDropdownCollapse"
				timeout="auto"
				unmountOnExit
			>
				<div className="commentsDropdownContent">
					<div className="newCommentButtonContainer">
						<Button
							variant="outlined"
							color="primary"
							startIcon={<AddIcon />}
							onClick={() => setNewCommentOpen(true)}
						>
							New
						</Button>
					</div>
					<NewCommentCollapse
						newCommentOpen={newCommentOpen}
						newCommentText={newCommentText}
						newCommentChanged={newCommentChanged}
						submitNewCommentClicked={submitNewCommentClicked}
						cancelNewCommentClicked={cancelNewCommentClicked}
					/>
					<div className="commentsContainer">
						{props.comments.length === 0 && (
							<div className="noCommentsText">
								<i>No comments found</i>
							</div>
						)}
						{props.comments.map((comment, index) => (
							<DetailComment
								key={index}
								comment={comment}
								showBottomBorder={
									index !== props.comments.length - 1
								}
								canDeleteComment={props.isAdmin}
								deleteComment={() => deleteComment(comment.id)}
							/>
						))}
					</div>
				</div>
			</Collapse>
		</div>
	);
};

const NewCommentCollapse = (props) => {
	return (
		<Collapse
			in={props.newCommentOpen}
			id="newCommentDropdownCollapse"
			timeout="auto"
			unmountOnExit
		>
			<div className="newCommentInputContainer">
				<div className="newCommentRow">
					<TextField
						sx={{ flex: 1 }}
						id="newCommentTextField"
						label="Comment"
						value={props.newCommentText}
						onChange={props.newCommentChanged}
					/>
				</div>
				<div className="newCommentRow">
					<Button
						variant="outlined"
						color="error"
						onClick={props.cancelNewCommentClicked}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={props.submitNewCommentClicked}
					>
						Submit
					</Button>
				</div>
			</div>
		</Collapse>
	);
};

export default CommentsCard;
