// DEPENDENCIES
import React, { useState, useEffect, useContext, createRef } from "react";

// CSS
import "./ProjectConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import ProjectConsoleEditAddDialog from "./ProjectConsoleEditAddDialog/ProjectConsoleEditAddDialog";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	property_name: "Property",
	name: "Project Name",
	display_name: "Display Name",
};

const searchDefaultValue = "property_name";

// OTHER

// REACT COMPONENT
const ProjectConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);

	const [projectsRows, setProjectsRows] = useState([]);
	const [projectsColumns, setProjectsColumns] = useState([]);

	const [propertySortDirection, setPropertySortDirection] = useState("none");
	const [createdSortDirection, setCreatedSortDirection] = useState("none");

	const [properties, setProperties] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [buildingComponents, setBuildingComponents] = useState([]);

	const [recordToEdit, setRecordToEdit] = useState({});
	const [loading, setLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);

	// pagination
	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredProjects]);

	// INPUT HANDLERS

	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
		handleAPICalls();
	};

	const handleEditAddDialogCloseNoRefresh = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await fetchProjects();
		await fetchProperties();
		await fetchEmployees();
		await fetchBuildingComponents();
		setLoading(false);
	};

	const fetchProjects = async (sortBy, sortDirection) => {
		let queryParams;
		if (sortBy && sortDirection) {
			queryParams = `sortBy=${sortBy}&sortDirection=${sortDirection}`;
		}

		// Fetch Projects
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/jobs/project/all?${queryParams}`
			);
			setProjects(res.data);
			setFilteredProjects(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
		} catch (error) {
			console.error(error);
		}
	};

	const fetchProperties = async () => {
		// Fetch Properties
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/property/all"
			);
			setProperties(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchEmployees = async () => {
		// Fetch Employees
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/all"
			);
			setEmployees(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchBuildingComponents = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/components/all"
			);
			setBuildingComponents(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	// pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// search
	const filterProjectConsoleRecords = (searchValue, searchByParameter) => {
		let filteredRecords = projects.filter((record) => {
			if (searchByParameter === "name") {
				return record.name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "display_name") {
				return record.display_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "property_name") {
				// Assuming 'property_name' is a key in the record that holds the associated property's name
				return (
					record.property_name &&
					record.property_name
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				);
			}
		});

		setFilteredProjects(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// Reset pagination to start at the first page
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const sortColumn = async (column) => {
		let direction;
		let columnToSort = column.text.toLowerCase();

		// set direction based on columntext
		switch (columnToSort) {
			case "property":
				direction = propertySortDirection;
				break;
			case "created":
				direction = createdSortDirection;
				break;
			default:
				direction = "none";
		}

		// switch direction to new direction
		switch (direction) {
			case "none":
				direction = "ASC";
				break;
			case "ASC":
				direction = "DESC";
				break;
			case "DESC":
				direction = "none";
				break;
			default:
				direction = "none";
		}

		// all other columns are set to none
		if (columnToSort !== "property") {
			setPropertySortDirection("none");
		}
		if (columnToSort !== "title") {
			setCreatedSortDirection("none");
		}

		// set new direction
		switch (columnToSort) {
			case "property":
				setPropertySortDirection(direction);
				break;
			case "created":
				setCreatedSortDirection(direction);
				break;
			default:
				break;
		}

		if (direction === "none") {
			// default direction and column
			direction = "ASC";
			columnToSort = "property";
		}

		await fetchProjects(columnToSort, direction);
	};

	const createColumnData = () => {
		setProjectsColumns([
			{
				text: "Property",
				sortFunction: sortColumn,
				sortDirection: propertySortDirection,
			},
			{ text: "Project Name" },
			{ text: "Display Name" },
			{ text: "Dropbox File Path" },
			{ text: "Owner" },
			{
				text: "Created",
				sortFunction: sortColumn,
				sortDirection: createdSortDirection,
			},
			{}, // Empty column for edit button
		]);
	};

	const createRowData = () => {
		setProjectsRows(
			filteredProjects.map((project) => [
				{ text: project.property_name },
				{ text: project.name },
				{ text: project.display_name },
				{ text: project.dropbox_path },
				{ text: project.first_name + " " + project.last_name },
				{ text: convertToPST(project.created) },
				{ icon: "edit", onClick: () => handleEditClick(project) },
			])
		);
	};

	// RENDER
	return (
		<div className="ProjectConsole">
			<div className="ProjectConsoleHeader">
				<div className="ProjectConsoleHeaderTitle">
					Project Console
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddClick}
						disabled={loading}
					>
						New Project
					</Button>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={filterProjectConsoleRecords}
				/>
			</div>
			{loading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!loading && (
				<div className="tableContainerDiv">
					{filteredProjects.length === 0 && <J2NoResultsFound />}
					{filteredProjects.length > 0 &&
						projectsRows.length > 0 &&
						projectsColumns.length > 0 && (
							<>
								<GenericTable
									columnData={projectsColumns}
									rowData={projectsRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<ProjectConsoleEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				onCloseNoRefresh={handleEditAddDialogCloseNoRefresh}
				recordToEdit={recordToEdit}
				editing={editing}
				projects={projects}
				properties={properties}
				employees={employees}
				buildingComponents={buildingComponents}
			/>
		</div>
	);
};

// EXPORT
export default ProjectConsole;
