// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./InfoCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Collapse, IconButton } from "@mui/material";

// MUI ICONS
import {
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon,
	OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const InfoCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [infoCardOpen, setInfoCardOpen] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	//   useEffect(() => {}, []);

	// INPUT HANDLERS
	const infoCardDropdownClicked = () => {
		if (props.info.data_type === "url") {
			// Open the URL in a new tab
			window.open(props.info.data, "_blank");
		} else {
			// Toggle the dropdown for iframe type
			setInfoCardOpen(!infoCardOpen);
			postAPICall(instance, accounts[0], "/api/logs/access/add", {
				page_name: "Info",
				employeeID: employeeID,
				notes: `Accessed the ${props.info.name} info.`,
				modified_by: employeeID,
			});
		}
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="InfoCard">
			<div className="infoCardHeader" onClick={infoCardDropdownClicked}>
				<div className="leftSideInfoCardHeader">
					<IconButton sx={{ color: "black" }}>
						{props.info.data_type === "iframe" ? (
							infoCardOpen ? (
								<ArrowDropUpIcon fontSize="small" />
							) : (
								<ArrowDropDownIcon fontSize="small" />
							)
						) : null}
						{props.info.data_type === "url" ? (
							<OpenInNewIcon fontSize="small" />
						) : null}
					</IconButton>
					<div className="infoCardTitles">
						<h2>{props.info.name}</h2>
						<h3>{props.info.info_category_name}</h3>
					</div>
				</div>
			</div>
			<Collapse in={infoCardOpen} id="infoiframeContainerCollapse">
				<div className="infoiframeContainer">
					{infoCardOpen && ( // saves resources by not rendering the iframe until the container is open
						<iframe
							id="IFrameElement"
							title={props.info.name}
							src={props.info.data.replace(
								"TOOLS_USER_FULL_NAME",
								props.info.zoho_dashboard_name
							)}
							width="100%"
							height={props.info.info_height}
							frameborder="0"
						></iframe>
					)}
				</div>
			</Collapse>
		</div>
	);
};

// EXPORT
export default InfoCard;
