// DEPENDENCIES
import React from 'react';


// CSS
import './PropertyInformationHelpDialog.css';


// MUI COMPONENTS
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Button from '@mui/material/Button';


// FUNCTIONAL COMPONENT
const PropertyInformationHelpDialog = (props) => {
    // INPUT HANDLERS
    const formClosed = () => {
        props.onClose();
    }


    // RENDER
    return (
        <Dialog
            id='propertyInformationHelpDialog'
            open={props.open}
            fullWidth
            onClose={formClosed}
        >
            <DialogTitle id='propertyInformationHelpDialogTitle'>Property Information</DialogTitle>
            <DialogContent
                id="propertyInformationHelpDialogContent"
            >
                <div className='helpParagraph'>
                    <h2>Searching Existing Properties</h2>
                    <p>
                        To search for an existing property, enter the contact's last name in the text field. Once you have entered three characters, the 
                        system will begin searching for property records in HubSpot that match the text you have entered.
                    </p>
                    <p>
                        If you see the contact you are looking for, click on the name to populate the fields with the property information. If you are 
                        unable to find the property you are looking for, you can create a new property by clicking the "Create New" button. 
                    </p>
                    <h2 id='createNewPropertyHeader'>Creating a New Property</h2>
                    <p>
                        To create a new property, click the "Create New" button. This will open a dropdown with the fields you need to fill out to create a new
                        property. Fill out the fields with the property information, everything but "Address Line 2" is required.
                    </p>
                    <p>
                        Please ensure the spelling, capitalization, and information is accurate. Once you submit the RFP, a property record will be created in HubSpot
                        and associated with the corresponding deal.
                    </p>
                </div>
                <div className='dialogButtons'>
                    <Button
                        id='closePropertyInformationHelpDialogButton'
                        onClick={formClosed}
                        color='primary'
                        variant='contained'
                    >
                        Close
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}


export default PropertyInformationHelpDialog;