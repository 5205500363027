// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./EditSpecInformationDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { putAPICall } from "../../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../../context/UserContext";

// MUI COMPONENTS
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Alert,
	Collapse,
	IconButton,
	CircularProgress,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS
import DivisionTextField from "../../../../DivisionTextField/DivisionTextField";

// CONSTANTS
import { selectIntegersRegex } from "../../../../../../constants";

// FUNCTIONAL COMPONENT
const EditSpecInformationDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Form data
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [division, setDivision] = useState("");
	const [statusID, setStatusID] = useState(0);

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.specRecord.id) {
			setTitle(props.specRecord.title);
			setDescription(props.specRecord.description);
			setDivision(props.specRecord.division);
			setStatusID(props.specRecord.status_id);
		}
	}, [props.specRecord, props.open]);

	// INPUT HANDLERS
	// Form data handlers
	const titleChanged = (event) => {
		setTitle(event.target.value);
	};

	const descriptionChanged = (event) => {
		setDescription(event.target.value);
	};

	const divisionChanged = (event) => {
		setDivision(event.target.value);
	};

	const statusChanged = (event) => {
		setStatusID(event.target.value);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertMessage(message);
		setAlertOpen(true);
	};

	const closeAlert = () => {
		setAlertMessage("");
		setAlertOpen(false);
	};

	// BUTTON HANDLERS
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				`/api/specifications/update`,
				{
					title: title,
					description: description ? description : "",
					division: division,
					statusID: statusID,
					modifiedByID: employeeID,
					content: props.specRecord.content,
					editable: props.specRecord.editable,
					parentID: props.specRecord.parent_id,
					phaseID: props.specRecord.phase_id,
					specID: props.specRecord.id,
				}
			);

			props.fetchSpecRecord();
		} catch (error) {
			openAlert("Error submitting form data");
			console.error(error);
		}

		setIsLoading(false);
		props.fetchSpecRecord();
		formClosed();
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const validateInputFields = () => {
		if (!title) {
			openAlert("Please enter a title");
			return false;
		}

		if (
			!division ||
			division.replace(selectIntegersRegex, "").length !== 6
		) {
			openAlert("Please enter a valid division");
			return false;
		}

		if (!statusID) {
			openAlert("Please select a status");
			return false;
		}

		return true;
	};

	const resetForm = () => {
		setTitle("");
		setDescription("");
		setDivision("");
		setStatusID(0);
		setAlertMessage("");
		setAlertOpen(false);
		setIsLoading(false);
	};

	const formClosed = () => {
		resetForm();
		props.editSpecInformationDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="editSpecInformationDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editSpecInformationDialogTitle">
				Edit {props.specRecord.title}
			</DialogTitle>
			<DialogContent id="editSpecInformationDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="editSpecInformationDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="newSpecDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="editSpecInformationDialogAlert"
									severity="error"
									action={
										<IconButton
											id="editSpecInformationDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="editSpecInformationCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="editSpecInformationDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="editSpecInformationTitle"
								label="Title"
								value={title}
								onChange={titleChanged}
							/>
						</div>
						<div className="editSpecInformationDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="editSpecInformationTitle"
								label="Description"
								value={description}
								onChange={descriptionChanged}
							/>
						</div>
						<div className="editSpecInformationDialogRow">
							<DivisionTextField
								sx={{ flex: 1 }}
								id="editSpecInformationTitle"
								label="Division"
								value={division}
								onChange={divisionChanged}
							/>
							<FormControl variant="outlined" style={{ flex: 1 }}>
								<InputLabel id="statusLabel">Status</InputLabel>
								<Select
									labelId="statusLabel"
									id="status"
									value={statusID}
									onChange={statusChanged}
									label="Status"
								>
									{props.statuses.map((status) => (
										<MenuItem
											key={status.id}
											value={status.id}
										>
											{status.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EditSpecInformationDialog;
