// DEPENDENCIES
import React, { useState } from "react";

// CSS
import "./SearchBar.css";

// MSAL

// API IMPORTS

// CONTEXT

// MUI COMPONENTS
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const SearchBar = (props) => {
	// STATES
	const [searchValue, setSearchValue] = useState("");
	const [searchByParameter, setSearchByParameter] = useState(
		props.searchDefaultValue
	);

	// INPUT HANDLERS
	const searchValueChanged = (event) => {
		setSearchValue(event.target.value);
		props.filterResults(event.target.value, searchByParameter);
	};

	const searchByParameterSelected = (event) => {
		setSearchByParameter(event.target.value);
		setSearchValue("");
		props.filterResults("", event.target.value);
	};

	// RENDER
	return (
		<div className="SearchBar">
			<div className="SearchFieldsContainer">
				<TextField
					id="searchField"
					label="Search"
					variant="outlined"
					value={searchValue}
					onChange={searchValueChanged}
				/>

				<FormControl sx={{ minWidth: "10vw" }}>
					<InputLabel id="searchByLabel">Search By</InputLabel>
					<Select
						labelId="searchByLabel"
						value={searchByParameter}
						label="Search By"
						onChange={searchByParameterSelected}
					>
						{Object.keys(props.searchByParameterMap).map((key) => (
							<MenuItem value={key} key={key}>
								{props.searchByParameterMap[key]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};

// EXPORT
export default SearchBar;
