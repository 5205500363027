// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./MyDashboards.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

import DashboardCard from "../DashboardCard/DashboardCard";

// CONSTANTS

// REACT COMPONENT
const MyDashboards = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API results
	const [dashboards, setDashboards] = useState([]);

	// Feedback
	const [isLoading, setIsLoading] = useState(true);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchDashboards();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Dashboards",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: `Accessed the My Dashboards page.`,
		});
	}, []);

	// INPUT HANDLERS

	// API FUNCTIONS
	const fetchDashboards = async () => {
		setIsLoading(true);

		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/dashboards/byUser",
				{
					userID: employeeID,
					isIndividual: 1, // True
				}
			);

			setDashboards(response.data);
		} catch (error) {
			console.log("Error in fetchDashboards: ", error);
		}

		setIsLoading(false);
	};

	// RENDER
	return (
		<div className="MyDashboards">
			<div className="myDashboardsHeader">
				<div className="myDashboardsHeaderTitle">My Dashboards</div>
			</div>
			{isLoading && (
				<div className="loadingSpinnerContainer">
					<CircularProgress />
				</div>
			)}
			{!isLoading && (
				<div className="dashboardsContainer">
					{dashboards.length === 0 && <J2NoResultsFound />}
					{dashboards.map((dashboard, index) => (
						<DashboardCard key={index} dashboard={dashboard} />
					))}
				</div>
			)}
		</div>
	);
};

export default MyDashboards;
