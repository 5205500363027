// DEPENDENCIES
import React, { useEffect, useState } from "react";

// CSS
import "./ContentCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { putAPICall } from "../../../../../config/apiCalls";

// MUI COMPONENTS
import { IconButton, Button, Collapse, Chip, Tooltip } from "@mui/material";
import {
	Article as ArticleIcon,
	Edit as EditIcon,
	Save as SaveIcon,
	Info as InfoIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import SpecTextEditor from "../../../SpecTextEditor/SpecTextEditor";
import EditContentCardDialogSubmit from "./EditContentCardDialogSubmit/EditContentCardDialogSubmit";

import SpecTextEditorInfo from "../../../SpecTextEditor/SpecTextEditorInfo/SpecTextEditorInfo";

// CONSTANTS
import { defaultBlue } from "../../../../../constants";

// FUNCTIONAL COMPONENT
const ContentCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [editingContent, setEditingContent] = useState(false);
	const [data, setData] = useState(props.data);
	const [loading, setLoading] = useState(false);
	const [commentModalOpen, setCommentModalOpen] = useState(false);
	const [helpDialogOpen, setHelpDialogOpen] = useState(false);

	// USE EFFECT
	useEffect(() => {
		setData(props.data);
	}, [props.data]);

	// displays default browser alert if user tries to leave page with unsavedchanges true
	useEffect(() => {
		const handleBeforeUnload = (e) => {
			e.preventDefault();
			e.returnValue = "";
		};

		if (editingContent) {
			window.addEventListener("beforeunload", handleBeforeUnload);
		} else {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		}
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [editingContent]);

	// INPUT HANDLERS
	const editContentCardClicked = () => {
		setEditingContent(true);
	};

	const onDataChange = (newData) => {
		setData(newData);
	};

	const handleSubmitClicked = () => {
		setLoading(true);
		setCommentModalOpen(true);
	};

	const submit = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/specifications/update",
				{
					title: props.specToEdit.title,
					description: props.specToEdit.description,
					division: props.specToEdit.division,
					statusID: props.specToEdit.status_id,
					modifiedByID: props.employee_id,
					content: data,
					editable: props.specToEdit.editable,
					parentID: props.specToEdit.parent_id,
					phaseID: props.specToEdit.phase_id,
					specID: props.specToEdit.id,
				}
			);
		} catch (error) {
			console.error(error);
		}
		props.fetchSpecRecord();
		setEditingContent(false);
		setLoading(false);
	};

	const autoSave = async (newData) => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/specifications/update",
				{
					title: props.specToEdit.title,
					description: props.specToEdit.description,
					division: props.specToEdit.division,
					statusID: props.specToEdit.status_id,
					modifiedByID: props.employee_id,
					content: newData,
					editable: props.specToEdit.editable,
					parentID: props.specToEdit.parent_id,
					phaseID: props.specToEdit.phase_id,
					specID: props.specToEdit.id,
				}
			);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCancel = () => {
		setData(props.data);
		setEditingContent(false);
	};

	// RETURN
	return (
		<div className="specDetailScreenListCard">
			<div className="specContentScreenListCardHeader">
				<div
					className="specDetailScreenListCardHeaderIconTitleContainer"
					onClick={props.contentDropdownClicked}
				>
					<IconButton sx={{ color: defaultBlue }}>
						<ArticleIcon sx={{ fontSize: 32 }} />
					</IconButton>
					<h2 className="specDetailScreenListCardHeaderTitle">
						Content
					</h2>
				</div>
				{props.contentOpen ? (
					props.isAdmin ? (
						<div className="SpecDetailScreenContentCardButtonGroup">
							<IconButton
								aria-label="info"
								onClick={() => setHelpDialogOpen(true)}
							>
								<InfoIcon />
							</IconButton>
							{!editingContent ? (
								<Button
									variant="contained"
									color="primary"
									startIcon={<EditIcon />}
									onClick={editContentCardClicked}
								>
									Edit
								</Button>
							) : (
								<>
									<Button
										variant="outlined"
										color="error"
										onClick={handleCancel}
									>
										Cancel
									</Button>
									<Button
										variant="contained"
										color="primary"
										startIcon={<SaveIcon />}
										onClick={handleSubmitClicked}
									>
										Save
									</Button>
								</>
							)}
						</div>
					) : (
						<div className="SpecDetailScreenContentCardButtonGroupView">
							<Tooltip title="Only Admin users can edit standard specs.">
								<Chip label="View Only" />
							</Tooltip>
						</div>
					)
				) : null}
			</div>
			<Collapse
				in={props.contentOpen}
				id="contentDropdownCollapse"
				timeout="auto"
				unmountOnExit
			>
				<div className="contentDropdownContent">
					<SpecTextEditor
						disabled={!editingContent}
						data={data}
						onDataChange={onDataChange}
						key={editingContent ? "editing" : "notEditing"} //triggers manual rerender, the editor is quirky
					/>
				</div>
			</Collapse>
			<EditContentCardDialogSubmit
				handleClose={() => setCommentModalOpen(false)}
				handleSubmit={submit}
				createNewComment={props.createNewComment}
				open={commentModalOpen}
			/>
			<SpecTextEditorInfo
				open={helpDialogOpen}
				handleHelpDialogClose={() => setHelpDialogOpen(false)}
			/>
		</div>
	);
};

export default ContentCard;
