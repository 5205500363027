// DEPENDENCIES
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";

// CSS
import "./index.css";

// COMPONENTS
import App from "./components/App/App";

// MSAL Instance
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

// RENDER
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<MsalProvider instance={msalInstance}>
			<div className="index">
				<App />
			</div>
		</MsalProvider>
	</BrowserRouter>
);
