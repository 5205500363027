import React, { useState } from "react";

// CSS
import "./DetailLibrarySearchBar.css";

// MUI COMPONENTS
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
} from "@mui/material";

// CONSTANTS
const searchByParameterMap = {
	title: "Title",
	id: "ID",
	assembliesGroupings: "Assemblies / Groupings",
};

// FUNCTIONAL COMPONENT
const DetailLibrarySearchBar = (props) => {
	// STATES
	const [searchValue, setSearchValue] = useState("");
	const [searchByParameter, setSearchByParameter] = useState("title");

	// INPUT HANDLERS
	const searchValueChanged = (event) => {
		setSearchValue(event.target.value);
		props.filterDetailLibraryRecords(event.target.value, searchByParameter);
	};

	const searchByParameterSelected = (event) => {
		setSearchByParameter(event.target.value);

		if (event.target.value !== "assembliesGroupings") {
			setSearchValue("");
			props.filterDetailLibraryRecords("", event.target.value);
		} else {
			setSearchValue("");
			props.assembliesGroupingsSelected();
		}
	};

	// RENDER
	return (
		<div className="DetailLibrarySearchBar">
			<div className="detailLibrarySearchFieldsContainer">
				<TextField
					id="searchField"
					label="Search"
					variant="outlined"
					value={searchValue}
					onChange={searchValueChanged}
				/>
				<FormControl sx={{ minWidth: "10vw" }}>
					<InputLabel id="searchByLabel">Search By</InputLabel>
					<Select
						labelId="searchByLabel"
						value={searchByParameter}
						label="Search By"
						onChange={searchByParameterSelected}
					>
						{Object.keys(searchByParameterMap).map((key) => (
							<MenuItem value={key}>
								{searchByParameterMap[key]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};

export default DetailLibrarySearchBar;
