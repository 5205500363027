// DEPENDENCIES
import React from "react";

// CSS
import "./PendingSpecChangePreviewCard.css";

// CONSTANTS
import { convertToPST } from "../../../../constants";

// REACT COMPONENT
const PendingSpecChangePreviewCard = (props) => {
	// RENDER
	return (
		<div className="PendingSpecChangePreviewCard">
			<div className="pendingSpecChangePreviewCardInfo">
				<div className="pendingSpecChangePreviewCardTitleContainer">
					<div className="pendingSpecChangePreviewCardDivision">
						{props.pendingSpecChange.division}
					</div>
					<div className="pendingSpecChangePreviewCardTitle">
						{props.pendingSpecChange.spec_title}
					</div>
				</div>
				<div className="pendingSpecChangePreviewCardDescription">
					{props.pendingSpecChange.description}
				</div>
				<div className="pendingSpecChangePreviewCardSubmitterContainer">
					<div className="pendingSpecChangePreviewCardPFPContainer">
						<img
							src={props.pendingSpecChange.submitted_by_photo}
							alt={`${props.pendingSpecChange.submitted_by} Headshot`}
						/>
					</div>
					<div className="pendingSpecChangePreviewCardUser">
						<h2 className="pendingSpecChangePreviewCardSubmitter">
							{props.pendingSpecChange.submitted_by}
						</h2>
						<h3 className="pendingSpecChangePreviewCardDate">
							{convertToPST(props.pendingSpecChange.created)}
						</h3>
					</div>
				</div>
			</div>
			<div
				className="pendingSpecChangePreviewCardStatus"
				style={{
					backgroundColor: props.pendingSpecChange.status_color,
				}}
			>
				{props.pendingSpecChange.status_name}
			</div>
		</div>
	);
};

// EXPORT
export default PendingSpecChangePreviewCard;
