// DEPENDENCIES
import React from "react";

// CSS
import "./AreYouSureDeleteSpecDialog.css";

// MUI COMPONENTS
import { Dialog, DialogContent, DialogTitle, Button } from "@mui/material";

// REACT COMPONENT
const AreYouSureDeleteSpecDialog = (props) => {
	// INPUT HANDLERS
	const confirmButtonClicked = () => {
		props.deleteSpecRecord();
	};

	// RENDER
	return (
		<Dialog
			id="areYouSureDeleteSpecDialog"
			open={props.open}
			fullWidth
			onClose={props.formClosed}
		>
			<DialogTitle id="areYouSureDeleteSpecDialogTitle">
				Warning
			</DialogTitle>
			<DialogContent id="areYouSureDeleteSpecDialogContent">
				<div className="areYouSureDeleteSpecDialogRow">
					Are you sure you want to delete this specification? This
					action cannot be undone.
				</div>
				<div className="dialogButtons">
					<Button
						onClick={props.formClosed}
						variant="outlined"
						color="primary"
					>
						Cancel
					</Button>
					<Button
						onClick={confirmButtonClicked}
						variant="contained"
						color="error"
					>
						Confirm
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default AreYouSureDeleteSpecDialog;
