// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./DevicesEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	deleteAPICall,
	postAPICall,
	putAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	ButtonGroup,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	IconButton,
	InputAdornment,
	TextField,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// MUI ICONS
import { Close as CloseIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const DevicesEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(true);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// device inputs
	const [office, setOffice] = useState("");
	const [deviceStatus, setDeviceStatus] = useState("");
	// laptops desktops inputs
	const [deviceCategory, setDeviceCategory] = useState("");
	const [defaultName, setDefaultName] = useState("");
	const [serialNumber, setSerialNumber] = useState("");
	const [j2DeviceName, setJ2DeviceName] = useState("");
	const [model, setModel] = useState("");
	const [storage, setStorage] = useState("");
	const [ram, setRam] = useState("");
	const [os, setOs] = useState("");
	const [manufacturer, setManufacturer] = useState("");
	// user inputs
	const [user, setUser] = useState("");
	const [checkOutDate, setCheckOutDate] = useState(dayjs()); // default to today
	// check in device
	const [checkInDate, setCheckInDate] = useState(dayjs()); // default to today
	// purchase inputs
	const [purchaseActive, setPurchaseActive] = useState(true); // default to purchase
	const [purchaseDate, setPurchaseDate] = useState(dayjs());
	const [purchaseAmount, setPurchaseAmount] = useState("");
	const [purchaseNotes, setPurchaseNotes] = useState("");
	// lease inputs
	const [leaseActive, setLeaseActive] = useState(false);
	const [existingLease, setExistingLease] = useState([]);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.editing) {
			handlePopulateFieldsOnEdit();
		} else {
			setLoading(false);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	const handleOfficeChange = (event, newValue) => {
		setOffice(newValue);
	};

	const handleDeviceStatusChange = (event, newValue) => {
		setDeviceStatus(newValue);
	};

	const handleDeviceCategoryChange = (event, newValue) => {
		setDeviceCategory(newValue);
	};

	const handleDefaultNameChange = (event) => {
		setDefaultName(event.target.value);
	};

	const handleSerialNumberChange = (event) => {
		setSerialNumber(event.target.value);
	};

	const handleJ2DeviceNameChange = (event) => {
		setJ2DeviceName(event.target.value);
	};

	const handleModelChange = (event, newValue) => {
		setModel(newValue);
	};

	const handleStorageChange = (event, newValue) => {
		setStorage(newValue);
	};

	const handleRamChange = (event, newValue) => {
		setRam(newValue);
	};

	const handleOsChange = (event, newValue) => {
		setOs(newValue);
	};

	const handleManufacturerChange = (event, newValue) => {
		setManufacturer(newValue);
	};

	const handleUserChange = (event, newValue) => {
		setUser(newValue);
	};

	const handleCheckOutDateChange = (date) => {
		setCheckOutDate(date);
	};

	const handleCheckInDateChange = (date) => {
		setCheckInDate(date);
	};

	const purchaseClicked = () => {
		setPurchaseActive(true);
		setLeaseActive(false);
	};

	const leaseClicked = () => {
		setPurchaseActive(false);
		setLeaseActive(true);
	};

	const handlePurchaseDateChange = (date) => {
		setPurchaseDate(date);
	};

	const handlePurchaseAmountChange = (event) => {
		const value = event.target.value;

		// Allow only valid money inputs
		const moneyRegex = /^\d*\.?\d{0,2}$/;

		if (moneyRegex.test(value) || value === "") {
			setPurchaseAmount(value);
		}
	};

	const handlePurchaseNotesChange = (event) => {
		setPurchaseNotes(event.target.value);
	};

	const existingLeaseChanged = (event, newValue) => {
		setExistingLease(newValue);
	};

	// API FUNCTIONS

	const handlePopulateFieldsOnEdit = () => {
		setLoading(true);
		// search through props.deviceCategories to find the deviceCategory object that matches the recordToEdit.device_category_id
		if (props.deviceCategories.length > 0) {
			let deviceCategoryObject = props.deviceCategories.find(
				(deviceCategory) =>
					deviceCategory.id === props.recordToEdit.device_category_id
			);
			setDeviceCategory(deviceCategoryObject);
		}
		// search through props.office to find the office object that matches the recordToEdit.office_id
		if (props.offices.length > 0) {
			let officeObject = props.offices.find(
				(office) => office.id === props.recordToEdit.office_id
			);
			setOffice(officeObject);
		}
		// search through props.deviceStatuses to find the deviceStatus object that matches the recordToEdit.device_status_id
		if (props.deviceStatuses.length > 0) {
			let deviceStatusObject = props.deviceStatuses.find(
				(deviceStatus) =>
					deviceStatus.id === props.recordToEdit.device_status_id
			);
			setDeviceStatus(deviceStatusObject);
		}
		setDefaultName(props.recordToEdit.default_name);
		setSerialNumber(props.recordToEdit.serial_number);
		setJ2DeviceName(props.recordToEdit.j2_device_name);
		setModel(props.recordToEdit.model);
		setStorage(props.recordToEdit.storage);
		setRam(props.recordToEdit.ram);
		setOs(props.recordToEdit.os);
		setManufacturer(props.recordToEdit.manufacturer);
		// search through props.employees to find the employee object that matches the recordToEdit.id (most recent user with no checkin date)
		props.employees.forEach((employee) => {
			if (employee.id === props.recordToEdit.employee_id) {
				setUser(employee);
			}
		});
		// leases and purchases
		// search through props.purchases to find the purchase object that matches the recordToEdit.id
		if (props.purchases.length > 0) {
			props.purchases.forEach((purchase) => {
				if (purchase.device_id === props.recordToEdit.id) {
					setPurchaseDate(dayjs(purchase.purchase_date));
					setPurchaseAmount(purchase.purchase_amount);
					setPurchaseNotes(purchase.notes);
					setPurchaseActive(true);
					setLeaseActive(false);
				}
			});
		}
		// search through props.leases to find the lease object that matches the recordToEdit
		if (props.devicesToLeases.length > 0) {
			props.devicesToLeases.forEach((leaseToDevice) => {
				if (leaseToDevice.device_id === props.recordToEdit.id) {
					let leaseObject = props.leases.find(
						(lease) => lease.id === leaseToDevice.lease_id
					);
					setExistingLease(leaseObject);
					setLeaseActive(true);
					setPurchaseActive(false);
				}
			});
		}

		setLoading(false);
	};

	const handleAddDeviceRecord = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/addDevice`,
				{
					device_category_id: deviceCategory.id,
					office_id: office.id,
					device_status_id: deviceStatus.id,
					default_name: defaultName,
					serial_number: serialNumber,
					j2_device_name: j2DeviceName,
					model: model,
					ram: ram,
					storage: storage,
					os: os,
					manufacturer: manufacturer,
					modified_by: employeeID,
				}
			);
			return res.data;
		} catch (error) {
			console.log(error);
			setAlertMessage("Error adding record.");
			setAlertOpen(true);
		}
	};

	const handleUpdateDeviceRecord = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/updateDevice`,
				{
					id: props.recordToEdit.id,
					device_category_id: deviceCategory.id,
					office_id: office.id,
					device_status_id: deviceStatus.id,
					default_name: defaultName,
					serial_number: serialNumber,
					j2_device_name: j2DeviceName,
					model: model,
					ram: ram,
					storage: storage,
					os: os,
					manufacturer: manufacturer,
					modified_by: employeeID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error updating record.");
			setAlertOpen(true);
		}
	};

	const checkInDevice = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/checkInDeviceToEmployee`,
				{
					check_in: checkInDate,
					modified_by: employeeID,
					device_id: props.recordToEdit.id,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error checking in device.");
			setAlertOpen(true);
		}
	};

	const checkOutDevice = async (deviceID) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/addDeviceToEmployee`,
				{
					device_id: deviceID,
					employee_id: user.id,
					check_out: checkOutDate,
					check_in: null,
					modified_by: employeeID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error checking out device.");
			setAlertOpen(true);
		}
	};

	const purchaseDevice = async (deviceID) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/addPurchase`,
				{
					device_id: deviceID,
					purchase_date: purchaseDate,
					purchase_amount: purchaseAmount,
					notes: purchaseNotes,
					modified_by: employeeID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error purchasing device.");
			setAlertOpen(true);
		}
	};

	const updatePurchaseDevice = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/updatePurchase`,
				{
					device_id: props.recordToEdit.id,
					purchase_date: purchaseDate,
					purchase_amount: purchaseAmount,
					notes: purchaseNotes,
					modified_by: employeeID,
					id: props.recordToEdit.purchase_id,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error updating purchase device.");
			setAlertOpen(true);
		}
	};

	const removePurchaseDevice = async (purchaseID) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/deletePurchase`,
				{
					id: purchaseID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error removing purchase device.");
			setAlertOpen(true);
		}
	};

	const associateDeviceToLease = async (deviceID, leaseID) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/addLeaseToDevice`,
				{
					device_id: deviceID,
					lease_id: leaseID,
					modified_by: employeeID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error associating device to lease.");
			setAlertOpen(true);
		}
	};

	const removeAssociatedDeviceToLease = async (leaseToDeviceID) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/deleteDeviceToLease`,
				{
					id: leaseToDeviceID,
				}
			);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error removing associated device to lease.");
			setAlertOpen(true);
		}
	};

	// HELPER FUNCTIONS
	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		// if editing, update record, else add record
		if (props.editing) {
			// find the lease to device association
			let leaseToDeviceRecord = props.devicesToLeases.find(
				(leaseToDevice) =>
					leaseToDevice.device_id === props.recordToEdit.id
			);
			// find the purchase record
			let purchaseRecord = props.purchases.find(
				(purchase) => purchase.device_id === props.recordToEdit.id
			);
			await handleUpdateDeviceRecord();
			// if no user originally and user is selected, check out device
			if (!props.recordToEdit.employee_id && user) {
				await checkOutDevice(props.recordToEdit.id);
			}
			// 1. remove previous associations / remove purchase
			if (existingLease) {
				// remove association if lease is not selected or doesnt match
				if (leaseToDeviceRecord) {
					if (
						!leaseActive ||
						leaseToDeviceRecord.id !== existingLease.id
					) {
						// find the lease to device association
						props.devicesToLeases.forEach((leaseToDevice) => {
							if (
								leaseToDevice.device_id ===
								props.recordToEdit.id
							) {
								removeAssociatedDeviceToLease(leaseToDevice.id);
							}
						});
					}
				}
			}
			// find the purchase record
			if (leaseActive && purchaseRecord) {
				// remove purchase if lease is selected
				await removePurchaseDevice(purchaseRecord.id);
			}
			// 2. add new associations / update purchase
			if (leaseActive) {
				// if lease has changed, associate device to lease
				if (leaseToDeviceRecord) {
					if (leaseToDeviceRecord.id !== existingLease.id) {
						await associateDeviceToLease(
							props.recordToEdit.id,
							existingLease.id
						);
					}
				}
			} else if (purchaseActive) {
				if (purchaseRecord) {
					// update purchase
					await updatePurchaseDevice();
				} else {
					// add purchase
					await purchaseDevice(props.recordToEdit.id);
				}
			}
		} else {
			// adding a whole new record
			// adding a new device + laptopdesktop record
			const deviceRes = await handleAddDeviceRecord();
			// check out device if user is selected
			if (user) {
				await checkOutDevice(deviceRes.insertId);
			}
			// adding a new purchase or lease record
			if (leaseActive) {
				// if lease is active and a lease is selected
				if (existingLease) {
					await associateDeviceToLease(
						deviceRes.insertId,
						existingLease.id
					);
				}
			} else if (purchaseActive) {
				// if purchase is active
				await purchaseDevice(deviceRes.insertId);
			}
		}

		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		props.onClose();
	};

	const handleCheckInDevice = async () => {
		if (checkInDate === "") {
			setAlertMessage("Check In Date is required.");
			setAlertOpen(true);
			return;
		}
		setLoading(true);
		await checkInDevice();
		resetInputFields();
		props.onClose();
	};

	const handleCancelClick = () => {
		resetInputFields();
		props.onCloseNoRefresh();
	};

	const resetInputFields = () => {
		setDeviceCategory("");
		setOffice("");
		setDeviceStatus("");
		setDefaultName("");
		setSerialNumber("");
		setJ2DeviceName("");
		setModel("");
		setStorage("");
		setRam("");
		setOs("");
		setManufacturer("");
		setUser("");
		setCheckOutDate(dayjs());
		setCheckInDate(dayjs());
		setLoading(false);
		setAlertMessage("");
		setAlertOpen(false);
		setPurchaseActive(true);
		setPurchaseDate(dayjs());
		setPurchaseAmount(0);
		setPurchaseNotes("");
		setLeaseActive(false);
		setExistingLease([]);
	};

	const validateInputFields = () => {
		if (deviceCategory === "") {
			setAlertMessage("Device Category is required.");
			setAlertOpen(true);
			return false;
		}
		if (office === "") {
			setAlertMessage("Office is required.");
			setAlertOpen(true);
			return false;
		}
		if (deviceStatus === "") {
			setAlertMessage("Device Status is required.");
			setAlertOpen(true);
			return false;
		}
		// default name not required
		if (serialNumber === "") {
			setAlertMessage("Serial Number is required.");
			setAlertOpen(true);
			return false;
		}
		if (j2DeviceName === "") {
			setAlertMessage("J2 Device Name is required.");
			setAlertOpen(true);
			return false;
		}
		if (model === "") {
			setAlertMessage("Model is required.");
			setAlertOpen(true);
			return false;
		}
		// storage is not required
		// ram is not required
		// os is not required
		// manufacturer is not required
		// user is not required
		if (user) {
			if (checkOutDate === "") {
				setAlertMessage("Check Out Date is required.");
				setAlertOpen(true);
				return false;
			}
		}
		// purchase or lease is required
		if (purchaseActive) {
			if (purchaseDate === "") {
				setAlertMessage("Purchase Date is required.");
				setAlertOpen(true);
				return false;
			}
			if (purchaseAmount === "") {
				setAlertMessage("Purchase Amount is required.");
				setAlertOpen(true);
				return false;
			}
			//notes not required
		} else if (leaseActive) {
			if (!existingLease) {
				setAlertMessage("Lease is required.");
				setAlertOpen(true);
				return false;
			}
		}
		return true;
	};

	// RENDER
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Dialog
				id="DevicesEditAddDialog"
				open={props.open}
				maxWidth="xl"
				fullWidth
				onClose={handleCancelClick}
			>
				<DialogTitle id="DevicesEditAddDialogTitle">
					{/* edit / add */}
					{props.editing
						? "Edit Laptop/Desktop Record"
						: "Add Laptop/Desktop Record"}
				</DialogTitle>
				{loading && (
					<div className="devicesLoadingSpinner">
						<CircularProgress />
					</div>
				)}
				{alertOpen && (
					<div className="devicesAlert">
						<Alert
							severity="warning"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setAlertOpen(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					</div>
				)}
				{!loading && (
					<DialogContent id="DevicesEditAddDialogContent">
						<div className="dialogSubtitle">Device Category</div>

						<FormControl sx={{ flex: 1 }}>
							<div className="FormBlock">
								<div className="FormRow">
									<Autocomplete
										sx={{ flexGrow: 1 }}
										id="deviceCategory"
										options={props.deviceCategories}
										getOptionLabel={(option) =>
											option ? option.display_name : ""
										}
										value={
											deviceCategory ? deviceCategory : ""
										}
										onChange={handleDeviceCategoryChange}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Device Category"
												variant="outlined"
											/>
										)}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>
												{option.display_name}
											</li>
										)}
									/>
								</div>
							</div>
						</FormControl>

						{deviceCategory && (
							<>
								<Divider />
								<div className="dialogSubtitle">
									Device Information
								</div>

								<FormControl sx={{ flex: 1 }}>
									<div className="FormBlock">
										<div className="FormRow">
											<TextField
												sx={{ flexGrow: 1 }}
												label="j2 Device Name"
												value={j2DeviceName}
												onChange={
													handleJ2DeviceNameChange
												}
												variant="outlined"
											/>
											<TextField
												sx={{ flexGrow: 1 }}
												label="Default Name"
												value={defaultName}
												onChange={
													handleDefaultNameChange
												}
												variant="outlined"
											/>
										</div>
										<div className="FormRow">
											<TextField
												sx={{ flexGrow: 1 }}
												label="Serial Number/Service Tag"
												value={serialNumber}
												onChange={
													handleSerialNumberChange
												}
												variant="outlined"
											/>
											<Autocomplete
												sx={{ flexGrow: 1 }}
												freeSolo
												value={model}
												onChange={(event, newValue) =>
													handleModelChange(
														event,
														newValue
													)
												}
												options={[
													...new Set(
														props.devices
															.map(
																(device) =>
																	device.model
															)
															.filter(Boolean)
													),
												]}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Model"
														variant="outlined"
													/>
												)}
											/>
										</div>
										<div className="FormRow">
											<Autocomplete
												sx={{ flexGrow: 1 }}
												freeSolo
												value={storage}
												onChange={(event, newValue) =>
													handleStorageChange(
														event,
														newValue
													)
												}
												options={[
													...new Set(
														props.devices
															.map(
																(device) =>
																	device.storage
															)
															.filter(Boolean)
													),
												]}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Storage"
														variant="outlined"
													/>
												)}
											/>
											<Autocomplete
												sx={{ flexGrow: 1 }}
												freeSolo
												value={ram}
												onChange={(event, newValue) =>
													handleRamChange(
														event,
														newValue
													)
												}
												options={[
													...new Set(
														props.devices
															.map(
																(device) =>
																	device.ram
															)
															.filter(Boolean)
													),
												]}
												renderInput={(params) => (
													<TextField
														{...params}
														label="RAM"
														variant="outlined"
													/>
												)}
											/>
											<Autocomplete
												sx={{ flexGrow: 1 }}
												freeSolo
												value={os}
												onChange={(event, newValue) =>
													handleOsChange(
														event,
														newValue
													)
												}
												options={[
													...new Set(
														props.devices
															.map(
																(device) =>
																	device.os
															)
															.filter(Boolean)
													),
												]}
												renderInput={(params) => (
													<TextField
														{...params}
														label="OS"
														variant="outlined"
													/>
												)}
											/>
										</div>
										<div className="FormRow">
											{/* <TextField
												sx={{ flexGrow: 1 }}
												label="Manufacturer"
												value={manufacturer}
												onChange={
													handleManufacturerChange
												}
												variant="outlined"
											/> */}
											<Autocomplete
												sx={{ flexGrow: 1 }}
												freeSolo
												value={manufacturer}
												onChange={(event, newValue) =>
													handleManufacturerChange(
														event,
														newValue
													)
												}
												options={[
													...new Set(
														props.devices
															.map(
																(device) =>
																	device.manufacturer
															)
															.filter(Boolean)
													),
												]}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Manufacturer"
														variant="outlined"
													/>
												)}
											/>
										</div>

										<div className="FormRow">
											<Autocomplete
												sx={{ flexGrow: 1 }}
												id="office"
												options={props.offices}
												getOptionLabel={(option) =>
													option ? option.state : ""
												}
												value={office ? office : ""}
												onChange={handleOfficeChange}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Office"
														variant="outlined"
													/>
												)}
												renderOption={(
													props,
													option
												) => (
													<li
														{...props}
														key={option.id}
													>
														{option.state}
													</li>
												)}
											/>
											<Autocomplete
												sx={{ flexGrow: 1 }}
												id="deviceStatus"
												options={props.deviceStatuses}
												getOptionLabel={(option) =>
													option
														? option.display_name
														: ""
												}
												value={
													deviceStatus
														? deviceStatus
														: ""
												}
												onChange={
													handleDeviceStatusChange
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Device Status"
														variant="outlined"
													/>
												)}
												renderOption={(
													props,
													option
												) => (
													<li
														{...props}
														key={option.id}
													>
														{option.display_name}
													</li>
												)}
											/>
										</div>
									</div>
								</FormControl>
							</>
						)}

						<Divider />

						<div className="dialogSubtitle">User Information</div>

						<FormControl sx={{ flex: 1 }}>
							<div className="FormBlock">
								{/* if editing an exisiting user, display the current user */}
								{props.editing && user ? (
									<div className="FormRow">
										<Autocomplete
											sx={{ flexGrow: 1 }}
											id="user"
											options={props.employees}
											getOptionLabel={(option) =>
												option
													? option.first_name +
													  " " +
													  option.last_name
													: ""
											}
											value={user ? user : ""}
											onChange={handleUserChange}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Current User"
													variant="outlined"
												/>
											)}
											renderOption={(props, option) => (
												<li {...props} key={option.id}>
													{option.first_name +
														" " +
														option.last_name}
												</li>
											)}
											disabled
										/>
										<DatePicker
											label="Check Out Date"
											value={checkOutDate}
											onChange={handleCheckOutDateChange}
											disabled
										/>
										<DatePicker
											label="Check In Date"
											value={checkInDate}
											onChange={handleCheckInDateChange}
										/>
										<Button
											variant="contained"
											color="primary"
											onClick={handleCheckInDevice}
										>
											Check In Device
										</Button>
									</div>
								) : (
									<div className="FormRow">
										<Autocomplete
											sx={{ flexGrow: 1 }}
											id="user"
											options={props.employees}
											getOptionLabel={(option) =>
												option
													? option.first_name +
													  " " +
													  option.last_name
													: ""
											}
											value={user ? user : ""}
											onChange={handleUserChange}
											renderInput={(params) => (
												<TextField
													{...params}
													label="User"
													variant="outlined"
												/>
											)}
											renderOption={(props, option) => (
												<li {...props} key={option.id}>
													{option.first_name +
														" " +
														option.last_name}
												</li>
											)}
										/>
										<DatePicker
											label="Check Out Date"
											value={checkOutDate}
											onChange={handleCheckOutDateChange}
										/>
									</div>
								)}
							</div>
						</FormControl>

						<Divider />

						<div className="dialogSubtitle">
							{purchaseActive
								? "Purchase Information"
								: "Lease Information"}
						</div>

						<ButtonGroup>
							<Button
								variant={"contained"}
								onClick={purchaseClicked}
								disabled={purchaseActive}
							>
								Purchase
							</Button>
							<Button
								variant={"contained"}
								onClick={leaseClicked}
								disabled={leaseActive}
							>
								Lease
							</Button>
						</ButtonGroup>

						{purchaseActive && (
							<FormControl sx={{ flex: 1 }}>
								<div className="FormBlock">
									<div className="FormRow">
										<DatePicker
											sx={{ flexGrow: 1 }}
											label="Purchase Date"
											value={purchaseDate}
											onChange={handlePurchaseDateChange}
										/>
										<TextField
											sx={{ flexGrow: 1 }}
											label="Purchase Amount"
											value={purchaseAmount}
											onChange={
												handlePurchaseAmountChange
											}
											variant="outlined"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														$
													</InputAdornment>
												),
											}}
										/>
									</div>
									<TextField
										label="Purchase Notes"
										value={purchaseNotes}
										onChange={handlePurchaseNotesChange}
										variant="outlined"
									/>
								</div>
							</FormControl>
						)}
						{leaseActive && (
							<FormControl sx={{ flex: 1 }}>
								<div className="FormBlock">
									<div className="FormRow">
										<Autocomplete
											sx={{ flexGrow: 1 }}
											id="leaseManufacturer"
											options={props.leases}
											getOptionLabel={(option) =>
												option
													? option.lease_number
														? option.lease_number
														: ""
													: ""
											}
											value={existingLease}
											onChange={existingLeaseChanged}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Lease"
													variant="outlined"
												/>
											)}
											renderOption={(props, option) => (
												<li {...props} key={option.id}>
													{option.lease_number}
												</li>
											)}
										/>
									</div>
								</div>
							</FormControl>
						)}

						<div className="editPhaseConsoleButtons">
							<Button
								variant="outlined"
								color="error"
								onClick={handleCancelClick}
								disabled={loading}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmitClick}
								disabled={loading}
							>
								Submit
							</Button>
						</div>
					</DialogContent>
				)}
			</Dialog>
		</LocalizationProvider>
	);
};

// EXPORT
export default DevicesEditAddDialog;
