// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./EditTicketDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { putAPICall, postAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
	Checkbox,
	FormControlLabel,
	FormGroup,
	InputAdornment,
	TextField,
} from "@mui/material";

// REACT COMPONENT
const EditTicketDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [id, setId] = useState(0);

	const [assigneeID, setAssigneeID] = useState(0);
	const [originalAssigneeID, setOriginalAssigneeID] = useState(0);

	const [ticketStatus, setTicketStatus] = useState({});

	const [ticketRecipientID, setTicketRecipientID] = useState(0);

	const [ticketComment, setTicketComment] = useState("");

	const [notifyUser, setNotifyUser] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setId(props.ticket.id);
			setAssigneeID(props.ticket.owner_id);
			setTicketRecipientID(props.ticket.submitted_by_id);
			setOriginalAssigneeID(props.ticket.owner_id);
			props.ticketStatuses.forEach((status) => {
				if (status.id == props.ticket.status_id) {
					setTicketStatus(status);
				}
			});
		}
	}, [props.open, props.ticket]);

	// INPUT HANDLERS
	const notifyUserChanged = (e) => {
		setNotifyUser(e.target.checked);
	};

	const assigneeIDChanged = (e) => {
		setAssigneeID(e.target.value);
	};

	const ticketStatusChanged = (e) => {
		setTicketStatus(e.target.value);
	};

	const ticketRecipientIDChanged = (e) => {
		setTicketRecipientID(e.target.value);
	};

	const ticketCommentChanged = (e) => {
		setTicketComment(e.target.value);
	};

	// BUTTONS
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;
		if (id === 0) return;

		setIsLoading(true);

		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/tickets/update",
				{
					taskOwnerID: assigneeID,
					statusID: ticketStatus.id,
					recipientID: ticketRecipientID,
					modifiedBy: employeeID,
					ticketID: id,
				},
				employeeID
			);

			if (assigneeID !== originalAssigneeID) {
				await sendEmailToTaskOwner();
			}

			if (ticketStatus.id != props.ticket.status_id || ticketComment) {
				await createChangeComment();
			}

			await postAPICall(
				instance,
				accounts[0],
				"/api/logs/automation/add",
				{
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					success: true,
					message: `Ticket with ID ${id} edited. New information is as follows: Task Owner ID: ${assigneeID}, Status ID: ${ticketStatus.id}, Recipient ID: ${ticketRecipientID}`,
					tool_name: "Tickets",
				}
			);

			setIsLoading(false);
			resetForm();
			props.fetchTickets();
			props.editTicketDialogClosed();
		} catch (error) {
			console.log("Error in Edit Ticket Dialog submit: ", error);
			setIsLoading(false);
		}
	};

	// API HELPER FUNCTIONS
	const sendEmailToTaskOwner = async () => {
		try {
			let res = await postAPICall(
				instance,
				accounts[0],
				"/api/tickets/email/assigned",
				{
					ticketID: id,
					taskOwnerID: assigneeID,
				}
			);
		} catch (error) {
			console.log("Error sending email: ", error);
		}
	};

	const createChangeComment = async () => {
		try {
			// build up comment
			let comment;
			if (props.ticket.status_id != ticketStatus.id) {
				comment = `Status changed from ${props.ticket.status_name} to ${ticketStatus.name}`;
			}
			if (ticketComment) {
				comment = comment
					? comment + ": " + ticketComment
					: ticketComment;
			}

			let res = await postAPICall(
				instance,
				accounts[0],
				"/api/tickets/comments/add",
				{
					ticketID: id,
					comment: comment,
					submittedByID: employeeID,
					modifiedBy: employeeID,
				}
			);

			// email user that comment has been added/status changed
			if (notifyUser) {
				await postAPICall(
					instance,
					accounts[0],
					"/api/tickets/email/comment",
					{
						ticketID: id,
						comment: comment,
					}
				);
			}
		} catch (error) {
			console.log("Error creating change comment: ", error);
		}
	};

	const sendEmailToEXPTechnical = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		const updateRes = await putAPICall(
			instance,
			accounts[0],
			"/api/tickets/update",
			{
				taskOwnerID: assigneeID,
				statusID: 6, // sent to exp status
				recipientID: ticketRecipientID,
				ticketID: id,
				modifiedBy: employeeID,
			},
			employeeID
		);

		let res = await postAPICall(
			instance,
			accounts[0],
			"/api/tickets/email/expTechnical",
			{
				ticketID: id,
			}
		);

		setIsLoading(false);
		resetForm();
		props.fetchTickets();
		props.editTicketDialogClosed();
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		setId(0);
		setAssigneeID(0);
		setOriginalAssigneeID(0);
		setTicketRecipientID(0);
		setTicketStatus({});
		setTicketComment("");
		setIsLoading(false);
	};

	const validateInputFields = () => {
		if (!ticketStatus || !ticketRecipientID) {
			return false;
		}

		// ticket comment optional

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.editTicketDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="editTicketDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editTicketDialogTitle">Edit Ticket</DialogTitle>
			<DialogContent id="editTicketDialogContent">
				{isLoading && (
					<div className="editTicketDialogRow">
						<CircularProgress />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="editTicketDialogRow">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="assigneeLabel">
									Assignee
								</InputLabel>
								<Select
									labelId="assigneeLabel"
									id="assigneeSelect"
									value={assigneeID}
									label="Assignee"
									onChange={assigneeIDChanged}
								>
									{props.itMembers.map((member) => (
										<MenuItem
											key={member.id}
											value={member.id}
										>
											{`${member.first_name} ${member.last_name}`}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="statusLabel">Status</InputLabel>
								<Select
									labelId="statusLabel"
									id="statusSelect"
									value={ticketStatus}
									label="Status"
									onChange={ticketStatusChanged}
								>
									{props.ticketStatuses.map((status) => (
										<MenuItem value={status}>
											{status.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="editTicketDialogRow">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="ticketRecipientLabel">
									Ticket Recipient
								</InputLabel>
								<Select
									labelId="ticketRecipientLabel"
									id="ticketRecipientSelect"
									value={ticketRecipientID}
									label="Ticket Recipient"
									onChange={ticketRecipientIDChanged}
								>
									{props.employees.map((employee) => (
										<MenuItem
											key={employee.id}
											value={employee.id}
										>
											{employee.first_name}{" "}
											{employee.last_name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="editTicketDialogRow">
							<FormControl sx={{ flex: 1 }}>
								<TextField
									id="ticketComment"
									label="Ticket Comment"
									value={ticketComment}
									onChange={ticketCommentChanged}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												{/* add ticket status comment if changed, not optional but can be added to */}
												{props.ticket.status_id !=
													ticketStatus.id &&
													"Status changed from " +
														props.ticket
															.status_name +
														" to " +
														ticketStatus.name}
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</div>
						<div className="editTicketDialogRow">
							<FormGroup sx={{ flex: 1 }}>
								<FormControlLabel
									control={
										<Checkbox
											checked={notifyUser}
											onChange={notifyUserChanged}
										/>
									}
									labelPlacement="right"
									label="Notify User?"
								/>
							</FormGroup>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={sendEmailToEXPTechnical}
						variant="contained"
						color="secondary"
						disabled={isLoading}
					>
						Send to EXP Technical
					</Button>
				</div>
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
						disabled={isLoading}
					>
						Cancel
					</Button>
					<Button
						onClick={submitButtonClicked}
						variant="contained"
						disabled={isLoading}
					>
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EditTicketDialog;
