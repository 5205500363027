// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./RoleConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import EditRoleDialog from "./EditRoleDialog/EditRoleDialog";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	role: "Role",
	tools: "Tools",
};

const searchDefaultValue = "role";

// REACT COMPONENT
const RoleConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API Results
	const [roles, setRoles] = useState([]);
	const [filteredRoles, setFilteredRoles] = useState([]);

	const [roleRows, setRoleRows] = useState([]);
	const [roleColumns, setRoleColumns] = useState([]);

	const [tools, setTools] = useState([]);
	const [selectedTools, setSelectedTools] = useState([]);
	const [selectedAdminTools, setSelectedAdminTools] = useState([]);

	// Dialog
	const [recordToEdit, setRecordToEdit] = useState({});
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// USE EFFECT
	useEffect(() => {
		fetchRoles();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredRoles]);

	// INPUT HANDLERS
	const editRecordClicked = async (record) => {
		await fetchSelectedTools(record.id);
		await fetchSelectedAdminTools(record.id);

		setRecordToEdit(record);
		setEditRoleDialogOpen(true);
	};

	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchRoles = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/roles/all"
			);
			setRoles(res.data);
			setFilteredRoles(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));

			await fetchTools();
		} catch (error) {
			console.log("Error in fetchRoles: ", error);
		}

		setIsLoading(false);
	};

	const fetchTools = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/roles/tools/all"
			);
			setTools(res.data);
		} catch (error) {
			console.log("Error in fetchTools: ", error);
		}
	};

	const fetchSelectedTools = async (roleID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/roles/tools/byRole",
				{
					roleID: roleID,
				}
			);

			setSelectedTools(res.data);
		} catch (error) {
			console.log("Error in fetchSelectedTools: ", error);
		}
	};

	const fetchSelectedAdminTools = async (roleID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/roles/tools/admin/byRole",
				{
					roleID: roleID,
				}
			);

			setSelectedAdminTools(res.data);
		} catch (error) {
			console.log("Error in fetchSelectedTools: ", error);
		}
	};

	// HELPER FUNCTIONS
	const filterRoles = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredRoleRecords = roles.filter((roleObj) => {
			if (searchByParameter === "role") {
				return (roleObj.role_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "tools") {
				return (roleObj.tools ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredRoles(filteredRoleRecords);
		setNumberOfPages(
			Math.ceil(filteredRoleRecords.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setRoleColumns([
			{ text: "Role Name" },
			{ text: "Tools" },
			{}, // blank for edit
		]);
	};

	const createRowData = () => {
		setRoleRows(
			filteredRoles.map((role) => [
				{ text: role.role_name },
				{
					text: (role.split_tools ?? "")
						.split("_SPLIT_")
						.map((tool) => <div>{tool}</div>),
				},
				{
					icon: "edit",
					onClick: () => editRecordClicked(role),
				},
			])
		);
	};

	// RENDER
	return (
		<div className="RoleConsole">
			<div className="roleConsoleHeader">
				<div className="roleConsoleHeaderTitle">Role Console</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterRoles(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredRoles.length === 0 && <J2NoResultsFound />}
					{filteredRoles.length > 0 && (
						<>
							<GenericTable
								columnData={roleColumns}
								rowData={roleRows.slice(
									paginationStartIndex,
									paginationEndIndex
								)}
							/>
							<div className="paginationDiv">
								<Pagination
									count={numberOfPages}
									color="primary"
									onChange={handlePageChange}
								/>
							</div>
						</>
					)}
				</div>
			)}
			<EditRoleDialog
				open={editRoleDialogOpen}
				tools={tools}
				selectedTools={selectedTools}
				selectedAdminTools={selectedAdminTools}
				editRoleDialogClosed={() => setEditRoleDialogOpen(false)}
				recordToEdit={recordToEdit}
				fetchRoles={fetchRoles}
			/>
		</div>
	);
};

export default RoleConsole;
