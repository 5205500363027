// DEPENDENCIES
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

// CSS
import "./DetailScreen.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, ButtonGroup, CircularProgress } from "@mui/material";

// CUSTOM COMPONENTS
import InformationCard from "./InformationCard/InformationCard";
import PDFsCard from "./PDFsCard/PDFsCard";
import CommentsCard from "./CommentsCard/CommentsCard";
import AssembliesAndGroupingsCard from "./AssembliesAndGroupingsCard/AssembliesAndGroupingsCard";

import ConfirmDeleteDetailDialog from "./ConfirmDeleteDetailDialog/ConfirmDeleteDetailDialog";

// FUNCTIONAL COMPONENT
const DetailScreen = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// PARAMS
	const { id } = useParams();

	// NAVIGATE
	const navigate = useNavigate();

	// STATES
	// API Data States
	const [detailRecord, setDetailRecord] = useState({});
	const [comments, setComments] = useState([]);
	const [detailStatuses, setDetailStatuses] = useState([]);

	const [bwPDFFileLink, setBWPDFFileLink] = useState("");
	const [colorPDFFileLink, setColorPDFFileLink] = useState("");
	const [dwgFileLink, setDWGFileLink] = useState("");

	const [assemblies, setAssemblies] = useState([]);
	const [groupings, setGroupings] = useState([]);

	const [selectedAssemblies, setSelectedAssemblies] = useState([]);
	const [selectedGroupings, setSelectedGroupings] = useState([]);

	// Dropdown States
	const [informationOpen, setInformationOpen] = useState(true);
	const [pdfsOpen, setPdfsOpen] = useState(true);
	const [commentsOpen, setCommentsOpen] = useState(true);
	const [assembliesAndGroupingsOpen, setAssembliesAndGroupingsOpen] =
		useState(true);

	// Feedback States
	const [isLoading, setIsLoading] = useState(false);

	// Dialog States
	const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
		useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchDetailRecord();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Detail Library",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: `Accessed the detail with ID of ${id}`,
		});
	}, [id]);

	// INPUT HANDLERS
	// Dropdown Handlers
	const expandAllMenus = () => {
		setInformationOpen(true);
		setPdfsOpen(true);
		setCommentsOpen(true);
		setAssembliesAndGroupingsOpen(true);
	};

	const collapseAllMenus = () => {
		setInformationOpen(false);
		setPdfsOpen(false);
		setCommentsOpen(false);
		setAssembliesAndGroupingsOpen(false);
	};

	const informationDropdownClicked = () => {
		setInformationOpen(!informationOpen);
	};

	const pdfsDropdownClicked = () => {
		setPdfsOpen(!pdfsOpen);
	};

	const commentsDropdownClicked = () => {
		setCommentsOpen(!commentsOpen);
	};

	const assembliesAndGroupingsDropdownClicked = () => {
		setAssembliesAndGroupingsOpen(!assembliesAndGroupingsOpen);
	};

	const confirmDeleteDialogOpened = async () => {
		if (!props.isAdmin) {
			return;
		}

		setConfirmDeleteDialogOpen(true);
	};

	const confirmDeleteDialogClosed = () => {
		setConfirmDeleteDialogOpen(false);
	};

	// API CALLS
	const fetchDetailRecord = async () => {
		setIsLoading(true);

		try {
			const detailRes = await getAPICall(
				instance,
				accounts[0],
				`/api/encompass/details/byID`,
				{
					id: id,
				}
			);

			if (detailRes.data.length === 0) {
				navigate("/detail_not_found");
			}

			setDetailRecord(detailRes.data[0]);

			await fetchComments();

			await fetchDetailStatuses();

			await fetchAssemblies();
			await fetchGroupings();

			await fetchBWPDFFile();
			await fetchColorPDFFile();
			await fetchDWGFile();
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	// Comments
	const fetchComments = async () => {
		const res = await getAPICall(
			instance,
			accounts[0],
			`/api/encompass/details/comments/byID`,
			{
				detailID: id,
			}
		);

		setComments(res.data);
	};

	// Assemblies
	const fetchAssemblies = async () => {
		const res = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/assemblies/all"
		);
		setAssemblies(res.data);

		const selectedRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/assemblies/byDetail",
			{
				detailID: id,
			}
		);

		setSelectedAssemblies(selectedRes.data);
	};

	// Groupings
	const fetchGroupings = async () => {
		const res = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/groupings/all"
		);
		setGroupings(res.data);

		const selectedRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/groupings/byDetail",
			{
				detailID: id,
			}
		);

		setSelectedGroupings(selectedRes.data);
	};

	// Statuses
	const fetchDetailStatuses = async () => {
		const res = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/statuses/all"
		);

		setDetailStatuses(res.data);
	};

	// Files
	const fetchBWPDFFile = async () => {
		const fileData = {
			detailID: id,
		};

		const res = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/bwPDF/get",
			fileData
		);

		console.log("BW PDF File: ", res.data);

		setBWPDFFileLink(res.data);
	};

	const fetchColorPDFFile = async () => {
		const fileData = {
			detailID: id,
		};

		const res = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/colorPDF/get",
			fileData
		);

		setColorPDFFileLink(res.data);
	};

	const fetchDWGFile = async () => {
		const fileData = {
			detailID: id,
		};

		const res = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/files/dwg/get",
			fileData
		);

		setDWGFileLink(res.data);
	};

	// RENDER
	return (
		<div className="DetailScreen">
			<div className="detailScreenHeader">
				<div className="detailScreenHeaderTitle">
					{detailRecord.title ? detailRecord.title : "Loading..."}
					<ButtonGroup>
						<Button
							onClick={collapseAllMenus}
							color="secondary"
							variant="contained"
						>
							Collapse All
						</Button>
						<Button
							onClick={expandAllMenus}
							color="primary"
							variant="contained"
						>
							Expand All
						</Button>
					</ButtonGroup>
				</div>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="detailScreenBody">
					<InformationCard
						detailRecord={detailRecord}
						informationDropdownClicked={informationDropdownClicked}
						informationOpen={informationOpen}
						dwgFileLink={dwgFileLink}
						detailStatuses={detailStatuses}
						comments={comments.slice(0, 2)}
						fetchDetailRecord={fetchDetailRecord}
					/>
					<PDFsCard
						detailRecord={detailRecord}
						pdfsDropdownClicked={pdfsDropdownClicked}
						pdfsOpen={pdfsOpen}
						bwPDFFileLink={bwPDFFileLink}
						colorPDFFileLink={colorPDFFileLink}
						dwgFileLink={dwgFileLink}
						fetchDetailRecord={fetchDetailRecord}
					/>
					<CommentsCard
						detailRecord={detailRecord}
						commentsDropdownClicked={commentsDropdownClicked}
						commentsOpen={commentsOpen}
						comments={comments}
						fetchComments={fetchComments}
						isAdmin={props.isAdmin}
					/>
					<AssembliesAndGroupingsCard
						detailRecord={detailRecord}
						assemblies={assemblies}
						groupings={groupings}
						selectedAssemblies={selectedAssemblies}
						selectedGroupings={selectedGroupings}
						assembliesAndGroupingsDropdownClicked={
							assembliesAndGroupingsDropdownClicked
						}
						assembliesAndGroupingsOpen={assembliesAndGroupingsOpen}
						fetchDetailRecord={fetchDetailRecord}
					/>
					{props.isAdmin && (
						<div className="detailScreenDeleteButtonContainer">
							<Button
								variant="contained"
								color="error"
								onClick={confirmDeleteDialogOpened}
							>
								Delete Detail
							</Button>
						</div>
					)}
					<ConfirmDeleteDetailDialog
						open={confirmDeleteDialogOpen}
						onClose={confirmDeleteDialogClosed}
						detailName={detailRecord.title}
						id={detailRecord.id}
						isAdmin={props.isAdmin}
					/>
				</div>
			)}
		</div>
	);
};

export default DetailScreen;
