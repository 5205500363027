// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./EditInformationCardDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall, putAPICall } from "../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// CONSTANTS
import { getDateTime } from "../../../../../constants";

// REACT COMPONENT
const EditInformationCardDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Record states
	const [id, setId] = useState(0);

	const [title, setTitle] = useState("");

	const [originalDetailStatusID, setOriginalDetailStatusID] = useState(0);
	const [detailStatusID, setDetailStatusID] = useState(0);

	const [detailStatusComment, setDetailStatusComment] = useState("");
	const [detailStatusCommentOpen, setDetailStatusCommentOpen] =
		useState(false);

	// Feedback states
	const [isLoading, setIsLoading] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setId(props.recordToEdit.id);
			setTitle(props.recordToEdit.title);
			setOriginalDetailStatusID(props.recordToEdit.detail_status_id);
			setDetailStatusID(props.recordToEdit.detail_status_id);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	// Form input handlers
	const titleChanged = (e) => {
		setTitle(e.target.value);
	};

	const detailStatusIDChanged = (e) => {
		setDetailStatusID(e.target.value);

		if (e.target.value === originalDetailStatusID) {
			setDetailStatusCommentOpen(false);
			setDetailStatusComment("");
		} else {
			setDetailStatusCommentOpen(true);
		}
	};

	const detailStatusCommentChanged = (e) => {
		setDetailStatusComment(e.target.value);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const closeAlert = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	// Submit button handler
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		try {
			await updateDetail();

			if (detailStatusID !== originalDetailStatusID) {
				await createStatusUpdateComment();
			}
		} catch (err) {
			openAlert(err);
		}

		setIsLoading(false);

		formClosed();
		props.fetchDetailRecord();
	};

	// API FUNCTIONS
	const updateDetail = async () => {
		const res = await putAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/update/information",
			{
				title: title,
				detailStatusID: detailStatusID,
				detailID: id,
			},
			employeeID
		);

		await postAPICall(instance, accounts[0], "/api/logs/automation/add", {
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			success: true,
			message: `Information edited on detail: ${props.recordToEdit.title}. New information is as follows: Title: ${title}; Detail Status ID: ${detailStatusID}`,
			tool_name: "Detail Library",
		});
	};

	const createStatusUpdateComment = async () => {
		try {
			let originalStatusName = props.detailStatuses.find(
				(status) => status.id === originalDetailStatusID
			).name;
			let newStatusName = props.detailStatuses.find(
				(status) => status.id === detailStatusID
			).name;

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/encompass/details/comments/add",
				{
					detailID: id,
					comment: `Changed status from ${originalStatusName} to ${newStatusName}. Comment: ${detailStatusComment}`,
					createdDateTime: getDateTime(),
					createdByID: employeeID,
				}
			);
		} catch (error) {
			console.error("Error creating new comment: ", error);
		}
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		closeAlert();

		setId(0);
		setTitle("");

		setOriginalDetailStatusID(0);
		setDetailStatusID(0);

		setDetailStatusComment("");
		setDetailStatusCommentOpen(false);
	};

	const validateInputFields = () => {
		if (id === 0) {
			openAlert(
				"Detail Record ID is invalid. Please refresh the page and try again. If the problem persists, contact the system administrator."
			);
			return false;
		}

		if (title === "") {
			openAlert("Title is required");
			return false;
		}

		if (detailStatusID === 0 || !detailStatusID) {
			openAlert("Status is required");
			return false;
		}

		if (
			detailStatusID !== originalDetailStatusID &&
			detailStatusComment === ""
		) {
			openAlert("Comment is required when changing the status");
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.editInformationCardDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="editInformationCardDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editInformationCardDialogTitle">
				{props.recordToEdit.title}
			</DialogTitle>
			<DialogContent id="editInformationCardDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="editInformationCardDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="editInformationCardDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="editInformationCardDialogAlert"
									severity="error"
									action={
										<IconButton
											id="editInformationCardDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="editInformationCardDialogCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="editInformationCardDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="titleTextField"
								label="Title"
								value={title}
								onChange={titleChanged}
							/>
						</div>
						<div className="editInformationCardDialogRow">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="statusLabel">Status</InputLabel>
								<Select
									labelId="statusLabel"
									id="statusSelect"
									value={detailStatusID}
									label="Status"
									onChange={detailStatusIDChanged}
								>
									{(props.detailStatuses ?? []).map(
										(status) => (
											<MenuItem
												key={status.id}
												value={status.id}
											>
												{status.name}
											</MenuItem>
										)
									)}
								</Select>
							</FormControl>
						</div>
						<div className="editInformationCardDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="editInformationCardDialogCollapse"
								in={detailStatusCommentOpen}
							>
								<TextField
									sx={{ width: "stretch" }}
									id="detailStatusCommentField"
									label="Status Comment"
									value={detailStatusComment}
									onChange={detailStatusCommentChanged}
								/>
							</Collapse>
						</div>
						<div className="dialogButtons">
							<Button
								onClick={formClosed}
								variant="outlined"
								color="error"
							>
								Cancel
							</Button>
							<Button
								onClick={submitButtonClicked}
								variant="contained"
							>
								Submit
							</Button>
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default EditInformationCardDialog;
