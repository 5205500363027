// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DropboxFileSelector.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS

import { TextField, IconButton, CircularProgress } from "@mui/material";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

// MUI ICONS

// CUSTOM COMPONENTS
import FolderCard from "../../Proposals/PendingJobs/DropboxInformationCard/FolderCard/FolderCard";

// CONSTANTS

// OTHER

// REACT COMPONENT
const DropboxFileSelector = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [folderSearchText, setFolderSearchText] = useState("");

	// API
	const [folders, setFolders] = useState([]);
	const [filteredFolders, setFilteredFolders] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	// USE EFFECT
	useEffect(() => {
		if (props.filePath === null) {
			return;
		}
		fetchFolders(props.filePath);
	}, [props.filePath]);

	// INPUT HANDLERS
	const folderClicked = async (folder) => {
		if (props.filePath.split("/").length >= 7 || isLoading) {
			return;
		}

		let newFilePath = `${props.filePath}/${folder}`;

		setIsLoading(true);

		try {
			props.setFilePath(newFilePath);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	const folderSearchTextChanged = (e) => {
		setFolderSearchText(e.target.value);

		if (e.target.value === "") {
			setFilteredFolders(folders);
		} else {
			setFilteredFolders(
				folders.filter((folder) =>
					folder.name
						.toLowerCase()
						.includes(e.target.value.toLowerCase())
				)
			);
		}
	};

	const backButtonClicked = async () => {
		if (props.filePath.split("/").length <= 1 || isLoading) {
			return;
		}

		setIsLoading(true);

		let newPath = props.filePath
			.split("/")
			.slice(0, props.filePath.split("/").length - 1);

		props.setFilePath(newPath.join("/"));

		setIsLoading(false);
	};

	// API FUNCTIONS
	const fetchFolders = async (path) => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/dropbox/listFolders`,
				{
					path: path,
				}
			);

			setFolders(
				res.data.entries
					.filter((entry) => entry[".tag"] === "folder")
					.sort((a, b) => {
						const nameA = a.name.toUpperCase(); // ignore upper and lowercase
						const nameB = b.name.toUpperCase(); // ignore upper and lowercase
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}

						// names must be equal
						return 0;
					})
			);
			setFilteredFolders(
				res.data.entries
					.filter((entry) => entry[".tag"] === "folder")
					.sort((a, b) => {
						const nameA = a.name.toUpperCase(); // ignore upper and lowercase
						const nameB = b.name.toUpperCase(); // ignore upper and lowercase
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}

						// names must be equal
						return 0;
					})
			);

			setFolderSearchText("");
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="DropboxFileSelector">
			<div className="dropboxFileSelectorSubheader">File Path</div>
			<div className="dropboxFileSelectorFilePathContainer">
				<IconButton onClick={backButtonClicked}>
					<ArrowBackIcon />
				</IconButton>
				<h1 style={{ flexGrow: 1 }}>
					{props.filePath === "" ? "/" : props.filePath}
				</h1>
			</div>
			<div className="filterDropboxFolderTextFieldContainer">
				<TextField
					sx={{ flex: 1 }}
					label="Search"
					value={folderSearchText}
					onChange={folderSearchTextChanged}
				/>
			</div>
			{isLoading && (
				<div className="folderLoadingContainer">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="resultsContainer">
					{filteredFolders.length === 0 && (
						<div className="noResultsFoundContainer">
							Nothing to see here!
						</div>
					)}
					{filteredFolders.length > 0 && (
						<div className="folderContainer">
							{filteredFolders.map((folder) => (
								<FolderCard
									key={folder.id}
									folder={folder}
									folderClicked={folderClicked}
								/>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

// EXPORT
export default DropboxFileSelector;
