// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./NewInfoDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const NewInfoDialog = (props) => {
	const employeeID = useContext(UserContext);

	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [name, setName] = useState("");
	const [data, setData] = useState("");
	const [dataType, setDataType] = useState("");
	const [infoHeight, setInfoHeight] = useState(0);
	const [infoCategoryID, setInfoCategoryID] = useState("");

	const [alertOpen, setAlertOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// INPUT HANDLERS
	const nameChanged = (event) => {
		setName(event.target.value);
	};

	const dataChanged = (event) => {
		setData(event.target.value);
	};

	const dataTypeChanged = (event) => {
		setDataType(event.target.value);
	};

	const infoHeightChanged = (event) => {
		setInfoHeight(event.target.value);
	};

	const infoCategoryIDChanged = (event) => {
		setInfoCategoryID(event);
	};

	// SUBMIT HANDLER
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);
		try {
			let res = await postAPICall(
				instance,
				accounts[0],
				"/api/info/createInfoRecord",
				{
					name: name,
					data: data,
					dataType: dataType,
					infoHeight: infoHeight ? infoHeight : 1,
					infoCategoryID: getCategoryIdFromName(infoCategoryID),
					modifiedBy: employeeID,
				}
			);
			console.log(res);
		} catch (error) {
			console.log(error);
		}

		setIsLoading(false);
		resetForm();
		props.newInfoDialogClosed();
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const resetForm = () => {
		setName("");
		setData("");
		setDataType("");
		setInfoHeight(0);
		setInfoCategoryID(null);

		setAlertOpen(false);
		setIsLoading(false);
	};

	const validateInputFields = () => {
		if (!name || !data || !dataType || !infoCategoryID) {
			setAlertOpen(true);
			return false;
		}
		if (dataType === "iframe" && !infoHeight) {
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	const formClosed = () => {
		resetForm();
		props.newInfoDialogClosedNoRefresh();
	};

	const getCategoryIdFromName = (categoryName) => {
		let category = props.categories.find(
			(category) => category.name === categoryName
		);
		return category.id;
	};

	// RENDER
	return (
		<Dialog
			id="newInfoDialog"
			open={props.open}
			fullWidth
			maxWidth="lg"
			onClose={formClosed}
		>
			<DialogTitle id="newInfoDialogTitle">New Info Record</DialogTitle>
			<DialogContent id="newInfoDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="newInfoDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="newInfoCollapse"
								in={alertOpen}
							>
								<Alert
									id="newInfoAlert"
									severity="error"
									action={
										<IconButton
											id="newInfoCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={() => {
												setAlertOpen(false);
											}}
										>
											<CloseIcon
												id="newInfoCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									Please fill out all fields.
								</Alert>
							</Collapse>
						</div>
						<div className="newInfoDialogRow">
							<TextField
								sx={{ flex: 3 }}
								id="newInfoName"
								label="Name"
								value={name}
								onChange={nameChanged}
							/>
						</div>

						<div className="newInfoDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newInfoDataLink"
								label="Data Link"
								value={data}
								onChange={dataChanged}
							/>
						</div>
						<div className="newInfoDialogRow">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="newInfoDataTypeLabel">
									Data Type
								</InputLabel>
								<Select
									label="Data Type"
									value={dataType}
									onChange={dataTypeChanged}
									id="newInfoDataType"
								>
									<MenuItem value="iframe">Iframe</MenuItem>
									<MenuItem value="url">URL</MenuItem>
								</Select>
							</FormControl>
							{/* TODO: should only be required if type is iframe? */}
							{dataType === "iframe" && (
								<TextField
									sx={{ flex: 1 }}
									id="newInfoHeight"
									label="Height"
									value={infoHeight}
									onChange={infoHeightChanged}
								/>
							)}
						</div>
						<div className="newInfoDialogRow">
							<Autocomplete
								sx={{ flex: 1 }}
								id="newInfoCategory"
								options={props.categories.map(
									(category) => category.name
								)}
								renderInput={(params) => (
									<TextField {...params} label="Category" />
								)}
								onChange={(event, value) => {
									infoCategoryIDChanged(value);
								}}
								value={infoCategoryID}
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default NewInfoDialog;
