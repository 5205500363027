// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./InfoAccessConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import EditInfoAccessConsoleDialog from "./EditInfoAccessConsoleDialog/EditInfoAccessConsoleDialog";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	role: "Role",
	infoCategories: "Info Categories",
};

const searchDefaultValue = "role";

// OTHER

// REACT COMPONENT
const InfoAccessConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API Results
	const [roles, setRoles] = useState([]);
	const [filteredRoles, setFilteredRoles] = useState([]);
	const [rolesRows, setRolesRows] = useState([]);
	const [rolesColumns, setRolesColumns] = useState([]);

	const [infoCategories, setInfoCategories] = useState([]);
	const [selectedInfoCategories, setSelectedInfoCategories] = useState([]);

	// Dialog
	const [recordToEdit, setRecordToEdit] = useState({});
	const [editInfoAccessDialogOpen, setEditInfoAccessDialogOpen] =
		useState(false);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// USE EFFECT
	useEffect(() => {
		fetchRoles();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredRoles]);

	// INPUT HANDLERS
	const editRecordClicked = async (record) => {
		await fetchSelectedInfoCategories(record.id);

		setRecordToEdit(record);
		setEditInfoAccessDialogOpen(true);
	};

	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchRoles = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/info/allInfoCategoryRoles"
			);

			setRoles(res.data);
			setFilteredRoles(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));

			await fetchInfoCategories();
		} catch (error) {
			console.log("Error in fetchInfoCategories: ", error);
		}

		setIsLoading(false);
	};

	const fetchInfoCategories = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/info/allInfoCategories"
			);
			setInfoCategories(res.data);
		} catch (error) {
			console.log("Error in fetchInfoCategories: ", error);
		}
	};

	const fetchSelectedInfoCategories = async (roleID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/info/infoCategories/byRole",
				{
					roleID: roleID,
				}
			);

			setSelectedInfoCategories(res.data);
		} catch (error) {
			console.log("Error in fetchSelectedInfoCategories: ", error);
		}
	};

	// HELPER FUNCTIONS
	const filterRoles = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredRoleRecords = roles.filter((roleObj) => {
			if (searchByParameter === "role") {
				return (roleObj.role_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "infoCategories") {
				return (roleObj.info_categories ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredRoles(filteredRoleRecords);
		setNumberOfPages(
			Math.ceil(filteredRoleRecords.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createRowData = () => {
		setRolesRows(
			filteredRoles.map((role) => [
				{ text: role.role_name },
				{
					text: (role.split_info_categories ?? "")
						.split("_SPLIT_")
						.map((category) => <div>{category}</div>),
				},
				{ icon: "edit", onClick: () => editRecordClicked(role) },
			])
		);
	};

	const createColumnData = () => {
		setRolesColumns([
			{ text: "Role Name" },
			{ text: "Info Categories" },
			{}, // Edit Button
		]);
	};

	// RENDER
	return (
		<div className="InfoAccessConsole">
			<div className="infoAccessConsoleHeader">
				<div className="infoAccessConsoleHeaderTitle">
					Info Access Console
				</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					searchByParameterMap={searchByParameterMap}
					filterResults={(searchValue, searchByParameter) =>
						filterRoles(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredRoles.length === 0 && <J2NoResultsFound />}
					{filteredRoles.length > 0 &&
						rolesColumns.length > 0 &&
						rolesRows.length > 0 && (
							<>
								<GenericTable
									columnData={rolesColumns}
									rowData={rolesRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<EditInfoAccessConsoleDialog
				open={editInfoAccessDialogOpen}
				infoCategories={infoCategories}
				selectedInfoCategories={selectedInfoCategories}
				editInfoAccessDialogClosed={() =>
					setEditInfoAccessDialogOpen(false)
				}
				recordToEdit={recordToEdit}
				fetchRoles={fetchRoles}
			/>
		</div>
	);
};

// EXPORT
export default InfoAccessConsole;
