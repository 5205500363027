// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./PermissionsConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

import EditPermissionsDialog from "./EditPermissionsDialog/EditPermissionsDialog";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	employeeName: "Employee Name",
	roles: "Roles",
};

const searchDefaultValue = "employeeName";

// REACT COMPONENT
const PermissionsConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API Results
	const [employees, setEmployees] = useState([]);
	const [filteredEmployees, setFilteredEmployees] = useState([]);

	const [roles, setRoles] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([]);

	const [permissionsRows, setPermissionsRows] = useState([]);
	const [permissionsColumns, setPermissionsColumns] = useState([]);

	// Dialog
	const [recordToEdit, setRecordToEdit] = useState({});
	const [editPermissionsDialogOpen, setEditPermissionsDialogOpen] =
		useState(false);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// USE EFFECT
	useEffect(() => {
		fetchEmployees();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredEmployees]);

	// INPUT HANDLERS
	const editEmployeeClicked = async (record) => {
		await fetchSelectedRoles(record.id);
		setRecordToEdit(record);
		setEditPermissionsDialogOpen(true);
	};

	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchEmployees = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/permissions/employees/all"
			);
			setEmployees(res.data);
			setFilteredEmployees(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));

			await fetchRoles();
		} catch (error) {
			console.log("Error in fetchEmployees: ", error);
		}

		setIsLoading(false);
	};

	const fetchRoles = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/permissions/roles/all"
			);
			setRoles(res.data);
		} catch (error) {
			console.log("Error in fetchRoles: ", error);
		}
	};

	const fetchSelectedRoles = async (employeeID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/permissions/roles/byEmployee",
				{
					employeeID: employeeID,
				}
			);
			setSelectedRoles(res.data);
		} catch (error) {
			console.log("Error in fetchSelectedRoles: ", error);
		}
	};

	// HELPER FUNCTIONS
	const filterEmployees = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredEmployeeRecords = employees.filter((employeeObj) => {
			if (searchByParameter === "employeeName") {
				return (employeeObj.employee_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "roles") {
				return (employeeObj.roles ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredEmployees(filteredEmployeeRecords);
		setNumberOfPages(
			Math.ceil(filteredEmployeeRecords.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createRowData = () => {
		setPermissionsRows(
			filteredEmployees.map((employee) => [
				{ text: employee.employee_name },
				{
					text: (employee.split_roles ?? "")
						.split("_SPLIT_")
						.map((role) => <div>{role}</div>),
					notApplicable: !employee.split_roles,
				},
				{ icon: "edit", onClick: () => editEmployeeClicked(employee) },
			])
		);
	};

	const createColumnData = () => {
		setPermissionsColumns([
			{ text: "Employee Name" },
			{ text: "Roles" },
			{}, // Edit Button
		]);
	};

	// RENDER
	return (
		<div className="PermissionsConsole">
			<div className="permissionsConsoleHeader">
				<div className="permissionsConsoleHeaderTitle">
					Permissions Console
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterEmployees(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredEmployees.length === 0 && <J2NoResultsFound />}
					{filteredEmployees.length > 0 &&
						permissionsColumns.length > 0 &&
						permissionsRows.length > 0 && (
							<>
								<GenericTable
									columnData={permissionsColumns}
									rowData={permissionsRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<EditPermissionsDialog
				open={editPermissionsDialogOpen}
				roles={roles}
				selectedRoles={selectedRoles}
				editPermissionsDialogClosed={() =>
					setEditPermissionsDialogOpen(false)
				}
				recordToEdit={recordToEdit}
				fetchEmployees={fetchEmployees}
			/>
		</div>
	);
};

export default PermissionsConsole;
