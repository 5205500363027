// DEPENDENCIES
import React from "react";

// CSS
import "./TicketComment.css";

// MUI COMPONENTS
import { IconButton } from "@mui/material";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";

// REACT COMPONENT
const TicketComment = (props) => {
	return (
		<div
			className="TicketComment"
			style={
				props.showBottomBorder
					? { borderBottom: "1px solid #e0e0e0" }
					: { borderBottom: "none" }
			}
		>
			<div className="ticketCommentContainer">
				<div className="ticketCommentBody">
					<div className="ticketCommentHeader">
						<div className="ticketCommentPFPContainer">
							<img
								src={props.comment.submitted_by_pfp_url}
								alt={`${props.comment.submitted_by} Headshot`}
							/>
						</div>
						<div className="ticketCommentTitlesContainer">
							<h2 className="ticketCommentSubmitter">
								{props.comment.submitted_by}
							</h2>
							<h3 className="ticketCommentDate">
								{props.comment.submitted_time}
							</h3>
						</div>
					</div>
					<p className="ticketCommentText">{props.comment.comment}</p>
				</div>
				{props.showDeleteButton && (
					<div className="ticketCommentDeleteButtonContainer">
						<IconButton
							onClick={() =>
								props.deleteComment(props.comment.id)
							}
							size="small"
							color="error"
						>
							<DeleteOutlineIcon fontSize="small" />
						</IconButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default TicketComment;
