// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./PhaseConsoleEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall, putAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	IconButton,
	TextField,
} from "@mui/material";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS
import DropboxFileSelector from "../../../Misc/DropboxFileSelector/DropboxFileSelector";

// CONSTANTS

// OTHER

// REACT COMPONENT
const PhaseConsoleEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS

	// STATES
	const [loading, setLoading] = useState(true);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// General inputs
	const [phaseName, setPhaseName] = useState("");
	const [phaseProperty, setPhaseProperty] = useState("");
	const [phaseProject, setPhaseProject] = useState("");
	const [phaseType, setPhaseType] = useState("");
	const [phaseDropBoxFolder, setPhaseDropBoxFolder] = useState("");
	const [companyCamID, setCompanyCamID] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		// populate fields if editing
		if (props.editing) {
			handlePopulateFieldsOnEdit();
		} else {
			setLoading(false);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	// general inputs

	const handlePhasePropertyChange = (event, newValue) => {
		setPhaseProperty(newValue);
	};

	const handlePhaseProjectChange = (event, newValue) => {
		setPhaseProject(newValue);
		setPhaseDropBoxFolder(newValue.dropbox_path);
	};

	const handlePhaseTypeChange = (event, newValue) => {
		setPhaseType(newValue);
	};

	const handlePhaseDropBoxFolderChange = (event) => {
		setPhaseDropBoxFolder(event.target.value);
	};

	const handleCompanyCamIDChange = (event) => {
		setCompanyCamID(event.target.value);
	};

	const handlePhaseNameChange = (event, newValue) => {
		setPhaseName(newValue);
	};

	// API FUNCTIONS

	// handles populating fields when editing
	const handlePopulateFieldsOnEdit = () => {
		setLoading(true);
		setPhaseProperty(
			props.properties.find(
				(property) => property.id === props.recordToEdit.property_id
			)
		);
		setPhaseProject(
			props.projects.find(
				(project) => project.id === props.recordToEdit.project_id
			)
		);
		setPhaseType(
			props.phaseTypes.find(
				(phaseType) =>
					phaseType.name === props.recordToEdit.phase_type_name
			)
		);
		setPhaseName(
			props.phaseNames.find(
				(phaseName) => phaseName.name === props.recordToEdit.phase_name
			)
		);
		setPhaseDropBoxFolder(props.recordToEdit.dropbox_path);
		setCompanyCamID(props.recordToEdit.company_cam_id);
		setLoading(false);
	};

	const handleAddRecord = async () => {
		setLoading(true);

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/jobs/phase/create",
				{
					name: phaseName.name,
					dropboxPath: phaseDropBoxFolder,
					bqeID: phaseProject.bqe_id,
					bqeCode: phaseProject.bqe_code,
					hubspotID: phaseProject.hubspot_id,
					companyCamID: companyCamID,
					propertyID: phaseProperty.id,
					projectID: phaseProject.id,
					ownerID: phaseProject.owner_id,
					phaseTypeID: phaseType.id,
					modifiedBy: employeeID,
				}
			);
			setAlertMessage("Record added successfully");
			setAlertOpen(true);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error adding record");
			setAlertOpen(true);
		}

		setLoading(false);
	};

	const handleUpdateRecord = async () => {
		setLoading(true);
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/jobs/phase/update",
				{
					id: props.recordToEdit.id,
					name: phaseName.name,
					dropboxPath: phaseDropBoxFolder,
					bqeID: phaseProject.bqe_id,
					bqeCode: phaseProject.bqe_code,
					hubspotID: phaseProject.hubspot_id,
					companyCamID: companyCamID,
					propertyID: phaseProperty.id,
					projectID: phaseProject.id,
					ownerID: phaseProject.owner_id,
					phaseTypeID: phaseType.id,
					modifiedBy: employeeID,
				}
			);
			setAlertMessage("Record updated successfully");
			setAlertOpen(true);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error updating record");
			setAlertOpen(true);
		}
		setLoading(false);
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		// if editing, update record, else add record
		if (props.editing) {
			await handleUpdateRecord();
		} else {
			await handleAddRecord();
		}
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		props.onClose();
	};

	const handleCancelClick = () => {
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		props.onCloseNoRefresh();
	};

	const resetInputFields = () => {
		setPhaseName("");
		setPhaseProperty("");
		setPhaseProject("");
		setPhaseType("");
		setPhaseDropBoxFolder("");
		setCompanyCamID("");
		setLoading(false);
		setAlertOpen(false);
		setAlertMessage("");
	};

	const validateInputFields = () => {
		if (
			!phaseProperty ||
			!phaseProject ||
			!phaseType ||
			!phaseDropBoxFolder ||
			!companyCamID ||
			!phaseName
		) {
			setAlertMessage("Please fill out all required fields");
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	// RENDER
	return (
		<Dialog
			id="PhaseConsoleEditAddDialog"
			open={props.open}
			maxWidth="xl"
			fullWidth
			onClose={handleCancelClick}
		>
			<DialogTitle id="PhaseConsoleEditAddDialogTitle">
				{/* edit / add */}
				{props.editing ? "Edit Phase Record" : "Add Phase Record"}
			</DialogTitle>
			{loading && (
				<div className="phaseConsoleLoadingSpinner">
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<DialogContent id="PhaseConsoleEditAddDialogContent">
					{alertOpen && (
						<Alert
							severity="warning"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setAlertOpen(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					)}

					<div className="dialogSubtitle">Tools Property Record</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									id="property"
									options={props.properties}
									getOptionLabel={(option) =>
										option ? option.name : ""
									}
									value={phaseProperty ? phaseProperty : ""}
									onChange={handlePhasePropertyChange}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Property"
											variant="outlined"
										/>
									)}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
								/>
							</div>
						</div>
					</FormControl>

					<Divider />

					<div className="dialogSubtitle">Tools Project Record</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									id="project"
									options={props.projects.filter(
										(project) =>
											project.property_id ===
											phaseProperty?.id
									)}
									getOptionLabel={(option) =>
										option ? option.name : ""
									}
									value={phaseProject ? phaseProject : ""}
									onChange={handlePhaseProjectChange}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Project"
											variant="outlined"
										/>
									)}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									disabled={!phaseProperty}
								/>
							</div>
						</div>
						<FormHelperText>
							{!phaseProperty && "Select a property"}
							{props.projects.filter(
								(project) =>
									project.property_id === phaseProperty?.id
							).length === 0 &&
								phaseProperty &&
								"No projects found"}
						</FormHelperText>
					</FormControl>

					<Divider />

					<div className="dialogSubtitle">Phase</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									id="phaseType"
									options={props.phaseTypes}
									getOptionLabel={(option) =>
										option ? option.name : ""
									}
									value={phaseType}
									onChange={handlePhaseTypeChange}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Phase Type"
											variant="outlined"
										/>
									)}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
								/>
							</div>
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									id="phaseName"
									options={props.phaseNames}
									getOptionLabel={(option) =>
										option ? option.name : ""
									}
									value={phaseName}
									onChange={handlePhaseNameChange}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Phase Name"
											variant="outlined"
										/>
									)}
								/>
							</div>
						</div>
					</FormControl>

					<Divider />

					<div className="dialogSubtitle">CompanyCam ID</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<TextField
									sx={{ flexGrow: 1 }}
									label="Company Cam ID"
									variant="outlined"
									value={companyCamID}
									onChange={handleCompanyCamIDChange}
								/>
							</div>
						</div>
					</FormControl>

					<Divider />

					<div className="dialogSubtitle">Dropbox Folder</div>

					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<DropboxFileSelector
									filePath={phaseDropBoxFolder}
									setFilePath={setPhaseDropBoxFolder}
								/>
							</div>
						</div>
					</FormControl>

					<div className="editPhaseConsoleButtons">
						<Button
							variant="outlined"
							color="error"
							onClick={handleCancelClick}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitClick}
							disabled={loading}
						>
							Submit
						</Button>
					</div>
				</DialogContent>
			)}
		</Dialog>
	);
};

// EXPORT
export default PhaseConsoleEditAddDialog;
