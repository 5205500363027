// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./Info.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../Misc/J2NoResultsFound/J2NoResultsFound";
import SearchBar from "../Misc/SearchBar/SearchBar";

import InfoCard from "./InfoCard/InfoCard";

// CONSTANTS
const searchByParameterMap = {
	title: "Title",
	category: "Category",
};

const searchDefaultValue = "title";

// OTHER

// REACT COMPONENT
const Info = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	// CONTEXT
	const employeeID = useContext(UserContext);
	const [categories, setCategories] = useState([]);
	const [expandedCategories, setExpandedCategories] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const [info, setInfo] = useState([]); // [ { id, title, description, data, category_id } ]
	const [filteredInfo, setFilteredInfo] = useState([]); // [ { id, title, description, data, category_id }

	// USE EFFECT
	useEffect(() => {
		fetchInfo();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Info",
			employeeID: employeeID,
			notes: "Accessed the Info page",
			modified_by: employeeID,
		});
	}, []);

	// INPUT HANDLERS

	const collapseAllMenus = () => {
		setExpandedCategories([]);
	};

	const expandAllMenus = () => {
		setExpandedCategories([...categories]);
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS

	const filterInfo = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredInfoRecords = info.filter((infoObj) => {
			if (searchByParameter === "title") {
				return (infoObj.name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "category") {
				return (infoObj.info_category_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredInfo(filteredInfoRecords);

		setIsLoading(false);
	};

	const fetchInfo = async () => {
		try {
			setIsLoading(true);

			let dataRes = await getAPICall(
				instance,
				accounts[0],
				`/api/info/byUser?userID=${employeeID}`
			);

			setInfo(dataRes.data);
			setFilteredInfo(dataRes.data);

			setIsLoading(false);
		} catch (error) {
			console.log("Error in fetchInfo: ", error);
		}
	};

	// RENDER
	return (
		<div className="Info">
			<div className="infoHeader">
				<div className="infoHeaderTitle">Info</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterInfo(searchValue, searchByParameter)
					}
					searchByParameterMap={searchByParameterMap}
				/>
			</div>
			{isLoading && (
				<div className="loadingSpinnerContainer">
					<CircularProgress />
				</div>
			)}
			{!isLoading && (
				<div className="infoContainer">
					{/* <div className="infoPreviewContainer"> */}
					{filteredInfo.length === 0 && <J2NoResultsFound />}
					{filteredInfo.map((info, index) => (
						<InfoCard key={index} info={info} />
					))}
					{/* </div> */}
				</div>
			)}
		</div>
	);
};

// EXPORT
export default Info;
