// DEPENDENCIES
import React from "react";

// CSS
import "./J2NoResultsFound.css";

// FUNCTIONAL COMPONENT
const J2NoResultsFound = () => {
	return <div className="J2NoResultsFound">Nothing to see here!</div>;
};

export default J2NoResultsFound;
