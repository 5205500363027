// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./ServiceHistory.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import ServiceHistoryEditAddDialog from "./ServiceHistoryEditAddDialog/ServiceHistoryEditAddDialog";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	device_name: "Device Name",
	ticket_number: "Ticket Number",
	incident_type: "Incident Type",
	device_category: "Device Category",
	notes: "Notes",
};

const searchByDefaultValue = "device_name";

// OTHER

// REACT COMPONENT
const ServiceHistory = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [serviceHistories, setServiceHistories] = useState([]);
	const [filteredServiceHistories, setFilteredServiceHistories] = useState(
		[]
	);

	const [serviceHistoriesRows, setServiceHistoriesRows] = useState([]);
	const [serviceHistoriesColumns, setServiceHistoriesColumns] = useState([]);

	const [incidentTypes, setIncidentTypes] = useState([]);
	const [devices, setDevices] = useState([]);
	// eventually more device types here

	const [recordToEdit, setRecordToEdit] = useState({});
	const [loading, setLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);

	// pagination
	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredServiceHistories]);

	// INPUT HANDLERS
	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
		handleAPICalls();
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await fetchServiceHistories();
		await fetchIncidentTypes();
		await fetchDevices();
		setLoading(false);
	};

	const fetchServiceHistories = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/serviceHistories/all"
			);
			setServiceHistories(res.data);
			setFilteredServiceHistories(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
			return res.data;
		} catch (error) {
			console.error(error);
		}
	};

	const fetchIncidentTypes = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/incidentTypes/all"
			);
			setIncidentTypes(res.data);
			return res.data;
		} catch (error) {
			console.error(error);
		}
	};

	const fetchDevices = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/all"
			);
			setDevices(res.data);
			return res.data;
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS

	// pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// search
	const filterServiceHistoryRecords = (searchValue, searchByParameter) => {
		let filteredRecords = serviceHistories.filter((record) => {
			if (searchByParameter === "device_name") {
				return record.j2_device_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "ticket_number") {
				return record.ticket_number
					.toString()
					.includes(searchValue.toString());
			} else if (searchByParameter === "incident_type") {
				return record.incident_type_display_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "device_category") {
				return record.category_display_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "notes") {
				return record.notes
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredServiceHistories(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const createColumnData = () => {
		setServiceHistoriesColumns([
			{ text: "Created" },
			{ text: "Ticket Number" },
			{ text: "Incident Type" },
			{ text: "Device Name" },
			{ text: "Device Category" },
			{ text: "Notes" },
			{}, // edit icon
		]);
	};

	const createRowData = () => {
		setServiceHistoriesRows(
			filteredServiceHistories.map((record) => [
				{ text: convertToPST(record.created) },
				{
					text: record.ticket_number,
					notApplicable: !record.ticket_number,
				},
				{ text: record.incident_type_display_name },
				{ text: record.j2_device_name },
				{ text: record.category_display_name },
				{ text: record.notes },
				{ icon: "edit", onClick: () => handleEditClick(record) },
			])
		);
	};

	// RENDER
	return (
		<div className="ServiceHistories">
			<div className="ServiceHistoriesHeader">
				<div className="ServiceHistoriesHeaderTitle">
					Service History
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddClick}
						disabled={loading}
					>
						New Service History Record
					</Button>
				</div>
				<SearchBar
					searchDefaultValue={searchByDefaultValue}
					searchByParameterMap={searchByParameterMap}
					filterResults={(searchValue, searchByParameter) =>
						filterServiceHistoryRecords(
							searchValue,
							searchByParameter
						)
					}
				/>
			</div>

			{loading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!loading && (
				<div className="tableContainerDiv">
					{filteredServiceHistories.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredServiceHistories.length > 0 &&
						serviceHistoriesRows.length > 0 &&
						serviceHistoriesColumns.length > 0 && (
							<>
								<GenericTable
									columnData={serviceHistoriesColumns}
									rowData={serviceHistoriesRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}

			{/* {loading ? (
				<div className="loadingDiv">
					<CircularProgress />
				</div>
			) : (
				<>
					{filteredServiceHistories.length === 0 &&
					serviceHistoriesRows.length > 0 &&
					serviceHistoriesColumns.length > 0 ? (
						<J2NoResultsFound />
					) : (
						<div className="tableContainerDiv">
							<GenericTable
								columnData={serviceHistoriesColumns}
								rowData={serviceHistoriesRows.slice(
									paginationStartIndex,
									paginationEndIndex
								)}
							/>
							<div className="paginationDiv">
								<Pagination
									count={numberOfPages}
									color="primary"
									onChange={handlePageChange}
								/>
							</div>
						</div>
					)}
				</>
			)} */}
			<ServiceHistoryEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				recordToEdit={recordToEdit}
				devices={devices}
				fullServiceHistories={serviceHistories}
				editing={editing}
				incidentTypes={incidentTypes}
			/>
		</div>
	);
};

// EXPORT
export default ServiceHistory;
