// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DeviceLeases.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

import DeviceLeasesEditAddDialog from "./DeviceLeasesEditAddDialog/DeviceLeasesEditAddDialog";

import DeviceLeasesDevices from "./DeviceLeasesDevices/DeviceLeasesDevices";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";
const searchByParameterMap = {
	manufacturer: "Manufacturer",
	lease_number: "Lease Number",
};

const searchDefaultValue = "manufacturer";

// OTHER

// REACT COMPONENT
const DeviceLeases = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [leases, setLeases] = useState([]);
	const [filteredLeases, setFilteredLeases] = useState([]);

	const [leaseRows, setLeaseRows] = useState([]);
	const [leaseColumns, setLeaseColumns] = useState([]);

	const [leasesToDevices, setLeasesToDevices] = useState([]);
	// eventually more device types here
	const [devices, setDevices] = useState([]);
	const [deviceCategories, setDeviceCategories] = useState([]);

	const [recordToEdit, setRecordToEdit] = useState({});
	const [loading, setLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);
	const [devicesOpen, setDevicesOpen] = useState(false);
	const [recordToShowDevices, setRecordToShowDevices] = useState({});

	// pagination
	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredLeases, leasesToDevices]);

	// INPUT HANDLERS

	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
		handleAPICalls();
	};

	const handleDevicesClick = (record) => {
		setRecordToShowDevices(record);
		setDevicesOpen(true);
	};

	const handleDevicesClose = () => {
		setDevicesOpen(false);
		setRecordToShowDevices({});
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await fetchLeases();
		await fetchDevices();
		await fetchDeviceCategories();
		await fetchLeasesToDevices();
		setLoading(false);
	};

	const fetchLeases = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/leases/all"
			);
			setLeases(res.data);
			setFilteredLeases(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
			return res.data;
		} catch (error) {
			console.error(error);
		}
	};

	const fetchDevices = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/all"
			);
			setDevices(res.data);
			return res.data;
		} catch (error) {
			console.error(error);
		}
	};

	const fetchDeviceCategories = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/deviceCategories/all"
			);
			setDeviceCategories(res.data);
			return res.data;
		} catch (error) {
			console.error(error);
		}
	};

	const fetchLeasesToDevices = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/devicesToLeases/all"
			);
			setLeasesToDevices(res.data);
			return res.data;
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS

	// pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// search
	const filterLeaseRecords = (searchValue, searchByParameter) => {
		let filteredRecords = leases.filter((record) => {
			if (searchByParameter === "manufacturer") {
				return record.manufacturer
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "lease_number") {
				return record.lease_number
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredLeases(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const createRowData = () => {
		setLeaseRows(
			filteredLeases.map((lease) => [
				{ text: lease.lease_number },
				{ text: convertToPST(lease.origin_date) },
				{
					text:
						lease.lease_length +
						" " +
						(lease.lease_length > 1 ? "Months" : "Month"),
				},
				{ text: convertToPST(lease.term_date) },
				{
					text: leasesToDevices.filter(
						(devices) => devices.lease_id === lease.id
					).length,
					link: true,
					onClick: () => handleDevicesClick(lease),
				},
				{ icon: "edit", onClick: () => handleEditClick(lease) },
			])
		);
	};

	const createColumnData = () => {
		setLeaseColumns([
			{ text: "Lease Number" },
			{ text: "Lease Start" },
			{ text: "Lease Length" },
			{ text: "Lease End" },
			{ text: "Devices" },
			{}, // edit button
		]);
	};

	// RENDER
	return (
		<div className="DeviceLeases">
			<div className="DeviceLeasesHeader">
				<div className="DeviceLeasesHeaderTitle">
					Device Leases
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddClick}
						disabled={loading}
					>
						New Lease
					</Button>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterLeaseRecords(searchValue, searchByParameter)
					}
				/>
			</div>
			{loading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!loading && (
				<div className="tableContainerDiv">
					{filteredLeases.length === 0 && <J2NoResultsFound />}
					{filteredLeases.length > 0 &&
						leaseColumns.length > 0 &&
						leaseRows.length > 0 && (
							<>
								<GenericTable
									columnData={leaseColumns}
									rowData={leaseRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<DeviceLeasesEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				recordToEdit={recordToEdit}
				deviceCategories={deviceCategories}
				devices={devices}
				leases={leases}
				editing={editing}
			/>
			<DeviceLeasesDevices
				open={devicesOpen}
				onClose={handleDevicesClose}
				leasesToDevices={leasesToDevices}
				deviceCategories={deviceCategories}
				devices={devices}
				recordToShowDevices={recordToShowDevices}
			/>
		</div>
	);
};

// EXPORT
export default DeviceLeases;
