// DEPENDENCIES
import React from 'react';


// CSS
import './ClientInformationHelpDialog.css';


// MUI COMPONENTS
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Button from '@mui/material/Button';


// FUNCTIONAL COMPONENT
const ClientInformationHelpDialog = (props) => {
    // INPUT HANDLERS
    const formClosed = () => {
        props.onClose();
    }


    // RENDER
    return (
        <Dialog
            id='clientInformationHelpDialog'
            open={props.open}
            fullWidth
            onClose={formClosed}
        >
            <DialogTitle id='clientInformationHelpDialogTitle'>Client Information</DialogTitle>
            <DialogContent
                id="clientInformationHelpDialogContent"
            >
                <div className='helpParagraph'>
                    <h2>Searching Existing Contacts</h2>
                    <p>
                        To search for an existing contact, enter the contact's last name in the text field. If you prefer to search by their email address,
                        select "Email" in the Search By dropdown.
                    </p>
                    <p>
                        Once you have entered three characters, the system will begin searching for contacts in 
                        HubSpot that match the text you have entered. If you see the contact you are looking for, click on the contact to populate the fields 
                        with the contact's information. If you are unable to find the contact you are looking for, you can create a new contact by clicking the
                        "Create New" button. 
                    </p>
                    <p>
                        Please note: If the contact in HubSpot doesn't have the required information (i.e. first name is empty), you will need to go to the 
                        HubSpot contact record and add the missing information. It is not possible to produce a proposal without all the contact fields filled out.
                    </p>
                    <h2 id='createNewContactHeader'>Creating a New Contact</h2>
                    <p>
                        To create a new contact, click the "Create New" button. This will open a dropdown with the fields you need to fill out to create a new
                        contact. Fill out the fields with the contact's information. 
                    </p>
                    <p>
                        Please ensure the spelling, capitalization, and information is accurate. Once you submit the RFP, a contact record will be created in HubSpot 
                        and associated with the corresponding deal.
                    </p>
                </div>
                <div className='dialogButtons'>
                    <Button
                        id='closeClientInformationHelpDialogButton'
                        onClick={formClosed}
                        color='primary'
                        variant='contained'
                    >
                        Close
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}


export default ClientInformationHelpDialog;