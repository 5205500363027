// CSS
import "./TicketStatusIcon.css";

// REACT COMPONENT
const TicketStatusIcon = (props) => {
	return (
		<div
			className="TicketStatusIcon"
			style={{ backgroundColor: props.hexColor }}
		>
			{props.statusTitle}
		</div>
	);
};

export default TicketStatusIcon;
