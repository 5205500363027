// DEPENDENCIES
import React, { useContext, useState } from "react";

// CSS
import "./NewCategoryDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../../config/apiCalls";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// REACT COMPONENT
const NewCategoryDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// FORM STATES
	const [name, setName] = useState("");

	// FEEDBACK STATES
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS
	const nameChanged = (event) => {
		setName(event.target.value);
	};

	const submitButtonClicked = async () => {
		let inputsValid = validateInputFields();
		if (!inputsValid) return;

		setIsLoading(true);
		await postAPICall(
			instance,
			accounts[0],
			"/api/tipsAndTricks/categories/add",
			{
				categoryName: name.toLowerCase(),
				modifiedBy: employeeID,
			}
		);
		setIsLoading(false);

		formClosed();
		props.fetchCategories();
	};

	const alertOpened = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const alertClosed = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	// HELPER FUNCTIONS
	const formClosed = () => {
		props.newCategoryDialogClosed();
		clearInputFields();
	};

	const validateInputFields = () => {
		if (name.trim() === "") {
			alertOpened("Please enter a name.");
			return false;
		}

		for (let i = 0; i < props.categories.length; i++) {
			if (
				(props.categories[i].name ?? "").toLowerCase() ===
				name.toLowerCase()
			) {
				alertOpened("Category already exists.");
				return false;
			}
		}

		return true;
	};

	const clearInputFields = () => {
		setName("");
		alertClosed();
	};

	// RENDER
	return (
		<Dialog
			id="newCategoryDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="newCategoryDialogTitle">New Category</DialogTitle>
			<DialogContent id="newCategoryDialogContent">
				<div className="newCategoryDialogRow">
					<Collapse
						sx={{ flex: 1 }}
						id="newCategoryCollapse"
						in={alertOpen}
					>
						<Alert
							id="newCategoryAlert"
							severity="error"
							action={
								<IconButton
									id="newCategoryCloseIconButton"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={alertClosed}
								>
									<CloseIcon
										id="newTipCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					</Collapse>
				</div>
				<div className="newCategoryDialogRow">
					<TextField
						sx={{ flex: 1 }}
						id="newCategoryName"
						label="Name"
						value={name}
						onChange={nameChanged}
					/>
				</div>
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<div className="submitButtonContainer">
						<Button
							onClick={submitButtonClicked}
							variant="contained"
							disabled={isLoading}
						>
							Submit
						</Button>
						{isLoading && (
							<CircularProgress
								size={24}
								sx={{
									color: "primary",
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default NewCategoryDialog;
