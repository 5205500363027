import React from "react";

import "./J2MultilineTextField.css";

const J2MultilineTextField = (props) => {
	return (
		<div className="J2MultilineTextField">
			<textarea
				id="j2MultilineTextField"
				rows={props.rows}
				placeholder={props.placeholder}
				value={props.value}
				onChange={(event) => props.onChange(event)}
			/>
		</div>
	);
};

export default J2MultilineTextField;
