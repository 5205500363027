// DEPENDENCIES
import React, { useState, useContext } from "react";

// CSS
import "./CommentsCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import { Button, TextField, IconButton, Collapse } from "@mui/material";
import {
	ChatBubble as ChatBubbleIcon,
	Add as AddIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import SpecComment from "./SpecComment/SpecComment";

// CONSTANTS
import { defaultOrange } from "../../../../../constants";

// FUNCTIONAL COMPONENT
const CommentsCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [newCommentOpen, setNewCommentOpen] = useState(false);

	const [newCommentText, setNewCommentText] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS
	// Form Handlers
	const newCommentChanged = (event) => {
		setNewCommentText(event.target.value);
	};

	// Buttons
	const submitNewCommentClicked = async () => {
		await createNewComment();

		props.fetchComments();

		newCommentClosed();
	};

	const cancelNewCommentClicked = () => {
		newCommentClosed();
	};

	// API FUNCTIONS
	const createNewComment = async () => {
		if (newCommentText === "") {
			setNewCommentOpen(false);
			return;
		}

		await props.createNewComment(newCommentText);
	};

	// HELPER FUNCTIONS
	const newCommentClosed = () => {
		setNewCommentOpen(false);
		setNewCommentText("");
	};

	// RENDER
	return (
		<div className="CommentsCard">
			<div
				className="commentsCardHeader"
				onClick={props.commentsDropdownClicked}
			>
				<IconButton sx={{ color: defaultOrange }}>
					<ChatBubbleIcon sx={{ fontSize: 32 }} />
				</IconButton>
				<h2 className="commentsCardHeaderTitle">Comments</h2>
			</div>
			<Collapse
				in={props.commentsOpen}
				id="commentsDropdownCollapse"
				timeout="auto"
				unmountOnExit
			>
				<div className="commentsDropdownContent">
					<div className="newCommentButtonContainer">
						<Button
							variant="outlined"
							color="primary"
							startIcon={<AddIcon />}
							onClick={() => setNewCommentOpen(true)}
						>
							New
						</Button>
					</div>
					<NewCommentCollapse
						newCommentOpen={newCommentOpen}
						newCommentText={newCommentText}
						newCommentChanged={newCommentChanged}
						submitNewCommentClicked={submitNewCommentClicked}
						cancelNewCommentClicked={cancelNewCommentClicked}
					/>
					<div className="commentsContainer">
						{props.comments.length === 0 && (
							<div className="noCommentsText">
								<i>No comments found</i>
							</div>
						)}
						{props.comments.map((comment, index) => (
							<SpecComment
								key={index}
								comment={comment}
								showBottomBorder={
									index !== props.comments.length - 1
								}
								canDeleteComment={props.isAdmin}
								deleteComment={() =>
									props.deleteComment(
										comment.id,
										comment.comment
									)
								}
							/>
						))}
					</div>
				</div>
			</Collapse>
		</div>
	);
};

const NewCommentCollapse = (props) => {
	return (
		<Collapse
			in={props.newCommentOpen}
			id="newCommentDropdownCollapse"
			timeout="auto"
			unmountOnExit
		>
			<div className="newCommentInputContainer">
				<div className="newCommentRow">
					<TextField
						sx={{ flex: 1 }}
						id="newCommentTextField"
						label="Comment"
						value={props.newCommentText}
						onChange={props.newCommentChanged}
					/>
				</div>
				<div className="newCommentRow">
					<Button
						variant="outlined"
						color="error"
						onClick={props.cancelNewCommentClicked}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={props.submitNewCommentClicked}
					>
						Submit
					</Button>
				</div>
			</div>
		</Collapse>
	);
};

export default CommentsCard;
