// REACT
import React from "react";

// CSS
import "./J2ErrorDialog.css";

// MUI
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

// COMPONENT
const J2ErrorDialog = (props) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Error</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{props.errorMessage}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

export default J2ErrorDialog;
