// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./DocumentGenerationOptionsDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// // API IMPORTS
import {
	putAPICall,
	postAPICall,
	postFormDataAPICall,
} from "../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LinearProgress, Typography } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

const titleMap = {
	buildingEnvelopeRepairs: "Building Envelope Repairs",
	roofRepairs: "Roof Repairs",
	custom: "Custom",
};
const subtitleMap = {
	bidSet: "Bid Set",
	constructionSet: "Construction Set",
	permitSet: "Permit Set",
	preliminaryReviewSet: "Preliminary Review Set",
	custom: "Custom",
};

// OTHER
// To convert the date to UTC
// var utc = require("dayjs/plugin/utc");
// dayjs.extend(utc);

// REACT COMPONENT
const DocumentGenerationOptionsDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Form Data
	const [publishDate, setPublishDate] = useState(null);

	const [reportTitleSelect, setReportTitleSelect] = useState("");
	const [reportTitleText, setReportTitleText] = useState("");

	const [reportSubtitleSelect, setReportSubtitleSelect] = useState("");
	const [reportSubtitleText, setReportSubtitleText] = useState("");

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [progressMessage, setProgressMessage] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS
	// Form Data Handlers
	const publishDateChanged = (newValue) => {
		setPublishDate(newValue || dayjs());
	};

	const reportTitleSelectChanged = (event) => {
		setReportTitleSelect(event.target.value);
		setReportSubtitleText("");
	};

	const reportTitleTextChanged = (event) => {
		setReportTitleText(event.target.value);
	};

	const reportSubtitleSelectChanged = (event) => {
		setReportSubtitleSelect(event.target.value);
		setReportSubtitleText("");
	};

	const reportSubtitleTextChanged = (event) => {
		setReportSubtitleText(event.target.value);
	};

	// Button Handlers
	const submitButtonClicked = async () => {
		if (!validateInputFields()) {
			return;
		}
		setProgress(0); // this is the percentage
		setProgressMessage("Generating...");

		setIsLoading(true);
		let pdfIndividualSpecUrls = [];

		try {
			// Determine the correct title and subtitle based on the selected or custom values
			const finalTitle =
				reportTitleSelect === "custom"
					? reportTitleText
					: titleMap[reportTitleSelect];
			const finalSubtitle =
				reportSubtitleSelect === "custom"
					? reportSubtitleText
					: subtitleMap[reportSubtitleSelect];

			if (props.generateCoverPage === true) {
				// Create Cover Page:
				setProgressMessage("Creating Cover Page...");
				setProgress(20);

				const coverPageUrl = await createCoverPage(
					finalTitle,
					finalSubtitle
				);
				pdfIndividualSpecUrls.push(coverPageUrl);
			}

			if (props.generateTableOfContents === true) {
				// Create Table Of Contents:
				setProgressMessage("Creating Table Of Contents...");
				setProgress(40);
				const tableOfContentsUrl = await createTableOfContents(
					finalTitle,
					finalSubtitle
				);
				pdfIndividualSpecUrls.push(tableOfContentsUrl);
			}

			// Create Each Spec PDF:
			setProgressMessage(
				props.selectedSpecifications.length > 1
					? "Creating Specs..."
					: "Creating Spec..."
			);
			setProgress(60);

			// generate a pdf from each selected spec individually, store the urls in an array
			const specUrls = await Promise.all(
				props.selectedSpecifications.map(async (spec) => {
					const tempSelectedSpecsRes = await postAPICall(
						instance,
						accounts[0],
						"/api/convert/html-to-pdf",
						{
							htmlContent: spec.content,
							property: props.job.property_name,
							address:
								props.propertyDetails.city +
								", " +
								props.propertyDetails.state,
							date: publishDate
								.startOf("day")
								.format("MM/DD/YYYY"),
							subtitle: finalSubtitle,
							title: finalTitle,
							spec_title: "SECTION" + " " + spec.division,
							spec_subtitle: spec.title,
							include_footer: true,
							include_header: true,
						}
					);
					return tempSelectedSpecsRes.data.Files[0].Url;
				})
			);

			pdfIndividualSpecUrls = [...pdfIndividualSpecUrls, ...specUrls];

			setProgressMessage("Merging...");
			setProgress(80);

			// Merge PDFS:
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/convert/merge-pdf",
				{
					files: pdfIndividualSpecUrls,
				}
			);

			// Automatically open the generated PDF URL in a new tab
			if (res.data.mergedPdfUrl) {
				window.open(res.data.mergedPdfUrl, "_blank");
			}

			if (props.saveToS3 === true) {
				// create form data to send
				const formData = new FormData();
				formData.append("title", finalTitle);
				formData.append("subtitle", finalSubtitle);
				formData.append("phaseID", props.jobID);
				formData.append("modifiedBy", employeeID);

				const fileBlob = await fetch(res.data.mergedPdfUrl).then(
					(r) => {
						return r.blob();
					}
				);
				// title + date in this format: "Bid_Specs_24_08_2022_1"
				let fileName =
					finalTitle.replace(/\s+/g, "_") +
					"_" +
					publishDate.startOf("day").format("MM_DD_YYYY") +
					"_" +
					props.jobID +
					".pdf";
				formData.append("file", fileBlob, fileName);

				// save the generated pdf to the database
				const saveRes = await postFormDataAPICall(
					instance,
					accounts[0],
					"/api/specifications/addSpecBook",
					formData
				);
			}

			if (!props.singleSpec) {
				setProgressMessage("Locking Job...");
				setProgress(90);
				// Lock the job
				await putAPICall(
					instance,
					accounts[0],
					"/api/jobs/design/toggleLock",
					{
						id: props.jobID,
						isLocked: 1,
						employeeID: employeeID,
					}
				);

				// Create Local Copies
				await props.createLocalCopies();
			}
		} catch (error) {
			console.log("Error in submitButtonClicked: ", error);
		}

		setProgressMessage("Cleaning Up...");
		setProgress(100);
		props.fetchDesignJobRecord();
		setIsLoading(false);

		formClosed();
	};

	// Create Cover Page:
	const createCoverPage = async (finalTitle, finalSubtitle) => {
		var address = props.propertyDetails.address_line1;

		if (props.propertyDetails.address_line2) {
			address += " " + props.propertyDetails.address_line2;
		}

		let coverSheetHTML = `<div class="documentContainer">`;
		coverSheetHTML += `<div class="headerContainer"><div class="headerContainer__titleContainer"><div class="headerContainer__title">${props.job.property_name}</div><div class="headerContainer__subtitle">${finalSubtitle}</div></div></div>`;
		coverSheetHTML += `<div class="photoContainer"></div><div class="logoContainer"></div>`;
		coverSheetHTML += `<div class="documentInfoContainer"><div class="documentInfoContainer__titleContainer"><div class="documentInfoContainer__titleContainer__title">${finalTitle}</div><div class="documentInfoContainer__titleContainer__subtitle">${
			props.job.property_name
		}</div><div class="documentInfoContainer__titleContainer__subtitle">${address}, ${
			props.propertyDetails.city
		}, ${props.propertyDetails.state} ${
			props.propertyDetails.zip
		}</div><div class="documentInfoContainer__titleContainer__subtitle">${publishDate
			.startOf("day")
			.format("MM/DD/YYYY")}</div></div></div>`;
		coverSheetHTML += `<div class="footerContainer"> <div class="footerContainer_top"></div> <div class="footerContainer_middle"> <div class="footerContainer_middle__row"> <div class="footerContainer_middle__row__column"> <div class="footerContainer_middle__row__column__state">WA</div> <div class="footerContainer_middle__row__column__address">8425 44th Ave West<br> Mukilteo, WA 98275</div> </div> <div class="footerContainer_middle__row__column"> <div class="footerContainer_middle__row__column__state">OR</div> <div class="footerContainer_middle__row__column__address">4504 S Corbett Ave Ste. 100<br>Portland, OR 97239</div> </div> <div class="footerContainer_middle__row__column"> <div class="footerContainer_middle__row__column__state">UT</div> <div class="footerContainer_middle__row__column__address">740 E 3900 South Ste. 208<br>Salt Lake City, UT 84107</div> </div> </div> <div class="footerContainer_middle__row"> <div class="footerContainer_middle__row__column"> <div class="footerContainer_middle__row__column__icon" id="email"></div> <div class="footerContainer_middle__row__column__detail">HELLO@J2CONSULTANTS.COM</div> </div> <div class="footerContainer_middle__row__column"> <div class="footerContainer_middle__row__column__icon" id="phone"></div> <div class="footerContainer_middle__row__column__detail">(425) 774-5600</div> </div> <div class="footerContainer_middle__row__column"> <div class="footerContainer_middle__row__column__icon" id="web"></div> <div class="footerContainer_middle__row__column__detail">WWW.J2CONSULTANTS.COM</div> </div> </div> </div> <div class="footerContainer_bottom"> <div class="footerContainer_bottom__textContainer"> <p>J2 Building Consultants</p><p class="pVertBar">|</p><p>The Building Doctors</p> </div> </div> </div> </div>`;

		try {
			const coverPageRes = await postAPICall(
				instance,
				accounts[0],
				"/api/convert/coverSheetPDF",
				{
					title: finalTitle,
					subtitle: finalSubtitle,
					htmlContent: coverSheetHTML,
					include_footer: false,
					include_header: false,
				}
			);

			return coverPageRes.data.Files[0].Url;
		} catch (error) {
			console.log("Error in createCoverPage: ", error);
			return "";
		}
	};

	// Create Table Of Contents:
	const createTableOfContents = async (finalTitle, finalSubtitle) => {
		let divisionMap = new Map();

		// Group specifications by division
		props.selectedSpecifications.forEach((spec) => {
			const division = spec.division ? spec.division.split(" ")[0] : "";
			if (division) {
				if (!divisionMap.has(division)) {
					divisionMap.set(division, []);
				}
				divisionMap.get(division).push(spec);
			}
		});

		// generate the HTML for each division
		let tableOfContentsHTML =
			"<h1>SECTION 00 01 10</h1><h2>TABLE OF CONTENTS</h2>";
		tableOfContentsHTML += props.divisionCodes
			.map((code) => {
				const divisionCode = code.division_code;
				let divisionHTML = `<h1 style='text-align: left;'>DIVISION ${divisionCode}&nbsp;&nbsp;&nbsp;${code.name.toUpperCase()}</h1>`;
				if (divisionMap.has(divisionCode)) {
					const specsHTML = divisionMap
						.get(divisionCode)
						.map(
							(spec) =>
								`<p>${spec.division}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${spec.title}</p>`
						)
						.join("");
					divisionHTML += specsHTML;
				}
				return divisionHTML;
			})
			.join("");

		const tableOfContentsRes = await postAPICall(
			instance,
			accounts[0],
			"/api/convert/html-to-pdf",
			{
				htmlContent: tableOfContentsHTML,
				property: props.job.property_name,
				address:
					props.propertyDetails.city +
					", " +
					props.propertyDetails.state,
				date: publishDate.startOf("day").format("MM/DD/YYYY"),
				subtitle: finalSubtitle,
				title: finalTitle,
				spec_subtitle: "TABLE OF CONTENTS",
				spec_title: "SECTION 00 01 10",
				include_footer: true,
				include_header: true,
			}
		);

		return tableOfContentsRes.data.Files[0].Url;
	};

	// Feedback Handlers
	const openAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const closeAlert = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const resetForm = () => {
		setPublishDate(null);

		setReportTitleSelect("");
		setReportTitleText("");

		setReportSubtitleSelect("");
		setReportSubtitleText("");

		closeAlert();
	};

	const validateInputFields = () => {
		if (!publishDate) {
			openAlert("Please select a publish date.");
			return false;
		}

		if (reportTitleSelect === "") {
			openAlert("Please select a report title.");
			return false;
		}

		if (reportTitleSelect === "custom" && reportTitleText === "") {
			openAlert("Please enter a custom report title.");
			return false;
		}

		if (reportSubtitleSelect === "") {
			openAlert("Please select a report subtitle.");
			return false;
		}

		if (reportSubtitleSelect === "custom" && reportSubtitleText === "") {
			openAlert("Please enter a custom report subtitle.");
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.documentGenerationOptionsDialogClosed();
	};

	// RENDER
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Dialog
				id="documentGenerationOptionsDialog"
				open={props.open}
				fullWidth
				disableEscapeKeyDown
			>
				<DialogTitle id="documentGenerationOptionsDialogTitle">
					Generate Document
				</DialogTitle>
				<DialogContent id="documentGenerationOptionsDialogContent">
					{isLoading && (
						<div className="loadingBarContainer">
							<LinearProgress
								variant="determinate"
								value={progress}
							/>
							<Typography>{progressMessage}</Typography>
						</div>
					)}
					{!isLoading && (
						<>
							<Collapse
								sx={{ width: "stretch" }}
								id="documentGenerationOptionsDialogCollapse"
								in={alertOpen}
							>
								<div className="documentGenerationOptionsDialogAlertContainer">
									<Alert
										sx={{ width: "stretch" }}
										id="documentGenerationOptionsDialogAlert"
										severity="error"
										action={
											<IconButton
												id="documentGenerationOptionsDialogCloseIconButton"
												aria-label="close"
												color="inherit"
												size="small"
												onClick={closeAlert}
											>
												<CloseIcon
													id="documentGenerationOptionsDialogAlertCloseIcon"
													fontSize="inherit"
												/>
											</IconButton>
										}
									>
										{alertMessage}
									</Alert>
								</div>
							</Collapse>
							<div className="documentGenerationOptionsDialogSectionFormData">
								<div className="documentGenerationOptionsDialogSection">
									<div className="documentGenerationOptionsDialogRow">
										<DatePicker
											sx={{ flex: 1 }}
											label="Publish Date"
											value={publishDate}
											onChange={publishDateChanged}
											slotProps={{
												textField: { size: "medium" },
											}}
										/>
									</div>
								</div>
								<div className="documentGenerationOptionsDialogSection">
									<div className="documentGenerationOptionsDialogRow">
										<FormControl sx={{ flex: 1 }}>
											<InputLabel id="titleLabel">
												Report Title
											</InputLabel>
											<Select
												labelId="titleLabel"
												id="title"
												value={reportTitleSelect}
												label="Report Title"
												onChange={
													reportTitleSelectChanged
												}
											>
												<MenuItem
													key={1}
													value="buildingEnvelopeRepairs"
												>
													Building Envelope Repairs
												</MenuItem>
												<MenuItem
													key={2}
													value="roofRepairs"
												>
													Roof Repairs
												</MenuItem>
												<MenuItem
													key={3}
													value="custom"
												>
													Custom
												</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className="documentGenerationOptionsDialogRow">
										<Collapse
											sx={{ flex: 1 }}
											in={reportTitleSelect === "custom"}
										>
											<div className="documentGenerationOptionsDialogCustomTitleContainer">
												<TextField
													sx={{ flex: 1 }}
													id="customTitle"
													label="Custom Title"
													value={reportTitleText}
													onChange={
														reportTitleTextChanged
													}
												/>
											</div>
										</Collapse>
									</div>
								</div>
								<div className="documentGenerationOptionsDialogSection">
									<div className="documentGenerationOptionsDialogRow">
										<FormControl sx={{ flex: 1 }}>
											<InputLabel id="subtitleLabel">
												Report Subtitle
											</InputLabel>
											<Select
												labelId="subtitleLabel"
												id="subtitle"
												value={reportSubtitleSelect}
												label="Report Subtitle"
												onChange={
													reportSubtitleSelectChanged
												}
											>
												<MenuItem
													key={1}
													value="bidSet"
												>
													Bid Set
												</MenuItem>
												<MenuItem
													key={2}
													value="constructionSet"
												>
													Construction Set
												</MenuItem>
												<MenuItem
													key={3}
													value="permitSet"
												>
													Permit Set
												</MenuItem>
												<MenuItem
													key={4}
													value="preliminaryReviewSet"
												>
													Preliminary Review Set
												</MenuItem>
												<MenuItem
													key={5}
													value="custom"
												>
													Custom
												</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className="documentGenerationOptionsDialogRow">
										<Collapse
											sx={{ flex: 1 }}
											in={
												reportSubtitleSelect ===
												"custom"
											}
										>
											<div className="documentGenerationOptionsDialogCustomTitleContainer">
												<TextField
													sx={{ flex: 1 }}
													id="customSubtitle"
													label="Custom Subtitle"
													value={reportSubtitleText}
													onChange={
														reportSubtitleTextChanged
													}
												/>
											</div>
										</Collapse>
									</div>
								</div>
							</div>
						</>
					)}
					{!isLoading ? (
						<div className="documentGenerationOptionsDialogButtons">
							<Button
								onClick={formClosed}
								variant="outlined"
								color="error"
							>
								Cancel
							</Button>
							<Button
								onClick={submitButtonClicked}
								variant="contained"
							>
								Submit
							</Button>
						</div>
					) : null}
				</DialogContent>
			</Dialog>
		</LocalizationProvider>
	);
};

// EXPORT
export default DocumentGenerationOptionsDialog;
