// DEPENDENCIES
import React, { useContext, useState } from "react";

// CSS
import "./NewTipDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// CONSTANTS
import { TTdataTypes, toTitleCase } from "../../../../constants";

// REACT COMPONENT
const NewTipDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [dataType, setDataType] = useState("iframe");

	const [title, setTitle] = useState("");
	const [categoryID, setCategoryID] = useState(1); // 'it' (information technology) has an ID of 1

	const [description, setDescription] = useState("");

	const [iframeLink, setIframeLink] = useState("");

	const [alertOpen, setAlertOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS
	const dataTypeChanged = (event) => {
		setDataType(event.target.value);
	};

	const titleChanged = (event) => {
		setTitle(event.target.value);
	};

	const categoryIDChanged = (event) => {
		setCategoryID(event.target.value);
	};

	const descriptionChanged = (event) => {
		setDescription(event.target.value);
	};

	const iframeLinkChanged = (event) => {
		setIframeLink(event.target.value);
	};

	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		let addRes = await postAPICall(
			instance,
			accounts[0],
			"/api/tipsAndTricks/add",
			{
				categoryID: categoryID,
				dataType: dataType,
				title: title,
				description: description,
				data: iframeLink,
				modifiedBy: employeeID,
			}
		);

		setIsLoading(false);

		formClosed();
		props.fetchTipsAndTricks();
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		setDataType("iframe");
		setTitle("");
		setCategoryID(1);
		setDescription("");
		setIframeLink("");

		setAlertOpen(false);
	};

	const validateInputFields = () => {
		if (!title || !categoryID || !description || !iframeLink) {
			setAlertOpen(true);
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.newTipDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="newTipDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="newTipDialogTitle">New Tip</DialogTitle>
			<DialogContent id="newTipDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="newTipDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="newTipCollapse"
								in={alertOpen}
							>
								<Alert
									id="newTipAlert"
									severity="error"
									action={
										<IconButton
											id="newTipCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={() => {
												setAlertOpen(false);
											}}
										>
											<CloseIcon
												id="newTipCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									Please fill out all fields
								</Alert>
							</Collapse>
						</div>
						<div className="newTipDialogRow">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="dataTypeLabel">
									Data Type
								</InputLabel>
								<Select
									sx={{ flexGrow: 1 }}
									id="newTipDataType"
									value={dataType}
									onChange={dataTypeChanged}
									labelId="dataTypeLabel"
									label="Data Type"
								>
									{TTdataTypes.map((categoryObj, index) => (
										<MenuItem
											key={categoryObj}
											value={categoryObj}
										>
											{toTitleCase(categoryObj)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="newTipDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newTipTitle"
								label="Title"
								value={title}
								onChange={titleChanged}
							/>
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="categoryLabel">
									Category
								</InputLabel>
								<Select
									labelId="categoryLabel"
									id="categorySelect"
									value={categoryID}
									label="Category"
									onChange={categoryIDChanged}
								>
									{props.categories.map(
										(categoryObj, index) => (
											<MenuItem
												key={categoryObj.id}
												value={categoryObj.id}
											>
												{toTitleCase(categoryObj.name)}
											</MenuItem>
										)
									)}
								</Select>
							</FormControl>
						</div>
						<div className="newTipDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newTipDescription"
								label="Description"
								value={description}
								onChange={descriptionChanged}
							/>
						</div>
						{/* // TODO: need some further validation for this field based on data type? */}
						<div className="newTipDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newTipIframeLink"
								label="Link" //  TODO: change label based on data type?
								value={iframeLink}
								onChange={iframeLinkChanged}
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default NewTipDialog;
