// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./NewJobTitlesDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@mui/material";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const NewJobTitlesDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	const [isLoading, setIsLoading] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);

	const [department, setDepartment] = useState("");
	const [jobTitleName, setJobTitleName] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS
	const jobTitleChanged = (event) => {
		setJobTitleName(event.target.value);
	};

	const departmentChanged = (event) => {
		setDepartment(event);
	};

	const formClosed = () => {
		resetForm();
		setAlertOpen(false);
		props.newJobTitlesDialogClosed();
	};

	// SUBMIT
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		let res = await postAPICall(
			instance,
			accounts[0],
			"/api/jobTitles/create",
			{
				name: jobTitleName,
				department_id: getDepartmentIdFromName(department),
				modified_by: employeeID,
			}
		);

		setIsLoading(false);

		formClosed();
		props.fetchJobTitles();
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const validateInputFields = () => {
		if (!department || !jobTitleName) {
			setAlertOpen(true);
			return false;
		}

		return true;
	};

	const resetForm = () => {
		setJobTitleName("");
		setDepartment("");
	};

	const getDepartmentIdFromName = (name) => {
		let department = props.departments.find(
			(department) => department.name === name
		);
		return department.id;
	};

	// RENDER
	return (
		<Dialog
			id="NewJobTitlesDialog"
			open={props.open}
			fullWidth
			maxWidth="lg"
			onClose={formClosed}
		>
			<DialogTitle id="NewJobTitlesDialogTitle">
				New Job Title
			</DialogTitle>
			<DialogContent id="NewJobTitlesDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="NewJobTitlesDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="NewJobTitlesDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="newEmployeeAlert"
									severity="error"
									action={
										<IconButton
											id="NewJobTitlesDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={() => {
												setAlertOpen(false);
											}}
										>
											<CloseIcon
												id="NewJobTitlesDialogCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									Please fill out all fields
								</Alert>
							</Collapse>
						</div>
						<div className="NewJobTitlesDialogRow">
							<TextField
								sx={{ flex: 3 }}
								id="NewJobTitlesDialogName"
								label="Name"
								value={jobTitleName}
								onChange={jobTitleChanged}
							/>
						</div>
						<div className="NewJobTitlesDialogRow">
							<Autocomplete
								sx={{ flex: 1 }}
								id="NewJobTitlesDepartment"
								options={props.departments.map(
									(departments) => departments.name
								)}
								renderInput={(params) => (
									<TextField {...params} label="Department" />
								)}
								onChange={(event, value) => {
									departmentChanged(value);
								}}
								value={department}
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default NewJobTitlesDialog;
