// DEPENDENCIES
import React, { useState, useEffect, useContext, useRef, useMemo } from "react";

// CSS
import "./PropertyConsoleEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	postAPICall,
	putAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	debounce,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS
import DropboxFileSelector from "../../../Misc/DropboxFileSelector/DropboxFileSelector";

// CONSTANTS

// OTHER

// REACT COMPONENT
const PropertyConsoleEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS
	const hubSpotPropertySearchByValueRef = useRef(null);
	const bqePropertyNameRef = useRef(null);

	// STATES
	const [loading, setLoading] = useState(true);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// HubSpot Property
	const [hubSpotPropertySearchByValue, setHubSpotPropertySearchByValue] =
		useState("");
	const [hubSpotPropertyOptions, setHubSpotPropertyOptions] = useState([]);
	const [hubSpotPropertySearchLoading, setHubSpotPropertySearchLoading] =
		useState(false);

	const [hubSpotPropertyRecord, setHubSpotPropertyRecord] = useState(null);

	// BQE Property
	const [bqePropertyOptions, setBqePropertyOptions] = useState([]);
	const [bqePropertyDetailedViewOpen, setBqePropertyDetailedViewOpen] =
		useState(false);
	const [bqePropertySearchValue, setBqePropertySearchValue] = useState("");
	const [bqePropertySearchLoading, setBqePropertySearchLoading] =
		useState(false);

	const [bqePropertyRecord, setBqePropertyRecord] = useState(null);

	// Other Inputs (General Inputs)
	const [dropboxFilePath, setDropboxFilePath] = useState("");
	const [OIC, setOIC] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		// if editing, populate fields
		if (props.editing) {
			handlePopulateFieldsOnEdit();
		} else {
			setLoading(false);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	const bqePropertyNameSelected = (event, newValue) => {
		if (newValue && newValue.displayName) {
			// if a deal name is selected and the user didn't just clear the autocomplete
			setBqePropertyRecord(newValue);
			setBqePropertySearchValue(newValue.displayName);
			setBqePropertyDetailedViewOpen(true);
		} else {
			// if the user cleared the autocomplete
			setBqePropertyRecord({});
			setBqePropertySearchValue("");
			setBqePropertyDetailedViewOpen(false);

			setBqePropertyOptions([]);
		}
	};

	const hubSpotPropertySearchByValueSelected = (event, newValue) => {
		// if the searchByValue is selected and the user didn't just clear the autocomplete
		if (newValue && newValue.properties) {
			setHubSpotPropertyRecord(newValue);
		} else {
			// if the user cleared the autocomplete (still triggers as removing the value is technically a selection event)
			setHubSpotPropertyRecord(null);
			setHubSpotPropertyOptions([]);
		}
	};

	// GENERAL INPUT HANDLERS

	const handleDropboxFilePathChange = (event) => {
		setDropboxFilePath(event.target.value);
	};

	const handleOICChange = (event) => {
		setOIC(event.target.value);
	};

	// API FUNCTIONS

	// populate fields on edit
	const handlePopulateFieldsOnEdit = async () => {
		setLoading(true);
		if (props.recordToEdit.bqe_id) {
			await fetchBQEPropertyRecord(props.recordToEdit.bqe_id);
		}
		if (props.recordToEdit.hubspot_id) {
			await fetchHubSpotPropertyRecord(props.recordToEdit.hubspot_id);
		}
		setDropboxFilePath(props.recordToEdit.dropbox_file_path);
		setOIC(props.recordToEdit.oic);
		setLoading(false);
	};

	const fetchBQEPropertyRecord = async (bqeID) => {
		setBqePropertySearchLoading(true);
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/bqe/property/byID",
				{
					id: bqeID,
				}
			);
			setBqePropertyRecord(res.data);
			setBqePropertyDetailedViewOpen(true);
		} catch (error) {
			console.log("Error in fetchBQEPropertyRecord: ", error);
		}
		setBqePropertySearchLoading(false);
	};

	const fetchHubSpotPropertyRecord = async (hubSpotID) => {
		setHubSpotPropertySearchLoading(true);
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hubspot/getPropertyFromPropertyID",
				{
					propertyID: hubSpotID,
				}
			);
			if (res.data) {
				hubSpotPropertySearchByValueChanged({
					target: { value: res.data.properties.property_name },
				});
				hubSpotPropertySearchByValueSelected(null, res.data);
				setHubSpotPropertySearchByValue(
					res.data.properties.property_name
				);
			} else {
				setAlertMessage(
					"Error fetching property records in HubSpot. Record may not be associated with a property in HubSpot."
				);
				setAlertOpen(true);
			}
		} catch (error) {
			console.log("Error in fetchHubSpotPropertyRecord: ", error);
		}
		setHubSpotPropertySearchLoading(false);
	};

	const bqePropertyNameChanged = useMemo(
		() =>
			debounce(async (event) => {
				setBqePropertySearchLoading(true);
				const textLength = event.target.value.length;

				// if the current text length is less than 3, we will get too many results, so return
				if (textLength < 3) {
					setBqePropertySearchLoading(false);
					setBqePropertyOptions([]);
					return;
				}

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/bqe/property/byName",
						{
							propertyName: event.target.value,
						}
					);

					setBqePropertyOptions(
						res.data == ""
							? []
							: res.data.filter(
									(option) =>
										!option.displayName.endsWith(
											"(deleted)"
										)
							  )
					);
				} catch (error) {
					setBqePropertyOptions([]);
					console.log("Error in bqepropertyNameChanged: ", error);
				}
				setBqePropertySearchLoading(false);
			}, 500),
		[]
	);

	const hubSpotPropertySearchByValueChanged = useMemo(
		() =>
			debounce(async (event) => {
				setHubSpotPropertySearchLoading(true);
				const textLength = event.target.value.length;

				// if the current text length is less than 3, we will get too many results, so return
				if (textLength < 3) {
					setHubSpotPropertySearchLoading(false);
					setHubSpotPropertyOptions([]);
					return;
				}

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/hubspot/search/properties",
						{
							propertyName: event.target.value,
						}
					);
					setHubSpotPropertyOptions(res.data);
				} catch (error) {
					console.log(error);
					setAlertMessage(
						"Error fetching property records in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
					);
					setAlertOpen(true);
				}
				setHubSpotPropertySearchLoading(false);
			}, 500),
		[]
	); // 500 represents the 500ms delay sent to the debounce function below

	// adds record to tools db
	const handleAddRecord = async () => {
		setLoading(true);
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/jobs/property/create",
				{
					name: hubSpotPropertyRecord.properties.property_name,
					addressLine1:
						hubSpotPropertyRecord.properties.property_address_1,
					addressLine2:
						hubSpotPropertyRecord.properties.property_address_2,
					city: hubSpotPropertyRecord.properties.property_city,
					state: hubSpotPropertyRecord.properties.property_state,
					zip: hubSpotPropertyRecord.properties.property_zip_code,
					dropboxFilePath: dropboxFilePath,
					bqeID: bqePropertyRecord.id,
					bqeCode: bqePropertyRecord.code,
					oic: OIC,
					createdBy: employeeID,
					hubspotID: hubSpotPropertyRecord.id,
				}
			);
			setAlertMessage("Record added successfully");
			setAlertOpen(true);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error adding record");
			setAlertOpen(true);
		}
		setLoading(false);
	};

	const handleUpdateRecord = async () => {
		setLoading(true);
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/jobs/property/update",
				{
					id: props.recordToEdit.id,
					name: hubSpotPropertyRecord.properties.property_name,
					addressLine1:
						hubSpotPropertyRecord.properties.property_address_1,
					addressLine2:
						hubSpotPropertyRecord.properties.property_address_2,
					city: hubSpotPropertyRecord.properties.property_city,
					state: hubSpotPropertyRecord.properties.property_state,
					zip: hubSpotPropertyRecord.properties.property_zip_code,
					dropboxFilePath: dropboxFilePath,
					bqeID: bqePropertyRecord.id,
					bqeCode: bqePropertyRecord.code,
					oic: OIC,
					modifiedBy: employeeID,
					hubspotID: hubSpotPropertyRecord.id,
				}
			);
			setAlertMessage("Record updated successfully");
			setAlertOpen(true);
		} catch (error) {
			console.log(error);
			setAlertMessage("Error updating record");
			setAlertOpen(true);
		}
		setLoading(false);
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		// if editing, update record, else add record
		if (props.editing) {
			await handleUpdateRecord();
		} else {
			await handleAddRecord();
		}

		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		props.onClose();
	};

	const handleCancelClick = () => {
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		props.onCloseNoRefresh();
	};

	const resetInputFields = () => {
		setLoading(false);
		setAlertOpen(false);
		setAlertMessage("");
		//reset refs
		hubSpotPropertySearchByValueRef.current = null;
		bqePropertyNameRef.current = null;

		setHubSpotPropertySearchByValue("");
		setHubSpotPropertyOptions([]);
		setHubSpotPropertySearchLoading(false);

		setBqePropertyOptions([]);
		setBqePropertyDetailedViewOpen(false);
		setBqePropertySearchValue("");
		setBqePropertySearchLoading(false);

		setDropboxFilePath("");
		setOIC("");

		setHubSpotPropertyRecord(null);

		setBqePropertyRecord(null);
	};

	const validateInputFields = () => {
		if (OIC === "" || dropboxFilePath === "") {
			setAlertMessage("Please Fill Out All Fields");
			setAlertOpen(true);
			return false;
		}
		if (bqePropertyRecord == null) {
			setAlertMessage("Please Select a BQE Property");
			setAlertOpen(true);
			return false;
		}
		if (!hubSpotPropertyRecord) {
			setAlertMessage("Please Select a HubSpot Property");
			setAlertOpen(true);
			return false;
		}

		// TODO: eventually OIC should be a dropdown based on offices
		if (OIC !== "OR" && OIC !== "WA" && OIC !== "UT") {
			setAlertMessage("OIC must be OR, WA, or UT");
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	// RENDER
	return (
		<Dialog
			id="PropertyConsoleEditAddDialog"
			open={props.open}
			maxWidth="xl"
			fullWidth
			onClose={handleCancelClick}
		>
			<DialogTitle id="PropertyConsoleEditAddDialogTitle">
				{/* edit / add */}
				{props.editing ? "Edit Property Record" : "Add Property Record"}
			</DialogTitle>
			{loading && (
				<div className="propertyConsoleLoadingSpinner">
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<DialogContent id="PropertyConsoleEditAddDialogContent">
					{alertOpen && (
						<Alert
							severity="warning"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setAlertOpen(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							}
						>
							{alertMessage}
						</Alert>
					)}

					{/* HubSpot Property */}
					<div className="dialogSubtitle">
						Search Existing HubSpot Property Record
					</div>
					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									options={hubSpotPropertyOptions}
									ref={hubSpotPropertySearchByValueRef}
									getOptionLabel={(option) =>
										option.properties
											? option.properties.property_name
											: option
									}
									value={
										hubSpotPropertyOptions.find(
											(option) =>
												option.properties
													.property_name ===
												hubSpotPropertySearchByValue
										) || null
									}
									filterSelectedOptions
									onChange={
										hubSpotPropertySearchByValueSelected
									}
									clearOnBlur={false}
									renderInput={(params) => (
										<TextField
											{...params}
											label="HubSpot Property Name"
											placeholder="HubSpot Property Name"
											value={
												hubSpotPropertySearchByValue ||
												""
											}
											onKeyUp={
												hubSpotPropertySearchByValueChanged
											}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{hubSpotPropertySearchLoading ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : null}
														{
															params.InputProps
																.endAdornment
														}
													</>
												),
											}}
										/>
									)}
								/>
							</div>
							<div className="FormRow">
								<TextField
									sx={{ flexGrow: 1 }}
									disabled
									label="Property Name"
									value={
										hubSpotPropertyRecord
											? hubSpotPropertyRecord.properties
													.property_name
											: ""
									}
								/>
							</div>
							<div className="FormRow">
								<TextField
									sx={{ flexGrow: 1 }}
									disabled
									label="Address Line 1"
									value={
										hubSpotPropertyRecord
											? hubSpotPropertyRecord.properties
													.property_address_1
											: ""
									}
								/>
								<TextField
									sx={{ flexGrow: 1 }}
									disabled
									label="Address Line 2"
									value={
										hubSpotPropertyRecord
											? hubSpotPropertyRecord.properties
													.property_address_2
											: ""
									}
								/>
							</div>
							<div className="FormRow">
								<TextField
									sx={{ flexGrow: 1 }}
									disabled
									label="City"
									value={
										hubSpotPropertyRecord
											? hubSpotPropertyRecord.properties
													.property_city
											: ""
									}
								/>
								<FormControl sx={{ flexGrow: 1 }}>
									<InputLabel id="propertyStateLabel">
										State
									</InputLabel>
									<Select
										labelId="hubSpotPropertyStateLabel"
										value={
											hubSpotPropertyRecord
												? hubSpotPropertyRecord
														.properties
														.property_state
												: ""
										}
										label="State"
										disabled
									>
										<MenuItem value="Washington">
											Washington
										</MenuItem>
										<MenuItem value="Oregon">
											Oregon
										</MenuItem>
										<MenuItem value="Utah">Utah</MenuItem>
										<MenuItem value="Idaho">Idaho</MenuItem>
									</Select>
								</FormControl>
								<TextField
									sx={{ flexGrow: 1 }}
									disabled
									label="ZIP"
									value={
										hubSpotPropertyRecord
											? hubSpotPropertyRecord.properties
													.property_zip_code
											: ""
									}
								/>
							</div>
						</div>
						<FormLabel>
							These values will be used to populate the Tools
							Record.
						</FormLabel>
					</FormControl>

					<Divider />

					<div className="dialogSubtitle">OIC</div>

					{/* TODO: fix OIC association and do a drop down to pick one instead of textbox */}
					<div className="FormBlock">
						<div className="FormRow">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="OIC">OIC</InputLabel>
								<Select
									value={OIC ? OIC : ""}
									onChange={handleOICChange}
									label="OIC"
									labelId="OIC"
								>
									<MenuItem value={"OR"}>OR</MenuItem>
									<MenuItem value={"WA"}>WA</MenuItem>
									<MenuItem value={"UT"}>UT</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>

					<Divider />
					<div className="dialogSubtitle">
						Search Existing BQE Property Records
					</div>

					{/* BQE PROPERTY */}
					<FormControl sx={{ flex: 1 }}>
						<div className="FormBlock">
							<div className="FormRow">
								<Autocomplete
									sx={{ flexGrow: 1 }}
									options={bqePropertyOptions}
									ref={bqePropertyNameRef}
									getOptionLabel={(option) =>
										option.displayName ?? option
									}
									filterSelectedOptions
									value={
										bqePropertyRecord &&
										Object.keys(bqePropertyRecord).length >
											0
											? bqePropertyRecord
											: ""
									}
									onChange={bqePropertyNameSelected}
									clearOnBlur={false}
									loading={loading}
									renderInput={(params) => (
										<TextField
											{...params}
											label="BQE Property Name"
											placeholder="BQE Property Name"
											value={bqePropertySearchValue || ""}
											onKeyUp={bqePropertyNameChanged}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{bqePropertySearchLoading ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : null}
														{
															params.InputProps
																.endAdornment
														}
													</>
												),
											}}
										/>
									)}
								/>
							</div>
						</div>

						<Collapse
							in={
								bqePropertyDetailedViewOpen && bqePropertyRecord
							}
							id="createNewPropertyCollapse"
						>
							<div
								className="FormBlock"
								style={{ marginTop: "1em" }}
							>
								<div className="FormRow">
									<TextField
										sx={{ flex: 1 }}
										disabled
										label="BQE Property Name"
										value={
											bqePropertyRecord
												? bqePropertyRecord?.displayName
												: ""
										}
									/>
								</div>
								<div className="FormRow">
									<TextField
										sx={{ flex: 1 }}
										disabled
										label="Address Line 1"
										value={
											bqePropertyRecord
												? bqePropertyRecord
														?.address?.[0]?.street1
												: ""
										}
									/>
									<TextField
										sx={{ flex: 1 }}
										disabled
										label="Address Line 2"
										value={
											bqePropertyRecord
												? bqePropertyRecord
														?.address?.[0]?.street2
												: ""
										}
									/>
								</div>
								<div className="FormRow">
									<TextField
										sx={{ flex: 1 }}
										disabled
										label="City"
										value={
											bqePropertyRecord
												? bqePropertyRecord
														?.address?.[0]?.city
												: ""
										}
									/>
									<TextField
										sx={{ flex: 1 }}
										disabled
										label="State"
										value={
											bqePropertyRecord
												? bqePropertyRecord
														?.address?.[0]?.state
												: ""
										}
									/>
									<TextField
										sx={{ flex: 1 }}
										disabled
										label="Zip"
										value={
											bqePropertyRecord
												? bqePropertyRecord
														?.address?.[0]?.zip
												: ""
										}
									/>
								</div>
							</div>
						</Collapse>
					</FormControl>

					<Divider />

					<div className="dialogSubtitle">Dropbox Information</div>

					<div className="FormBlock">
						<div className="FormRow">
							<DropboxFileSelector
								filePath={dropboxFilePath}
								setFilePath={setDropboxFilePath}
							/>
						</div>
					</div>

					<div className="editPropertyConsoleButtons">
						<Button
							variant="outlined"
							color="error"
							onClick={handleCancelClick}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitClick}
							disabled={loading}
						>
							Submit
						</Button>
					</div>
				</DialogContent>
			)}
		</Dialog>
	);
};

// EXPORT
export default PropertyConsoleEditAddDialog;
