// REACT IMPORTS
import React, { useContext } from "react";

// CSS
import "./IFrameCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button } from "@mui/material";

// FUNCTIONAL COMPONENT
const IFrameCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS

	// RENDER
	return (
		<div className="IFrameCard">
			<div className="cardHeader">
				<div className="leftSideCardHeader">
					<div className="cardTitles">
						<h2>{props.cardTitle}</h2>
						<h3>{props.description}</h3>
					</div>
				</div>
				<Button
					sx={{ padding: "0px" }}
					onClick={() => props.reportIssueClicked(props.id)}
				>
					Report an Issue
				</Button>
			</div>
			<div className="IFrameContainer">
				<iframe
					id="IFrameElement"
					title={props.cardTitle}
					src={props.link}
					width="100%"
					height="640"
					frameborder="0"
				></iframe>
			</div>
		</div>
	);
};

export default IFrameCard;
