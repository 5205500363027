// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// CSS
import "./ServiceHistoryEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall, putAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";

import { Close as CloseIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const ServiceHistoryEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(true);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [device, setDevice] = useState("");
	const [ticketNumber, setTicketNumber] = useState("");
	const [incidentType, setIncidentType] = useState("");
	const [notes, setNotes] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.editing) {
			handlePopulateFieldsOnEdit();
		} else {
			setLoading(false);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	const handleDeviceChange = (event, newValue) => {
		setDevice(newValue);
	};

	const handleTicketNumberChange = (event) => {
		setTicketNumber(event.target.value);
	};

	const handleIncidentTypeChange = (event, newValue) => {
		setIncidentType(newValue);
	};

	const handleNotesChange = (event) => {
		setNotes(event.target.value);
	};

	// API FUNCTIONS

	const handlePopulateFieldsOnEdit = async () => {
		setLoading(true);
		setDevice(props.recordToEdit);
		setTicketNumber(props.recordToEdit.ticket_number);
		props.incidentTypes.forEach((incidentType) => {
			if (
				incidentType.display_name ==
				props.recordToEdit.incident_type_display_name
			) {
				setIncidentType(incidentType);
			}
		});
		setNotes(props.recordToEdit.notes);
		setLoading(false);
	};

	const addServiceHistoryRecord = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/addServiceHistory",
				{
					device_id: device.id,
					ticket_number: ticketNumber,
					incident_type_id: incidentType.id,
					notes: notes,
					modified_by: employeeID,
				}
			);
			setAlertMessage("Service History Record Added");
			setAlertOpen(true);
		} catch (error) {
			console.error(error);
		}
	};

	const updateServiceHistoryRecord = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/updateServiceHistory",
				{
					id: props.recordToEdit.id,
					device_id: device.device_id,
					ticket_number: ticketNumber,
					incident_type_id: incidentType.id,
					notes: notes,
					modified_by: employeeID,
				}
			);
			setAlertMessage("Service History Record Updated");
			setAlertOpen(true);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS

	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		if (props.editing) {
			await updateServiceHistoryRecord();
		} else {
			await addServiceHistoryRecord();
		}
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
		props.onClose();
	};

	const handleCancelClick = () => {
		resetInputFields();
		setAlertMessage("");
		setAlertOpen(false);
		props.onClose();
	};

	const resetInputFields = () => {
		setDevice("");
		setTicketNumber("");
		setIncidentType("");
		setNotes("");
		setAlertMessage("");
		setAlertOpen(false);
	};

	const validateInputFields = () => {
		if (!device) {
			setAlertMessage("Please select a device");
			setAlertOpen(true);
			return false;
		}
		// ticket number is optional
		if (!incidentType) {
			setAlertMessage("Please select an incident type");
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	// RENDER
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Dialog
				id="ServiceHistoriesEditAddDialog"
				open={props.open}
				maxWidth="xl"
				fullWidth
				onClose={handleCancelClick}
			>
				<DialogTitle id="ServiceHistoriesEditAddDialogTitle">
					{/* edit / add */}
					{props.editing
						? "Edit Service History Record"
						: "Add Service History Record"}
				</DialogTitle>
				{loading && (
					<div className="serviceHistoriesLoadingSpinner">
						<CircularProgress />
					</div>
				)}
				{!loading && (
					<DialogContent id="ServiceHistoriesEditAddDialogContent">
						{alertOpen && (
							<Alert
								severity="warning"
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setAlertOpen(false);
										}}
									>
										<CloseIcon />
									</IconButton>
								}
							>
								{alertMessage}
							</Alert>
						)}

						<FormControl sx={{ flex: 1 }}>
							<div className="FormBlock">
								<div className="FormRow">
									<Autocomplete
										sx={{ flexGrow: 1 }}
										id="device"
										options={
											props.devices ? props.devices : []
										}
										getOptionLabel={(option) =>
											option ? option.j2_device_name : ""
										}
										value={device ? device : ""}
										onChange={handleDeviceChange}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Device"
												variant="outlined"
											/>
										)}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>
												{option.j2_device_name}
											</li>
										)}
									/>
								</div>
								<div className="FormRow">
									<TextField
										sx={{ flexGrow: 1 }}
										label="Ticket Number"
										variant="outlined"
										value={ticketNumber}
										onChange={handleTicketNumberChange}
									/>
									<Autocomplete
										sx={{ flexGrow: 1 }}
										id="incidentType"
										options={props.incidentTypes}
										getOptionLabel={(option) =>
											option ? option.display_name : ""
										}
										value={incidentType ? incidentType : ""}
										onChange={handleIncidentTypeChange}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Incident Type"
												variant="outlined"
											/>
										)}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>
												{option.display_name}
											</li>
										)}
									/>
								</div>
								<div className="FormRow">
									<TextField
										sx={{ flexGrow: 1 }}
										label="Notes"
										variant="outlined"
										multiline
										rows={4}
										value={notes}
										onChange={handleNotesChange}
									/>
								</div>
							</div>
						</FormControl>

						<div className="editServiceHistoriesButtons">
							<Button
								variant="outlined"
								color="error"
								onClick={handleCancelClick}
								disabled={loading}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmitClick}
								disabled={loading}
							>
								Submit
							</Button>
						</div>
					</DialogContent>
				)}
			</Dialog>
		</LocalizationProvider>
	);
};

// EXPORT
export default ServiceHistoryEditAddDialog;
