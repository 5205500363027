// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

// CSS
import "./DesignJobSpecificationEdit.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	deleteAPICall,
	getAPICall,
	postAPICall,
	putAPICall,
} from "../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import { Alert, Button, CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpIcon from "@mui/icons-material/Help";

// MUI ICONS

// CUSTOM COMPONENTS
import SpecTextEditor from "../../../SpecTextEditor/SpecTextEditor";
import SpecTextEditorInfo from "../../../SpecTextEditor/SpecTextEditorInfo/SpecTextEditorInfo";
import J2NoResultsFound from "../../../../Misc/J2NoResultsFound/J2NoResultsFound";
import SubmitForReviewDialog from "./SubmitForReviewDialog/SubmitForReviewDialog";
import AddChangesCommentDialog from "./AddChangesCommentDialog/AddChangesCommentDialog";

// CONSTANTS

// OTHER

// REACT COMPONENT
const DesignJobSpecificationEdit = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [designJob, setDesignJob] = useState({});
	const [specification, setSpecification] = useState({});
	const [data, setData] = useState("");

	// Feedback
	const [loading, setLoading] = useState(false);
	const [alertOpen, setAlertOpen] = useState("");
	const [saving, setSaving] = useState(false);
	const [failedAPICalls, setFailedAPICalls] = useState(false);
	const [helpDialogOpen, setHelpDialogOpen] = useState(false);

	// Dialogs
	const [submitForReviewDialogOpen, setSubmitForReviewDialogOpen] =
		useState(false);
	const [addChangesCommentDialogOpen, setAddChangesCommentDialogOpen] =
		useState(false);

	// Other
	const [changesMade, setChangesMade] = useState(false);
	const [changesComment, setChangesComment] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// NAVIGATE
	const navigate = useNavigate();

	// get id and specID from url
	const { id, specID } = useParams();

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Design Job Specification Edit",
			employeeID: employeeID,
			notes:
				"Accessed the Design Job Specification Edit page for job ID " +
				id +
				" and spec ID " +
				specID,
		});
	}, []);

	useEffect(() => {
		handleAPICalls();
	}, [navigate]);

	// displays default browser alert if user tries to leave page with unsaved changes
	useEffect(() => {
		const handleBeforeUnload = (e) => {
			e.preventDefault();
			e.returnValue = "";
		};

		if (alertOpen !== "") {
			window.addEventListener("beforeunload", handleBeforeUnload);
		} else {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		}
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [alertOpen]);

	// INPUT HANDLERS
	const submitForReviewClicked = () => {
		setSubmitForReviewDialogOpen(true);
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await checkIfSpecIsAssociated();
		await getDesignJob();
		await getSpecification();
		setLoading(false);
	};

	const getDesignJob = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/jobs/design/byID`,
				{
					id: id,
				}
			);
			setDesignJob(res.data[0]);

			if (res.data[0].is_locked === 1) {
				navigate(`/specs/design_jobs/${id}`);
			}
		} catch (error) {
			setFailedAPICalls(true);
			console.log(error);
		}
	};

	const getSpecification = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/byID`,
				{
					id: specID,
				}
			);
			setSpecification(res.data[0]);
			setData(res.data[0].content);
		} catch (error) {
			setFailedAPICalls(true);
			console.log(error);
		}
	};

	const checkIfSpecIsAssociated = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/byJob`,
				{
					id: id,
				}
			);

			const isAssociated = res.data.some(
				(item) => item.id === Number(specID) || item.id === specID
			);
			// If specID is not found, redirect back to the job page
			if (!isAssociated) {
				navigate(`/specs/design_jobs/${id}`);
			}
		} catch (error) {
			setFailedAPICalls(true);
			console.log("Error:", error);
		}
	};

	const updateSpecification = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				`/api/specifications/update`,
				{
					title: specification.title,
					description: specification.description,
					division: specification.division,
					statusID: specification.status_id,
					modifiedByID: employeeID,
					content: data,
					editable: specification.editable,
					parentID: specification.parent_id,
					phaseID: id,
					specID: specID,
				}
			);
		} catch (error) {
			setAlertOpen("Error saving changes. Changes not saved.");
			console.log(error);
		}
	};

	const createNewSpec = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/specifications/add`,
				{
					title: specification.title,
					description: specification.description,
					division: specification.division,
					statusID: specification.status_id,
					modifiedBy: employeeID,
					content: data,
					editable: specification.editable,
					parentID: specification.id,
					phaseID: id,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	// disassociate spec from job
	const removeSpecAssociation = async () => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/specifications/removeFromJob",
				{
					jobID: id,
					specID: specID,
					employeeID: employeeID,
					specName: specification.title,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	// used for adding a job spec record
	const associateSpecToJob = async (specId) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/specifications/associateToJob`,
				{
					jobID: id,
					specID: specId,
					modifiedBy: employeeID,
					specName: specification.title,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	const handleSaveClick = async () => {
		setAddChangesCommentDialogOpen(false);

		setSaving(true);

		// check if child or parent spec
		if (specification.parent_id === null) {
			// create child spec
			const newSpec = await createNewSpec();
			// remove old spec association
			await removeSpecAssociation();
			// associate spec to job
			await associateSpecToJob(newSpec.data.insertId);
			// add changes comment
			await addSpecComment(newSpec.data.insertId);
			// redirect to new page
			navigate(`/specs/design_jobs/${id}/edit/${newSpec.data.insertId}`);
		} else {
			await updateSpecification();
			// add changes comment
			await addSpecComment(specID);
			// reload data
			await handleAPICalls();
		}

		setAlertOpen("");
		setSaving(false);

		// reset changes made
		setChangesMade(false);
	};

	const addSpecComment = async (specId) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/specifications/comments/add`,
				{
					specID: specId,
					createdByID: employeeID,
					comment: changesComment,
				}
			);
		} catch (error) {
			console.error(error);
		}
	};

	const handleDataChange = (newData) => {
		if (JSON.stringify(newData) !== JSON.stringify(specification.content)) {
			setChangesMade(true);
			setAlertOpen("You have unsaved changes.");
		} else {
			setChangesMade(false);
			setAlertOpen("");
		}
		setData(newData);
	};

	// HELPER FUNCTIONS

	// RENDER
	return (
		<>
			{failedAPICalls ? (
				<J2NoResultsFound />
			) : (
				<div className="DesignJobSpecificationEdit">
					<div className="DesignJobSpecificationEditHeader">
						{loading ? (
							<div className="DesignJobSpecificationEditHeaderTitle">
								Loading...
							</div>
						) : (
							<>
								<IconButton
									id="DesignJobSpecificationEditBackButton"
									sx={{ paddingLeft: 0 }}
									onClick={(e) => {
										// Check for unsaved changes
										if (alertOpen !== "") {
											// If unsaved changes exist, show the browser alert
											const confirmation = window.confirm(
												"You have unsaved changes. Are you sure you want to leave?"
											);
											if (!confirmation) {
												// If the user cancels, prevent navigation
												e.preventDefault();
												return;
											}
										}
										// Otherwise, navigate to the previous page
										navigate(`/specs/design_jobs/${id}`);
									}}
								>
									<ArrowBackIcon />
								</IconButton>
								<div className="DesignJobSpecificationEditHeaderTitle">
									Editing: {specification.title}
								</div>
								<div className="DesignJobSpecificationEditHeaderSubtitle">
									{designJob.job_name}
								</div>
							</>
						)}
					</div>
					<div className="DesignJobSpecificationContent">
						{loading && (
							<div className="DesignJobSpecificationEditLoadingDiv">
								<CircularProgress color="primary" />
							</div>
						)}
						{!loading && (
							<>
								<div className="DesignJobSpecificationEditContent">
									<SpecTextEditor
										data={specification.content}
										disabled={false}
										onDataChange={handleDataChange}
									/>
								</div>

								<div className="DesignJobSpecificationEditContentButtons">
									<div className="DesignJobSpecificationEditContentAlert">
										{alertOpen !== "" && (
											<Alert
												severity="warning"
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
													flexGrow: 1,
												}}
											>
												{alertOpen}
											</Alert>
										)}
									</div>
									<IconButton
										aria-label="help"
										onClick={() => setHelpDialogOpen(true)}
									>
										<HelpIcon />
									</IconButton>
									<Button
										variant="contained"
										color="secondary"
										onClick={submitForReviewClicked}
										disabled={
											specification.parent_id === null ||
											changesMade
										}
									>
										Submit For Review
									</Button>
									<Button
										variant="contained"
										color="primary"
										onClick={() =>
											setAddChangesCommentDialogOpen(true)
										}
										disabled={!changesMade}
									>
										{saving ? (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										) : (
											"Save"
										)}
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			)}
			<SpecTextEditorInfo
				open={helpDialogOpen}
				handleHelpDialogClose={() => setHelpDialogOpen(false)}
			/>
			<SubmitForReviewDialog
				open={submitForReviewDialogOpen}
				handleClose={() => setSubmitForReviewDialogOpen(false)}
				specName={specification.title}
				specID={specID}
				newData={data}
				parentID={specification.parent_id}
				jobID={id}
				handleSaveClick={handleSaveClick}
			/>
			<AddChangesCommentDialog
				open={addChangesCommentDialogOpen}
				handleClose={() => setAddChangesCommentDialogOpen(false)}
				handleSaveClick={handleSaveClick}
				changesComment={changesComment}
				setChangesComment={setChangesComment}
			/>
		</>
	);
};

// EXPORT
export default DesignJobSpecificationEdit;
