// DEPENDENCIES
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CSS
import "./NewSpecDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS
import DivisionTextField from "../../DivisionTextField/DivisionTextField";

// CONSTANTS
import { selectIntegersRegex } from "../../../../constants";

// FUNCTIONAL COMPONENT
const NewSpecDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Form data
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [division, setDivision] = useState("");
	const [statusID, setStatusID] = useState(0);

	// Feedback
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// NAVIGATE
	const navigate = useNavigate();

	// INPUT HANDLERS
	// Form data handlers
	const titleChanged = (event) => {
		setTitle(event.target.value);
	};

	const descriptionChanged = (event) => {
		setDescription(event.target.value);
	};

	const divisionChanged = (event) => {
		setDivision(event.target.value);
	};

	const statusChanged = (event) => {
		setStatusID(event.target.value);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertMessage(message);
		setAlertOpen(true);
	};

	const closeAlert = () => {
		setAlertMessage("");
		setAlertOpen(false);
	};

	// BUTTON HANDLERS
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		// add some dummy data to content to give the user something to start with
		let content = `<h1>SECTION ${division}</h1><h2>${title}</h2><ol><li><span class='bold uppercase'>sample text</span><ol><li><span class='bold uppercase'>Sample Text</span><ol><li>Sample Text</li></ol></li></ol></li></ol><h3>END OF SECTION</h3>`;

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/specifications/add",
				{
					title: title,
					description: description ? description : "",
					division: division,
					statusID: statusID,
					modifiedBy: employeeID,
					content: content,
					editable: true,
					parentID: null,
					phaseID: null,
				}
			);
			navigate(`/specs/library/${res.data.insertId}`);
		} catch (error) {
			openAlert("Error submitting form data");
			console.error(error);
		}

		setIsLoading(false);
		formClosed();
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const validateInputFields = () => {
		if (!title) {
			openAlert("Please enter a title");
			return false;
		}

		if (
			!division ||
			division.replace(selectIntegersRegex, "").length !== 6
		) {
			openAlert("Please enter a valid division");
			return false;
		}

		if (!statusID) {
			openAlert("Please select a status");
			return false;
		}

		return true;
	};

	const resetForm = () => {
		setTitle("");
		setDescription("");
		setDivision("");
		setStatusID(0);
		setAlertMessage("");
		setAlertOpen(false);
		setIsLoading(false);
	};

	const formClosed = () => {
		resetForm();
		props.newSpecDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="newSpecDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="newSpecDialogTitle">New Spec</DialogTitle>
			<DialogContent id="newSpecDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="newSpecDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="newSpecDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="newSpecDialogAlert"
									severity="error"
									action={
										<IconButton
											id="newSpecDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="newDetailCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="newSpecDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newSpecTitle"
								label="Title"
								value={title}
								onChange={titleChanged}
							/>
						</div>
						<div className="newSpecDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newSpecTitle"
								label="Description"
								value={description}
								onChange={descriptionChanged}
							/>
						</div>
						<div className="newSpecDialogRow">
							<DivisionTextField
								sx={{ flex: 1 }}
								id="newSpecTitle"
								label="Division"
								value={division}
								onChange={divisionChanged}
							/>
							<FormControl variant="outlined" style={{ flex: 1 }}>
								<InputLabel id="statusLabel">Status</InputLabel>
								<Select
									labelId="statusLabel"
									id="status"
									value={statusID}
									onChange={statusChanged}
									label="Status"
								>
									{props.statuses.map((status) => (
										<MenuItem
											key={status.id}
											value={status.id}
										>
											{status.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default NewSpecDialog;
