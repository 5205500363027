// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./Devices.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

import DevicesEditAddDialog from "./DevicesEditAddDialog/DevicesEditAddDialog";
import DevicesPreviousUsers from "./DevicesPreviousUsers/DevicesPreviousUsers";

import DevicesServiceHistoryDialog from "./DevicesServiceHistoryDialog/DevicesServiceHistoryDialog";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	j2_name: "J2 Name",
	name: "Name",
	status: "Status",
	model: "Model",
	serial_number: "Serial # / Service Tag",
	current_user: "Current User",
};

const searchDefaultValue = "name";

// OTHER

// REACT COMPONENT
const Devices = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [devices, setDevices] = useState([]);
	const [filteredDevices, setFilteredDevices] = useState([]);

	const [deviceRows, setDeviceRows] = useState([]);
	const [deviceColumns, setDeviceColumns] = useState([]);

	const [employees, setEmployees] = useState([]);
	const [offices, setOffices] = useState([]);
	const [deviceStatuses, setDeviceStatuses] = useState([]);
	const [devicesToEmployees, setDevicesToEmployees] = useState([]);
	const [serviceHistories, setServiceHistories] = useState([]);
	const [incidentTypes, setIncidentTypes] = useState([]);
	const [purchases, setPurchases] = useState([]);
	const [leases, setLeases] = useState([]);
	const [devicesToLeases, setDevicesToLeases] = useState([]);
	const [deviceCategories, setDeviceCategories] = useState([]);

	const [recordToEdit, setRecordToEdit] = useState({});
	const [loading, setLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);
	const [previousUsersOpen, setPreviousUsersOpen] = useState(false);
	const [recordToShowPreviousUsers, setRecordToShowPreviousUsers] = useState(
		{}
	);
	const [serviceHistoryOpen, setServiceHistoryOpen] = useState(false);
	const [recordToShowServiceHistory, setRecordToShowServiceHistory] =
		useState({});

	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredDevices]);

	// INPUT HANDLERS
	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
		handleAPICalls();
	};

	const handleEditAddDialogCloseNoRefresh = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
	};

	const handlePreviousUsersClick = (record) => {
		setRecordToShowPreviousUsers(record);
		setPreviousUsersOpen(true);
	};

	const handlePreviousUsersClose = () => {
		setPreviousUsersOpen(false);
		setRecordToShowPreviousUsers({});
	};

	const handleServiceHistoryClick = (record) => {
		setRecordToShowServiceHistory(record);
		setServiceHistoryOpen(true);
	};

	const handleServiceHistoryClose = () => {
		setServiceHistoryOpen(false);
		setRecordToShowServiceHistory({});
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await getDevices();
		await getEmployees();
		await getOffices();
		await getDeviceStatuses();
		await getDevicesToEmployees();
		await getServiceHistories();
		await getIncidentTypes();
		await getPurchases();
		await getLeases();
		await getDevicesToLeases();
		await getDeviceCategories();
		setLoading(false);
	};

	const getDevices = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/all`
			);
			setDevices(res.data);
			setFilteredDevices(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
		} catch (error) {
			console.error(error);
		}
	};

	const getEmployees = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/employees/all`
			);
			setEmployees(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getOffices = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/employees/offices/all`
			);
			setOffices(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getDeviceStatuses = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/deviceStatuses/all`
			);
			setDeviceStatuses(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getDevicesToEmployees = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/devicesToEmployees/all`
			);
			setDevicesToEmployees(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getServiceHistories = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/serviceHistories/all`
			);
			setServiceHistories(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getIncidentTypes = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/incidentTypes/all`
			);
			setIncidentTypes(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getPurchases = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/purchases/all`
			);
			setPurchases(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getLeases = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/leases/all`
			);
			setLeases(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getDevicesToLeases = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/devicesToLeases/all`
			);
			setDevicesToLeases(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getDeviceCategories = async () => {
		try {
			let res = await getAPICall(
				instance,
				accounts[0],
				`/api/hardwareInventory/deviceCategories/all`
			);
			setDeviceCategories(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	// pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// search
	const filterDeviceRecords = (searchValue, searchByParameter) => {
		let filteredRecords = devices.filter((record) => {
			if (searchByParameter === "name") {
				return record.default_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "j2_name") {
				return record.j2_device_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "serial_number") {
				return record.serial_number
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "model") {
				return record.model
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "status") {
				return record.status_display_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "current_user") {
				const firstName = record.employee_first_name
					? record.employee_first_name.toLowerCase()
					: "";
				const lastName = record.employee_last_name
					? record.employee_last_name.toLowerCase()
					: "";
				const fullName = `${firstName} ${lastName}`;

				return fullName.includes(searchValue.toLowerCase());
			}
		});

		setFilteredDevices(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const createColumnData = () => {
		setDeviceColumns([
			{ text: "J2 Name" },
			{ text: "Name" },
			{ text: "Status" },
			{ text: "Office" },
			{ text: "Model" },
			{ text: "RAM" },
			{ text: "Storage" },
			{ text: "OS" },
			{ text: "Serial # / Service Tag" },
			{ text: "Manufacturer" },
			{ text: "Current User" },
			{ text: "Previous Users" },
			{}, // edit
			{}, // service history
		]);
	};

	const createRowData = () => {
		setDeviceRows(
			filteredDevices.map((device) => [
				{
					text: device.j2_device_name,
					notApplicable: !device.j2_device_name,
				},
				{
					text: device.default_name,
					notApplicable: !device.default_name,
				},
				{
					text: device.status_display_name,
					notApplicable: !device.status_display_name,
				},
				{
					text: device.office_state,
					notApplicable: !device.office_state,
				},
				{
					text: device.model,
					notApplicable: !device.model,
				},
				{
					text: device.ram,
					notApplicable: !device.ram,
				},
				{
					text: device.storage,
					notApplicable: !device.storage,
				},
				{
					text: device.os,
					notApplicable: !device.os,
				},
				{
					text: device.serial_number,
					notApplicable: !device.serial_number,
				},
				{
					text: device.manufacturer,
					notApplicable: !device.manufacturer,
				},
				{
					text:
						device.employee_first_name +
						" " +
						device.employee_last_name,
					notApplicable: !device.employee_id,
				},
				{
					icon: "recentActors",
					onClick: () => handlePreviousUsersClick(device),
				},
				{
					icon: "edit",
					onClick: () => handleEditClick(device),
				},
				{
					icon: "restore",
					onClick: () => handleServiceHistoryClick(device),
				},
			])
		);
	};

	// RENDER
	return (
		<div className="Devices">
			<div className="DevicesHeader">
				<div className="DevicesHeaderTitle">
					Devices
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddClick}
						disabled={loading}
					>
						New Device Record
					</Button>
				</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					searchByParameterMap={searchByParameterMap}
					filterResults={(searchValue, searchByParameter) =>
						filterDeviceRecords(searchValue, searchByParameter)
					}
				/>
			</div>

			{loading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!loading && (
				<div className="tableContainerDiv">
					{filteredDevices.length === 0 && <J2NoResultsFound />}
					{filteredDevices.length > 0 &&
						deviceColumns.length > 0 &&
						deviceRows.length > 0 && (
							<>
								<GenericTable
									columnData={deviceColumns}
									rowData={deviceRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<DevicesEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				onCloseNoRefresh={handleEditAddDialogCloseNoRefresh}
				recordToEdit={recordToEdit}
				employees={employees}
				offices={offices}
				deviceStatuses={deviceStatuses}
				devicesToEmployees={devicesToEmployees}
				editing={editing}
				purchases={purchases}
				leases={leases}
				devicesToLeases={devicesToLeases}
				deviceCategories={deviceCategories}
				devices={devices}
			/>
			<DevicesPreviousUsers
				open={previousUsersOpen}
				onClose={handlePreviousUsersClose}
				recordToShowPreviousUsers={recordToShowPreviousUsers}
				devicesToEmployees={devicesToEmployees}
			/>
			<DevicesServiceHistoryDialog
				open={serviceHistoryOpen}
				onClose={handleServiceHistoryClose}
				recordToShowServiceHistory={recordToShowServiceHistory}
				serviceHistories={serviceHistories}
				incidentTypes={incidentTypes}
				getServiceHistories={getServiceHistories}
			/>
		</div>
	);
};

// EXPORT
export default Devices;
