// DEPENDENCIES
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// MSAL
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from "@azure/msal-react";
import { getAPICall } from "../../config/apiCalls";

// CSS
import "./App.css";
import j2logo from "../../images/j2_logo_full.png";

// CONTEXT
import { UserContext } from "../../context/UserContext";

// MUI COMPONENTS
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";

// CUSTOM COMPONENTS
// Various
import Login from "../Login/Login";
import Navigation from "../Navigation/Navigation";

import J2PageNotFound from "../Misc/J2PageNotFound/J2PageNotFound";

// Proposals
import RFP from "../Proposals/RFP/RFP";
import RFA from "../Proposals/RFA/RFA";
import PendingJobs from "../Proposals/PendingJobs/PendingJobs";
import PendingJobReviewScreen from "../Proposals/PendingJobs/PendingJobReviewScreen/PendingJobReviewScreen";

// Logs
import AccessLog from "../Logs/AccessLog/AccessLog";
import AutomationLog from "../Logs/AutomationLog/AutomationLog";

// Tips & Tricks
import TipsAndTricks from "../TipsAndTricks/TipsAndTricks";
import TipsAndTricksDetails from "../TipsAndTricks/TipsAndTricksDetails/TipsAndTricksDetails";

// Info
import Info from "../Info/Info";

// Admin
import TipsAndTricksConsole from "../Admin/TipsAndTricksConsole/TipsAndTricksConsole";
import CategoryConsole from "../Admin/CategoryConsole/CategoryConsole";
import EmployeeConsole from "../Admin/EmployeeConsole/EmployeeConsole";
import PermissionsConsole from "../Admin/PermissionsConsole/PermissionsConsole";
import RoleConsole from "../Admin/RoleConsole/RoleConsole";
import DashboardAccessConsole from "../Admin/DashboardAccessConsole/DashboardAccessConsole";
import JobTitlesConsole from "../Admin/JobTitlesConsole/JobTitlesConsole";
import InfoConsole from "../Admin/InfoConsole/InfoConsole";
import InfoAccessConsole from "../Admin/InfoAccessConsole/InfoAccessConsole";
import PropertyConsole from "../Admin/PropertyConsole/PropertyConsole";
import DeviceStatusesConsole from "../Admin/DeviceStatusesConsole/DeviceStatusesConsole";
import AssembliesConsole from "../Admin/AssembliesConsole/AssembliesConsole";
import ProjectConsole from "../Admin/ProjectConsole/ProjectConsole";
import PhaseConsole from "../Admin/PhaseConsole/PhaseConsole";

// Tickets
import ITRequest from "../Tickets/ITRequest/ITRequest";
import YourTickets from "../Tickets/YourTickets/YourTickets";
import ActiveTickets from "../Tickets/ActiveTickets/ActiveTickets";
import InactiveTickets from "../Tickets/InactiveTickets/InactiveTickets";

// Detail Library
import DetailLibrary from "../DetailLibrary/DetailLibrary";
import DetailScreen from "../DetailLibrary/DetailScreen/DetailScreen";

// Specifications
import SpecLibrary from "../Specifications/SpecLibrary/SpecLibrary";
import SpecDetailScreen from "../Specifications/SpecLibrary/SpecDetailScreen/SpecDetailScreen";
import DesignJobs from "../Specifications/DesignJobs/DesignJobs";
import DesignJobDetailScreen from "../Specifications/DesignJobs/DesignJobDetailScreen/DesignJobDetailScreen";
import PendingChanges from "../Specifications/PendingChanges/PendingChanges";
import PendingSpecChangeReviewScreen from "../Specifications/PendingChanges/PendingSpecChangeReviewScreen/PendingSpecChangeReviewScreen";
import DesignJobSpecificationEdit from "../Specifications/DesignJobs/DesignJobDetailScreen/DesignJobSpecificationEdit/DesignJobSpecificationEdit";
import SpecificationChangeLog from "../Specifications/SpecificationChangeLog/SpecificationChangeLog";
import DesignJobChangeLog from "../Specifications/DesignJobChangeLog/DesignJobChangeLog";

// CRS
import CoreRepairScopes from "../CoreRepairScopes/CoreRepairScopes";

// Jobs
import Jobs from "../Jobs/Jobs";

// Hardware Inventory
import Devices from "../HardwareInventory/Devices/Devices";
import DeviceLeases from "../HardwareInventory/DeviceLeases/DeviceLeases";
import ServiceHistory from "../HardwareInventory/ServiceHistory/ServiceHistory";

// Dashboards
import AllDashboards from "../Dashboards/AllDashboards/AllDashboards";
import MyDashboards from "../Dashboards/MyDashboards/MyDashboards";

// Profile
import EmailSignature from "../Profile/EmailSignature/EmailSignature";

// CONSTANTS
import { theme } from "../../constants";

// REACT COMPONENT
function App() {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// User States
	const [accessibleTools, setAccessibleTools] = useState([]);

	// Context States
	const [employeeID, setEmployeeID] = useState(0);

	// Feedback States
	const [isLoading, setIsLoading] = useState(false);

	// USE EFFECT
	useEffect(() => {
		if (accounts.length > 0) {
			fetchUserInfo();
		}
	}, [accounts]);

	// FETCH USER INFO FUNCTIONS
	const fetchUserInfo = async () => {
		setIsLoading(true);

		try {
			const idRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/idFromEmail",
				{ email: accounts[0].username }
			);
			setEmployeeID(idRes.data[0].id);

			await fetchAccessibleTools(idRes.data[0].id);
		} catch (error) {
			console.error("App.js - fetchUserInfo - error:", error);

			// Log out user if error
			instance.logoutRedirect({
				postLogoutRedirectUri: "/",
				onRedirectNavigate: (url) => {
					// Return false if you would like to stop navigation after local logout
					return false;
				},
			});

			return null;
		}

		setIsLoading(false);
	};

	const fetchAccessibleTools = async (employeeID) => {
		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/tools",
				{ employeeID: employeeID }
			);
			setAccessibleTools(response.data);

			return;
		} catch (error) {
			console.error("Error fetching accessible tools", error);
		}
	};

	// LOADING
	if (isLoading) {
		return (
			<div className="loading">
				<img alt="j2 logo" src={j2logo} id="loading-logo" />
				<CircularProgress />
			</div>
		);
	}

	// STANDARD
	return (
		<>
			<AuthenticatedTemplate>
				<ThemeProvider theme={theme}>
					<UserContext.Provider value={employeeID}>
						<div className="App" id="app">
							<div className="nav">
								<Navigation accessibleTools={accessibleTools} />
							</div>
							<div className="app">
								<Routes>
									{/* Proposals */}
									<Route path="/" element={<RFP />}></Route>
									<Route
										path="/rfa"
										element={<RFA />}
									></Route>
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Pending Jobs")
									) && (
										<>
											<Route
												path="/jobs/pending"
												element={<PendingJobs />}
											></Route>
											<Route
												path="/jobs/pending/:id"
												element={
													<PendingJobReviewScreen />
												}
											></Route>
										</>
									)}

									{/* Logs */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Logs")
									) && (
										<>
											<Route
												path="/logs/access"
												element={<AccessLog />}
											></Route>
											<Route
												path="/logs/automation"
												element={<AutomationLog />}
											></Route>
										</>
									)}

									{/* Tips & Tricks */}
									<Route
										path="/tips_and_tricks"
										element={<TipsAndTricks />}
									></Route>
									<Route
										path="/tips_and_tricks/:id"
										element={<TipsAndTricksDetails />}
									></Route>

									{/* Info */}
									<Route
										path="/info"
										element={<Info />}
									></Route>

									{/* Admin */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Admin")
									) && (
										<>
											<Route
												path="/admin/info_console"
												element={<InfoConsole />}
											></Route>
											<Route
												path="/admin/info_access_console"
												element={<InfoAccessConsole />}
											></Route>
											<Route
												path="/admin/tips_and_tricks_console"
												element={
													<TipsAndTricksConsole />
												}
											></Route>
											<Route
												path="/admin/category_console"
												element={<CategoryConsole />}
											></Route>
											<Route
												path="/admin/employee_console"
												element={<EmployeeConsole />}
											></Route>
											<Route
												path="/admin/permissions"
												element={<PermissionsConsole />}
											></Route>
											<Route
												path="/admin/role_console"
												element={<RoleConsole />}
											></Route>
											<Route
												path="/admin/dashboard_access_console"
												element={
													<DashboardAccessConsole />
												}
											></Route>
											<Route
												path="/admin/job_titles_console"
												element={<JobTitlesConsole />}
											></Route>
											<Route
												path="/admin/assemblies_console"
												element={<AssembliesConsole />}
											></Route>
											<Route
												path="/admin/property_console"
												element={<PropertyConsole />}
											></Route>
											<Route
												path="/admin/project_console"
												element={<ProjectConsole />}
											></Route>
											<Route
												path="/admin/phase_console"
												element={<PhaseConsole />}
											></Route>
											<Route
												path="/admin/device_status_console"
												element={
													<DeviceStatusesConsole />
												}
											></Route>
										</>
									)}

									{/* IT Request */}
									<Route
										path="/tickets/it_request"
										element={<ITRequest />}
									></Route>
									<Route
										path="/tickets/submitted"
										element={<YourTickets />}
									></Route>
									{Boolean(
										accessibleTools.find(
											(t) => t.name === "Tickets"
										)?.admin_access
									) && (
										<>
											<Route
												path="/tickets/active"
												element={<ActiveTickets />}
											></Route>
											<Route
												path="/tickets/inactive"
												element={<InactiveTickets />}
											></Route>
										</>
									)}

									{/* Detail Library */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Detail Library")
									) && (
										<>
											<Route
												path="/encompass/detail_library"
												element={<DetailLibrary />}
											></Route>
											<Route
												path="/encompass/detail_library/:id"
												element={
													<DetailScreen
														isAdmin={Boolean(
															accessibleTools.find(
																(t) =>
																	t.name ===
																	"Detail Library"
															)?.admin_access
														)}
													/>
												}
											></Route>
										</>
									)}

									{/* Specifications */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Spec Library")
									) && (
										<>
											<Route
												path="/specs/design_jobs"
												element={<DesignJobs />}
											></Route>
											<Route
												path="/specs/design_jobs/:id"
												element={
													<DesignJobDetailScreen />
												}
											></Route>
											<Route
												path="/specs/design_jobs/:id/edit/:specID"
												element={
													<DesignJobSpecificationEdit />
												}
											></Route>
											<Route
												path="/specs/library"
												element={
													<SpecLibrary
														isAdmin={Boolean(
															accessibleTools.find(
																(t) =>
																	t.name ===
																	"Spec Library"
															)?.admin_access
														)}
													/>
												}
											></Route>
											<Route
												path="/specs/library/:id"
												element={
													<SpecDetailScreen
														isAdmin={Boolean(
															accessibleTools.find(
																(t) =>
																	t.name ===
																	"Spec Library"
															)?.admin_access
														)}
													/>
												}
											></Route>
											{Boolean(
												accessibleTools.find(
													(t) =>
														t.name ===
														"Spec Library"
												)?.admin_access
											) && (
												<>
													<Route
														path="/specs/pending_changes"
														element={
															<PendingChanges />
														}
													></Route>
													<Route
														path="/specs/pending_changes/:id"
														element={
															<PendingSpecChangeReviewScreen />
														}
													></Route>
													<Route
														path="/specs/spec_change_log"
														element={
															<SpecificationChangeLog />
														}
													></Route>
													<Route
														path="/specs/design_job_change_log"
														element={
															<DesignJobChangeLog />
														}
													></Route>
												</>
											)}
										</>
									)}

									{/* Core Repair Scopes */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Core Repair Scopes")
									) && (
										<>
											<Route
												path="/encompass/core_repair_scopes"
												element={
													<CoreRepairScopes
														isAdmin={Boolean(
															accessibleTools.find(
																(t) =>
																	t.name ===
																	"Core Repair Scopes"
															)?.admin_access
														)}
													/>
												}
											></Route>
										</>
									)}

									{/* Jobs */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Spec Library")
									) && (
										<>
											<Route
												path="/jobs"
												element={<Jobs />}
											></Route>
										</>
									)}

									{/* Hardware Inventory */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Hardware Inventory")
									) && (
										<>
											<Route
												path="/hardware_inventory/devices"
												element={<Devices />}
											></Route>
											<Route
												path="/hardware_inventory/leases"
												element={<DeviceLeases />}
											></Route>
											<Route
												path="/hardware_inventory/service_history"
												element={<ServiceHistory />}
											></Route>
										</>
									)}

									{/* Dashboards */}
									<Route
										path="/dashboards/all"
										element={<AllDashboards />}
									></Route>
									<Route
										path="/dashboards/mine"
										element={<MyDashboards />}
									></Route>

									{/* Dev */}
									{/* <Route path="/dev" element={<Dev />}></Route> */}

									{/* Profile */}
									{Boolean(
										accessibleTools
											.map((t) => t.name)
											.includes("Email Signature")
									) && (
										<>
											<Route
												path="/profile/signature"
												element={<EmailSignature />}
											></Route>
										</>
									)}

									{/* Page Not Found */}
									<Route
										path="*"
										element={<J2PageNotFound />}
									></Route>
								</Routes>
							</div>
						</div>
					</UserContext.Provider>
				</ThemeProvider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<ThemeProvider theme={theme}>
					<Login />
				</ThemeProvider>
			</UnauthenticatedTemplate>
		</>
	);
}

export default App;
