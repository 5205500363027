// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./TipsAndTricks.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../context/UserContext";

// MUI COMPONENTS
import {
	CircularProgress,
	Button,
	ButtonGroup,
	Collapse,
	IconButton,
} from "@mui/material";

import {
	ArrowDropDown as ArrowDropDownIcon,
	ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import SearchBar from "../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../Misc/J2NoResultsFound/J2NoResultsFound";

import TipsAndTricksPreviewCard from "./TipsAndTricksPreviewCard/TipsAndTricksPreviewCard";

// CONSTANTS
import { toTitleCase } from "../../constants";

const searchByParameterMap = {
	category: "Category",
	title: "Title",
	description: "Description",
};

const searchDefaultValue = "category";

// FUNCTIONAL COMPONENT
const TipsAndTricks = (props) => {
	// INSTANCE
	const { instance, accounts } = useMsal();

	// STATES
	const [tipsAndTricks, setTipsAndTricks] = useState([]); // [ { id, title, description, data, category_id } ]
	const [filteredTipsAndTricks, setFilteredTipsAndTricks] = useState([]); // [ { id, title, description, data, category_id }

	const [categories, setCategories] = useState([]);
	const [expandedCategories, setExpandedCategories] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchTipsAndTricks();
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Tips and Tricks",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the Tips and Tricks page",
		});
	}, []);

	// INPUT HANDLERS
	const toggleCategory = (categoryName) => {
		if (expandedCategories.includes(categoryName)) {
			setExpandedCategories(
				expandedCategories.filter((cat) => cat !== categoryName)
			);
		} else {
			setExpandedCategories([...expandedCategories, categoryName]);
		}
	};

	const collapseAllMenus = () => {
		setExpandedCategories([]);
	};

	const expandAllMenus = () => {
		setExpandedCategories([...categories]);
	};

	// HELPER FUNCTIONS
	const fetchTipsAndTricks = async () => {
		try {
			setIsLoading(true);

			let dataRes = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/formatted"
			);

			setTipsAndTricks(dataRes.data);
			setFilteredTipsAndTricks(dataRes.data);
			createCategoryNameArray(dataRes.data);

			setIsLoading(false);
		} catch (error) {
			console.log(
				"Error in TipsandTricks - fetchTipsAndTricks(): ",
				error
			);
		}
	};

	const createCategoryNameArray = (records) => {
		let categoryNames = new Set();
		records.forEach((tipObj) => {
			if (tipObj.visibility) {
				categoryNames.add(tipObj.category_name);
			}
		});
		setCategories([...categoryNames]);
	};

	const filterTipsAndTricks = (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredTips = tipsAndTricks.filter((tipObj) => {
			if (searchByParameter === "title") {
				return (tipObj.title ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "description") {
				return (tipObj.description ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "category") {
				return (tipObj.category_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		createCategoryNameArray(filteredTips);
		setFilteredTipsAndTricks(filteredTips);

		setIsLoading(false);
	};

	// RENDER
	return (
		<div className="TipsAndTricks">
			<div className="tipsAndTricksHeaderGroup">
				<div className="tipsAndTricksHeader">Tips & Tricks</div>
				<div className="buttonGroupContainer">
					<ButtonGroup>
						<Button
							onClick={collapseAllMenus}
							color="secondary"
							variant="contained"
						>
							Collapse All
						</Button>
						<Button
							onClick={expandAllMenus}
							color="primary"
							variant="contained"
						>
							Expand All
						</Button>
					</ButtonGroup>
				</div>
			</div>
			<SearchBar
				filterResults={(searchValue, searchByParameter) =>
					filterTipsAndTricks(searchValue, searchByParameter)
				}
				searchByParameterMap={searchByParameterMap}
				searchDefaultValue={searchDefaultValue}
			/>
			{isLoading && (
				<div className="loadingSpinnerContainer">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tipsAndTricksContainer">
					{(filteredTipsAndTricks.length === 0 ||
						categories.length === 0) && <J2NoResultsFound />}
					{categories.map((categoryName, index) => (
						<div id="tipsAndTricksPreviewContainer" key={index}>
							<div className="categoryHeader">
								<IconButton
									sx={{ color: "black", float: "left" }}
									onClick={() => toggleCategory(categoryName)}
								>
									{expandedCategories.includes(
										categoryName
									) ? (
										<ArrowDropUpIcon />
									) : (
										<ArrowDropDownIcon />
									)}
								</IconButton>
								<h2 className="categoryTitle">
									{toTitleCase(categoryName)}
								</h2>
							</div>
							<Collapse
								in={expandedCategories.includes(categoryName)}
								timeout="auto"
								unmountOnExit
							>
								{filteredTipsAndTricks
									.filter(
										(tipObj) =>
											tipObj.category_name ===
												categoryName &&
											tipObj.visibility
									)
									.map((record, index) => (
										<TipsAndTricksPreviewCard
											key={index}
											record={record}
										/>
									))}
							</Collapse>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default TipsAndTricks;
