// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DesignJobs.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { SPEC_LIBRARY_RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	jobName: "Job Name",
	property: "Property",
	project: "Project",
	phase: "Phase",
	oic: "OIC",
};

const searchDefaultValue = "jobName";

// OTHER

// REACT COMPONENT
const DesignJobs = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [designJobsRecords, setDesignJobsRecords] = useState([]);
	const [filteredDesignJobsRecords, setFilteredDesignJobsRecords] = useState(
		[]
	);

	const [designJobRows, setDesignJobRows] = useState([]);
	const [designJobColumns, setDesignJobColumns] = useState([]);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] = useState(
		SPEC_LIBRARY_RECORDS_PER_PAGE
	);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		// Fetch Spec Library Records
		fetchDesignJobsRecords();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Design Jobs",
			employeeID: employeeID,
			notes: "Accessed the Design Jobs page",
		});
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredDesignJobsRecords]);

	// INPUT HANDLERS
	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex(
			(currentPage - 1) * SPEC_LIBRARY_RECORDS_PER_PAGE
		);
		setPaginationEndIndex(currentPage * SPEC_LIBRARY_RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchDesignJobsRecords = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/design/all"
			);
			setDesignJobsRecords(res.data);
			setFilteredDesignJobsRecords(res.data);
			setNumberOfPages(
				Math.ceil(res.data.length / SPEC_LIBRARY_RECORDS_PER_PAGE)
			);
		} catch (error) {
			console.log("Error in fetchDesignJobsRecords: ", error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	// Filter records
	const filterDesignJobsRecords = (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredRecords = designJobsRecords.filter((record) => {
			if (searchByParameter === "jobName") {
				return (record.job_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "property") {
				return (record.property_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "project") {
				return (record.project_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "phase") {
				return (record.name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "oic") {
				return (record.oic ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredDesignJobsRecords(filteredRecords);
		setNumberOfPages(
			Math.ceil(filteredRecords.length / SPEC_LIBRARY_RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(SPEC_LIBRARY_RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setDesignJobColumns([
			{ text: "Job Name" },
			{ text: "PM" },
			{ text: "OIC" },
		]);
	};

	const createRowData = () => {
		setDesignJobRows(
			filteredDesignJobsRecords.map((record) => [
				{
					text: (
						// note: using <a> tag instead of link and onClick props so on ctrl+click it opens in new tab
						<a href={`/specs/design_jobs/${record.id}`}>
							{record.job_name}
						</a>
					),
				},
				{ text: record.project_manager },
				{ text: record.oic },
			])
		);
	};

	// RENDER
	return (
		<div className="DesignJobs">
			<div className="designJobsHeader">
				<div className="designJobsHeaderTitle">Design Jobs</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					searchByParameterMap={searchByParameterMap}
					filterResults={(searchValue, searchByParameter) =>
						filterDesignJobsRecords(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredDesignJobsRecords.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredDesignJobsRecords.length > 0 &&
						designJobColumns.length > 0 &&
						designJobRows.length > 0 && (
							<>
								<GenericTable
									columnData={designJobColumns}
									rowData={designJobRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
		</div>
	);
};

// EXPORT
export default DesignJobs;
