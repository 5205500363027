// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./GeneralInformationCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	ButtonGroup,
	Collapse,
	Divider,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import {
	Check as CheckIcon,
	Close as CloseIcon,
	Edit as EditIcon,
	ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS
import { zipCodeRegex, integerRegex } from "../../../../constants";

// REACT COMPONENT
const GeneralInformationCard = (props) => {
	// STATES
	const [editGeneralInformation, setEditGeneralInformation] = useState(false);

	const [componentAlertOpen, setComponentAlertOpen] = useState(false);
	const [phaseAlertOpen, setPhaseAlertOpen] = useState(false);

	// CONTEXT

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS
	// General
	// These two don't really do anything, initially I had a "lock" on the general information card to prevent accidental changes.
	// The editGeneralInformation flag just disables/enables all general information input fields
	const editGeneralInformationClicked = () => {
		setEditGeneralInformation(true);
	};

	const saveGeneralInformationClicked = () => {
		setEditGeneralInformation(false);
	};

	// Property
	const propertyInfoChanged = (event) => {
		props.setPropertyInfo((prevInfo) => ({
			...prevInfo,
			[event.target.name]: event.target.value,
		}));
	};

	// Project
	const projectInfoChanged = (event) => {
		props.setProjectInfo((prevInfo) => ({
			...prevInfo,
			[event.target.name]: event.target.value,
		}));
	};

	const componentSelected = (event, newValue) => {
		// If the user tries to select more than two, don't change it and show an alert
		if (newValue.length > 2) {
			setComponentAlertOpen(true);
			return;
		} else {
			setComponentAlertOpen(false);
		}

		props.setSelectedComponents([...newValue]);

		// Change the project name and display name to reflect the selected components
		props.setProjectInfo((prevInfo) => ({
			...prevInfo,
			name: newValue.map((component) => component.name).join(", "),
			displayName: newValue
				.map((component) => component.abbreviated_name)
				.join(", "),
		}));
	};

	// Phase(s)
	const phaseBudgetsChanged = (event) => {
		props.setPhaseBudgets((prevBudgets) => ({
			...prevBudgets,
			[event.target.name]: event.target.value,
		}));
	};

	const phaseCostBasisChanged = (event) => {
		props.setPhaseCostBases((prevCostBases) => ({
			...prevCostBases,
			[event.target.name]: event.target.value,
		}));
	};

	const onePhaseClicked = () => {
		props.setNumberOfPhases(1);

		// Clear out the other phases
		props.setPhase2({});
		props.setPhase3({});
		props.setPhase4({});

		props.setPhaseCostBases((prevCostBases) => ({
			...prevCostBases,
			phase2: "",
			phase3: "",
			phase4: "",
		}));
	};

	const twoPhasesClicked = () => {
		props.setNumberOfPhases(2);

		props.setPhase3({});
		props.setPhase4({});

		props.setPhaseCostBases((prevCostBases) => ({
			...prevCostBases,
			phase3: "",
			phase4: "",
		}));
	};

	const threePhasesClicked = () => {
		props.setNumberOfPhases(3);

		props.setPhase4({});

		props.setPhaseCostBases((prevCostBases) => ({
			...prevCostBases,
			phase4: "",
		}));
	};

	const fourPhasesClicked = () => {
		props.setNumberOfPhases(4);
	};

	const phase1Selected = (event) => {
		// If the user tries to select the same phase as another phase, don't change it and show an alert
		if (
			event.target.value == props.phase2.id ||
			event.target.value == props.phase3.id ||
			event.target.value == props.phase4.id
		) {
			setPhaseAlertOpen(true);
			return;
		}

		// Find the phase object that matches the selected value
		let phase = props.allPhases.find(
			(phase) => phase.id === event.target.value
		);
		props.setPhase1(phase);
	};

	const phase2Selected = (event) => {
		// If the user tries to select the same phase as another phase, don't change it and show an alert
		if (
			event.target.value == props.phase1.id ||
			event.target.value == props.phase3.id ||
			event.target.value == props.phase4.id
		) {
			setPhaseAlertOpen(true);
			return;
		}

		// Find the phase object that matches the selected value
		let phase = props.allPhases.find(
			(phase) => phase.id === event.target.value
		);
		props.setPhase2(phase);
	};

	const phase3Selected = (event) => {
		// If the user tries to select the same phase as another phase, don't change it and show an alert
		if (
			event.target.value == props.phase1.id ||
			event.target.value == props.phase2.id ||
			event.target.value == props.phase4.id
		) {
			setPhaseAlertOpen(true);
			return;
		}

		// Find the phase object that matches the selected value
		let phase = props.allPhases.find(
			(phase) => phase.id === event.target.value
		);
		props.setPhase3(phase);
	};

	const phase4Selected = (event) => {
		// If the user tries to select the same phase as another phase, don't change it and show an alert
		if (
			event.target.value == props.phase1.id ||
			event.target.value == props.phase2.id ||
			event.target.value == props.phase3.id
		) {
			setPhaseAlertOpen(true);
			return;
		}

		// Find the phase object that matches the selected value
		let phase = props.allPhases.find(
			(phase) => phase.id === event.target.value
		);
		props.setPhase4(phase);
	};

	// Deal Owner
	const dealOwnerSelected = (event, newValue) => {
		props.setDealOwner(newValue);
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="GeneralInformationCard">
			<div className="generalInformationCardHeader">
				<h1>General Information</h1>
				{!editGeneralInformation && (
					<IconButton
						aria-label="edit"
						onClick={editGeneralInformationClicked}
					>
						<EditIcon fontSize="small" sx={{ color: "#3C4142" }} />
					</IconButton>
				)}
				{editGeneralInformation && (
					<div className="generalInformationCardHeaderButtons">
						<Button
							variant="contained"
							color="primary"
							onClick={saveGeneralInformationClicked}
						>
							Done
						</Button>
					</div>
				)}
			</div>
			<div className="generalInformationCardSubheader">
				<h1>Property Information</h1>
			</div>
			<div className="generalInformationCardRow">
				<TextField
					sx={{ flex: 1 }}
					label="Property Name"
					disabled={!editGeneralInformation}
					value={props.propertyInfo.name}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{props.propertyInfo.name ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						),
					}}
					name="name"
					onChange={propertyInfoChanged}
				/>
			</div>
			<div className="generalInformationCardRow">
				<TextField
					sx={{ flex: 2 }}
					label="Address Line 1"
					disabled={!editGeneralInformation}
					value={props.propertyInfo.addressLine1}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{props.propertyInfo.addressLine1 ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						),
					}}
					name="addressLine1"
					onChange={propertyInfoChanged}
				/>
				<TextField
					sx={{ flex: 1 }}
					label="Address Line 2"
					disabled={!editGeneralInformation}
					value={props.propertyInfo.addressLine2}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<CheckIcon color="success" size={20} />
							</InputAdornment>
						),
					}}
					name="addressLine2"
					onChange={propertyInfoChanged}
				/>
			</div>
			<div className="generalInformationCardRow">
				<TextField
					sx={{ flex: 1 }}
					label="Property City"
					disabled={!editGeneralInformation}
					value={props.propertyInfo.city}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{props.propertyInfo.city ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						),
					}}
					name="city"
					onChange={propertyInfoChanged}
				/>
				<FormControl
					sx={{ flex: 1 }}
					disabled={!editGeneralInformation}
				>
					<InputLabel id="propertyStateLabel">
						Property State
					</InputLabel>
					<Select
						labelId="propertyStateLabel"
						value={props.propertyInfo.state}
						disabled={!editGeneralInformation}
						label="Property State"
						startAdornment={
							<InputAdornment position="start">
								{props.propertyInfo.state ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						}
						name="state"
						onChange={propertyInfoChanged}
					>
						<MenuItem value="Washington">Washington</MenuItem>
						<MenuItem value="Oregon">Oregon</MenuItem>
						<MenuItem value="Utah">Utah</MenuItem>
						<MenuItem value="Idaho">Idaho</MenuItem>
					</Select>
				</FormControl>
				<TextField
					sx={{ flex: 1 }}
					label="Property Zip"
					disabled={!editGeneralInformation}
					value={props.propertyInfo.zip}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{props.propertyInfo.zip &&
								props.propertyInfo.zip.match(zipCodeRegex) ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						),
					}}
					name="zip"
					onChange={propertyInfoChanged}
				/>
			</div>
			<Divider sx={{ width: "stretch" }} />
			<div className="generalInformationCardSubheader">
				<h1>Project Information</h1>
			</div>
			<div className="generalInformationCardAlertSubheader">
				<h2>Components</h2>
				<Collapse
					sx={{ width: "stretch" }}
					id="generalInformationCardComponentCollapse"
					in={componentAlertOpen}
				>
					<div className="generalInformationCardAlertContainer">
						<Alert
							id="generalInformationCardComponentAlert"
							severity="warning"
							action={
								<IconButton
									id="generalInformationCardComponentAlertCloseIconButton"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => setComponentAlertOpen(false)}
								>
									<CloseIcon
										id="generalInfoComponentAlertCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							}
						>
							You may select up to 2 components.
						</Alert>
					</div>
				</Collapse>
			</div>
			<div className="generalInformationCardRow">
				<Autocomplete
					sx={{ flex: 1 }}
					limitTags={2}
					onChange={componentSelected}
					multiple
					value={props.selectedComponents}
					id="componentSelect"
					disabled={!editGeneralInformation}
					options={props.allComponents.filter(
						(component) =>
							!props.selectedComponents
								.map((c) => c.id)
								.includes(component.id)
					)}
					getOptionLabel={(option) => option.name}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							label="Components"
							placeholder="Components"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										{props.selectedComponents.length > 0 ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			</div>
			<div className="generalInformationCardAlertSubheader">
				<h2>OIC</h2>
			</div>
			<div className="generalInformationCardRow">
				<FormControl
					sx={{ flex: 1 }}
					disabled={!editGeneralInformation}
				>
					<InputLabel id="oicLabel">OIC</InputLabel>
					<Select
						labelId="oicLabel"
						value={props.projectInfo.oic}
						disabled={!editGeneralInformation}
						label="OIC"
						startAdornment={
							<InputAdornment position="start">
								{props.projectInfo.oic ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						}
						name="oic"
						onChange={projectInfoChanged}
					>
						<MenuItem value="WA">WA</MenuItem>
						<MenuItem value="OR">OR</MenuItem>
						<MenuItem value="UT">UT</MenuItem>=
					</Select>
				</FormControl>
			</div>
			<Divider sx={{ width: "stretch" }} />
			<div className="generalInformationCardAlertSubheader">
				<h1>Phase Information</h1>
				<Collapse
					sx={{ width: "stretch" }}
					id="generalInformationCardPhaseCollapse"
					in={phaseAlertOpen}
				>
					<div className="generalInformationCardAlertContainer">
						<Alert
							id="generalInformationCardPhaseAlert"
							severity="warning"
							action={
								<IconButton
									id="generalInformationCardPhaseAlertCloseIconButton"
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => setPhaseAlertOpen(false)}
								>
									<CloseIcon
										id="generalInfoPhaseAlertCloseIcon"
										fontSize="inherit"
									/>
								</IconButton>
							}
						>
							You cannot select duplicate phases.
						</Alert>
					</div>
				</Collapse>
			</div>
			<div className="numberOfPhasesContainer">
				<h3>Number of Phases</h3>
				<ButtonGroup>
					<Button
						onClick={onePhaseClicked}
						variant={
							props.numberOfPhases === 1
								? "contained"
								: "outlined"
						}
						disabled={!editGeneralInformation}
					>
						One
					</Button>
					<Button
						onClick={twoPhasesClicked}
						variant={
							props.numberOfPhases === 2
								? "contained"
								: "outlined"
						}
						disabled={!editGeneralInformation}
					>
						Two
					</Button>
					<Button
						onClick={threePhasesClicked}
						variant={
							props.numberOfPhases === 3
								? "contained"
								: "outlined"
						}
						disabled={!editGeneralInformation}
					>
						Three
					</Button>
					<Button
						onClick={fourPhasesClicked}
						variant={
							props.numberOfPhases === 4
								? "contained"
								: "outlined"
						}
						disabled={!editGeneralInformation}
					>
						Four
					</Button>
				</ButtonGroup>
			</div>
			<div className="pendingJobReviewScreenPhaseContainer">
				<FormControl
					sx={{ flex: 4 }}
					disabled={!editGeneralInformation}
				>
					<InputLabel id="phase1Label">Phase 1</InputLabel>
					<Select
						labelId="phase1Label"
						value={props.phase1.id}
						disabled={!editGeneralInformation}
						label="Phase 1"
						onChange={phase1Selected}
						startAdornment={
							<InputAdornment position="start">
								{JSON.stringify(props.phase1) !== "{}" ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						}
					>
						{props.allPhases.map((phase) => (
							<MenuItem key={phase.id} value={phase.id}>
								{phase.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl
					sx={{ flex: 1 }}
					disabled={!editGeneralInformation}
				>
					<InputLabel id="phase1CostBasisLabel">
						Phase 1 Cost Basis
					</InputLabel>
					<Select
						labelId="phase1CostBasisLabel"
						value={props.phaseCostBases.phase1}
						disabled={!editGeneralInformation}
						label="Phase 1 Cost Basis"
						name="phase1"
						onChange={phaseCostBasisChanged}
						startAdornment={
							<InputAdornment position="start">
								{props.phaseCostBases.phase1 != "" ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						}
					>
						<MenuItem value="flatFee">Flat Fee</MenuItem>
						<MenuItem value="timeAndCost">Time & Cost</MenuItem>
					</Select>
				</FormControl>
				<TextField
					sx={{ flex: 1 }}
					label="Budget"
					disabled={!editGeneralInformation}
					value={props.phaseBudgets.phase1}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">$</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="start">
								{props.phaseBudgets.phase1 != "0" &&
								props.phaseBudgets.phase1.match(integerRegex) !=
									null ? (
									<CheckIcon color="success" size={20} />
								) : (
									<ErrorOutlineIcon color="error" size={20} />
								)}
							</InputAdornment>
						),
					}}
					name="phase1"
					onChange={phaseBudgetsChanged}
				/>
			</div>
			{props.numberOfPhases > 1 && (
				<div className="pendingJobReviewScreenPhaseContainer">
					<FormControl
						sx={{ flex: 4 }}
						disabled={!editGeneralInformation}
					>
						<InputLabel id="phase2Label">Phase 2</InputLabel>
						<Select
							labelId="phase2Label"
							value={props.phase2.id}
							disabled={!editGeneralInformation}
							label="Phase 2"
							onChange={phase2Selected}
							startAdornment={
								<InputAdornment position="start">
									{JSON.stringify(props.phase2) !== "{}" ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							}
						>
							{props.allPhases.map((phase) => (
								<MenuItem key={phase.id} value={phase.id}>
									{phase.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl
						sx={{ flex: 1 }}
						disabled={!editGeneralInformation}
					>
						<InputLabel id="phase2CostBasisLabel">
							Phase 2 Cost Basis
						</InputLabel>
						<Select
							labelId="phase2CostBasisLabel"
							value={props.phaseCostBases.phase2}
							disabled={!editGeneralInformation}
							label="Phase 2 Cost Basis"
							name="phase2"
							onChange={phaseCostBasisChanged}
							startAdornment={
								<InputAdornment position="start">
									{props.phaseCostBases.phase2 != "" ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							}
						>
							<MenuItem value="flatFee">Flat Fee</MenuItem>
							<MenuItem value="timeAndCost">Time & Cost</MenuItem>
						</Select>
					</FormControl>
					<TextField
						sx={{ flex: 1 }}
						label="Budget"
						disabled={!editGeneralInformation}
						value={props.phaseBudgets.phase2}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="start">
									{props.phaseBudgets.phase2 != "0" &&
									props.phaseBudgets.phase2.match(
										integerRegex
									) != null ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							),
						}}
						name="phase2"
						onChange={phaseBudgetsChanged}
					/>
				</div>
			)}
			{props.numberOfPhases > 2 && (
				<div className="pendingJobReviewScreenPhaseContainer">
					<FormControl
						sx={{ flex: 4 }}
						disabled={!editGeneralInformation}
					>
						<InputLabel id="phase3Label">Phase 3</InputLabel>
						<Select
							labelId="phase3Label"
							value={props.phase3.id}
							disabled={!editGeneralInformation}
							label="Phase 3"
							onChange={phase3Selected}
							startAdornment={
								<InputAdornment position="start">
									{JSON.stringify(props.phase3) !== "{}" ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							}
						>
							{props.allPhases.map((phase) => (
								<MenuItem key={phase.id} value={phase.id}>
									{phase.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl
						sx={{ flex: 1 }}
						disabled={!editGeneralInformation}
					>
						<InputLabel id="phase3CostBasisLabel">
							Phase 3 Cost Basis
						</InputLabel>
						<Select
							labelId="phase3CostBasisLabel"
							value={props.phaseCostBases.phase3}
							disabled={!editGeneralInformation}
							label="Phase 3 Cost Basis"
							name="phase3"
							onChange={phaseCostBasisChanged}
							startAdornment={
								<InputAdornment position="start">
									{props.phaseCostBases.phase3 != "" ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							}
						>
							<MenuItem value="flatFee">Flat Fee</MenuItem>
							<MenuItem value="timeAndCost">Time & Cost</MenuItem>
						</Select>
					</FormControl>
					<TextField
						sx={{ flex: 1 }}
						label="Budget"
						disabled={!editGeneralInformation}
						value={props.phaseBudgets.phase3}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="start">
									{props.phaseBudgets.phase3 != "0" &&
									props.phaseBudgets.phase3.match(
										integerRegex
									) != null ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							),
						}}
						name="phase3"
						onChange={phaseBudgetsChanged}
					/>
				</div>
			)}
			{props.numberOfPhases > 3 && (
				<div className="pendingJobReviewScreenPhaseContainer">
					<FormControl
						sx={{ flex: 4 }}
						disabled={!editGeneralInformation}
					>
						<InputLabel id="phase4Label">Phase 4</InputLabel>
						<Select
							labelId="phase4Label"
							value={props.phase4.id}
							disabled={!editGeneralInformation}
							label="Phase 4"
							onChange={phase4Selected}
							startAdornment={
								<InputAdornment position="start">
									{JSON.stringify(props.phase4) !== "{}" ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							}
						>
							{props.allPhases.map((phase) => (
								<MenuItem key={phase.id} value={phase.id}>
									{phase.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl
						sx={{ flex: 1 }}
						disabled={!editGeneralInformation}
					>
						<InputLabel id="phase4CostBasisLabel">
							Phase 4 Cost Basis
						</InputLabel>
						<Select
							labelId="phase4CostBasisLabel"
							value={props.phaseCostBases.phase4}
							disabled={!editGeneralInformation}
							label="Phase 4 Cost Basis"
							name="phase4"
							onChange={phaseCostBasisChanged}
							startAdornment={
								<InputAdornment position="start">
									{props.phaseCostBases.phase4 != "" ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							}
						>
							<MenuItem value="flatFee">Flat Fee</MenuItem>
							<MenuItem value="timeAndCost">Time & Cost</MenuItem>
						</Select>
					</FormControl>
					<TextField
						sx={{ flex: 1 }}
						label="Budget"
						disabled={!editGeneralInformation}
						value={props.phaseBudgets.phase4}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="start">
									{props.phaseBudgets.phase4 != "0" &&
									props.phaseBudgets.phase4.match(
										integerRegex
									) != null ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							),
						}}
						name="phase4"
						onChange={phaseBudgetsChanged}
					/>
				</div>
			)}
			<Divider sx={{ width: "stretch" }} />
			<div className="generalInformationCardSubheader">
				<h1>Deal Owner</h1>
			</div>
			<div className="generalInformationCardRow">
				<Autocomplete
					sx={{ flex: 1 }}
					onChange={dealOwnerSelected}
					disabled={!editGeneralInformation}
					id="dealOwnerSelect"
					options={props.activeEmployees}
					value={props.dealOwner}
					getOptionLabel={(option) =>
						option.first_name && option.last_name
							? `${option.first_name} ${option.last_name}`
							: ""
					}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment position="start">
										{props.dealOwner &&
										JSON.stringify(props.dealOwner) !==
											"{}" ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
							disabled={!editGeneralInformation}
							label="Deal Owner"
							placeholder="Deal Owner"
						/>
					)}
				/>
			</div>
		</div>
	);
};

export default GeneralInformationCard;
