import React, { useState } from "react";

// CSS
import "./AssembliesGroupingsSearchBar.css";

// MUI COMPONENTS
import {
	Autocomplete,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
} from "@mui/material";

// CONSTANTS
const searchByParameterMap = {
	title: "Title",
	id: "ID",
	assembliesGroupings: "Assemblies / Groupings",
};

// FUNCTIONAL COMPONENT
const AssembliesGroupingsSearchBar = (props) => {
	// STATES
	const [selectedAssemblies, setSelectedAssemblies] = useState([]);
	const [selectedGroupings, setSelectedGroupings] = useState([]);

	const [searchByParameter, setSearchByParameter] = useState(
		"assembliesGroupings"
	);

	// INPUT HANDLERS
	const assemblySelected = (event, newValue) => {
		setSelectedAssemblies([...newValue]);
		props.filterAssemblyGroupingsSearchBar(
			[...newValue],
			selectedGroupings
		);
	};

	const groupingSelected = (event, newValue) => {
		setSelectedGroupings([...newValue]);
		props.filterAssemblyGroupingsSearchBar(selectedAssemblies, [
			...newValue,
		]);
	};

	const searchByParameterSelected = (event) => {
		setSearchByParameter(event.target.value);

		if (event.target.value !== "assembliesGroupings") {
			setSelectedAssemblies([]);
			setSelectedGroupings([]);

			setSearchByParameter("assembliesGroupings");
			props.assembliesGroupingsUnselected();
		}
	};

	// RENDER
	return (
		<div className="AssembliesGroupingsSearchBar">
			<div className="assembliesGroupingsSearchBarFieldsContainer">
				<Autocomplete
					sx={{ flex: 1 }}
					limitTags={3}
					onChange={assemblySelected}
					multiple
					value={selectedAssemblies}
					id="assemblySelect"
					options={props.assemblies.filter(
						(assembly) =>
							!selectedAssemblies
								.map((a) => a.id)
								.includes(assembly.id)
					)}
					getOptionLabel={(option) => option.name}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							label="Assemblies"
							placeholder="Assemblies"
						/>
					)}
				/>
				<Autocomplete
					sx={{ flex: 1 }}
					limitTags={3}
					onChange={groupingSelected}
					multiple
					value={selectedGroupings}
					id="groupingSelect"
					options={props.groupings.filter(
						(grouping) =>
							!selectedGroupings
								.map((g) => g.id)
								.includes(grouping.id)
					)}
					getOptionLabel={(option) => option.name}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							label="Groupings"
							placeholder="Groupings"
						/>
					)}
				/>
				<FormControl sx={{ minWidth: "10vw" }}>
					<InputLabel id="searchByLabel">Search By</InputLabel>
					<Select
						labelId="searchByLabel"
						value={searchByParameter}
						label="Search By"
						onChange={searchByParameterSelected}
					>
						{Object.keys(searchByParameterMap).map((key) => (
							<MenuItem value={key}>
								{searchByParameterMap[key]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};

export default AssembliesGroupingsSearchBar;
