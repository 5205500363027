// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DeviceStatusesConsoleEditAddDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall, putAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// MUI ICONS
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const DeviceStatusesConsoleEditAddDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(true);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	const [name, setName] = useState("");
	const [displayName, setDisplayName] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.editing) {
			handlePopulateFieldsOnEdit();
		} else {
			setLoading(false);
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS

	const nameChanged = (event) => {
		setName(event.target.value);
	};

	const displayNameChanged = (event) => {
		setDisplayName(event.target.value);
	};

	// API FUNCTIONS
	const handlePopulateFieldsOnEdit = () => {
		setLoading(true);
		setName(props.recordToEdit.name);
		setDisplayName(props.recordToEdit.display_name);
		setLoading(false);
	};

	const handleAddDeviceStatus = async () => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/addDeviceStatus",
				{
					name: name,
					display_name: displayName,
					modified_by: employeeID,
				}
			);
		} catch (error) {
			console.error(error);
		}
	};

	const handleUpdateDeviceStatus = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/hardwareInventory/updateDeviceStatus",
				{
					id: props.recordToEdit.id,
					name: name,
					display_name: displayName,
					modified_by: employeeID,
				}
			);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	const handleSubmitClick = async () => {
		if (!validateInputFields()) return;
		setLoading(true);
		if (props.editing) {
			await handleUpdateDeviceStatus();
		} else {
			await handleAddDeviceStatus();
		}
		resetInputFields();
		props.onClose();
	};

	const handleCancelClick = () => {
		resetInputFields();
		props.onClose();
	};

	const resetInputFields = () => {
		setName("");
		setDisplayName("");
		setAlertMessage("");
		setAlertOpen(false);
		setLoading(false);
	};

	const validateInputFields = () => {
		if (name === "") {
			setAlertMessage("Name is required.");
			setAlertOpen(true);
			return false;
		}
		if (displayName === "") {
			setAlertMessage("Display Name is required.");
			setAlertOpen(true);
			return false;
		}
		return true;
	};

	// RENDER
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Dialog
				id="DeviceStatusesConsoleEditAddDialog"
				open={props.open}
				maxWidth="xl"
				fullWidth
				onClose={handleCancelClick}
			>
				<DialogTitle id="DeviceStatusesConsoleEditAddDialogTitle">
					{/* edit / add */}
					{props.editing ? "Edit Device Status" : "Add Device Status"}
				</DialogTitle>

				{loading && (
					<div className="deviceStatusesConsoleLoadingSpinner">
						<CircularProgress />
					</div>
				)}
				{!loading && (
					<DialogContent id="DeviceStatusesConsoleEditAddDialogContent">
						{alertOpen && (
							<Alert
								severity="warning"
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setAlertOpen(false);
										}}
									>
										<CloseIcon />
									</IconButton>
								}
							>
								{alertMessage}
							</Alert>
						)}

						<TextField
							id="name"
							label="Name"
							variant="outlined"
							value={name}
							onChange={nameChanged}
						/>

						<TextField
							id="display_name"
							label="Display Name"
							variant="outlined"
							value={displayName}
							onChange={displayNameChanged}
						/>

						<div className="editDeviceStatusesConsoleButtons">
							<Button
								variant="outlined"
								color="error"
								onClick={handleCancelClick}
								disabled={loading}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmitClick}
								disabled={loading}
							>
								Submit
							</Button>
						</div>
					</DialogContent>
				)}
			</Dialog>
		</LocalizationProvider>
	);
};

// EXPORT
export default DeviceStatusesConsoleEditAddDialog;
