// DEPENDENCIES
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS
import "./SpecTextEditorInfo.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../../config/apiCalls";

// CONTEXT

// MUI COMPONENTS
import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@mui/material";

// MUI ICONS
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const SpecTextEditorInfo = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(false);
	const [tipsAndTricks, setTipsAndTricks] = useState([]);

	// NAVIGATE
	const navigate = useNavigate();

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, [props.open]);

	// API CALLS
	const handleAPICalls = async () => {
		setLoading(true);
		let categories = await getAllCategories();
		// search for category with name "spec library"
		let specLibraryCategory = categories.find(
			// TODO: eventually shouldnt be hardcoded
			(category) => category.name === "spec library"
		);
		if (specLibraryCategory) {
			await getTipsAndTricksByCategory(specLibraryCategory.id);
		}
		setLoading(false);
	};

	const getAllCategories = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/categories/all"
			);
			return res.data;
		} catch (error) {
			console.log(error);
		}
	};

	const getTipsAndTricksByCategory = async (categoryID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/tipsAndTricks/byCategory/${categoryID}`
			);
			// exclude records with visibility set to false (0)
			res.data = res.data.filter((record) => record.visibility);
			setTipsAndTricks(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	// INPUT HANDLERS
	const OpenTipsAndTricksPageClickHandler = (tipsAndTrick) => {
		window.open(`/tips_and_tricks/${tipsAndTrick.id}`, "_blank");
	};

	// RENDER
	return (
		<>
			<Dialog
				id="SpecTextEditorInfoDialog"
				open={props.open}
				onClose={props.handleHelpDialogClose}
				fullWidth
			>
				<DialogTitle id="SpecTextEditorInfoDialogTitle">
					Help
				</DialogTitle>
				<DialogContent id="SpecTextEditorInfoDialogContent">
					{!loading ? (
						<>
							{tipsAndTricks.map((tipOrTrick, index) => (
								<div
									className="infoItem"
									style={{
										borderBottom: "1px solid #e0e0e0",
										paddingBottom: "1em",
									}}
								>
									<div className="infoItemText">
										<div className="infoItemTitle">
											{tipOrTrick.title}
										</div>
										<div className="infoItemSubtitle">
											{tipOrTrick.description}
										</div>
									</div>
									<div className="infoItemIcons">
										<IconButton
											size="small"
											color="secondary"
											onClick={() =>
												OpenTipsAndTricksPageClickHandler(
													tipOrTrick
												)
											}
										>
											<OpenInNewIcon />
										</IconButton>
									</div>
								</div>
							))}
						</>
					) : (
						<div className="loadingDiv">
							<CircularProgress color="primary" />
						</div>
					)}
					<div className="SpecTextEditorInfoButton">
						<Button
							variant="contained"
							onClick={props.handleHelpDialogClose}
						>
							Close
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

// EXPORT
export default SpecTextEditorInfo;
