import axios from "axios";
import { loginRequest } from "./authConfig";

export const getAPICall = async (instance, account, endpoint, data = null) => {
	const tokenRes = await instance.acquireTokenSilent({
		...loginRequest,
		account: account,
	});

	const options = {
		headers: data
			? {
					Authorization: `Bearer ${tokenRes.accessToken}`,
					"Content-Type": "application/json",
			  }
			: {
					Authorization: `Bearer ${tokenRes.accessToken}`,
			  },
		params: data ?? null,
	};

	const res = await axios.get(endpoint, options);

	return res;
};

export const putAPICall = async (
	instance,
	account,
	endpoint,
	data,
	modifiedBy = null
) => {
	const tokenRes = await instance.acquireTokenSilent({
		...loginRequest,
		account: account,
	});

	data.modifiedBy = modifiedBy;

	const options = {
		headers: {
			Authorization: `Bearer ${tokenRes.accessToken}`,
			"Content-Type": "application/json",
		},
	};

	const res = await axios.put(endpoint, data, options);

	return res;
};

export const postAPICall = async (
	instance,
	account,
	endpoint,
	data,
	modifiedBy = null
) => {
	const tokenRes = await instance.acquireTokenSilent({
		...loginRequest,
		account: account,
	});

	if (!data.modifiedBy) {
		data.modifiedBy = modifiedBy;
	}

	const options = {
		headers: {
			Authorization: `Bearer ${tokenRes.accessToken}`,
			"Content-Type": "application/json",
		},
	};

	const res = await axios.post(endpoint, data, options);

	return res;
};

export const postFormDataAPICall = async (
	instance,
	account,
	endpoint,
	formData
) => {
	const tokenRes = await instance.acquireTokenSilent({
		...loginRequest,
		account: account,
	});

	const options = {
		headers: {
			Authorization: `Bearer ${tokenRes.accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const res = await axios.post(endpoint, formData, options);

	return res;
};

export const deleteAPICall = async (
	instance,
	account,
	endpoint,
	data = null
) => {
	const tokenRes = await instance.acquireTokenSilent({
		...loginRequest,
		account: account,
	});

	const options = {
		headers: {
			Authorization: `Bearer ${tokenRes.accessToken}`,
		},
		data: data,
	};

	const res = await axios.delete(endpoint, options);

	return res;
};
