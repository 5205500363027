// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./AreYouSureRemoveSpecDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
// import { getAPICall, postAPICall } from "../../../../../../config/apiCalls";

// CONTEXT
// import { UserContext } from "../../../../../../context/UserContext";

// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import TextField from "@mui/material/TextField";

import Alert from "@mui/material/Alert";

import Collapse from "@mui/material/Collapse";

import CircularProgress from "@mui/material/CircularProgress";

// MUI ICONS
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

// CUSTOM COMPONENTS
import SpecComment from "../../../../SpecLibrary/SpecDetailScreen/CommentsCard/SpecComment/SpecComment";
import J2NoResultsFound from "../../../../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS

// OTHER

// REACT COMPONENT
const AreYouSureRemoveSpecDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Content

	// Feedback

	// CONTEXT
	// const employeeID = useContext(UserContext);

	// USE EFFECT

	// INPUT HANDLERS
	const confirmButtonClicked = () => {
		props.formClosed();
		props.saveButtonClicked();
	};
	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<Dialog
			id="areYouSureRemoveSpecDialog"
			open={props.open}
			fullWidth
			onClose={props.formClosed}
		>
			<DialogTitle id="areYouSureRemoveSpecDialogTitle">
				Warning
			</DialogTitle>
			<DialogContent id="areYouSureRemoveSpecDialogContent">
				<div className="areYouSureRemoveSpecDialogRow">
					Are you sure you want to save your changes? These locally
					edited specs will be permanently removed:
				</div>
				<div className="areYouSureRemoveSpecDialogSpecNameContainer">
					{props.areYouSureRemovedSpecs.map((spec) => (
						<div className="areYouSureRemoveSpecDialogSpecName">
							<div className="areYouSureRemoveSpecDialogSpecNameDivision">
								{spec.division}
							</div>
							<div className="areYouSureRemoveSpecDialogSpecNameTitle">
								{spec.title}
							</div>
						</div>
					))}
				</div>
				<div className="dialogButtons">
					<Button
						onClick={props.formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button
						onClick={confirmButtonClicked}
						variant="contained"
						color="primary"
					>
						Confirm
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// EXPORT
export default AreYouSureRemoveSpecDialog;
