// DEPENDENCIES
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS
import "./ConfirmDeleteDetailDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { deleteAPICall } from "../../../../config/apiCalls";

// MUI COMPONENTS
import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@mui/material";

// FUNCTIONAL COMPONENT
const ConfirmDeleteDetailDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [isLoading, setIsLoading] = useState(false);

	// USE NAVIGATE
	const navigate = useNavigate();

	// INPUT HANDLERS
	const formClosed = () => {
		props.onClose();
	};

	const confirmDeletePressed = async () => {
		if (!props.isAdmin) {
			return;
		}

		if (props.id === null || props.id === undefined || props.id === 0) {
			return;
		}

		setIsLoading(true);

		const deleteRes = await deleteAPICall(
			instance,
			accounts[0],
			"/api/encompass/details/delete",
			{
				detailID: props.id,
			}
		);

		setIsLoading(false);

		formClosed();

		navigate("/encompass/detail_library");
	};

	// RENDER
	return (
		<Dialog
			id="confirmDeleteDetailDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="confirmDeleteDetailDialogTitle">
				Delete {props.detailName}
			</DialogTitle>
			<DialogContent id="confirmDeleteDetailDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress />
					</div>
				)}
				{!isLoading && (
					<p id="deleteDetailDialogParagraph">
						Are you sure you want to delete the detail{" "}
						{props.detailName}? <b>This action cannot be undone.</b>
					</p>
				)}
				<div className="dialogButtons">
					<Button
						id="closeConfirmDeleteDetailDialogButton"
						onClick={formClosed}
						disabled={isLoading}
						color="error"
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						id="confirmDeleteDetailButton"
						onClick={confirmDeletePressed}
						disabled={isLoading}
						color="error"
						variant="contained"
					>
						Confirm
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmDeleteDetailDialog;
