// DEPENDENCIES
import React from "react";

// CSS
import "./SpecComment.css";

// MUI COMPONENTS
import { IconButton } from "@mui/material";

import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";

// CONSTANTS
import { convertToPST } from "../../../../../../constants";

// REACT COMPONENT
const SpecComment = (props) => {
	return (
		<div
			className="SpecComment"
			style={
				props.showBottomBorder
					? { borderBottom: "1px solid #e0e0e0" }
					: { borderBottom: "none" }
			}
		>
			<div className="specCommentContainer">
				<div className="specCommentBody">
					<div className="specCommentHeader">
						<div className="specCommentPFPContainer">
							<img
								src={props.comment.submitted_by_pfp_url}
								alt={`${props.comment.submitted_by} Headshot`}
							/>
						</div>
						<div className="specCommentTitlesContainer">
							<h2 className="specCommentSubmitter">
								{props.comment.submitted_by}
							</h2>
							<h3 className="specCommentDate">
								{convertToPST(props.comment.created)}
							</h3>
						</div>
					</div>
					<p className="specCommentText">{props.comment.comment}</p>
				</div>
				{props.canDeleteComment ? (
					<div className="specCommentDeleteButtonContainer">
						<IconButton
							onClick={() =>
								props.deleteComment(props.comment.id)
							}
							size="small"
							color="error"
						>
							<DeleteOutlineIcon fontSize="small" />
						</IconButton>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default SpecComment;
