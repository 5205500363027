// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./TicketCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, putAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	Checkbox,
	Collapse,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@mui/material";

import {
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS
import TicketStatusIcon from "./TicketStatusIcon/TicketStatusIcon";

import TicketComment from "../TicketComment/TicketComment";

// CONSTANTS
import { toTitleCase } from "../../../constants";

const requestTypeOptions = {
	bugIssue: "Bug/Issue",
	change: "Change",
	newFeature: "New Feature",
	other: "Other",
};

// RENDER
const TicketCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [comments, setComments] = useState([]);

	const [markAsCompleteComment, setMarkAsCompleteComment] = useState("");

	const [markedAsCompleteOpen, setMarkedAsCompleteOpen] = useState(false);

	const [ticketOpen, setTicketOpen] = useState(false);

	const [notifyUser, setNotifyUser] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchComments();
	}, []);

	// INPUT HANDLERS

	const notifyUserChanged = (e) => {
		setNotifyUser(e.target.checked);
	};

	const handleTicketOpen = () => {
		setTicketOpen(!ticketOpen);
	};

	const ticketMarkedAsComplete = () => {
		setMarkedAsCompleteOpen(true);
	};

	const markedAsCompleteCommentChange = (event) => {
		setMarkAsCompleteComment(event.target.value);
	};

	const confirmMarkedAsComplete = async () => {
		try {
			await putAPICall(
				instance,
				accounts[0],
				"/api/tickets/markAsComplete",
				{
					ticketID: props.id,
					modifiedBy: employeeID,
				}
			);

			await postAPICall(
				instance,
				accounts[0],
				"/api/logs/automation/add",
				{
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					success: true,
					message: `Marked Ticket as Complete. Ticket ID: ${props.id}`,
					tool_name: "Tickets",
				}
			);

			let comment =
				"Ticket Marked as Complete" +
				(markAsCompleteComment ? ": " + markAsCompleteComment : "");

			await postAPICall(
				instance,
				accounts[0],
				"/api/tickets/comments/add",
				{
					ticketID: props.id,
					comment: comment,
					submittedByID: employeeID,
					submittedTime: dayjs().format("MMM D, YYYY h:mm a"),
				}
			);

			// email user that ticket has been marked as complete
			if (notifyUser) {
				await postAPICall(
					instance,
					accounts[0],
					"/api/tickets/email/comment",
					{
						ticketID: props.id,
						comment: comment,
					}
				);
			}

			setMarkedAsCompleteOpen(false);
			setMarkAsCompleteComment("");
			props.fetchTickets();
		} catch (error) {
			console.log("Error in confirmMarkedAsComplete: ", error);
		}
	};

	// API FUNCTIONS
	const fetchComments = async () => {
		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/tickets/comments",
				{
					ticketID: props.id,
				}
			);

			setComments(response.data);
		} catch (error) {
			console.log("Error in fetchComments: ", error);
		}
	};

	// RENDER
	return (
		<div className="TicketCard">
			<div className="ticketCardHeader" onClick={handleTicketOpen}>
				<div className="ticketCardHeaderTitleContainer">
					<h2 className="ticketCardHeaderTitle">{props.title}</h2>
					<IconButton
						sx={{
							color: "black",
							minHeight: 0,
							minWidth: 0,
							padding: 0,
						}}
					>
						{ticketOpen ? (
							<ArrowDropUpIcon fontSize="small" />
						) : (
							<ArrowDropDownIcon fontSize="small" />
						)}
					</IconButton>
				</div>
				<TicketStatusIcon
					statusTitle={props.statusTitle}
					hexColor={props.hexColor}
				/>
			</div>
			<Collapse in={ticketOpen} className="ticketCardBody">
				<p className="ticketCardBodyDescription">{props.description}</p>
				<div className="ticketCardBodyRow">
					<div className="ticketCardBodyRowItem">
						<p className="ticketCardBodyRowItemTitle">
							Request Type
						</p>
						<p className="ticketCardBodyRowItemValue">
							{requestTypeOptions[props.requestType]}
						</p>
					</div>
					<div className="ticketCardBodyRowItem">
						<p className="ticketCardBodyRowItemTitle">Priority</p>
						<p className="ticketCardBodyRowItemValue">
							{toTitleCase(props.priority)}
						</p>
					</div>
					<div className="ticketCardBodyRowItem">
						<p className="ticketCardBodyRowItemTitle">Category</p>
						<p className="ticketCardBodyRowItemValue">
							{props.category
								? toTitleCase(props.category)
								: "N/A"}
						</p>
					</div>
				</div>
				<div className="ticketCardBodyRow">
					<div className="ticketCardBodyRowItem">
						<p className="ticketCardBodyRowItemTitle">
							Application Name
						</p>
						<p className="ticketCardBodyRowItemValue">
							{props.appName}
						</p>
					</div>
					<div className="ticketCardBodyRowItem">
						<p className="ticketCardBodyRowItemTitle">Task Owner</p>
						<p className="ticketCardBodyRowItemValue">
							{props.taskOwner !== "null null"
								? props.taskOwner
								: "Unassigned"}
						</p>
					</div>
					<div className="ticketCardBodyRowItem">
						<p className="ticketCardBodyRowItemTitle">
							Submitted Date
						</p>
						<p className="ticketCardBodyRowItemValue">
							{props.submittedDate}
						</p>
					</div>
				</div>
				{props.screenshotLink && (
					<div className="screenshotContainer">
						<p className="ticketCardBodyRowItemTitle">Screenshot</p>
						<img
							src={props.screenshotLink}
							alt="Ticket Screenshot"
						/>
					</div>
				)}
				{comments.length > 0 && (
					<div className="commentsContainer">
						<p className="ticketCardBodyRowItemTitle">Comments</p>
						{comments.map((comment, index) => (
							<TicketComment
								key={index}
								comment={comment}
								showBottomBorder={index !== comments.length - 1}
								showDeleteButton={false}
							/>
						))}
					</div>
				)}
				{props.statusTitle !== "Complete" && (
					<div className="dialogButtons">
						<Button
							variant="outlined"
							color="primary"
							onClick={ticketMarkedAsComplete}
						>
							Mark as Complete
						</Button>
					</div>
				)}
			</Collapse>
			<Dialog
				id="confirmMarkTicketDialog"
				open={markedAsCompleteOpen}
				fullWidth
				onClose={() => setMarkedAsCompleteOpen(false)}
			>
				<DialogTitle id="confirmMarkTicketDialogTitle">
					{props.title}
				</DialogTitle>
				<DialogContent id="confirmMarkTicketContent">
					Are you sure you want to mark this ticket as complete? This
					action cannot be undone.
					<div>
						<TextField
							id="markAsCompleteComment"
							label="Comment"
							variant="outlined"
							value={markAsCompleteComment}
							onChange={markedAsCompleteCommentChange}
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										Ticket Marked as Complete
									</InputAdornment>
								),
							}}
						/>
					</div>
					<div>
						<FormControlLabel
							control={
								<Checkbox
									checked={notifyUser}
									onChange={notifyUserChanged}
								/>
							}
							labelPlacement="right"
							label="Notify User?"
						/>
					</div>
					<div className="dialogButtons">
						<Button
							variant="outlined"
							color="error"
							onClick={() => setMarkedAsCompleteOpen(false)}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={confirmMarkedAsComplete}
						>
							Confirm
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default TicketCard;
