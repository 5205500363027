// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

// CSS
import "./PendingJobs.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// CUSTOM COMPONENTS
import PendingJobCard from "./PendingJobCard/PendingJobCard";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS

// REACT COMPONENT
const PendingJobs = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [pendingJobRecords, setPendingJobRecords] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT

	// USE EFFECT
	useEffect(() => {
		fetchPendingJobs();
	}, []);

	// API METHODS
	const fetchPendingJobs = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/pending/unsubmitted"
			);
			setPendingJobRecords(res.data);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	return (
		<div className="PendingJobs">
			<div className="pendingJobsHeader">Pending Jobs</div>
			{isLoading && (
				<div className="pendingJobLoadingSpinner">
					<CircularProgress />
				</div>
			)}
			{!isLoading && (
				<div className="pendingJobsContainer">
					{pendingJobRecords.length > 0 ? (
						pendingJobRecords.map((record) => (
							<RouterLink
								id="pendingJobCardLink"
								to={`/jobs/pending/${record.id}`}
								underline="none"
							>
								<PendingJobCard job={record} />
							</RouterLink>
						))
					) : (
						<J2NoResultsFound />
					)}
				</div>
			)}
		</div>
	);
};

export default PendingJobs;
