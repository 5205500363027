// DEPENDENCIES
import React, { useState, useMemo, useRef, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./RFA.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	ButtonGroup,
	CircularProgress,
	Collapse,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

// CUSTOM COMPONENTS
import J2ErrorDialog from "../../Misc/J2ErrorDialog/J2ErrorDialog";
import J2MultilineTextField from "../../Misc/J2MultilineTextField/J2MultilineTextField";

// CONSTANTS
const ELEMENT_SIZE = "medium";

const searchByOptionsMap = new Map([
	["email", "Email"],
	["lastname", "Last Name"],
]);

// REACT COMPONENT
const RFA = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS (for autocompletes)
	const dealNameRef = useRef(null);
	const clientSearchByValueRef = useRef(null);

	// API RESULT STATES
	const [dealOptions, setDealOptions] = useState([]);
	const [clientOptions, setClientOptions] = useState([]);

	// CREATE NEW/SEARCH EXISTING STATES
	const [createNewClientEnabled, setCreateNewClientEnabled] = useState(false);
	const [searchExistingClientEnabled, setSearchExistingClientEnabled] =
		useState(true);

	// DETAILED VIEW/SEARCH BAR COLLAPSE STATES
	const [dealDetailedViewOpen, setDealDetailedViewOpen] = useState(false);
	const [clientDetailedViewOpen, setClientDetailedViewOpen] = useState(false);

	const [clientSearchBarOpen, setClientSearchBarOpen] = useState(true);

	// SEARCH BAR STATES
	const [clientSearchByParameter, setClientSearchByParameter] =
		useState("lastname");
	const [clientSearchByValue, setClientSearchByValue] = useState("");

	// DETAILED VIEW STATES
	const [dealID, setDealID] = useState("");
	const [dealName, setDealName] = useState("");
	const [dealAmount, setDealAmount] = useState("");
	const [dealProposalSignedDate, setDealProposalSignedDate] = useState("");
	const [dealCurrentAmendmentNumber, setDealCurrentAmendmentNumber] =
		useState(0);
	const [dealOIC, setDealOIC] = useState("");

	const [clientEmail, setClientEmail] = useState("");
	const [clientFirstName, setClientFirstName] = useState("");
	const [clientLastName, setClientLastName] = useState("");
	const [clientRecordID, setClientRecordID] = useState("");

	// SCOPE OF SERVICES STATES
	const [numberOfServices, setNumberOfServices] = useState(1);

	const [serviceScope1, setServiceScope1] = useState("");
	const [serviceScope2, setServiceScope2] = useState("");
	const [serviceScope3, setServiceScope3] = useState("");
	const [serviceScope4, setServiceScope4] = useState("");
	const [serviceScope5, setServiceScope5] = useState("");

	const [serviceScope1Cost, setServiceScope1Cost] = useState("");
	const [serviceScope2Cost, setServiceScope2Cost] = useState("");
	const [serviceScope3Cost, setServiceScope3Cost] = useState("");
	const [serviceScope4Cost, setServiceScope4Cost] = useState("");
	const [serviceScope5Cost, setServiceScope5Cost] = useState("");

	// AMENDMENT INFORMATION STATES
	const [additionalNotes, setAdditionalNotes] = useState("");

	// MISC. STATES
	const [isSubmitting, setIsSubmitting] = useState(false);

	// SUCCESS/ERROR STATES
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [isSuccess, setIsSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "RFA",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the RFA page",
		});
	}, []);

	// DEAL INPUT HANDLERS
	const dealNameSelected = (event, newValue) => {
		if (newValue && newValue.properties) {
			// if a deal name is selected and the user didn't just clear the autocomplete
			setDealDetailedViewOpen(true);
			setDealID(newValue.properties.hs_object_id);
			setDealName(newValue.properties.dealname);
			setDealOIC(newValue.properties.oic);
			!newValue.properties.amount
				? setDealAmount("N/A")
				: setDealAmount(newValue.properties.amount);
			!newValue.properties.proposal_signed_date
				? setDealProposalSignedDate("N/A")
				: setDealProposalSignedDate(
						dayjs(newValue.properties.proposal_signed_date).format(
							"MM/DD/YYYY"
						)
				  );
			!newValue.properties.current_amendment_number
				? setDealCurrentAmendmentNumber(1)
				: setDealCurrentAmendmentNumber(
						parseInt(newValue.properties.current_amendment_number) +
							1
				  );
		} else {
			// if the user cleared the autocomplete
			setDealDetailedViewOpen(false);
			setDealID("");
			setDealName("");
			setDealAmount("");
			setDealProposalSignedDate("");
			setDealOIC("");
			setDealCurrentAmendmentNumber(0);
			setDealOptions([]);
		}
	};

	// CLIENT INPUT HANDLERS
	const createNewClientClicked = () => {
		// if the user clicks the button while the button is already selected, do nothing
		if (createNewClientEnabled) {
			return;
		}

		// close client search bar
		setClientSearchBarOpen(false);

		// enable the create new client view
		setCreateNewClientEnabled(true);
		setSearchExistingClientEnabled(false);

		// clear all the previous values that were in before
		setClientSearchByValue("");
		setClientEmail("");
		setClientFirstName("");
		setClientLastName("");
		setClientRecordID("");

		// clear the searchbyvalue autocomplete
		const clientSearchByValueEle =
			clientSearchByValueRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (clientSearchByValueEle) clientSearchByValueEle.click();

		// open the detailed view
		setClientDetailedViewOpen(true);
	};

	const searchExistingClientClicked = (override = false) => {
		// override is for when all the inputs are to be cleared, not just a repeated click on the search existing client button when already selected
		if (searchExistingClientEnabled && !override) {
			return;
		}

		// open the client search bar
		setClientSearchBarOpen(true);
		setClientDetailedViewOpen(false);

		// enable the search existing client view
		setSearchExistingClientEnabled(true);
		setCreateNewClientEnabled(false);

		// clear all the previous values that were in before, set parameter back to lastname (default)
		setClientSearchByParameter("lastname");
		setClientSearchByValue("");
		setClientEmail("");
		setClientFirstName("");
		setClientLastName("");
		setClientRecordID("");
	};

	const clientSearchByParameterSelected = (event) => {
		const newParameter = event.target.value;
		setClientSearchByParameter(newParameter);
	};

	const clientSearchByValueSelected = (event, newValue) => {
		if (newValue && newValue.properties) {
			// if the searchByValue is selected and the user didn't just clear the autocomplete
			setClientDetailedViewOpen(true);
			setClientEmail(newValue.properties.email);
			setClientFirstName(newValue.properties.firstname);
			setClientLastName(newValue.properties.lastname);
			setClientRecordID(newValue.properties.hs_object_id);
		} else {
			// if the user cleared the autocomplete
			setClientDetailedViewOpen(false);
			setClientEmail("");
			setClientFirstName("");
			setClientLastName("");
			setClientRecordID("");
			setClientOptions([]);
		}
	};

	const clientEmailChanged = (event) => {
		setClientEmail(event.target.value);
	};

	const clientFirstNameChanged = (event) => {
		setClientFirstName(event.target.value);
	};

	const clientLastNameChanged = (event) => {
		setClientLastName(event.target.value);
	};

	// SCOPE OF SERVICES INPUT HANDLERS
	const addServicePressed = () => {
		if (numberOfServices === 5) {
			return;
		}
		setNumberOfServices(numberOfServices + 1);
	};

	const removeServicePressed = () => {
		if (numberOfServices === 1) {
			return;
		}
		setNumberOfServices(numberOfServices - 1);
	};

	const serviceScope1Changed = (event) => {
		setServiceScope1(event.target.value);
	};

	const serviceScope2Changed = (event) => {
		setServiceScope2(event.target.value);
	};

	const serviceScope3Changed = (event) => {
		setServiceScope3(event.target.value);
	};

	const serviceScope4Changed = (event) => {
		setServiceScope4(event.target.value);
	};

	const serviceScope5Changed = (event) => {
		setServiceScope5(event.target.value);
	};

	const serviceScope1CostChanged = (event) => {
		setServiceScope1Cost(event.target.value);
	};

	const serviceScope2CostChanged = (event) => {
		setServiceScope2Cost(event.target.value);
	};

	const serviceScope3CostChanged = (event) => {
		setServiceScope3Cost(event.target.value);
	};

	const serviceScope4CostChanged = (event) => {
		setServiceScope4Cost(event.target.value);
	};

	const serviceScope5CostChanged = (event) => {
		setServiceScope5Cost(event.target.value);
	};

	// AMENDMENT INFORMATION INPUT HANDLERS
	const additionalNotesChanged = (event) => {
		setAdditionalNotes(event.target.value);
	};

	// API FUNCTIONS
	const dealNameChanged = useMemo(
		() =>
			debounce(async (event) => {
				const textLength = event.target.value.length;

				// if the current text length is less than 3, we will get too many results, so return
				if (textLength < 3) {
					setDealOptions([]);
					return;
				}

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/hubspot/search/deals",
						{
							dealName: event.target.value,
						}
					);

					setDealOptions(res.data);
				} catch (error) {
					console.log("Error in dealNameChanged: ", error);
				}
			}, 500),
		[]
	);

	const clientSearchByValueChanged = useMemo(
		() =>
			debounce(async (event) => {
				const textLength = event.target.value.length;

				if (textLength < 3) {
					setClientOptions([]);
					return;
				}

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/hubspot/search/contacts",
						{
							searchParameter: clientSearchByParameter,
							value: event.target.value,
						}
					);

					setClientOptions(res.data);
				} catch (error) {
					// errorMessageOpened('Error fetching contact records from HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator.');
				}
			}, 500),
		[clientSearchByParameter]
	); // this [clientSearchByParameter] is the dependency array, which tells the useMemo hook to re-render when clientSearchByParameter is updated

	const createNewClientRecordInHubspot = async () => {
		// makes sure required data is inputted
		if (!clientEmail || !clientFirstName || !clientLastName) {
			errorMessageOpened(
				"Make sure client first name, client last name, and email are all filled out. If you believe this to be an error, contact your system administrator."
			);
			return;
		}

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hubspot/addContact",
				{
					email: clientEmail,
					firstName: clientFirstName,
					lastName: clientLastName,
				}
			);

			setClientRecordID(res.data.id);
			return res.data.id;
		} catch (error) {
			errorMessageOpened(
				"Error creating a contact record in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
			);
		}
	};

	// UI HELPER FUNCTIONS
	const errorMessageClosed = () => {
		setIsError(false);
		setErrorMessage("");
	};

	const successMessageClosed = () => {
		setIsSuccess(false);
		setSuccessMessage("");
	};

	const errorMessageOpened = (errorMessage) => {
		setIsError(true);
		setErrorMessage(errorMessage);
		successMessageClosed();
	};

	const successMessageOpened = (successMessage) => {
		setIsSuccess(true);
		setSuccessMessage(successMessage);
		errorMessageClosed();
	};

	const clearInputFields = () => {
		setDealOptions([]);
		setClientOptions([]);

		setNumberOfServices(1);
		setServiceScope1("");
		setServiceScope2("");
		setServiceScope3("");
		setServiceScope4("");
		setServiceScope5("");

		setServiceScope1Cost("");
		setServiceScope2Cost("");
		setServiceScope3Cost("");
		setServiceScope4Cost("");
		setServiceScope5Cost("");

		setAdditionalNotes("");

		searchExistingClientClicked(true);
		const clientSearchByValueEle =
			clientSearchByValueRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (clientSearchByValueEle) clientSearchByValueEle.click();

		setDealDetailedViewOpen(false);
		setDealID("");
		setDealName("");
		setDealAmount("");
		setDealProposalSignedDate("");
		setDealCurrentAmendmentNumber(0);

		const dealNameEle = dealNameRef.current.getElementsByClassName(
			"MuiAutocomplete-clearIndicator"
		)[0];
		if (dealNameEle) dealNameEle.click();
	};

	// MISC. HELPER FUNCTIONS
	function debounce(callback, wait) {
		let timeoutId = null;
		return (...args) => {
			window.clearTimeout(timeoutId);
			timeoutId = window.setTimeout(() => {
				callback.apply(null, args);
			}, wait);
		};
	}

	const validateInputFields = () => {
		if (!dealName) {
			errorMessageOpened(
				"Please select a deal and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!clientRecordID && !createNewClientEnabled) {
			errorMessageOpened(
				"Please select a recipient and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (
			createNewClientEnabled &&
			(!clientEmail || !clientFirstName || !clientLastName)
		) {
			errorMessageOpened(
				"Please fill out all the fields for the new client and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		// Scope of Services
		if (!serviceScope1) {
			errorMessageOpened(
				"Please enter the first service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope2 && numberOfServices > 1) {
			errorMessageOpened(
				"Please enter the second service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope3 && numberOfServices > 2) {
			errorMessageOpened(
				"Please enter the third service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope4 && numberOfServices > 3) {
			errorMessageOpened(
				"Please enter the fourth service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope5 && numberOfServices > 4) {
			errorMessageOpened(
				"Please enter the fifth service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		// Service Costs
		if (!serviceScope1Cost) {
			errorMessageOpened(
				"Please enter the estimated cost for the first service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope2Cost && numberOfServices > 1) {
			errorMessageOpened(
				"Please enter the estimated cost for the second service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope3Cost && numberOfServices > 2) {
			errorMessageOpened(
				"Please enter the estimated cost for the third service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope4Cost && numberOfServices > 3) {
			errorMessageOpened(
				"Please enter the estimated cost for the fourth service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		if (!serviceScope5Cost && numberOfServices > 4) {
			errorMessageOpened(
				"Please enter the estimated cost for the fifth service and try again. If you believe this to be an error, contact your system administrator."
			);
			return false;
		}

		// Service Costs (no special chars)
		for (let char of serviceScope1Cost) {
			if (char < "0" || char > "9") {
				errorMessageOpened(
					'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the first service cost and try again. If you believe this to be an error, contact your system administrator.'
				);
				return false;
			}
		}

		if (numberOfServices > 1) {
			for (let char of serviceScope2Cost) {
				if (char < "0" || char > "9") {
					errorMessageOpened(
						'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the second service cost and try again. If you believe this to be an error, contact your system administrator.'
					);
					return false;
				}
			}
		}

		if (numberOfServices > 2) {
			for (let char of serviceScope3Cost) {
				if (char < "0" || char > "9") {
					errorMessageOpened(
						'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the third service cost and try again. If you believe this to be an error, contact your system administrator.'
					);
					return false;
				}
			}
		}

		if (numberOfServices > 3) {
			for (let char of serviceScope4Cost) {
				if (char < "0" || char > "9") {
					errorMessageOpened(
						'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the fourth service cost and try again. If you believe this to be an error, contact your system administrator.'
					);
					return false;
				}
			}
		}

		if (numberOfServices > 4) {
			for (let char of serviceScope5Cost) {
				if (char < "0" || char > "9") {
					errorMessageOpened(
						'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the fifth service cost and try again. If you believe this to be an error, contact your system administrator.'
					);
					return false;
				}
			}
		}

		// Service Costs (numeric validation)
		// Notes: Right now, this accepts numbers such as "1ea", parsing to 1. If it starts with a number, it will parse all the integers ahead of the first letter (i.e. "1e2" will parse to 1). Base 10 has something to do with this...
		// Notes (cont): Regex would potentially fix this, but this solution should be researched/tested
		if (
			isNaN(parseInt(serviceScope1Cost, 10)) ||
			parseInt(serviceScope1Cost, 10) < 0
		) {
			errorMessageOpened(
				'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the first service cost and try again. If you believe this to be an error, contact your system administrator.'
			);
			return false;
		}

		if (
			(isNaN(parseInt(serviceScope2Cost, 10)) ||
				parseInt(serviceScope2Cost, 10) < 0) &&
			numberOfServices > 1
		) {
			errorMessageOpened(
				'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the second service cost and try again. If you believe this to be an error, contact your system administrator.'
			);
			return false;
		}

		if (
			(isNaN(parseInt(serviceScope3Cost, 10)) ||
				parseInt(serviceScope3Cost, 10) < 0) &&
			numberOfServices > 2
		) {
			errorMessageOpened(
				'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the third service cost and try again. If you believe this to be an error, contact your system administrator.'
			);
			return false;
		}

		if (
			(isNaN(parseInt(serviceScope4Cost, 10)) ||
				parseInt(serviceScope4Cost, 10) < 0) &&
			numberOfServices > 3
		) {
			errorMessageOpened(
				'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the fourth service cost and try again. If you believe this to be an error, contact your system administrator.'
			);
			return false;
		}

		if (
			(isNaN(parseInt(serviceScope5Cost, 10)) ||
				parseInt(serviceScope5Cost, 10) < 0) &&
			numberOfServices > 4
		) {
			errorMessageOpened(
				'Please enter a whole number (no decimals) with no special characters (i.e. "$" or ",") for the fifth service cost and try again. If you believe this to be an error, contact your system administrator.'
			);
			return false;
		}

		return true;
	};

	// RFA SUBMITTED
	const submitRFAPressed = async () => {
		try {
			// this adds a loading spinner and disables the button
			setIsSubmitting(true);

			// retrieve MSAL user email and handle error if necessary
			const userRes = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/byID",
				{ id: employeeID }
			);

			if (
				userRes.data.length === 0 ||
				userRes.data.error ||
				!userRes.data[0].last_name
			) {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message: "Error retrieving Microsoft user profile",
					success: false,
					tool_name: "RFA",
				});
				errorMessageOpened(
					"Error retrieving Microsoft user profile. Refresh your browser and try again. If the problem persists, contact your system administrator."
				);
				setIsSubmitting(false);
				return;
			}

			const userName =
				userRes.data[0].first_name + " " + userRes.data[0].last_name;

			// this checks to see if an existing client is selected, and if not, creates a new client record in HubSpot
			var associatedClientRecordID = clientRecordID;
			if (!clientRecordID) {
				associatedClientRecordID =
					await createNewClientRecordInHubspot();
				if (!associatedClientRecordID) {
					errorMessageOpened(
						"Error creating client record in Hubspot. Refresh your browser and try again. If the problem persists, contact your system administrator."
					);
					setIsSubmitting(false);
					postAPICall(
						instance,
						accounts[0],
						"/api/logs/automation/add",
						{
							employeeID: employeeID,
							date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
							message: "Error creating client record in Hubspot",
							success: false,
							tool_name: "RFA",
						}
					);
					return;
				}
			}

			// this checks to see if the input fields are valid
			if (!validateInputFields()) {
				setIsSubmitting(false);
				return;
			}

			// sends a create amendment request to Express server which relays data to hubspot api
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hubspot/createAmendment",
				{
					dealID: dealID,
					amendmentNumber: dealCurrentAmendmentNumber,
					contactID: associatedClientRecordID,
					lineItem1: serviceScope1 ? serviceScope1 : "",
					lineItem2: serviceScope2 ? serviceScope2 : "",
					lineItem3: serviceScope3 ? serviceScope3 : "",
					lineItem4: serviceScope4 ? serviceScope4 : "",
					lineItem5: serviceScope5 ? serviceScope5 : "",
					lineItem1Cost: serviceScope1Cost
						? parseInt(serviceScope1Cost, 10)
						: 0,
					lineItem2Cost: serviceScope2Cost
						? parseInt(serviceScope2Cost, 10)
						: 0,
					lineItem3Cost: serviceScope3Cost
						? parseInt(serviceScope3Cost, 10)
						: 0,
					lineItem4Cost: serviceScope4Cost
						? parseInt(serviceScope4Cost, 10)
						: 0,
					lineItem5Cost: serviceScope5Cost
						? parseInt(serviceScope5Cost, 10)
						: 0,
					taskCreatedTime: dayjs().startOf("day").valueOf(),
					taskSubject: `Create Amendment #${dealCurrentAmendmentNumber}: ${dealName}. Amendment recipient is ${clientFirstName} ${clientLastName} (${clientEmail}). RFA submitted by ${userName}. Additional notes: ${additionalNotes}.`,
					taskOwnerID: dealOIC === "OR" ? "487532464" : "57152858", // ? Britany : Kelli
				}
			);

			// if there is an error, display the error message
			if (res.data.error) {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message: "Error creating amendment in Hubspot",
					success: false,
					tool_name: "RFA",
				});
				setIsSubmitting(false);
				errorMessageOpened(
					res.data.error ||
						"Error submitting RFA. Refresh your browser and try again. If the problem persists, contact your system administrator."
				);
			} else {
				// if there is no error, display the success message and clear the input fields
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message: `RFA submitted successfully for deal: ${dealName}. Amendment recipient is ${clientFirstName} ${clientLastName} (${clientEmail}).`,
					success: true,
					tool_name: "RFA",
				});
				setIsSubmitting(false);
				successMessageOpened("RFA submitted successfully");
				clearInputFields();
			}
		} catch (error) {
			setIsSubmitting(false);
			postAPICall(instance, accounts[0], "/api/logs/automation/add", {
				employeeID: employeeID,
				date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
				message: `Undefined error submitting RFA: ${error}`,
				success: false,
				tool_name: "RFA",
			});
		}
	};

	return (
		<div className="RFA">
			<div className="rfaHeader">RFA</div>
			<div className="rfaCardDialogRow" id="successDialogDiv">
				<Collapse in={isSuccess} id="dialogCollapse">
					<Alert
						sx={{ marginTop: "1em", marginBottom: "1em" }}
						severity="success"
						onClose={successMessageClosed}
					>
						{successMessage}
					</Alert>
				</Collapse>
			</div>
			<div className="rfaCard">
				<div className="cardHeader">
					<h2>Deal Information</h2>
				</div>
				<div className="rfaCardRow">
					<Autocomplete
						sx={{ flexGrow: 1 }}
						options={dealOptions}
						size={ELEMENT_SIZE}
						ref={dealNameRef}
						getOptionLabel={(option) =>
							option.properties
								? option.properties.dealname
								: option
						}
						filterSelectedOptions
						onChange={dealNameSelected}
						clearOnBlur={false}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Deal Name"
								placeholder="Deal Name"
								value={dealName || ""}
								onKeyUp={dealNameChanged}
							/>
						)}
					/>
				</div>
				<Collapse in={dealDetailedViewOpen} id="detailedViewCollapse">
					<div className="rfaCardRow">
						<TextField
							sx={{ flexGrow: 1 }}
							size={ELEMENT_SIZE}
							disabled
							label="Amount (USD)"
							value={dealAmount || ""}
						/>
						<TextField
							sx={{ flexGrow: 1 }}
							size={ELEMENT_SIZE}
							disabled
							label="Proposal Signed Date"
							value={dealProposalSignedDate || ""}
						/>
					</div>
				</Collapse>
			</div>
			<Collapse in={dealDetailedViewOpen} id="clientCollapse">
				<div className="rfaCard" id="clientInformationDiv">
					<div className="cardHeader">
						<h2>Recipient Information</h2>
						<ButtonGroup>
							<Button
								variant={
									createNewClientEnabled
										? "contained"
										: "outlined"
								}
								onClick={createNewClientClicked}
								size={ELEMENT_SIZE}
							>
								Create New
							</Button>
							<Button
								variant={
									searchExistingClientEnabled
										? "contained"
										: "outlined"
								}
								onClick={searchExistingClientClicked}
								size={ELEMENT_SIZE}
							>
								Search Existing
							</Button>
						</ButtonGroup>
					</div>
					<Collapse in={clientSearchBarOpen} id="searchBarCollapse">
						<div
							className="searchByRFACardRow"
							id="clientSearchByFields"
						>
							<Autocomplete
								sx={{ flexGrow: 1 }}
								options={clientOptions}
								size={ELEMENT_SIZE}
								ref={clientSearchByValueRef}
								getOptionLabel={(option) =>
									option.properties
										? `${
												option.properties.firstname ||
												""
										  } ${
												option.properties.lastname || ""
										  } (${option.properties.email || ""})`
										: option
								}
								filterSelectedOptions
								onChange={clientSearchByValueSelected}
								clearOnBlur={false}
								renderInput={(params) => (
									<TextField
										{...params}
										label={searchByOptionsMap.get(
											clientSearchByParameter
										)}
										placeholder={searchByOptionsMap.get(
											clientSearchByParameter
										)}
										value={clientSearchByValue || ""}
										onKeyUp={clientSearchByValueChanged}
									/>
								)}
							/>
							<FormControl sx={{ width: "20%" }}>
								<InputLabel id="clientSearchByLabel">
									Search By
								</InputLabel>
								<Select
									labelId="clientSearchByLabel"
									value={clientSearchByParameter}
									label="Search By"
									onChange={clientSearchByParameterSelected}
									size={ELEMENT_SIZE}
								>
									<MenuItem value="lastname">
										Last Name
									</MenuItem>
									<MenuItem value="email">Email</MenuItem>
								</Select>
							</FormControl>
						</div>
					</Collapse>
					<Collapse
						in={clientDetailedViewOpen}
						id="detailedViewCollapse"
					>
						<div className="rfaCardRow">
							<TextField
								sx={{ flexGrow: 1 }}
								size={ELEMENT_SIZE}
								disabled={!createNewClientEnabled}
								onChange={clientEmailChanged}
								label="Email"
								value={clientEmail || ""}
							/>
						</div>
						<div className="rfaCardRow">
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewClientEnabled}
								size={ELEMENT_SIZE}
								onChange={clientFirstNameChanged}
								label="First Name"
								value={clientFirstName || ""}
							/>
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewClientEnabled}
								onChange={clientLastNameChanged}
								size={ELEMENT_SIZE}
								label="Last Name"
								value={clientLastName || ""}
							/>
						</div>
					</Collapse>
				</div>
				<div className="rfaCard" id="scopeOfServicesDiv">
					<div className="cardHeader">
						<h2>Scope of Service(s)</h2>
						<ButtonGroup>
							<Button
								variant="outlined"
								color="error"
								size={ELEMENT_SIZE}
								onClick={removeServicePressed}
							>
								Remove
							</Button>
							<Button
								variant="contained"
								color="primary"
								size={ELEMENT_SIZE}
								onClick={addServicePressed}
							>
								Add
							</Button>
						</ButtonGroup>
					</div>
					<div className="rfaServiceTextFields">
						<div className="rfaServiceTextFieldsRow">
							<TextField
								sx={{ flex: 5 }}
								size={ELEMENT_SIZE}
								label="Service 1"
								value={serviceScope1 || ""}
								onChange={serviceScope1Changed}
							/>
							<TextField
								sx={{ flex: 1 }}
								size={ELEMENT_SIZE}
								label="Estimated Cost"
								value={serviceScope1Cost || ""}
								onChange={serviceScope1CostChanged}
							/>
						</div>
						{numberOfServices > 1 && (
							<div className="rfaServiceTextFieldsRow">
								<TextField
									sx={{ flex: 5 }}
									size={ELEMENT_SIZE}
									label="Service 2"
									value={serviceScope2 || ""}
									onChange={serviceScope2Changed}
								/>
								<TextField
									sx={{ flex: 1 }}
									size={ELEMENT_SIZE}
									label="Estimated Cost"
									value={serviceScope2Cost || ""}
									onChange={serviceScope2CostChanged}
								/>
							</div>
						)}
						{numberOfServices > 2 && (
							<div className="rfaServiceTextFieldsRow">
								<TextField
									sx={{ flex: 5 }}
									size={ELEMENT_SIZE}
									label="Service 3"
									value={serviceScope3 || ""}
									onChange={serviceScope3Changed}
								/>
								<TextField
									sx={{ flex: 1 }}
									size={ELEMENT_SIZE}
									label="Estimated Cost"
									value={serviceScope3Cost || ""}
									onChange={serviceScope3CostChanged}
								/>
							</div>
						)}
						{numberOfServices > 3 && (
							<div className="rfaServiceTextFieldsRow">
								<TextField
									sx={{ flex: 5 }}
									size={ELEMENT_SIZE}
									label="Service 4"
									value={serviceScope4 || ""}
									onChange={serviceScope4Changed}
								/>
								<TextField
									sx={{ flex: 1 }}
									size={ELEMENT_SIZE}
									label="Estimated Cost"
									value={serviceScope4Cost || ""}
									onChange={serviceScope4CostChanged}
								/>
							</div>
						)}
						{numberOfServices > 4 && (
							<div className="rfaServiceTextFieldsRow">
								<TextField
									sx={{ flex: 5 }}
									size={ELEMENT_SIZE}
									label="Service 5"
									value={serviceScope5 || ""}
									onChange={serviceScope5Changed}
								/>
								<TextField
									sx={{ flex: 1 }}
									size={ELEMENT_SIZE}
									label="Estimated Cost"
									value={serviceScope5Cost || ""}
									onChange={serviceScope5CostChanged}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="rfaCard">
					<div className="cardHeader">
						<h2>Amendment Information</h2>
					</div>
					<div className="rfaCardRow">
						<J2MultilineTextField
							label="Additional Notes"
							value={additionalNotes || ""}
							placeholder="Additional Notes"
							onChange={additionalNotesChanged}
						/>
					</div>
				</div>
				<div className="rfaButtonContainer">
					<div className="rfaSubmitButtonContainer">
						<Button
							color="primary"
							variant="contained"
							size={ELEMENT_SIZE}
							onClick={submitRFAPressed}
							disabled={isSubmitting}
						>
							Submit
						</Button>
						{isSubmitting && (
							<CircularProgress
								size={24}
								sx={{
									color: "primary",
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</div>
				</div>
				<J2ErrorDialog
					open={isError}
					onClose={errorMessageClosed}
					errorMessage={errorMessage}
				/>
			</Collapse>
		</div>
	);
};

export default RFA;
