// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

// CSS
import "./DesignJobDetailScreen.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	postAPICall,
	deleteAPICall,
	putAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import CircularProgress from "@mui/material/CircularProgress";

// MUI ICONS

// CUSTOM COMPONENTS
import SpecificationsListCard from "./SpecificationsListCard/SpecificationsListCard";

import AddSpecificationsDialog from "./AddSpecificationsDialog/AddSpecificationsDialog";
import DocumentGenerationOptionsDialog from "./DocumentGenerationOptionsDialog/DocumentGenerationOptionsDialog";
import SpecBookLinksCard from "./SpecBookLinksCard/SpecBookLinksCard";

import AreYouSureRemoveSpecDialog from "./SpecificationsListCard/AreYouSureRemoveSpecDialog/AreYouSureRemoveSpecDialog";

// CONSTANTS

// OTHER

// REACT COMPONENT
const DesignJobDetailScreen = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// PARAMS
	const { id } = useParams();

	// STATES
	const [designJobRecord, setDesignJobRecord] = useState({});
	const [allStandardSpecifications, setAllStandardSpecifications] = useState(
		[]
	);
	const [allSpecifications, setAllSpecifications] = useState([]);
	const [selectedSpecifications, setSelectedSpecifications] = useState([]);
	const [originalSelectedSpecifications, setOriginalSelectedSpecifications] =
		useState([]);
	const [propertyDetails, setPropertyDetails] = useState({});
	const [divisionCodes, setDivisionCodes] = useState([]);
	const [specBooks, setSpecBooks] = useState([]);

	const [isJobLocked, setIsJobLocked] = useState(false);

	// Dialog States
	const [addSpecDialogOpen, setAddSpecDialogOpen] = useState(false);
	const [
		documentGenerationOptionsDialogOpen,
		setDocumentGenerationOptionsDialogOpen,
	] = useState(false);
	const [areYouSureRemoveSpecDialogOpen, setAreYouSureRemoveSpecDialogOpen] =
		useState(false);

	const [areYouSureRemovedSpecs, setAreYouSureRemovedSpecs] = useState([]);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);
	const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);

	// USE NAVIGATE
	const navigate = useNavigate();

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchDesignJobRecord();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Design Job Detail Screen",
			employeeID: employeeID,
			notes:
				"Accessed the Design Job Detail Screen page for job ID " + id,
		});
	}, []);

	useEffect(() => {
		checkIfUnsavedChanges();
	}, [selectedSpecifications, isJobLocked]);

	// displays default browser alert if user tries to leave page with unsavedchanges true
	useEffect(() => {
		const handleBeforeUnload = (e) => {
			e.preventDefault();
			e.returnValue = "";
		};

		if (isUnsavedChanges) {
			window.addEventListener("beforeunload", handleBeforeUnload);
		} else {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		}
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [isUnsavedChanges]);

	// INPUT HANDLERS
	// Dropdown Handlers
	const addSpecifications = async () => {
		setAddSpecDialogOpen(true);
	};

	const generateDocumentClicked = async () => {
		setDocumentGenerationOptionsDialogOpen(true);
	};

	// List Handlers
	const selectedSpecificationsChanged = (newList) => {
		setSelectedSpecifications(newList);
	};

	// Button Handlers
	const saveButtonClicked = async () => {
		setIsLoading(true);

		try {
			await saveUnsavedChanges();
			await fetchDesignJobRecord();

			setIsUnsavedChanges(false);
		} catch (error) {
			console.error("Error saving specifications:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const toggleLock = () => {
		if (isJobLocked) {
			setIsJobLocked(false);
		} else {
			setIsJobLocked(true);
		}
	};

	// API FUNCTIONS

	// remove spec
	const removeSpec = async (spec) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/specifications/removeSpec",
				{
					specID: spec.id,
					specName: spec.title,
					isLocal: spec.parent_id !== null,
					deletedByID: employeeID,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	// disassociate spec from job
	const removeSpecAssociation = async (spec) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/specifications/removeFromJob",
				{
					jobID: id,
					specID: spec.id,
					employeeID: employeeID,
					specName: spec.title,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	// used for adding a job spec record
	const associateSpecToJob = async (specId, specTitle) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/specifications/associateToJob`,
				{
					jobID: id,
					specID: specId,
					modifiedBy: employeeID,
					specName: specTitle,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	const updateSpecInJob = async (spec) => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				`/api/specifications/update`,
				{
					title: spec.title,
					description: spec.description,
					division: spec.division,
					statusID: spec.status_id,
					modifiedByID: employeeID,
					content: spec.content,
					editable: spec.editable,
					parentID: spec.parent_id,
					phaseID: spec.phase_id,
					specID: spec.id,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	const updateJobLockStatus = async () => {
		try {
			const res = await putAPICall(
				instance,
				accounts[0],
				"/api/jobs/design/toggleLock",
				{
					id: id,
					isLocked: isJobLocked,
					employeeID: employeeID,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	const createNewSpec = async (spec) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				`/api/specifications/add`,
				{
					title: spec.title,
					description: spec.description,
					division: spec.division,
					statusID: spec.status_id,
					modifiedBy: employeeID,
					content: spec.content,
					editable: spec.editable,
					parentID: spec.parent_id,
					phaseID: spec.phase_id,
				}
			);

			return res;
		} catch (error) {
			console.error(error);
		}
	};

	const fetchDesignJobRecord = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/jobs/design/byID`,
				{
					id: id,
				}
			);

			if (res.data.length === 0) {
				navigate("/specs/design_jobs/");
			}

			setDesignJobRecord(res.data[0]);
			setIsJobLocked(res.data[0].is_locked);

			await fetchDesignJobSpecifications();
			await fetchAllSpecifications();
			await fetchAllStandardSpecifications();
			await fetchPropertyDetails(res.data[0].property_name);
			await fetchDivisionCodes();
			await fetchSpecBooks();
		} catch (error) {
			console.log("Error in fetchDesignJobRecord: ", error);
		}

		setIsLoading(false);
	};

	// previously generated spec book records
	const fetchSpecBooks = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/specBooks/byJob",
				{
					id: id,
				}
			);

			setSpecBooks(res.data);
		} catch (error) {
			console.log("Error in fetchSpecBooks: ", error);
		}
	};

	const fetchDivisionCodes = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/divisionCodes/all"
			);

			setDivisionCodes(res.data);
		} catch (error) {
			console.log("Error in fetchDivisionCodes: ", error);
		}
	};

	const fetchPropertyDetails = async (propertyName) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/property/byName",
				{
					name: designJobRecord.property_name
						? designJobRecord.property_name
						: propertyName,
				}
			);

			setPropertyDetails(res.data[0]);
		} catch (error) {
			console.log("Error in fetchPropertyDetails: ", error);
		}
	};

	// fetches all standard specifications
	const fetchAllStandardSpecifications = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/allStandardSpecifications"
			);

			setAllStandardSpecifications(res.data);
		} catch (error) {
			console.log("Error in fetchAllStandardSpecifications: ", error);
		}
	};

	// fetches all specifications
	const fetchAllSpecifications = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/allSpecifications"
			);

			setAllSpecifications(res.data);
		} catch (error) {
			console.log("Error in fetchAllSpecifications: ", error);
		}
	};

	const fetchDesignJobSpecifications = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/byJob",
				{
					id: id,
				}
			);

			setSelectedSpecifications(res.data);
			setOriginalSelectedSpecifications(res.data);
		} catch (error) {
			console.log("Error in fetchDesignJobSpecifications: ", error);
		}
	};

	// LIST MODIFIERS
	const specificationRemoved = async (specID) => {
		try {
			setSelectedSpecifications(
				selectedSpecifications.filter((spec) => spec.id !== specID)
			);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	const checkIfUnsavedChanges = async () => {
		if (
			JSON.stringify(selectedSpecifications) !==
				JSON.stringify(originalSelectedSpecifications) ||
			isJobLocked != designJobRecord.is_locked
		) {
			setIsUnsavedChanges(true);
		} else {
			setIsUnsavedChanges(false);
		}
	};

	const saveUnsavedChanges = async () => {
		// in this order
		// 1. remove specs from job where record is in originalSelectedSpecifications but not in selectedSpecifications
		for (const spec of originalSelectedSpecifications) {
			if (!selectedSpecifications.some((s) => s.id === spec.id)) {
				await removeSpecAssociation(spec);
				if (spec.parent_id !== null) {
					await removeSpec(spec);
				}
			}
		}

		// 2 & 3. Add or update specs in job
		for (const spec of selectedSpecifications) {
			// check if spec is new to the job
			const originalSpec = originalSelectedSpecifications.find(
				(s) => s.id === spec.id
			);

			// check if spec is updated from standard using allspecifications
			const originalSpecFromAllSpecs = allSpecifications.find(
				(s) => s.id === spec.id
			);

			if (
				!originalSpec &&
				spec.content !== originalSpecFromAllSpecs.content
			) {
				// spec added to job and changed from standard in one move
				const newSpec = {
					title: spec.title,
					description: spec.description,
					division: spec.division,
					status_id: spec.status_id,
					modifiedBy: employeeID,
					content: spec.content,
					editable: spec.editable,
					parent_id: originalSpecFromAllSpecs.id,
					phase_id: id,
				};
				// Ensure all operations complete before moving on
				const response = await createNewSpec(newSpec);
				if (response && response.data.insertId) {
					await removeSpecAssociation(originalSpec);
					await associateSpecToJob(
						response.data.insertId,
						spec.title
					);
				}
			} else if (!originalSpec) {
				// spec added to job unchanged
				await associateSpecToJob(spec.id, spec.title);
			} else if (spec.content !== originalSpec.content) {
				// spec existing in job and changed from standard
				if (spec.parent_id === null) {
					// Handle new "child" spec case
					const newSpec = {
						title: spec.title,
						description: spec.description,
						division: spec.division,
						status_id: spec.status_id,
						modifiedBy: employeeID,
						content: spec.content,
						editable: spec.editable,
						parent_id: originalSpec.id,
						phase_id: id,
					};
					// Ensure all operations complete before moving on
					const response = await createNewSpec(newSpec);
					if (response && response.data.insertId) {
						await removeSpecAssociation(originalSpec);
						await associateSpecToJob(
							response.data.insertId,
							spec.title
						);
					}
				} else {
					// If spec is already a "child"
					await updateSpecInJob(spec);
				}
			}
		}

		// 4. Update job lock status
		await updateJobLockStatus();

		// 5. Create local copy of specs (if necessary) if the job is locked
		if (isJobLocked) {
			await createLocalCopies();
		}
	};

	const createLocalCopies = async () => {
		try {
			for (let spec of selectedSpecifications) {
				if (spec.parent_id === null) {
					// Handle new "child" spec case
					const newSpec = {
						title: spec.title,
						description: spec.description,
						division: spec.division,
						status_id: spec.status_id,
						modifiedBy: employeeID,
						content: spec.content,
						editable: spec.editable,
						parent_id: spec.id,
						phase_id: id,
					};
					// Ensure all operations complete before moving on
					const response = await createNewSpec(newSpec);

					if (response && response.data.insertId) {
						await removeSpecAssociation(spec);
						await associateSpecToJob(
							response.data.insertId,
							spec.title
						);
					}
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const checkIfLocalSpecsRemoved = () => {
		let removedLocalSpecs = [];

		for (const spec of originalSelectedSpecifications) {
			if (!selectedSpecifications.some((s) => s.id === spec.id)) {
				if (spec.parent_id !== null) {
					removedLocalSpecs.push(spec);
				}
			}
		}

		if (removedLocalSpecs.length > 0) {
			setAreYouSureRemoveSpecDialogOpen(true);
			setAreYouSureRemovedSpecs(removedLocalSpecs);
		} else {
			setAreYouSureRemoveSpecDialogOpen(false);
			setAreYouSureRemovedSpecs([]);

			saveButtonClicked();
		}
	};

	const handleSpecificationEditClicked = async (spec) => {
		// 	direct to edit page
		navigate(`/specs/design_jobs/${id}/edit/${spec.id}`);
	};

	// RENDER
	return (
		<div className="DesignJobDetailScreen">
			<div className="designJobDetailScreenHeader">
				<div className="designJobDetailScreenHeaderTitle">
					{isLoading ? (
						"Loading..."
					) : (
						<div>
							{designJobRecord.property_name}:{" "}
							{designJobRecord.project_display_name}
						</div>
					)}
				</div>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="designJobDetailScreenBody">
					<SpecificationsListCard
						addSpecifications={addSpecifications}
						selectedSpecifications={selectedSpecifications}
						selectedSpecificationsChanged={(newList) =>
							selectedSpecificationsChanged(newList)
						}
						specificationRemoved={(specID) =>
							specificationRemoved(specID)
						}
						unsavedChanges={isUnsavedChanges}
						saveButtonClicked={checkIfLocalSpecsRemoved}
						generateDocumentClicked={generateDocumentClicked}
						jobID={designJobRecord.id}
						job={designJobRecord}
						propertyDetails={propertyDetails}
						handleSpecificationEditClicked={(spec) =>
							handleSpecificationEditClicked(spec)
						}
						toggleLock={toggleLock}
						isJobLocked={isJobLocked}
					/>
					<SpecBookLinksCard specBooks={specBooks} />
				</div>
			)}
			<AddSpecificationsDialog
				open={addSpecDialogOpen}
				addSpecificationsDialogClosed={() =>
					setAddSpecDialogOpen(false)
				}
				selectedSpecificationsChanged={(newList) =>
					selectedSpecificationsChanged(newList)
				}
				allSpecifications={allStandardSpecifications}
				selectedSpecifications={selectedSpecifications}
				fetchDesignJobRecord={fetchDesignJobRecord}
				jobID={designJobRecord.id}
			/>
			<DocumentGenerationOptionsDialog
				open={documentGenerationOptionsDialogOpen}
				documentGenerationOptionsDialogClosed={() =>
					setDocumentGenerationOptionsDialogOpen(false)
				}
				jobID={designJobRecord.id}
				selectedSpecifications={selectedSpecifications}
				job={designJobRecord}
				propertyDetails={propertyDetails}
				divisionCodes={divisionCodes}
				fetchDesignJobRecord={fetchDesignJobRecord}
				generateTableOfContents={true}
				generateCoverPage={true}
				saveToS3={true}
				createLocalCopies={createLocalCopies}
				singleSpec={false}
			/>
			<AreYouSureRemoveSpecDialog
				open={areYouSureRemoveSpecDialogOpen}
				areYouSureRemovedSpecs={areYouSureRemovedSpecs}
				saveButtonClicked={saveButtonClicked}
				formClosed={() => setAreYouSureRemoveSpecDialogOpen(false)}
			/>
		</div>
	);
};

// EXPORT
export default DesignJobDetailScreen;
