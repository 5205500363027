// DEPENDENCIES
import React from "react";

// CSS
import "./PendingJobCard.css";

// COMPONENT
const PendingJobCard = (props) => {
	// STATES

	// CONTEXT

	// USE EFFECT

	return (
		<div className="PendingJobCard" onClick={props.pendingJobClicked}>
			<div className="pendingJobCardTitle">
				{props.job.property_name}: {props.job.project_name}
			</div>
			<div className="pendingJobCardTags">
				<div className="oicTag">{props.job.oic}</div>
				<div className="dealOwnerTag">{props.job.deal_owner}</div>
			</div>
		</div>
	);
};

export default PendingJobCard;
