// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./SpecificationChangeLog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	employee: "Employee",
	specDivision: "Specification Division",
	specTitle: "Specification Title",
	description: "Description",
	type: "Type",
};

const searchDefaultValue = "employee";

// OTHER

// REACT COMPONENT
const SpecificationChangeLog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [specificationChangeLogs, setSpecificationChangeLogs] = useState([]);
	const [
		filteredSpecificationChangeLogs,
		setFilteredSpecificationChangeLogs,
	] = useState([]);
	const [specificationChangeLogRows, setSpecificationChangeLogRows] =
		useState([]);
	const [specificationChangeLogColumns, setSpecificationChangeLogColumns] =
		useState([]);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchLogs();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Specification Change Logs",
			employeeID: employeeID,
			notes: "Accessed the Specification Change Logs page",
		});
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredSpecificationChangeLogs]);

	// INPUT HANDLERS
	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchLogs = async () => {
		setIsLoading(true);

		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/logs/specs/all"
			);

			setSpecificationChangeLogs(response.data);
			setFilteredSpecificationChangeLogs(response.data);
			setNumberOfPages(
				Math.ceil(response.data.length / RECORDS_PER_PAGE)
			);
		} catch (error) {
			console.log(error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	const filterSpecificationChangeLogRecords = (
		searchValue,
		searchByParameter
	) => {
		setIsLoading(true);

		let filteredRecords = specificationChangeLogs.filter((record) => {
			if (searchByParameter === "employee") {
				return (record.employee_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "specDivision") {
				return (record.spec_division ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "specTitle") {
				return (record.spec_title ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "description") {
				return (record.description ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "type") {
				let specType = record.spec_parent_id ? "Local" : "J2 Standard";
				return (specType ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredSpecificationChangeLogs(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setSpecificationChangeLogColumns([
			{ text: "Employee" },
			{ text: "Spec" },
			{ text: "Description" },
			{ text: "Type" },
			{ text: "Time" },
		]);
	};

	const createRowData = () => {
		const createSpecHTML = (log) => {
			return (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<span style={{ color: "gray" }}>{log.spec_division}</span>
					<span style={{ color: "black" }}> {log.spec_title}</span>
				</div>
			);
		};

		setSpecificationChangeLogRows(
			filteredSpecificationChangeLogs.map((log) => [
				{ text: log.employee_name },
				{ text: createSpecHTML(log) },
				{ text: log.description },
				{
					text:
						!log.spec_parent_id && log.spec_title
							? "J2 Standard"
							: "Local",
				},
				{ text: convertToPST(log.created) },
			])
		);
	};

	// RENDER
	return (
		<div className="SpecificationChangeLog">
			<div className="specificationChangeLogHeader">
				<div className="specificationChangeLogHeaderTitle">
					Specification Change Logs
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={filterSpecificationChangeLogRecords}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredSpecificationChangeLogs.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredSpecificationChangeLogs.length > 0 &&
						specificationChangeLogColumns.length > 0 &&
						specificationChangeLogRows.length > 0 && (
							<>
								<GenericTable
									columnData={specificationChangeLogColumns}
									rowData={specificationChangeLogRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
		</div>
	);
};

// EXPORT
export default SpecificationChangeLog;
