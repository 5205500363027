// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./CategoryConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import NewCategoryDialog from "./NewCategoryDialog/NewCategoryDialog";
import EditCategoryDialog from "./EditCategoryDialog/EditCategoryDialog";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	name: "Name",
};

const searchDefaultValue = "name";

// REACT COMPONENT
const CategoryConsole = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [categories, setCategories] = useState([]); // [{ id, category_name }]
	const [categoriesRows, setCategoriesRows] = useState([]);
	const [categoriesColumns, setCategoriesColumns] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState([]); // [{ id, category_name }]

	// New/Edit Category States
	const [newCategoryOpen, setNewCategoryOpen] = useState(false);
	const [editCategoryOpen, setEditCategoryOpen] = useState(false);

	const [recordToEdit, setRecordToEdit] = useState({});

	// Loading
	const [isLoading, setIsLoading] = useState(false);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// USE EFFECT
	useEffect(() => {
		fetchCategories();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredCategories]);

	// INPUT HANDLERS
	const newCategoryClicked = () => {
		setNewCategoryOpen(true);
	};

	const newCategoryDialogClosed = () => {
		setNewCategoryOpen(false);
	};

	const editCategoryClicked = (category) => {
		setRecordToEdit(category);
		setEditCategoryOpen(true);
	};

	const editCategoryDialogClosed = () => {
		setEditCategoryOpen(false);
	};

	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchCategories = async () => {
		try {
			setIsLoading(true);

			const categoryRes = await getAPICall(
				instance,
				accounts[0],
				"/api/tipsAndTricks/categories/all"
			);
			setCategories(categoryRes.data);
			setFilteredCategories(categoryRes.data);
			setNumberOfPages(
				Math.ceil(categoryRes.data.length / RECORDS_PER_PAGE)
			);

			setIsLoading(false);
		} catch (error) {
			console.error(
				"TipsAndTricksConsole.js - fetchTipsAndTricks() - Error fetching tips and tricks: ",
				error
			);
		}
	};

	const filterCategories = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredCategoryRecords = categories.filter((categoryObj) => {
			if (searchByParameter === "name") {
				return (categoryObj.name ?? "")
					.toLowerCase()
					.includes((searchValue ?? "").toLowerCase());
			}
		});

		setFilteredCategories(filteredCategoryRecords);
		setNumberOfPages(
			Math.ceil(filteredCategoryRecords.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setCategoriesColumns([{ text: "Name" }, {}]);
	};

	const createRowData = () => {
		setCategoriesRows(
			filteredCategories.map((category) => [
				{ text: category.name },
				{ onClick: () => editCategoryClicked(category), icon: "edit" },
			])
		);
	};

	// RENDER
	return (
		<div className="CategoryConsole">
			<div className="categoryConsoleHeader">
				<div className="categoryConsoleHeaderTitle">
					Category Console
					<div className="newRecordButtonContainer">
						<Button
							variant="contained"
							color="primary"
							onClick={newCategoryClicked}
							disabled={isLoading}
						>
							New Category
						</Button>
					</div>
				</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					searchByParameterMap={searchByParameterMap}
					filterResults={(searchValue, searchByParameter) =>
						filterCategories(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredCategories.length === 0 && <J2NoResultsFound />}
					{filteredCategories.length > 0 && (
						<>
							<GenericTable
								columnData={categoriesColumns}
								rowData={categoriesRows.slice(
									paginationStartIndex,
									paginationEndIndex
								)}
							/>
							<div className="paginationDiv">
								<Pagination
									count={numberOfPages}
									color="primary"
									onChange={handlePageChange}
								/>
							</div>
						</>
					)}
				</div>
			)}
			<NewCategoryDialog
				open={newCategoryOpen}
				newCategoryDialogClosed={newCategoryDialogClosed}
				categories={categories}
				fetchCategories={fetchCategories}
			/>
			<EditCategoryDialog
				open={editCategoryOpen}
				recordToEdit={recordToEdit}
				editCategoryDialogClosed={editCategoryDialogClosed}
				categories={categories}
				fetchCategories={fetchCategories}
			/>
		</div>
	);
};

export default CategoryConsole;
