// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

// CSS
import "./PendingSpecChangeReviewScreen.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	putAPICall,
	postAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import PendingSpecChangeComparisonCard from "./PendingSpecChangeComparisonCard/PendingSpecChangeComparisonCard";

import RejectPendingSpecChangeDialog from "./RejectPendingSpecChangeDialog/RejectPendingSpecChangeDialog";

// CONSTANTS

// OTHER

// REACT COMPONENT
const PendingSpecChangeReviewScreen = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// PARAMS
	const { id } = useParams();

	// STATES
	const [pendingSpecChange, setPendingSpecChange] = useState({});
	const [parentSpecContent, setParentSpecContent] = useState(
		"<h1>LOADING...</h1>"
	);

	// Dialogs
	const [rejectDialogOpen, setRejectDialogOpen] = useState(false);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// NAVIGATE
	const navigate = useNavigate();

	// USE EFFECT
	useEffect(() => {
		fetchInitialData();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Accessed the Pending Spec Change Review Screen",
			employeeID: employeeID,
			notes:
				"Accessed the Pending Spec Change Review Screen page with the ID: " +
				id,
		});
	}, []);

	// INPUT HANDLERS
	const handleReject = () => {
		setRejectDialogOpen(true);
	};

	const handleApprove = async () => {
		setIsLoading(true);

		await acceptPendingChangeRecord();
		await updateSpecContent();

		setIsLoading(false);
	};

	const handleRejectDialogClose = () => {
		setRejectDialogOpen(false);
	};

	// API FUNCTIONS
	const fetchPendingChangeRecord = async () => {
		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/pendingChanges/byID`,
				{
					id: id,
				}
			);

			if (response.data.length === 0) {
				// If no pending change record is found, redirect to the not found page
				navigate(`/specs/not_found`);
				return;
			}

			setPendingSpecChange(response.data[0]);

			if (response.data[0].status_id !== 4) {
				// 4 is the status_id representing "Open"
				navigate(`/specs/pending_changes`);
				return;
			}

			const parentSpecResponse = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/byID`,
				{
					id: response.data[0].spec_id,
				}
			);

			setParentSpecContent(parentSpecResponse.data[0].content);
		} catch (error) {
			console.error("fetchPendingChangeRecord error: ", error);
		}
	};

	const updateSpecContent = async () => {
		try {
			const response = await putAPICall(
				instance,
				accounts[0],
				`/api/specifications/update/html`,
				{
					modifiedByID: employeeID,
					content: pendingSpecChange.new_content,
					specID: pendingSpecChange.spec_id,
				}
			);
		} catch (error) {
			console.error("fetchPendingChangeRecord error: ", error);
		}
	};

	const acceptPendingChangeRecord = async () => {
		try {
			const response = await putAPICall(
				instance,
				accounts[0],
				`/api/specifications/pendingChanges/accept`,
				{
					specChangeID: id,
					employeeID: employeeID,
				}
			);

			navigate(`/specs/pending_changes`);
		} catch (error) {
			console.error("acceptPendingChangeRecord error: ", error);
		}
	};

	const rejectPendingChangeRecord = async () => {
		try {
			const response = await putAPICall(
				instance,
				accounts[0],
				`/api/specifications/pendingChanges/reject`,
				{
					specChangeID: id,
					employeeID: employeeID,
				}
			);

			navigate(`/specs/pending_changes`);
		} catch (error) {
			console.error("rejectPendingChangeRecord error: ", error);
		}
	};

	// HELPER FUNCTIONS
	const fetchInitialData = async () => {
		setIsLoading(true);

		await fetchPendingChangeRecord();

		setIsLoading(false);
	};

	// RENDER
	return (
		<div className="PendingSpecChangeReviewScreen">
			<div className="pendingSpecChangeReviewScreenHeader">
				<div className="pendingSpecChangeReviewScreenHeaderTitle">
					{isLoading ? "Loading..." : pendingSpecChange.spec_title}
				</div>
			</div>
			<div className="pendingSpecChangeReviewScreenContentContainer">
				{isLoading && (
					<div className="pendingSpecChangeReviewScreenLoadingContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<PendingSpecChangeComparisonCard
						pendingSpecChange={pendingSpecChange}
						handleReject={handleReject}
						handleApprove={handleApprove}
						parentSpecContent={parentSpecContent}
					/>
				)}
			</div>
			<RejectPendingSpecChangeDialog
				open={rejectDialogOpen}
				handleRejectDialogClose={handleRejectDialogClose}
				rejectPendingChangeRecord={rejectPendingChangeRecord}
			/>
		</div>
	);
};

// EXPORT
export default PendingSpecChangeReviewScreen;
