// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import HtmlDiff from "htmldiff-js";

// CSS
import "./PendingSpecChangeComparisonCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import { Button } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS
import {
	convertToPST,
	previewCSS,
	previewCSS2,
} from "../../../../../constants";

// OTHER

// REACT COMPONENT
const PendingSpecChangeComparisonCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [mergedContent, setMergedContent] = useState("");

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		setMergedContent(
			HtmlDiff.execute(
				props.parentSpecContent ?? "<h1>LOADING...</h1>",
				props.pendingSpecChange.new_content ?? ""
			)
		);
	}, [props.parentSpecContent]);

	// INPUT HANDLERS

	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="PendingSpecChangeComparisonCard">
			<div className="pendingSpecChangeComparisonCardHeader"></div>
			<div className="pendingSpecChangeComparisonCardDescriptionContainer">
				<div className="pendingSpecChangeComparisonCardDescriptionBody">
					<div className="pendingSpecChangeComparisonCardDescriptionHeader">
						<div className="pendingSpecChangeComparisonCardDescriptionPFPContainer">
							<img
								src={props.pendingSpecChange.submitted_by_photo}
								alt={`${props.pendingSpecChange.submitted_by} Headshot`}
							/>
						</div>
						<div className="pendingSpecChangeComparisonCardDescriptionTitlesContainer">
							<h2 className="pendingSpecChangeComparisonCardDescriptionSubmitter">
								{props.pendingSpecChange.submitted_by}
							</h2>
							<h3 className="pendingSpecChangeComparisonCardDescriptionDate">
								{convertToPST(props.pendingSpecChange.created)}
							</h3>
						</div>
					</div>
					<p className="pendingSpecChangeComparisonCardDescriptionText">
						{props.pendingSpecChange.description}
					</p>
				</div>
			</div>
			<div className="pendingSpecChangeComparisonCardContent">
				<div className="pendingSpecChangeComparisonCardOriginalContainer">
					<div className="pendingSpecChangeComparisonCardOriginalHeader">
						Modified Specification
					</div>
					<div className="pendingSpecChangeComparisonOriginal">
						<iframe
							style={{
								border: "none",
								margin: "0em 0.5em",
							}}
							src={
								"data:text/html," +
								encodeURIComponent(
									`<!DOCTYPE html><html><head><meta charset="UTF-8"><style>${previewCSS}${" "}${previewCSS2}</style></head><body>${mergedContent}</body></html>`
								)
							}
							width="100%"
							height="100%"
						/>
					</div>
				</div>
				<div className="pendingSpecChangeComparisonCardNewContainer">
					<div className="pendingSpecChangeComparisonCardNewHeader">
						New Specification
					</div>
					<div className="pendingSpecChangeComparisonNew">
						<iframe
							style={{
								border: "none",
								margin: "0em 0.5em",
							}}
							src={
								"data:text/html," +
								encodeURIComponent(
									`<!DOCTYPE html><html><head><meta charset="UTF-8"><style>${previewCSS}</style></head><body>${props.pendingSpecChange.new_content}</body></html>`
								)
							}
							width="100%"
							height="100%"
						/>
					</div>
				</div>
			</div>
			<div className="pendingSpecChangeComparisonCardButtonContainer">
				<Button
					className="pendingSpecChangeComparisonCardButton"
					variant="contained"
					color="error"
					onClick={props.handleReject}
				>
					Reject
				</Button>
				<Button
					className="pendingSpecChangeComparisonCardButton"
					variant="contained"
					color="primary"
					onClick={props.handleApprove}
				>
					Approve
				</Button>
			</div>
		</div>
	);
};

// EXPORT
export default PendingSpecChangeComparisonCard;
