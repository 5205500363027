// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./CoreRepairScopes.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination, Snackbar } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import GenericTable from "../Misc/Table/GenericTable";
import CoreRepairScopesSearchBar from "./CoreRepairScopesSearchBar/CoreRepairScopesSearchBar";
import AssembliesSearchBar from "./AssembliesSearchBar/AssembliesSearchBar";

import CoreRepairScopeDeleteDialog from "./CoreRepairScopeDeleteDialog/CoreRepairScopeDeleteDialog";
import CoreRepairScopeEditAddDialog from "./CoreRepairScopesEditAddDialog/CoreRepairScopesEditAddDialog";

import J2NoResultsFound from "../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../constants";

// OTHER

// REACT COMPONENT
const CoreRepairScopes = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [coreRepairScopes, setCoreRepairScopes] = useState([]);
	const [coreRepairScopesRows, setCoreRepairScopesRows] = useState([]);
	const [coreRepairScopesColumns, setCoreRepairScopesColumns] = useState([]);
	const [filteredCoreRepairScopes, setFilteredCoreRepairScopes] = useState(
		[]
	);

	const [assemblies, setAssemblies] = useState([]);
	const [buildingElements, setBuildingElements] = useState([]);
	const [repairScopeSpecifications, setRepairScopeSpecifications] = useState(
		[]
	);
	const [repairScopeAssemblies, setRepairScopeAssemblies] = useState([]);

	const [allSpecifications, setAllSpecifications] = useState([]);
	const [recordToEdit, setRecordToEdit] = useState({});
	const [recordToDelete, setRecordToDelete] = useState({});

	const [loading, setLoading] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const [assembliesSearchBarSelected, setAssembliesSearchBarSelected] =
		useState(false);

	// pagination
	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredCoreRepairScopes]);

	// INPUT HANDLERS

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = async (doRefresh) => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({}); // clear record

		if (doRefresh) {
			await handleAPICalls(); // refresh data
		}
	};

	const handleDeleteClick = async (record) => {
		setRecordToDelete(record);
		setDeleteDialogOpen(true);
	};

	const handleDeleteDialogClose = async (doRefresh) => {
		setDeleteDialogOpen(false);
		setRecordToDelete({}); // clear record

		if (doRefresh) {
			await handleAPICalls(); // refresh data
		}
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await fetchCoreRepairScopes();
		await fetchAssemblies();
		await fetchBuildingElements();
		await fetchCoreRepairScopeSpecifications();
		await fetchRepairScopeAssemblies();
		await fetchAllSpecifications();
		setLoading(false);

		return null;
	};

	const fetchCoreRepairScopes = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/all"
			);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
			setCoreRepairScopes(res.data);
			setFilteredCoreRepairScopes(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchAssemblies = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/encompass/assemblies/all"
			);
			setAssemblies(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchBuildingElements = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/buildingElements/all"
			);
			setBuildingElements(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchCoreRepairScopeSpecifications = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/repairScopeSpecifications/all"
			);
			setRepairScopeSpecifications(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchRepairScopeAssemblies = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/coreRepairScopes/repairScopeAssemblies/all"
			);
			setRepairScopeAssemblies(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchAllSpecifications = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/allStandardSpecifications"
			);
			// exclude specs with parent_id
			res.data = res.data.filter((spec) => spec.parent_id === null);
			setAllSpecifications(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	//pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// Search bar
	const filterCoreRepairScopesRecords = (searchValue, searchByParameter) => {
		let filteredRecords = coreRepairScopes.filter((record) => {
			if (searchByParameter === "description") {
				return (record.description ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "replacement_material") {
				return (record.replacement_material ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "assembly_name") {
				return (record.assembly_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "building_type") {
				return (record.building_type ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredCoreRepairScopes(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const filterAssemblySearchBar = async (selectedAssemblies) => {
		let filteredRecords = [];

		let selectedAssemblyIDs = selectedAssemblies.map(
			(assembly) => assembly.id
		);

		for (let record of coreRepairScopes) {
			var assemblyIDs = (record.assembly_ids ?? "").split(",");

			assemblyIDs =
				assemblyIDs.length === 1 && assemblyIDs[0] === ""
					? []
					: assemblyIDs;

			assemblyIDs = assemblyIDs.map((id) => parseInt(id));

			if (isSubset(selectedAssemblyIDs, assemblyIDs)) {
				filteredRecords.push(record);
			}
		}

		setFilteredCoreRepairScopes(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const isSubset = (subsetArray, parentArray) => {
		if (subsetArray.length === 0) {
			return true;
		}

		return subsetArray.every((el) => {
			return parentArray.includes(el);
		});
	};

	const assemblySearchBarSelected = () => {
		setAssembliesSearchBarSelected(true);
	};

	const assemblySearchBarUnselected = () => {
		setAssembliesSearchBarSelected(false);
	};

	const copyDescription = (description) => {
		navigator.clipboard
			.writeText(description)
			.then(() => {
				setSnackbarMessage("copied to clipboard");
				setOpenSnackbar(true);
			})
			.catch((error) => {
				console.error("Failed to copy description: ", error);
				setSnackbarMessage("failed to copy");
			});
	};

	const snackbarActions = () => {
		return (
			<>
				<Button onClick={() => setOpenSnackbar(false)}>Close</Button>
			</>
		);
	};

	const createColumnData = () => {
		setCoreRepairScopesColumns([
			{ text: "Building Element" },
			{ text: "Related Assemblies" },
			{ text: "Description" },
			{ text: "Replacement Material" },
			{ text: "Related Specifications" },
			{},
			{},
			{},
		]);
	};

	const createRowData = () => {
		setCoreRepairScopesRows(
			filteredCoreRepairScopes.map((crs) => [
				{ text: crs.building_type },
				{
					text: (crs.split_assemblies ?? "")
						.split("_SPLIT_")
						.map((spec) => <li>{spec}</li>),
					notApplicable: !crs.split_assemblies,
				},
				{ text: crs.description },
				{ text: crs.replacement_material },
				{
					text: (crs.split_specifications ?? "")
						.split("_SPLIT_")
						.map((spec) => {
							return <li>{spec}</li>;
						}),
					notApplicable: !crs.split_specifications,
				},
				{
					onClick: () => copyDescription(crs.description),
					icon: "copy",
				},
				{
					onClick: () => handleEditClick(crs),
					icon: "edit",
				},
				{
					onClick: () => handleDeleteClick(crs),
					icon: "delete",
				},
			])
		);
	};

	// RENDER
	return (
		<div className="CoreRepairScopes">
			<div className="CoreRepairScopesHeader">
				<div className="CoreRepairScopesHeaderTitle">
					Core Repair Scopes
					{props.isAdmin ? (
						<Button
							variant="contained"
							color="primary"
							onClick={handleAddClick}
							disabled={loading}
						>
							New Record
						</Button>
					) : null}
				</div>
				{assembliesSearchBarSelected ? (
					<AssembliesSearchBar
						filterAssemblySearchBar={(
							searchValue,
							searchByParameter
						) =>
							filterAssemblySearchBar(
								searchValue,
								searchByParameter
							)
						}
						assemblySearchBarSelected={assemblySearchBarSelected}
						assemblySearchBarUnselected={
							assemblySearchBarUnselected
						}
						assemblies={assemblies}
					/>
				) : (
					<CoreRepairScopesSearchBar
						// searchByParameterMap={searchByParameterMap}
						// searchDefaultValue={searchDefaultValue}
						filterResults={(searchValue, searchByParameter) =>
							filterCoreRepairScopesRecords(
								searchValue,
								searchByParameter
							)
						}
						assemblySearchBarSelected={assemblySearchBarSelected}
						assemblySearchBarUnselected={
							assemblySearchBarUnselected
						}
					/>
				)}
			</div>
			{loading ? (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			) : (
				<>
					{filteredCoreRepairScopes.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredCoreRepairScopes.length > 0 &&
						coreRepairScopesRows.length > 0 &&
						coreRepairScopesColumns.length > 0 && (
							<div className="tableContainerDiv">
								<GenericTable
									columnData={coreRepairScopesColumns}
									rowData={coreRepairScopesRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</div>
						)}
				</>
			)}
			<Snackbar
				open={openSnackbar}
				onClose={() => {
					setOpenSnackbar(false);
					setSnackbarMessage("");
				}}
				message={snackbarMessage}
				autoHideDuration={6000}
				action={snackbarActions()}
			/>
			<CoreRepairScopeEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				recordToEdit={recordToEdit}
				assemblies={assemblies}
				buildingElements={buildingElements}
				editing={editing}
				setSnackbarMessage={setSnackbarMessage}
				setOpenSnackbar={setOpenSnackbar}
				repairScopeSpecifications={repairScopeSpecifications}
				repairScopeAssemblies={repairScopeAssemblies}
				allSpecifications={allSpecifications}
			/>
			<CoreRepairScopeDeleteDialog
				open={deleteDialogOpen}
				onClose={handleDeleteDialogClose}
				recordToDelete={recordToDelete}
				setSnackbarMessage={setSnackbarMessage}
				setOpenSnackbar={setOpenSnackbar}
				repairScopeSpecifications={repairScopeSpecifications}
			/>
		</div>
	);
};

// EXPORT
export default CoreRepairScopes;
