// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./PropertyConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import PropertyConsoleEditAddDialog from "./PropertyConsoleEditAddDialog/PropertyConsoleEditAddDialog";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	name: "Name",
	address: "Address",
	oic: "OIC",
};

const searchDefaultValue = "name";

// OTHER

// REACT COMPONENT
const PropertyConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [properties, setProperties] = useState([]);
	const [filteredProperties, setFilteredProperties] = useState([]);

	const [propertyRows, setPropertyRows] = useState([]);
	const [propertyColumns, setPropertyColumns] = useState([]);

	const [nameSortDirection, setNameSortDirection] = useState("none");
	const [createdSortDirection, setCreatedSortDirection] = useState("none");

	const [offices, setOffices] = useState([]);

	const [recordToEdit, setRecordToEdit] = useState({});
	const [loading, setLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);

	// pagination
	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredProperties]);

	// INPUT HANDLERS

	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
		handleAPICalls();
	};

	const handleEditAddDialogCloseNoRefresh = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await fetchProperties();
		await fetchOffices();
		setLoading(false);
	};

	const fetchProperties = async (sortBy, sortDirection) => {
		let queryParams;
		if (sortBy && sortDirection) {
			queryParams = `sortBy=${sortBy}&sortDirection=${sortDirection}`;
		}

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/jobs/property/all?${queryParams}`
			);
			setProperties(res.data);
			setFilteredProperties(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
		} catch (error) {
			console.log(error);
		}
	};

	const fetchOffices = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/offices/all"
			);
			setOffices(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	// HELPER FUNCTIONS
	// pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// search
	const filterPropertyConsoleRecords = (searchValue, searchByParameter) => {
		let filteredRecords = properties.filter((record) => {
			if (searchByParameter === "name") {
				return record.name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "address") {
				const fullAddress = `${record.address_line1} ${record.address_line2} ${record.city} ${record.state} ${record.zip}`;
				return fullAddress
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "oic") {
				return record.oic
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredProperties(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const sortColumn = async (column) => {
		let direction;
		let columnToSort = column.text.toLowerCase();

		// set direction based on columntext
		switch (columnToSort) {
			case "name":
				direction = nameSortDirection;
				break;
			case "created":
				direction = createdSortDirection;
				break;
			default:
				direction = "none";
		}

		// switch direction to new direction
		switch (direction) {
			case "none":
				direction = "ASC";
				break;
			case "ASC":
				direction = "DESC";
				break;
			case "DESC":
				direction = "none";
				break;
			default:
				direction = "none";
		}

		// all other columns are set to none
		if (columnToSort !== "id") {
			setNameSortDirection("none");
		}
		if (columnToSort !== "title") {
			setCreatedSortDirection("none");
		}

		// set new direction
		switch (columnToSort) {
			case "name":
				setNameSortDirection(direction);
				break;
			case "created":
				setCreatedSortDirection(direction);
				break;
			default:
				break;
		}

		if (direction === "none") {
			// default direction and column
			direction = "ASC";
			columnToSort = "name";
		}

		await fetchProperties(columnToSort, direction);
	};

	const createColumnData = () => {
		setPropertyColumns([
			{
				text: "Name",
				sortFunction: sortColumn,
				sortDirection: nameSortDirection,
			},
			{ text: "Address" },
			{ text: "DropBox File Path" },
			{ text: "OIC" },
			{
				text: "Created",
				sortFunction: sortColumn,
				sortDirection: createdSortDirection,
			},
			{}, // edit icon
		]);
	};

	const createRowData = () => {
		setPropertyRows(
			filteredProperties.map((property) => [
				{ text: property.name },
				{
					text: `${property.address_line1} ${
						property.address_line2 != null
							? property.address_line2
							: null
					} ${property.city} ${property.state} ${property.zip}`,
				},
				{ text: property.dropbox_file_path },
				{ text: property.oic },
				{ text: convertToPST(property.created) },
				{
					icon: "edit",
					onClick: () => handleEditClick(property),
				},
			])
		);
	};

	// RENDER
	return (
		<div className="PropertyConsole">
			<div className="PropertyConsoleHeader">
				<div className="PropertyConsoleHeaderTitle">
					Property Console
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddClick}
						disabled={loading}
					>
						New Property
					</Button>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={filterPropertyConsoleRecords}
				/>
			</div>
			{loading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!loading && (
				<div className="tableContainerDiv">
					{filteredProperties.length === 0 && <J2NoResultsFound />}
					{filteredProperties.length > 0 &&
						propertyRows.length > 0 &&
						propertyColumns.length > 0 && (
							<>
								<GenericTable
									columnData={propertyColumns}
									rowData={propertyRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>

								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<PropertyConsoleEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				onCloseNoRefresh={handleEditAddDialogCloseNoRefresh}
				recordToEdit={recordToEdit}
				editing={editing}
				properties={properties}
				offices={offices}
			/>
		</div>
	);
};

// EXPORT
export default PropertyConsole;
