// DEPENDENCIES
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

// CSS
import "./BQEInformationCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Autocomplete,
	Button,
	ButtonGroup,
	CircularProgress,
	Collapse,
	Divider,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import {
	Check as CheckIcon,
	ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS
import { zipCodeRegex } from "../../../../constants";

// REACT COMPONENT
const BQEInformationCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS (for autocompletes)
	const propertyNameRef = useRef(null);

	// STATES
	// API results
	const [propertyOptions, setPropertyOptions] = useState([]);
	const [projectOptions, setProjectOptions] = useState([]);

	//  Property
	const [propertySearchValue, setPropertySearchValue] = useState("");

	// Collapse
	const [projectButtonsEnabled, setProjectButtonsEnabled] = useState(true);

	// Feedback
	const [isPropertyLoading, setIsPropertyLoading] = useState(false);
	const [isProjectLoading, setIsProjectLoading] = useState(false);

	// CONTEXT

	// USE EFFECT

	useEffect(() => {
		if (props.bqePropertyRecord) {
			// if a property record is selected
			setPropertySearchValue(props.bqePropertyRecord.displayName);
			setProjectButtonsEnabled(true);
			fetchProjectRecords(props.bqePropertyRecord.id);
		} else {
			// if a property record is not selected, clear the search value and disable the project buttons
			setPropertySearchValue("");
			setProjectButtonsEnabled(false);
			setProjectOptions([]);
		}
	}, []);

	// Check to see if entered information is valid
	useEffect(() => {
		const propertyRecordValid =
			(!props.bqePropertyRecordCreateNew &&
				props.bqePropertyRecord &&
				props.bqePropertyRecord.id) ||
			(props.bqePropertyRecordCreateNew &&
				props.propertyInfo.name &&
				props.propertyInfo.addressLine1 &&
				props.propertyInfo.city &&
				props.propertyInfo.state &&
				props.propertyInfo.zip.match(zipCodeRegex) != null);
		const projectRecordValid =
			(!props.bqeProjectRecordCreateNew && props.bqeProjectRecordID) ||
			(props.bqeProjectRecordCreateNew &&
				props.projectInfo.displayName &&
				props.dealOwner);

		if (propertyRecordValid && projectRecordValid) {
			props.setIsBqeInformationValid(true);
		} else {
			props.setIsBqeInformationValid(false);
		}
	}, [
		props.bqePropertyRecord,
		props.bqeProjectRecordID,
		props.bqePropertyRecordCreateNew,
		props.bqeProjectRecordCreateNew,
	]);

	// INPUT HANDLERS
	// Property Record Creation
	const searchExistingPropertyClicked = () => {
		if (!props.bqePropertyRecordCreateNew) {
			return;
		}

		props.setBqePropertyRecordCreateNew(false);
		props.setBqePropertyRecord(null);

		setPropertyOptions([]);

		projectSectionClosed();
	};

	const createNewPropertyClicked = () => {
		if (props.bqePropertyRecordCreateNew) {
			return;
		}

		// Property
		props.setBqePropertyRecordCreateNew(true);

		setPropertyOptions([]);
		setPropertySearchValue("");

		props.setBqePropertyRecord(null);
		props.setBqeProjectRecordID(null);

		// Clear the autocomplete
		const propertySearchValueEle =
			propertyNameRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (propertySearchValueEle) propertySearchValueEle.click();

		// Project
		setProjectOptions([]);

		setProjectButtonsEnabled(false);

		props.setBqeProjectRecordCreateNew(true);
	};

	// Project Record Creation
	const searchExistingProjectClicked = () => {
		props.setBqeProjectRecordCreateNew(false);
	};

	const createNewProjectClicked = () => {
		props.setBqeProjectRecordCreateNew(true);

		props.setBqeProjectRecordID(null);
	};

	const propertyNameSelected = (event, newValue) => {
		if (newValue && newValue.displayName) {
			// if a deal name is selected and the user didn't just clear the autocomplete
			props.setBqePropertyRecord(newValue);
			setPropertySearchValue(newValue.displayName);

			projectSectionOpened(newValue.id);
		} else {
			// if the user cleared the autocomplete
			props.setBqePropertyRecord(null);
			setPropertySearchValue("");

			setPropertyOptions([]);

			projectSectionClosed();
		}
	};

	const projectSelected = (event) => {
		props.setBqeProjectRecordID(event.target.value);
	};

	// API FUNCTIONS
	const propertyNameChanged = useMemo(
		() =>
			debounce(async (event) => {
				const textLength = event.target.value.length;

				// if the current text length is less than 3, we will get too many results, so return
				if (textLength < 3) {
					setPropertyOptions([]);
					return;
				}

				setIsPropertyLoading(true);

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/bqe/property/byName",
						{
							propertyName: event.target.value,
						}
					);

					setPropertyOptions(
						res.data == ""
							? []
							: res.data.filter(
									(option) =>
										!option.displayName.endsWith(
											"(deleted)"
										)
							  )
					);
				} catch (error) {
					setPropertyOptions([]);
					console.log("Error in propertyNameChanged: ", error);
				}

				setIsPropertyLoading(false);
			}, 500), // 500ms debounce
		[]
	);

	const fetchProjectRecords = async (propertyID) => {
		// Fetch project records for the selected property
		setIsProjectLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/bqe/project/byProperty",
				{
					propertyID: propertyID,
				}
			);

			// Filter out deleted projects
			setProjectOptions(
				res.data == ""
					? []
					: res.data.filter(
							(option) =>
								!option.displayName.endsWith("(deleted)")
					  )
			);
		} catch (error) {
			setProjectOptions([]);
			console.log("Error in fetchProjectRecords: ", error);
		}

		setIsProjectLoading(false);
	};

	// HELPER FUNCTIONS
	// Standard debounce function
	function debounce(callback, wait) {
		let timeoutId = null;
		return (...args) => {
			window.clearTimeout(timeoutId);
			timeoutId = window.setTimeout(() => {
				callback.apply(null, args);
			}, wait);
		};
	}

	const projectSectionOpened = (id) => {
		fetchProjectRecords(id);

		setProjectButtonsEnabled(true);

		props.setBqeProjectRecordCreateNew(false);
	};

	const projectSectionClosed = () => {
		setProjectOptions([]);
	};

	// RENDER
	return (
		<div className="BQEInformationCard">
			<div className="bqeCardHeader">BQE Information</div>
			<div className="bqeCardSubheader">
				<h1>Property Record</h1>
				<ButtonGroup>
					<Button
						onClick={createNewPropertyClicked}
						variant={
							props.bqePropertyRecordCreateNew
								? "contained"
								: "outlined"
						}
					>
						Create New
					</Button>
					<Button
						onClick={searchExistingPropertyClicked}
						variant={
							!props.bqePropertyRecordCreateNew
								? "contained"
								: "outlined"
						}
					>
						Search Existing
					</Button>
				</ButtonGroup>
			</div>

			{/* SEARCH EXISTING PROPERTY */}
			<Collapse
				in={!props.bqePropertyRecordCreateNew}
				id="searchExistingPropertyCollapse"
			>
				<div className="collapseContainerDiv">
					<div className="bqeCardRow">
						<Autocomplete
							sx={{ flex: 1 }}
							options={propertyOptions}
							ref={propertyNameRef}
							getOptionLabel={(option) =>
								option.displayName ?? option
							}
							filterSelectedOptions
							value={props.bqePropertyRecord}
							onChange={propertyNameSelected}
							clearOnBlur={false}
							loading={isPropertyLoading}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Property Name"
									placeholder="Property Name"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<InputAdornment position="end">
												{isPropertyLoading ? (
													<CircularProgress
														color="primary"
														size={20}
													/>
												) : null}
												{params.InputProps.endAdornment}
											</InputAdornment>
										),
										startAdornment: (
											<InputAdornment position="start">
												{props.bqePropertyRecord ==
												null ? (
													<ErrorOutlineIcon
														color="error"
														size={20}
													/>
												) : (
													<CheckIcon
														color="success"
														size={20}
													/>
												)}
											</InputAdornment>
										),
									}}
									value={propertySearchValue || ""}
									onKeyUp={propertyNameChanged}
								/>
							)}
						/>
					</div>
				</div>
			</Collapse>

			{/* CREATE NEW PROPERTY */}
			<Collapse
				in={props.bqePropertyRecordCreateNew}
				id="createNewPropertyCollapse"
			>
				<div className="collapseContainerDiv">
					<div className="bqeCardRow">
						<TextField
							sx={{ flex: 1 }}
							disabled
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{props.propertyInfo.name ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
							label="Property Name"
							value={props.propertyInfo.name}
						/>
					</div>
					<div className="bqeCardRow">
						<TextField
							sx={{ flex: 1 }}
							disabled
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{props.propertyInfo.addressLine1 ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
							label="Address Line 1"
							value={props.propertyInfo.addressLine1}
						/>
						<TextField
							sx={{ flex: 1 }}
							disabled
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<CheckIcon color="success" size={20} />
									</InputAdornment>
								),
							}}
							label="Address Line 2"
							value={props.propertyInfo.addressLine2}
						/>
					</div>
					<div className="bqeCardRow">
						<TextField
							sx={{ flex: 1 }}
							disabled
							label="City"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{props.propertyInfo.city ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
							value={props.propertyInfo.city}
						/>
						<TextField
							sx={{ flex: 1 }}
							disabled
							label="State"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{props.propertyInfo.state ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
							value={props.propertyInfo.state}
						/>
						<TextField
							sx={{ flex: 1 }}
							disabled
							label="Zip"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{props.propertyInfo.zip.match(
											zipCodeRegex
										) != null ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
							value={props.propertyInfo.zip}
						/>
					</div>
				</div>
			</Collapse>

			{/* PROJECT SECTION */}
			{(props.bqePropertyRecordCreateNew || props.bqePropertyRecord) && (
				<Divider sx={{ width: "stretch" }} />
			)}
			<Collapse
				in={props.bqePropertyRecordCreateNew || props.bqePropertyRecord}
				id="projectSectionCollapse"
			>
				<div className="bqeCardSubheader" id="bqeCardCollapseSubheader">
					<h1>Project Record</h1>
					<ButtonGroup>
						<Button
							onClick={createNewProjectClicked}
							variant={
								props.bqeProjectRecordCreateNew
									? "contained"
									: "outlined"
							}
							disabled={!projectButtonsEnabled}
						>
							Create New
						</Button>
						<Button
							onClick={searchExistingProjectClicked}
							variant={
								!props.bqeProjectRecordCreateNew
									? "contained"
									: "outlined"
							}
							disabled={!projectButtonsEnabled}
						>
							Search Existing
						</Button>
					</ButtonGroup>
				</div>

				{/* SEARCH EXISTING PROJECT */}
				<Collapse
					in={!props.bqeProjectRecordCreateNew}
					id="bqeCardCollapse"
				>
					<div className="collapseContainerDiv">
						{isProjectLoading && (
							<div className="bqeLoadingCardRow">
								<CircularProgress color="primary" />
							</div>
						)}
						{!isProjectLoading && (
							<div className="bqeCardRow">
								<FormControl sx={{ flex: 1 }}>
									<InputLabel id="projectRecordLabel">
										Project
									</InputLabel>
									<Select
										labelId="projectRecordLabel"
										value={props.bqeProjectRecordID}
										label="Project"
										onChange={projectSelected}
										startAdornment={
											<InputAdornment position="start">
												{props.bqeProjectRecordID ==
												null ? (
													<ErrorOutlineIcon
														color="error"
														size={20}
													/>
												) : (
													<CheckIcon
														color="success"
														size={20}
													/>
												)}
											</InputAdornment>
										}
									>
										{projectOptions.map((option) => (
											<MenuItem
												key={option.id}
												value={option.id}
											>
												{option.displayName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						)}
					</div>
				</Collapse>

				{/* CREATE NEW PROJECT */}
				<Collapse
					in={props.bqeProjectRecordCreateNew}
					id="bqeCardCollapse"
				>
					<div className="collapseContainerDiv">
						<div className="bqeCardRow">
							<TextField
								sx={{ flex: 1 }}
								disabled
								label="Project Name"
								value={props.projectInfo.displayName}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											{props.projectInfo.displayName ? (
												<CheckIcon
													color="success"
													size={20}
												/>
											) : (
												<ErrorOutlineIcon
													color="error"
													size={20}
												/>
											)}
										</InputAdornment>
									),
								}}
							/>
							<TextField
								sx={{ flex: 1 }}
								disabled
								label="Project Manager"
								value={props.dealOwner}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											{props.dealOwner ? (
												<CheckIcon
													color="success"
													size={20}
												/>
											) : (
												<ErrorOutlineIcon
													color="error"
													size={20}
												/>
											)}
										</InputAdornment>
									),
								}}
							/>
						</div>
					</div>
				</Collapse>
			</Collapse>
		</div>
	);
};

export default BQEInformationCard;
