// DEPENDENCIES
import React, { useState, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./DashboardCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Collapse, IconButton } from "@mui/material";

import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

// CUSTOM COMPONENTS

// CONSTANTS

// REACT COMPONENT
const DashboardCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [dashboardCardOpen, setDashboardCardOpen] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// INPUT HANDLERS
	const dashboardCardDropdownClicked = () => {
		setDashboardCardOpen(!dashboardCardOpen);

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Dashboards",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: `Accessed the ${props.dashboard.name} dashboard.`,
		});
	};

	// RENDER
	return (
		<div className="DashboardCard">
			<div
				className="dashboardCardHeader"
				onClick={dashboardCardDropdownClicked}
			>
				<div className="leftSideDashboardCardHeader">
					<IconButton sx={{ color: "black" }}>
						{dashboardCardOpen ? (
							<ArrowDropUp />
						) : (
							<ArrowDropDown />
						)}
					</IconButton>
					<div className="dashboardCardTitles">
						<h2>{props.dashboard.name}</h2>
						<h3>{props.dashboard.dashboard_category_name}</h3>
					</div>
				</div>
			</div>
			<Collapse
				in={dashboardCardOpen}
				id="dashboardiframeContainerCollapse"
			>
				<div className="dashboardiframeContainer">
					{dashboardCardOpen && ( // saves resources by not rendering the iframe until the container is open
						<iframe
							id="IFrameElement"
							title={props.dashboard.name}
							src={props.dashboard.iframe_link.replace(
								"TOOLS_USER_FULL_NAME",
								props.dashboard.zoho_dashboard_name
							)}
							width="100%"
							height={props.dashboard.dashboard_height}
							frameborder="0"
						></iframe>
					)}
				</div>
			</Collapse>
		</div>
	);
};

export default DashboardCard;
