// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./SpecificationListItem.css";

// API IMPORTS

// MUI COMPONENTS
import { Typography, IconButton } from "@mui/material";

// MUI ICONS
import CommentIcon from "@mui/icons-material/Comment";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Tooltip from "@mui/material/Tooltip";
// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const SpecificationListItem = (props) => {
	// STATES
	const [editTooltipOpen, setEditTooltipOpen] = useState(false);

	// CONTEXT

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS
	const handleEditTooltipOpen = () => {
		if (props.unsavedChanges || props.isJobLocked) {
			setEditTooltipOpen(true);
		}
	};

	const handleEditTooltipClose = () => {
		setEditTooltipOpen(false);
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div
			className="SpecificationListItem"
			style={
				props.isJobLocked
					? {
							backgroundColor: "#dddfe1",
							boxShadow: "0px 5px 15px rgb(0, 0, 0, 0)",
					  }
					: {
							backgroundColor: "#ffffff",
							boxShadow: "0px 5px 15px rgb(0, 0, 0, 0.35)",
					  }
			}
		>
			<div className="contentContainer">
				<div className="division">{props.spec.division}</div>
				<div className="title">{props.spec.title}</div>
			</div>

			<div className="iconContainer">
				{props.spec.parent_id !== null ? (
					<Typography style={{ color: "gray" }}>
						Local Copy
					</Typography>
				) : null}
				<IconButton
					className="specificationListItemIconButton"
					sx={{ minHeight: 0, minWidth: 0, padding: 0 }} // minHeight and minWidth are set to 0 to remove "padding" (MUI quirk)
				>
					<CommentIcon
						id="commentIcon"
						color="secondary"
						onClick={() =>
							props.openSpecificationComments(props.spec)
						}
					/>
				</IconButton>
				<Tooltip
					open={editTooltipOpen}
					onOpen={handleEditTooltipOpen}
					onClose={handleEditTooltipClose}
					arrow
					title="Unlock the job and save your changes to edit."
				>
					<div>
						<IconButton
							className="specificationListItemIconButton"
							sx={{ minHeight: 0, minWidth: 0, padding: 0 }} // minHeight and minWidth are set to 0 to remove "padding" (MUI quirk)
							disabled={props.unsavedChanges || props.isJobLocked}
						>
							<EditIcon
								id="editIcon"
								color={
									props.unsavedChanges || props.isJobLocked
										? "disabled"
										: "primary"
								}
								onClick={() =>
									props.editSpecificationText(props.spec)
								}
							/>
						</IconButton>
					</div>
				</Tooltip>
				<IconButton
					id="specificationListItemIconButton"
					sx={{ minHeight: 0, minWidth: 0, padding: 0 }} // minHeight and minWidth are set to 0 to remove "padding" (MUI quirk)
					onClick={() =>
						props.handleGenerateSingleSpecificationClicked(
							props.spec
						)
					}
				>
					<DownloadIcon id="downloadIcon" color="primary" />
				</IconButton>
				<IconButton
					id="specificationListItemIconButton"
					sx={{ minHeight: 0, minWidth: 0, padding: 0 }} // minHeight and minWidth are set to 0 to remove "padding" (MUI quirk)
					onClick={() => props.specificationRemoved(props.spec.id)}
					disabled={props.isJobLocked}
				>
					<RemoveCircleOutlineIcon
						id="removeIcon"
						color={props.isJobLocked ? "disabled" : "error"}
					/>
				</IconButton>
			</div>
		</div>
	);
};

// EXPORT
export default SpecificationListItem;
