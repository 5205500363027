// DEPENDENCIES
import React, { useEffect, useContext } from "react";

// CSS
import "./CompanyCamInformationCard.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import { TextField, InputAdornment } from "@mui/material";

import {
	Check as CheckIcon,
	ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS
import { dealNameRegex } from "../../../../constants";

// OTHER

// REACT COMPONENT
const CompanyCamInformationCard = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {}, []);

	// INPUT HANDLERS

	// API FUNCTIONS

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="CompanyCamInformationCard">
			<div className="ccCardHeader">Company Cam Information</div>
			<div className="ccPhaseContainer">
				<div className="ccPhaseHeader">Phase 1: {props.phase1}</div>
				<div className="ccPhaseContainerRow">
					<TextField
						sx={{ flex: 1 }}
						label="CC Project Name"
						value={`${props.propertyName}: ${props.projectDisplayName}: ${props.phase1}`}
						disabled
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									{`${props.propertyName}: ${props.projectDisplayName}: ${props.phase1}`.match(
										dealNameRegex
									) != null ? (
										<CheckIcon color="success" size={20} />
									) : (
										<ErrorOutlineIcon
											color="error"
											size={20}
										/>
									)}
								</InputAdornment>
							),
						}}
					/>
				</div>
			</div>
			{props.phase2 !== "" && (
				<div className="ccPhaseContainer">
					<div className="ccPhaseHeader">Phase 2: {props.phase2}</div>
					<div className="ccPhaseContainerRow">
						<TextField
							sx={{ flex: 1 }}
							label="CC Project Name"
							value={`${props.propertyName}: ${props.projectDisplayName}: ${props.phase2}`}
							disabled
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{`${props.propertyName}: ${props.projectDisplayName}: ${props.phase2}`.match(
											dealNameRegex
										) != null ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
					</div>
				</div>
			)}
			{props.phase3 !== "" && (
				<div className="ccPhaseContainer">
					<div className="ccPhaseHeader">Phase 3: {props.phase3}</div>
					<div className="ccPhaseContainerRow">
						<TextField
							sx={{ flex: 1 }}
							label="CC Project Name"
							value={`${props.propertyName}: ${props.projectDisplayName}: ${props.phase3}`}
							disabled
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{`${props.propertyName}: ${props.projectDisplayName}: ${props.phase3}`.match(
											dealNameRegex
										) != null ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
					</div>
				</div>
			)}
			{props.phase4 !== "" && (
				<div className="ccPhaseContainer">
					<div className="ccPhaseHeader">Phase 4: {props.phase4}</div>
					<div className="ccPhaseContainerRow">
						<TextField
							sx={{ flex: 1 }}
							label="CC Project Name"
							value={`${props.propertyName}: ${props.projectDisplayName}: ${props.phase4}`}
							disabled
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{`${props.propertyName}: ${props.projectDisplayName}: ${props.phase4}`.match(
											dealNameRegex
										) != null ? (
											<CheckIcon
												color="success"
												size={20}
											/>
										) : (
											<ErrorOutlineIcon
												color="error"
												size={20}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

// EXPORT
export default CompanyCamInformationCard;
