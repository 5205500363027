// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DevicesPreviousUsers.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
} from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { convertToPST } from "../../../../constants";

// OTHER

// REACT COMPONENT
const DevicesPreviousUsers = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [loading, setLoading] = useState(true);
	const [previousUsers, setPreviousUsers] = useState({});

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		setLoading(true);
		const filteredUsers = props.devicesToEmployees.filter(
			(record) =>
				record.device_id === props.recordToShowPreviousUsers.id &&
				record.check_in // Ensures check_in has a value
		);
		if (filteredUsers.length > 0) {
			setPreviousUsers(filteredUsers);
		} else {
			setPreviousUsers([]);
		}
		setLoading(false);
	}, [props.open, props.recordToShowPreviousUsers]);

	// INPUT HANDLERS

	// API FUNCTIONS

	// HELPER FUNCTIONS

	const handleClose = () => {
		setPreviousUsers({});
		props.onClose();
	};

	// RENDER
	return (
		<Dialog
			id="DevicesPreviousUsersDialog"
			open={props.open}
			fullWidth
			onClose={handleClose}
		>
			<DialogTitle id="DevicesPreviousUsersDialogTitle">
				Previous Users
			</DialogTitle>
			{loading && (
				<div className="DevicesPreviousUsersLoadingSpinner">
					<CircularProgress />
				</div>
			)}

			{!loading &&
				(Object.keys(previousUsers).length === 0 ? (
					<div className="DevicesPreviousUsersNoResultsFound">
						<J2NoResultsFound />
					</div>
				) : (
					<DialogContent id="DevicesPreviousUsersDialogContent">
						<div className="FormBlock">
							<Divider />
							{previousUsers.map((record, index) => {
								return (
									<>
										<div className="FormRow">
											<strong>Name: </strong>
											{record.first_name +
												" " +
												record.last_name}
											<strong>Check-Out: </strong>
											{convertToPST(record.check_out)}
											<strong>Check-In: </strong>
											{convertToPST(record.check_in)}
										</div>
										<Divider />
									</>
								);
							})}
						</div>
						<div className="DevicesPreviousUsersButtons">
							<Button
								variant="outlined"
								color="error"
								onClick={handleClose}
							>
								Close
							</Button>
						</div>
					</DialogContent>
				))}
		</Dialog>
	);
};

// EXPORT
export default DevicesPreviousUsers;
