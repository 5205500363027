// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./AddChangesCommentDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS

// CONTEXT

// MUI COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

// MUI ICONS

// CUSTOM COMPONENTS

// CONSTANTS

// OTHER

// REACT COMPONENT
const AddChangesCommentDialog = (props) => {
	// INPUT HANDLERS
	const commentInputChanged = (event) => {
		props.setChangesComment(event.target.value);
	};

	// RENDER
	return (
		<Dialog
			id="addChangesCommentDialog"
			open={props.open}
			maxWidth="xl"
			disableEscapeKeyDown
		>
			<DialogTitle id="addChangesCommentDialogTitle">
				Please Describe Your Changes
			</DialogTitle>
			<DialogContent id="addChangesCommentDialogContent">
				<div className="addChangesCommentDialogRow">
					<TextField
						sx={{ flex: 1 }}
						id="addChangesCommentDialogRowComment"
						label="Describe your changes"
						value={props.changesComment}
						onChange={commentInputChanged}
					/>
				</div>
				<div className="dialogButtons">
					<Button
						onClick={props.handleClose}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={props.handleSaveClick} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default AddChangesCommentDialog;
