// DEPENDENCIES
import React from "react";
import dayjs from "dayjs";

// CSS
import "./DetailComment.css";

// MUI COMPONENTS
import { IconButton } from "@mui/material";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";

// REACT COMPONENT
const DetailComment = (props) => {
	return (
		<div
			className="DetailComment"
			style={
				props.showBottomBorder
					? { borderBottom: "1px solid #e0e0e0" }
					: { borderBottom: "none" }
			}
		>
			<div className="detailCommentContainer">
				<div className="detailCommentBody">
					<div className="detailCommentHeader">
						<div className="detailCommentPFPContainer">
							<img
								src={props.comment.submitted_by_pfp_url}
								alt={`${props.comment.submitted_by} Headshot`}
							/>
						</div>
						<div className="detailCommentTitlesContainer">
							<h2 className="detailCommentSubmitter">
								{props.comment.submitted_by}
							</h2>
							<h3 className="detailCommentDate">
								{dayjs(props.comment.created).format(
									"MMM DD, YYYY"
								)}
							</h3>
						</div>
					</div>
					<p className="detailCommentText">{props.comment.comment}</p>
				</div>
				{props.canDeleteComment && (
					<div className="detailCommentDeleteButtonContainer">
						<IconButton
							onClick={() =>
								props.deleteComment(props.comment.id)
							}
							size="small"
							color="error"
						>
							<DeleteOutlineIcon fontSize="small" />
						</IconButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default DetailComment;
