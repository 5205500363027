// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./PhaseConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import PhaseConsoleEditAddDialog from "./PhaseConsoleEditAddDialog/PhaseConsoleEditAddDialog";
import GenericTable from "../../Misc/Table/GenericTable";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";
import SearchBar from "../../Misc/SearchBar/SearchBar";

const searchByParameterMap = {
	property_name: "Property",
	project_name: "Project",
	phase_name: "Phase Name",
	phase_type_name: "Phase Type",
	owner: "Owner",
};

const searchDefaultValue = "phase_name";

// OTHER

// REACT COMPONENT
const PhaseConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [phases, setPhases] = useState([]);
	const [filteredPhases, setFilteredPhases] = useState([]);

	const [phaseRows, setPhaseRows] = useState([]);
	const [phaseColumns, setPhaseColumns] = useState([]);

	const [propertySortDirection, setPropertySortDirection] = useState("none");
	const [createdSortDirection, setCreatedSortDirection] = useState("none");

	const [properties, setProperties] = useState([]);
	const [projects, setProjects] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [phaseTypes, setPhaseTypes] = useState([]);
	const [phaseNames, setPhaseNames] = useState([]);

	const [recordToEdit, setRecordToEdit] = useState({});
	const [loading, setLoading] = useState(false);
	const [editAddDialogOpen, setEditAddDialogOpen] = useState(false);
	const [editing, setEditing] = useState(false);

	// pagination
	const [numberOfPages, setNumberOfPages] = useState(5);
	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		handleAPICalls();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredPhases]);

	// INPUT HANDLERS

	const handleAddClick = () => {
		setEditing(false);
		setEditAddDialogOpen(true);
	};

	const handleEditClick = (record) => {
		setRecordToEdit(record);
		setEditing(true);
		setEditAddDialogOpen(true);
	};

	const handleEditAddDialogClose = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
		handleAPICalls();
	};

	const handleEditAddDialogCloseNoRefresh = () => {
		setEditing(false);
		setEditAddDialogOpen(false);
		setRecordToEdit({});
	};

	// API FUNCTIONS

	const handleAPICalls = async () => {
		setLoading(true);
		await fetchPhases();
		await fetchProperties();
		await fetchProjects();
		await fetchEmployees();
		await fetchPhaseTypes();
		await fetchPhaseNames();
		setLoading(false);
	};

	const fetchPhases = async (sortBy, sortDirection) => {
		let queryParams;
		if (sortBy && sortDirection) {
			queryParams = `sortBy=${sortBy}&sortDirection=${sortDirection}`;
		}

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/jobs/phases/all?${queryParams}`
			);
			setPhases(res.data);
			setFilteredPhases(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));
		} catch (error) {
			console.log(error);
		}
	};

	const fetchProperties = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/property/all"
			);
			setProperties(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchProjects = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/project/all"
			);
			setProjects(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchEmployees = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/employees/all"
			);
			setEmployees(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchPhaseTypes = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/phaseTypes/all"
			);
			setPhaseTypes(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchPhaseNames = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/jobs/phases/names/all"
			);
			setPhaseNames(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	// HELPER FUNCTIONS
	// pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// search
	const filterPhaseConsoleRecords = (searchValue, searchByParameter) => {
		let filteredRecords = phases.filter((record) => {
			if (searchByParameter === "phase_name") {
				return record.name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "property_name") {
				return record.property_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "project_name") {
				return record.project_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "phase_type_name") {
				return record.phase_type_name
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "owner") {
				const ownerName = `${record.owner_first_name || ""} ${
					record.owner_last_name || ""
				}`.toLowerCase();
				return ownerName.includes(searchValue.toLowerCase());
			}
		});

		setFilteredPhases(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);
	};

	const sortColumn = async (column) => {
		let direction;
		let columnToSort = column.text.toLowerCase();

		// set direction based on columntext
		switch (columnToSort) {
			case "property":
				direction = propertySortDirection;
				break;
			case "created":
				direction = createdSortDirection;
				break;
			default:
				direction = "none";
		}

		// switch direction to new direction
		switch (direction) {
			case "none":
				direction = "ASC";
				break;
			case "ASC":
				direction = "DESC";
				break;
			case "DESC":
				direction = "none";
				break;
			default:
				direction = "none";
		}

		// all other columns are set to none
		if (columnToSort !== "property") {
			setPropertySortDirection("none");
		}
		if (columnToSort !== "title") {
			setCreatedSortDirection("none");
		}

		// set new direction
		switch (columnToSort) {
			case "property":
				setPropertySortDirection(direction);
				break;
			case "created":
				setCreatedSortDirection(direction);
				break;
			default:
				break;
		}

		if (direction === "none") {
			// default direction and column
			direction = "ASC";
			columnToSort = "property";
		}

		await fetchPhases(columnToSort, direction);
	};

	const createColumnData = () => {
		setPhaseColumns([
			{
				text: "Property",
				sortFunction: sortColumn,
				sortDirection: propertySortDirection,
			},
			{ text: "Project" },
			{ text: "Phase Name" },
			{ text: "Phase Type" },
			{ text: "Dropbox Path" },
			{ text: "Owner" },
			{
				text: "Created",
				sortFunction: sortColumn,
				sortDirection: createdSortDirection,
			},
			{}, // edit button
		]);
	};

	const createRowData = () => {
		setPhaseRows(
			filteredPhases.map((phase) => [
				{ text: phase.property_name },
				{ text: phase.project_name },
				{ text: phase.name },
				{ text: phase.phase_type_name },
				{ text: phase.dropbox_path },
				{
					text: `${phase.owner_first_name || ""} ${
						phase.owner_last_name || ""
					}`,
				},
				{ text: convertToPST(phase.created) },
				{
					icon: "edit",
					onClick: () => handleEditClick(phase),
				},
			])
		);
	};

	// RENDER
	return (
		<div className="PhaseConsole">
			<div className="PhaseConsoleHeader">
				<div className="PhaseConsoleHeaderTitle">
					Phase Console
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddClick}
						disabled={loading}
					>
						New Phase
					</Button>
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={filterPhaseConsoleRecords}
				/>
			</div>
			{loading && (
				<div className="loadingDiv">
					<CircularProgress />
				</div>
			)}
			{!loading && (
				<div className="tableContainerDiv">
					{filteredPhases.length === 0 && <J2NoResultsFound />}
					{filteredPhases.length > 0 &&
						phaseRows.length > 0 &&
						phaseColumns.length > 0 && (
							<>
								<GenericTable
									columnData={phaseColumns}
									rowData={phaseRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<PhaseConsoleEditAddDialog
				open={editAddDialogOpen}
				onClose={handleEditAddDialogClose}
				onCloseNoRefresh={handleEditAddDialogCloseNoRefresh}
				recordToEdit={recordToEdit}
				properties={properties}
				projects={projects}
				employees={employees}
				phaseTypes={phaseTypes}
				phaseNames={phaseNames}
				editing={editing}
			/>
		</div>
	);
};

// EXPORT
export default PhaseConsole;
