// DEPENDENCIES
import React, { useState, useEffect } from "react";

// CSS
import "./DashboardAccessConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import EditDashboardAccessDialog from "./EditDashboardAccessDialog/EditDashboardAccessDialog";
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	role: "Role",
	dashboardCategories: "Dashboard Categories",
};

const searchDefaultValue = "role";

// REACT COMPONENT
const DashboardAccessConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// API Results
	const [roles, setRoles] = useState([]);
	const [filteredRoles, setFilteredRoles] = useState([]);
	const [rolesRows, setRolesRows] = useState([]);
	const [rolesColumns, setRolesColumns] = useState([]);

	const [dashboardCategories, setDashboardCategories] = useState([]);
	const [selectedDashboardCategories, setSelectedDashboardCategories] =
		useState([]);

	// Dialog
	const [recordToEdit, setRecordToEdit] = useState({});
	const [editDashboardAccessDialogOpen, setEditDashboardAccessDialogOpen] =
		useState(false);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// USE EFFECT
	useEffect(() => {
		fetchRoles();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredRoles]);

	// INPUT HANDLERS
	const editRecordClicked = async (record) => {
		await fetchSelectedDashboardCategories(record.id);

		setRecordToEdit(record);
		setEditDashboardAccessDialogOpen(true);
	};

	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchRoles = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/roles/dashboardCategories/all"
			);
			setRoles(res.data);
			setFilteredRoles(res.data);
			setNumberOfPages(Math.ceil(res.data.length / RECORDS_PER_PAGE));

			await fetchDashboardCategories();
		} catch (error) {
			console.log("Error in fetchRoles: ", error);
		}

		setIsLoading(false);
	};

	const fetchDashboardCategories = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/roles/dashboardCategories/list"
			);
			setDashboardCategories(res.data);
		} catch (error) {
			console.log("Error in fetchTools: ", error);
		}
	};

	const fetchSelectedDashboardCategories = async (roleID) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/roles/dashboardCategories/byRole",
				{
					roleID: roleID,
				}
			);

			setSelectedDashboardCategories(res.data);
		} catch (error) {
			console.log("Error in fetchSelectedTools: ", error);
		}
	};

	// HELPER FUNCTIONS
	const filterRoles = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredRoleRecords = roles.filter((roleObj) => {
			if (searchByParameter === "role") {
				return (roleObj.role_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "dashboardCategories") {
				return (roleObj.dashboard_categories ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredRoles(filteredRoleRecords);
		setNumberOfPages(
			Math.ceil(filteredRoleRecords.length / RECORDS_PER_PAGE)
		);

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createRowData = () => {
		setRolesRows(
			filteredRoles.map((role) => [
				{ text: role.role_name },
				{
					text: (role.split_dashboard_categories ?? "")
						.split("_SPLIT_")
						.map((category) => <div>{category}</div>),
				},
				{ icon: "edit", onClick: () => editRecordClicked(role) },
			])
		);
	};

	const createColumnData = () => {
		setRolesColumns([
			{ text: "Role Name" },
			{ text: "Dashboard Categories" },
			{}, // Empty column for edit button
		]);
	};

	// RENDER
	return (
		<div className="DashboardAccessConsole">
			<div className="dashboardAccessConsoleHeader">
				<div className="dashboardAccessConsoleHeaderTitle">
					Dashboard Access Console
				</div>
				<SearchBar
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
					filterResults={(searchValue, searchByParameter) =>
						filterRoles(searchValue, searchByParameter)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredRoles.length === 0 && <J2NoResultsFound />}
					{filteredRoles.length > 0 && (
						<>
							<GenericTable
								columnData={rolesColumns}
								rowData={rolesRows.slice(
									paginationStartIndex,
									paginationEndIndex
								)}
							/>
							<div className="paginationDiv">
								<Pagination
									count={numberOfPages}
									color="primary"
									onChange={handlePageChange}
								/>
							</div>
						</>
					)}
				</div>
			)}
			<EditDashboardAccessDialog
				open={editDashboardAccessDialogOpen}
				dashboardCategories={dashboardCategories}
				selectedDashboardCategories={selectedDashboardCategories}
				editDashboardAccessDialogClosed={() =>
					setEditDashboardAccessDialogOpen(false)
				}
				recordToEdit={recordToEdit}
				fetchRoles={fetchRoles}
			/>
		</div>
	);
};

export default DashboardAccessConsole;
