// DEPENDENCIES
import React, { useContext, useEffect, useState } from "react";

// CSS
import "./NewEmployeeDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API CONFIG
import { postAPICall } from "../../../../config/apiCalls";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// CONSTANTS
import { verifyEmailRegex } from "../../../../constants";

// REACT COMPONENT
const NewEmployeeDialog = (props) => {
	const employeeID = useContext(UserContext);

	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [initials, setInitials] = useState("");

	const [oicID, setOicID] = useState(0);
	const [cellPhone, setCellPhone] = useState("");
	const [deskPhone, setDeskPhone] = useState("");
	const [email, setEmail] = useState("");

	const [hubspotID, setHubspotID] = useState("");
	const [companyCamID, setCompanyCamID] = useState("");
	const [ripplingID, setRipplingID] = useState("");
	const [bqeID, setBqeID] = useState("");
	const [jobTitle, setJobTitle] = useState("");

	const [alertOpen, setAlertOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// INPUT HANDLERS
	const firstNameChanged = (event) => {
		setFirstName(event.target.value);
	};

	const lastNameChanged = (event) => {
		setLastName(event.target.value);
	};

	const initialsChanged = (event) => {
		setInitials(event.target.value);
	};

	const oicIDChanged = (event) => {
		setOicID(event.target.value);
	};

	const emailChanged = (event) => {
		setEmail(event.target.value);
	};

	const cellPhoneChanged = (event) => {
		setCellPhone(event.target.value);
	};

	const deskPhoneChanged = (event) => {
		setDeskPhone(event.target.value);
	};

	const hubspotIDChanged = (event) => {
		setHubspotID(event.target.value);
	};

	const companyCamIDChanged = (event) => {
		setCompanyCamID(event.target.value);
	};

	const ripplingIDChanged = (event) => {
		setRipplingID(event.target.value);
	};

	const bqeIDChanged = (event) => {
		setBqeID(event.target.value);
	};

	const jobTitleChanged = (event) => {
		setJobTitle(event);
	};

	// SUBMIT HANDLER
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		let res = await postAPICall(
			instance,
			accounts[0],
			"/api/employees/add",
			{
				firstName: firstName,
				lastName: lastName,
				initials: initials,
				oicID: oicID !== 0 ? oicID : null,
				cellPhone: cellPhone,
				deskPhone: deskPhone,
				email: email,
				hubspotID: hubspotID ? hubspotID : null,
				companyCamID: companyCamID ? companyCamID : null,
				ripplingID: ripplingID ? ripplingID : null,
				bqeID: bqeID ? bqeID : null,
				jobTitleID: jobTitle ? getJobTitleIdFromName(jobTitle) : null,
				modifiedBy: employeeID,
			}
		);

		setIsLoading(false);

		formClosed();
		props.fetchEmployees();
	};

	// API FUNCTIONS

	// HELPER FUNCTIONS
	const resetForm = () => {
		setFirstName("");
		setLastName("");
		setInitials("");

		setOicID(0);
		setCellPhone("");
		setDeskPhone("");
		setEmail("");

		setHubspotID("");
		setCompanyCamID("");
		setRipplingID("");
		setBqeID("");
		setJobTitle("");

		setAlertOpen(false);
		setIsLoading(false);
	};

	const validateInputFields = () => {
		if (
			!firstName ||
			!lastName ||
			!initials ||
			!email ||
			!verifyEmailRegex(email)
		) {
			setAlertOpen(true);
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.newEmployeeDialogClosed();
	};

	const getJobTitleNameFromId = (jobTitleID) => {
		let jobTitle = props.jobTitles.find(
			(jobTitle) => jobTitle.id === jobTitleID
		);
		return jobTitle.name;
	};

	const getJobTitleIdFromName = (jobTitleName) => {
		let jobTitle = props.jobTitles.find(
			(jobTitle) => jobTitle.name === jobTitleName
		);
		return jobTitle.id;
	};

	// RENDER
	return (
		<Dialog
			id="newEmployeeDialog"
			open={props.open}
			fullWidth
			maxWidth="lg"
			onClose={formClosed}
		>
			<DialogTitle id="newEmployeeDialogTitle">New Employee</DialogTitle>
			<DialogContent id="newEmployeeDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="newEmployeeDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="newEmployeeCollapse"
								in={alertOpen}
							>
								<Alert
									id="newEmployeeAlert"
									severity="error"
									action={
										<IconButton
											id="newEmployeeCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={() => {
												setAlertOpen(false);
											}}
										>
											<CloseIcon
												id="newEmployeeCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									Please fill out all fields (first name, last
									name, email, initials).
								</Alert>
							</Collapse>
						</div>
						<div className="newEmployeeDialogRow">
							<TextField
								sx={{ flex: 3 }}
								id="newEmployeeFirstName"
								label="First Name"
								value={firstName}
								onChange={firstNameChanged}
							/>
							<TextField
								sx={{ flex: 3 }}
								id="newEmployeeLastName"
								label="Last Name"
								value={lastName}
								onChange={lastNameChanged}
							/>
							<TextField
								sx={{ flex: 1 }}
								id="newEmployeeInitials"
								label="Initials"
								value={initials}
								onChange={initialsChanged}
							/>
						</div>
						<div className="newEmployeeDialogRow">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="newEmployeeOicIDLabel">
									OIC
								</InputLabel>
								<Select
									labelId="newEmployeeOicIDLabel"
									id="newEmployeeOicID"
									value={oicID}
									label="OIC"
									onChange={oicIDChanged}
								>
									{props.offices.map((office, index) => (
										<MenuItem key={index} value={office.id}>
											{office.state}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<TextField
								sx={{ flex: 3 }}
								id="newEmployeeEmail"
								label="Email"
								value={email}
								onChange={emailChanged}
								error={!verifyEmailRegex(email) && email !== ""}
							/>
							<TextField
								sx={{ flex: 3 }}
								id="newEmployeeCellPhone"
								label="Phone (Cell)"
								value={cellPhone}
								onChange={cellPhoneChanged}
							/>
							<TextField
								sx={{ flex: 3 }}
								id="newDeskPhone"
								label="Phone (Desk)"
								value={deskPhone}
								onChange={deskPhoneChanged}
							/>
						</div>
						<div className="newEmployeeDialogRow">
							<TextField
								sx={{ flex: 1 }}
								id="newEmployeeHubspotID"
								label="Hubspot ID"
								value={hubspotID}
								onChange={hubspotIDChanged}
							/>
							<TextField
								sx={{ flex: 1 }}
								id="newEmployeeCompanyCamID"
								label="CompanyCam ID"
								value={companyCamID}
								onChange={companyCamIDChanged}
							/>
							<TextField
								sx={{ flex: 1 }}
								id="newEmployeeRipplingID"
								label="Rippling ID"
								value={ripplingID}
								onChange={ripplingIDChanged}
							/>
							<TextField
								sx={{ flex: 1 }}
								id="newEmployeeBqeID"
								label="BQE ID"
								value={bqeID}
								onChange={bqeIDChanged}
							/>
						</div>
						<div className="newEmployeeDialogRow">
							<Autocomplete
								sx={{ flex: 1 }}
								id="newEmployeeJobTitle"
								options={props.jobTitles.map(
									(jobTitle) => jobTitle.name
								)}
								renderInput={(params) => (
									<TextField {...params} label="Job Title" />
								)}
								onChange={(event, value) => {
									jobTitleChanged(value);
								}}
								value={jobTitle}
							/>
						</div>
					</>
				)}
				<div className="dialogButtons">
					<Button
						onClick={formClosed}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>
					<Button onClick={submitButtonClicked} variant="contained">
						Submit
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default NewEmployeeDialog;
