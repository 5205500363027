// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./EditAssembliesAndGroupingsCardDialog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	getAPICall,
	postAPICall,
	deleteAPICall,
} from "../../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../../context/UserContext";

// MUI COMPONENTS
import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	TextField,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

// REACT COMPONENT
const EditAssembliesAndGroupingsCardDialog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	// Record states
	const [id, setId] = useState(0);

	// Form states
	const [selectedAssemblies, setSelectedAssemblies] = useState([]);
	const [selectedGroupings, setSelectedGroupings] = useState([]);

	// Feedback states
	const [isLoading, setIsLoading] = useState(false);
	const [groupingsLoading, setGroupingsLoading] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	// Misc
	const [availableGroupings, setAvailableGroupings] = useState([]);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (props.open) {
			setId(props.recordToEdit.id);
			setSelectedAssemblies(props.selectedAssemblies);
			setSelectedGroupings(props.selectedGroupings);

			fetchInitialAvailableGroupings();
		}
	}, [props.open, props.recordToEdit]);

	// INPUT HANDLERS
	// Form input handlers
	const assemblySelected = (event, newValue) => {
		setSelectedAssemblies([...newValue]);
		filterGroupings(newValue);
	};

	const groupingSelected = (event, newValue) => {
		setSelectedGroupings([...newValue]);
	};

	// Feedback handlers
	const openAlert = (message) => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	const closeAlert = () => {
		setAlertOpen(false);
		setAlertMessage("");
	};

	// Submit button handler
	const submitButtonClicked = async () => {
		if (!validateInputFields()) return;

		setIsLoading(true);

		try {
			await removeAssemblies(id);
			await addAssemblies(id);

			await removeGroupings(id);
			await addGroupings(id);

			await postAPICall(
				instance,
				accounts[0],
				"/api/logs/automation/add",
				{
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					success: true,
					message: `Assemblies and Groupings edited on detail: ${
						props.recordToEdit.title
					}. New information is as follows: Assemblies: ${selectedAssemblies
						.map((a) => a.name)
						.toString()}; Groupings: ${selectedGroupings
						.map((g) => g.name)
						.toString()}`,
					tool_name: "Detail Library",
				}
			);
		} catch (err) {
			openAlert(err);
		}

		await props.fetchDetailRecord();
		formClosed();

		setIsLoading(false);
	};

	// API FUNCTIONS
	// Initial API calls
	const fetchInitialAvailableGroupings = async () => {
		setGroupingsLoading(true);

		let groupingRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/groupings/byAssembly",
			{
				assemblyIDs: props.selectedAssemblies.map((a) => a.id),
			}
		);

		setAvailableGroupings(groupingRes.data);

		setGroupingsLoading(false);
	};

	// Filter groupings
	const filterGroupings = async (newValue) => {
		setGroupingsLoading(true);

		let groupingRes = await getAPICall(
			instance,
			accounts[0],
			"/api/encompass/groupings/byAssembly",
			{
				assemblyIDs: newValue.map((a) => a.id),
			}
		);
		setAvailableGroupings(groupingRes.data);

		let availableGroupingIDs = groupingRes.data.map((g) => g.id);
		setSelectedGroupings(
			selectedGroupings.filter((grouping) =>
				availableGroupingIDs.includes(grouping.id)
			)
		);

		setGroupingsLoading(false);
	};

	// Assemblies
	const addAssemblies = async (detailID) => {
		if (selectedAssemblies.length === 0) return null;

		for (let i = 0; i < selectedAssemblies.length; i++) {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/encompass/assemblies/add",
				{
					detailID: detailID,
					assemblyID: selectedAssemblies[i].id,
				},
				employeeID
			);
		}
	};

	const removeAssemblies = async (detailID) => {
		const res = await deleteAPICall(
			instance,
			accounts[0],
			"/api/encompass/assemblies/deleteByDetail",
			{
				detailID: detailID,
			}
		);

		return res;
	};

	// Groupings
	const addGroupings = async (detailID) => {
		if (selectedGroupings.length === 0) return null;

		for (let i = 0; i < selectedGroupings.length; i++) {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/encompass/groupings/add",
				{
					detailID: detailID,
					groupingID: selectedGroupings[i].id,
				},
				employeeID
			);
		}
	};

	const removeGroupings = async (detailID) => {
		const res = await deleteAPICall(
			instance,
			accounts[0],
			"/api/encompass/groupings/deleteByDetail",
			{
				detailID: detailID,
			}
		);

		return res;
	};

	// HELPER FUNCTIONS
	const resetForm = () => {
		closeAlert();

		setId(0);
	};

	const validateInputFields = () => {
		if (selectedAssemblies.length === 0) {
			openAlert("Please select at least one assembly.");
			return false;
		}

		return true;
	};

	const formClosed = () => {
		resetForm();
		props.editAssembliesAndGroupingsCardDialogClosed();
	};

	// RENDER
	return (
		<Dialog
			id="editAssembliesAndGroupingsCardDialog"
			open={props.open}
			fullWidth
			onClose={formClosed}
		>
			<DialogTitle id="editAssembliesAndGroupingsCardDialogTitle">
				{props.recordToEdit.title}
			</DialogTitle>
			<DialogContent id="editAssembliesAndGroupingsCardDialogContent">
				{isLoading && (
					<div className="loadingSpinnerContainer">
						<CircularProgress color="primary" />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="editAssembliesAndGroupingsCardDialogRow">
							<Collapse
								sx={{ flex: 1 }}
								id="editAssembliesAndGroupingsCardDialogCollapse"
								in={alertOpen}
							>
								<Alert
									id="editAssembliesAndGroupingsCardDialogAlert"
									severity="error"
									action={
										<IconButton
											id="editAssembliesAndGroupingsCardDialogCloseIconButton"
											aria-label="close"
											color="inherit"
											size="small"
											onClick={closeAlert}
										>
											<CloseIcon
												id="editAssembliesAndGroupingsCardDialogCloseIcon"
												fontSize="inherit"
											/>
										</IconButton>
									}
								>
									{alertMessage}
								</Alert>
							</Collapse>
						</div>
						<div className="editAssembliesAndGroupingsCardDialogRow">
							<Autocomplete
								sx={{ flex: 1 }}
								limitTags={2}
								onChange={assemblySelected}
								multiple
								value={selectedAssemblies}
								id="assemblySelect"
								options={props.assemblies.filter(
									(assembly) =>
										!selectedAssemblies
											.map((a) => a.id)
											.includes(assembly.id)
								)}
								getOptionLabel={(option) => option.name}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField
										{...params}
										label="Assemblies"
										placeholder="Assemblies"
									/>
								)}
							/>
						</div>
						<div className="editAssembliesAndGroupingsCardDialogRow">
							{groupingsLoading && (
								<LinearProgress
									sx={{
										width: "100%",
										marginTop: "1em",
										marginBottom: "1em",
									}}
									color="primary"
								/>
							)}
							{!groupingsLoading && (
								<Autocomplete
									sx={{ flex: 1 }}
									limitTags={2}
									onChange={groupingSelected}
									multiple
									id="groupingSelect"
									options={availableGroupings.filter(
										(grouping) =>
											!selectedGroupings
												.map((g) => g.id)
												.includes(grouping.id)
									)}
									value={selectedGroupings}
									getOptionLabel={(option) => option.name}
									filterSelectedOptions
									renderInput={(params) => (
										<TextField
											{...params}
											label="Groupings"
											placeholder="Groupings"
										/>
									)}
								/>
							)}
						</div>
						<div className="dialogButtons">
							<Button
								onClick={formClosed}
								variant="outlined"
								color="error"
							>
								Cancel
							</Button>
							<Button
								onClick={submitButtonClicked}
								variant="contained"
							>
								Submit
							</Button>
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default EditAssembliesAndGroupingsCardDialog;
