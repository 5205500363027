// DEPENDENCIES
import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import dayjs from "dayjs";

// CSS
import "./RFP.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import {
	Button,
	ButtonGroup,
	TextField,
	Autocomplete,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Radio,
	RadioGroup,
	Collapse,
	Alert,
	CircularProgress,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// CUSTOM COMPONENTS
import J2MultilineTextField from "../../Misc/J2MultilineTextField/J2MultilineTextField";
import J2ErrorDialog from "../../Misc/J2ErrorDialog/J2ErrorDialog";

// Help Dialogs
import ClientInformationHelpDialog from "./RFPHelpDialogs/ClientInformationHelpDialog/ClientInformationHelpDialog";
import PropertyInformationHelpDialog from "./RFPHelpDialogs/PropertyInformationHelpDialog/PropertyInformationHelpDialog";
import ProposalSummaryHelpDialog from "./RFPHelpDialogs/ProposalSummaryHelpDialog/ProposalSummaryHelpDialog";

// CONSTANTS
const searchByOptionsMap = new Map([
	["email", "Email"],
	["lastname", "Last Name"],
]);

const rfpSourceMap = {
	referral: "Referral",
	returnClient: "Return Client",
	internetSearch: "Internet Search",
	socialMedia: "Social Media",
	j2Website: "J2 Website",
	j2EducationEvent: "J2 Education Event",
	j2SocialEvent: "J2 Social Event",
	externalEvent: "External Event (CAI, OWCAM, etc.)",
	clientDrop: "Client Drop (Holiday Gift or Office Visit)",
	santaVisit: "Santa Visit",
};

const buildingTypeMap = new Map([
	["Apartment", "Apartment"],
	["Commercial", "Commercial Building/Mixed Use"],
	["Condominium", "Condominium HOA"],
	["Nonprofit", "Nonprofit"],
	["Public", "Public"],
	["School", "School"],
	["Single Family Residence", "Single Family Residence"],
	["Other", "Other"],
]);

// OTHER
// To convert the date to UTC for compatibility with HubSpot API
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

// REACT COMPONENT
const RFP = () => {
	// MSAL
	const { instance, accounts } = useMsal();

	// REFS
	const clientSearchByValueRef = useRef(null);
	const propertySearchByValueRef = useRef(null);

	// API RESULT STATES
	const [clientOptions, setClientOptions] = useState([]);
	const [propertyOptions, setPropertyOptions] = useState([]);

	// CREATE NEW/SEARCH EXISTING STATES
	const [createNewClientEnabled, setCreateNewClientEnabled] = useState(false);
	const [searchExistingClientEnabled, setSearchExistingClientEnabled] =
		useState(true);

	const [createNewPropertyEnabled, setCreateNewPropertyEnabled] =
		useState(false);
	const [searchExistingPropertyEnabled, setSearchExistingPropertyEnabled] =
		useState(true);

	// DETAILED VIEW/SEARCH BAR COLLAPSE STATES
	const [clientDetailedViewOpen, setClientDetailedViewOpen] = useState(false);
	const [clientSearchBarOpen, setClientSearchBarOpen] = useState(true);

	const [propertyDetailedViewOpen, setPropertyDetailedViewOpen] =
		useState(false);
	const [propertySearchBarOpen, setPropertySearchBarOpen] = useState(true);

	// SEARCH BAR STATES
	const [clientSearchByParameter, setClientSearchByParameter] =
		useState("lastname");
	const [clientSearchByValue, setClientSearchByValue] = useState("");

	const [clientSearchByValueKey, setClientSearchByValueKey] = useState(true); // key to refresh the Autocomplete TF

	const [propertySearchByValue, setPropertySearchByValue] = useState("");

	// DETAILED VIEW STATES
	const [clientInfo, setClientInfo] = useState({
		email: "",
		firstname: "",
		lastname: "",
		recordID: "",
	});

	const [propertyInfo, setPropertyInfo] = useState({
		name: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		recordID: "",
	});

	// SERVICE STATES (CHECKBOXES)
	const [serviceCheckboxes, setServiceCheckboxes] = useState({
		visual: false,
		invasive: false,
		design: false,
		bidAssistance: false,
		contractAdministration: false,
		rapidVisual: false,
		claimSupport: false,
		feasibility: false,
		generalConsulting: false,
		engineeringStructural: false,
		drainage: false,
		additionalServices: false,
		windowTesting: false,
		romExercise: false,
	});

	const [
		serviceInvasiveNumberOfOpenings,
		setServiceInvasiveNumberOfOpenings,
	] = useState("");

	// SERVICE STATES (ENABLED/DISABLED)
	const [enabledServices, setEnabledServices] = useState({
		visual: true,
		invasive: true,
		design: true,
		bidAssistance: true,
		contractAdministration: true,
		rapidVisual: true,
		claimSupport: true,
		feasibility: true,
		generalConsulting: true,
		engineeringStructural: true,
		drainage: true,
		additionalServices: true,
		windowTesting: true,
		romExercise: true,
	});

	// SERVICE COST BASIS STATES (RADIO BUTTONS)
	const [serviceCostBases, setServiceCostBases] = useState({
		visual: "flatFee",
		invasive: "flatFee",
		design: "flatFee",
		bidAssistance: "flatFee",
		contractAdministration: "timeAndCost",
		rapidVisual: "flatFee",
		claimSupport: "timeAndCost",
		feasibility: "flatFee",
		generalConsulting: "timeAndCost",
		engineeringStructural: "timeAndCost",
		drainage: "timeAndCost",
		additionalServices: "timeAndCost",
		windowTesting: "flatFee",
		romExercise: "flatFee",
	});

	// SERVICE MESSAGE STATES
	const [serviceMessageOpen, setServiceMessageOpen] = useState(false);
	const [serviceMessageText, setServiceMessageText] = useState("");

	// COMPONENT STATES (COUNTS)
	const [numComponents, setNumComponents] = useState(0);

	// COMPONENT/LOCATION STATES (CHECKBOXES)
	const [componentCheckboxes, setComponentCheckboxes] = useState({
		BE: false,
		interiorUnit: false,
		interiorAttic: false,
		interiorCrawlSpace: false,
		roof: false,
		site: false,
		carportGarage: false,
		decks: false,
		windows: false,
		stairway: false,
		plumbing: false,
		HVAC: false,
	});

	// COMPONENT STATES (ENABLED/DISABLED)
	const [enabledComponents, setEnabledComponents] = useState({
		BE: true,
		interiorUnit: true,
		interiorAttic: true,
		interiorCrawlSpace: true,
		roof: true,
		site: true,
		carportGarage: true,
		decks: true,
		windows: true,
		stairway: true,
		plumbing: true,
		HVAC: true,
	});

	// COMPONENT MESSAGE STATES
	const [componentMessageOpen, setComponentMessageOpen] = useState(false);
	const [componentMessageText, setComponentMessageText] = useState("");

	// VARIOUS STATES
	const [proposalSource, setProposalSource] = useState("");
	const [proposalSourceReferral, setProposalSourceReferral] = useState("");

	const [scopeBackground, setScopeBackground] = useState("");
	const [additionalNotes, setAdditionalNotes] = useState("");

	const [buildingType, setBuildingType] = useState("");
	const [oic, setOIC] = useState("");
	const [proposalDueDate, setProposalDueDate] = useState(
		dayjs().add(3, "day")
	);

	// ERROR STATES
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [clientEmailError, setClientEmailError] = useState(false);

	// SUCCESS STATES
	const [isSuccess, setIsSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");

	const [isSubmitting, setIsSubmitting] = useState(false);

	// HELP DIALOG STATES
	const [
		clientInformationHelpDialogOpen,
		setClientInformationHelpDialogOpen,
	] = useState(false);
	const [
		propertyInformationHelpDialogOpen,
		setPropertyInformationHelpDialogOpen,
	] = useState(false);
	const [proposalSummaryHelpDialogOpen, setProposalSummaryHelpDialogOpen] =
		useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		if (employeeID !== 0) {
			postAPICall(instance, accounts[0], "/api/logs/access/add", {
				page_name: "RFP",
				employeeID: employeeID,
				date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
				notes: "Accessed the RFP page",
			});
		}
	}, []);

	useEffect(() => {
		if (numComponents >= 2) {
			disableUncheckedComponents();
			componentMessageOpened(
				"Only two components can be selected at a time."
			);
		} else {
			enableAllComponents();
			componentMessageClosed();
		}
	}, [numComponents]);

	// CLIENT INPUT HANDLER METHODS
	const createNewClientClicked = () => {
		// if the create new client button is already enabled, don't do anything
		if (createNewClientEnabled) {
			return;
		}

		// close the client search bar
		setClientSearchBarOpen(false);

		// enable the create new client view
		setCreateNewClientEnabled(true);
		setSearchExistingClientEnabled(false);

		// clear all previous values that were in before
		setClientInfo({
			email: "",
			firstname: "",
			lastname: "",
			recordID: "",
		});

		// clear the searchbyvalue autocomplete
		const clientSearchByValueEle =
			clientSearchByValueRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (clientSearchByValueEle) clientSearchByValueEle.click();

		// open the client detailed view
		setClientDetailedViewOpen(true);
	};

	const searchExistingClientClicked = (override = false) => {
		// override is for when all the inputs are to be cleared, not just a repeated click on the search existing client button when already selected
		if (searchExistingClientEnabled && !override) {
			return;
		}

		// open the client search bar
		setClientSearchBarOpen(true);
		setClientDetailedViewOpen(false);

		// enable the search existing client view
		setSearchExistingClientEnabled(true);
		setCreateNewClientEnabled(false);

		// clear all previous values that were in before
		setClientSearchByValue("");

		setClientInfo({
			email: "",
			firstname: "",
			lastname: "",
			recordID: "",
		});
	};

	const clientSearchByParameterSelected = (event) => {
		const newParameter = event.target.value;
		setClientSearchByParameter(newParameter);

		// clear the searchByValue autocomplete
		const clientSearchByValueEle =
			clientSearchByValueRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (clientSearchByValueEle) clientSearchByValueEle.click();

		// close client detailed view
		setClientDetailedViewOpen(false);

		// clear all values that could've been in before
		setClientSearchByValue("");
		setClientSearchByValueKey(!clientSearchByValueKey); // this is a key to refresh the Autocomplete TF

		setClientInfo({
			email: "",
			firstname: "",
			lastname: "",
			recordID: "",
		});

		setClientOptions([]);
	};

	const clientSearchByValueSelected = (event, newValue) => {
		if (newValue && newValue.properties) {
			// if the searchByValue is selected and the user didn't just clear the autocomplete
			setClientDetailedViewOpen(true);

			setClientInfo({
				email: newValue.properties.email,
				firstname: newValue.properties.firstname,
				lastname: newValue.properties.lastname,
				recordID: newValue.properties.hs_object_id,
			});
		} else {
			// if the user cleared the autocomplete (still triggers as removing the value is technically a selection event)
			setClientDetailedViewOpen(false);

			setClientInfo({
				email: "",
				firstname: "",
				lastname: "",
				recordID: "",
			});
		}
	};

	// TODO: IMPLEMENT
	const clientInfoChanged = (event) => {
		setClientInfo((prevInfo) => ({
			...prevInfo,
			[event.target.name]: event.target.value,
		}));

		if (event.target.name === "email") {
			setClientEmailError(false);
		}
	};

	const checkEmail = async (event) => {
		const textLength = event.target.value.length;
		const emailRegex = /^\S+@\S+\.\S+$/;
		// if the current text length is less than 3, we will get too many results, so return
		if (textLength < 3 || event.target.value.match(emailRegex) === null) {
			setClientEmailError(false);
			return;
		}

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hubspot/getContactFromEmail",
				{
					email: event.target.value,
				}
			);

			if (res.data && res.data.id) {
				setClientEmailError(true);
			} else {
				setClientEmailError(false);
			}
		} catch (error) {
			errorMessageOpened(
				"Error fetching contact records from HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
			);
		}
	};

	// PROPERTY INPUT HANDLER METHODS
	const propertySearchByValueSelected = (event, newValue) => {
		// if the searchByValue is selected and the user didn't just clear the autocomplete
		if (newValue && newValue.properties) {
			setPropertyDetailedViewOpen(true);

			setPropertyInfo({
				name: newValue.properties.property_name,
				address1: newValue.properties.property_address_1,
				address2: newValue.properties.property_address_2,
				city: newValue.properties.property_city,
				state: newValue.properties.property_state,
				zip: newValue.properties.property_zip_code,
				recordID: newValue.properties.hs_object_id,
			});
		} else {
			// if the user cleared the autocomplete (still triggers as removing the value is technically a selection event)
			setPropertyDetailedViewOpen(false);

			setPropertyInfo({
				name: "",
				address1: "",
				address2: "",
				city: "",
				state: "",
				zip: "",
				recordID: "",
			});

			setPropertyOptions([]);
		}
	};

	const createNewPropertyClicked = () => {
		if (createNewPropertyEnabled) {
			return;
		}

		// close the property search bar
		setPropertySearchBarOpen(false);

		// enable the create new property view
		setCreateNewPropertyEnabled(true);
		setSearchExistingPropertyEnabled(false);

		// clear all previous values that were in before
		setPropertySearchByValue("");

		setPropertyInfo({
			name: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			recordID: "",
		});

		// clear the property searchbyvalue autocomplete
		const propertySearchByValueEle =
			propertySearchByValueRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (propertySearchByValueEle) propertySearchByValueEle.click();

		// open the property detailed view
		setPropertyDetailedViewOpen(true);
	};

	const searchExistingPropertyClicked = (override = false) => {
		if (searchExistingPropertyEnabled && !override) {
			return;
		}

		// open the property search bar
		setPropertySearchBarOpen(true);
		setPropertyDetailedViewOpen(false);

		// enable the search existing property view
		setSearchExistingPropertyEnabled(true);
		setCreateNewPropertyEnabled(false);

		// clear all previous values that were in before
		setPropertySearchByValue("");

		setPropertyInfo({
			name: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			recordID: "",
		});
	};

	const propertyInfoChanged = (event) => {
		setPropertyInfo((prevInfo) => ({
			...prevInfo,
			[event.target.name]: event.target.value,
		}));
	};

	// SERVICE INPUT HANDLER METHODS
	const genericServiceChanged = (event) => {
		setServiceCheckboxes((prevInfo) => ({
			...prevInfo,
			[event.target.name]: event.target.checked,
		}));
	};

	const serviceVisualChanged = (event) => {
		if (event.target.checked) {
			// when visual is checked, invasive is disabled
			setServiceCheckboxes((prevInfo) => ({
				...prevInfo,
				visual: true,
				invasive: false,
			}));

			setEnabledServices((prevInfo) => ({
				...prevInfo,
				invasive: false,
			}));

			setServiceInvasiveNumberOfOpenings("");
			serviceMessageOpened("Visual cannot be combined with Invasive.");
		} else {
			// when visual is unchecked, invasive is enabled (not selected)
			setServiceCheckboxes((prevInfo) => ({
				...prevInfo,
				visual: false,
				invasive: false,
			}));

			setEnabledServices((prevInfo) => ({
				...prevInfo,
				invasive: true,
			}));

			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceInvasiveChanged = (event) => {
		if (event.target.checked) {
			// when invasive is checked, visual is disabled
			setServiceCheckboxes((prevInfo) => ({
				...prevInfo,
				visual: false,
				invasive: true,
			}));

			setEnabledServices((prevInfo) => ({
				...prevInfo,
				visual: false,
			}));

			serviceMessageOpened("Invasive cannot be combined with Visual.");
		} else {
			// when invasive is unchecked, visual is enabled (not selected)
			setServiceCheckboxes((prevInfo) => ({
				...prevInfo,
				visual: false,
				invasive: false,
			}));

			setEnabledServices((prevInfo) => ({
				...prevInfo,
				visual: true,
			}));

			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceRapidVisualChanged = (event) => {
		setServiceCheckboxes((prevInfo) => ({
			...prevInfo,
			rapidVisual: event.target.checked,
		}));

		if (event.target.checked) {
			disableAllServicesBut(["Rapid Visual"]);
			setServiceInvasiveNumberOfOpenings("");
			serviceMessageOpened(
				"Rapid Visual (Wellness Exam) is a standalone service."
			);
		} else {
			enableAllServices();
			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceClaimSupportChanged = (event) => {
		setServiceCheckboxes((prevInfo) => ({
			...prevInfo,
			claimSupport: event.target.checked,
		}));

		if (event.target.checked) {
			disableAllServicesBut(["Claim Support"]);
			setServiceInvasiveNumberOfOpenings("");
			serviceMessageOpened("Claim Support is a standalone service.");
		} else {
			enableAllServices();
			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceFeasibilityChanged = (event) => {
		setServiceCheckboxes((prevInfo) => ({
			...prevInfo,
			feasibility: event.target.checked,
		}));

		if (event.target.checked) {
			disableAllServicesBut(["Feasibility"]);
			setServiceInvasiveNumberOfOpenings("");
			serviceMessageOpened("Feasibility is a standalone service.");
		} else {
			enableAllServices();
			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceGeneralConsultingChanged = (event) => {
		setServiceCheckboxes((prevInfo) => ({
			...prevInfo,
			generalConsulting: event.target.generalConsulting,
		}));

		if (event.target.checked) {
			disableAllServicesBut(["General Consulting"]);
			setServiceInvasiveNumberOfOpenings("");
			serviceMessageOpened("General Consulting is a standalone service.");
		} else {
			enableAllServices();
			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceEngineeringStructuralChanged = (event) => {
		setServiceCheckboxes((prevInfo) => ({
			...prevInfo,
			engineeringStructural: event.target.checked,
		}));

		if (event.target.checked) {
			disableAllServicesBut(["Engineering - Structural Services"]);
			setServiceInvasiveNumberOfOpenings("");
			serviceMessageOpened(
				"Engineering - Structural Services is a standalone service."
			);
		} else {
			enableAllServices();
			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceAdditionalServicesChanged = (event) => {
		setServiceCheckboxes((prevInfo) => ({
			...prevInfo,
			additionalServices: event.target.checked,
		}));

		if (event.target.checked) {
			disableAllServicesBut(["Additional Services"]);
			setServiceInvasiveNumberOfOpenings("");
			serviceMessageOpened("Additional Services are standalone.");
		} else {
			enableAllServices();
			serviceMessageClosed();
			setServiceInvasiveNumberOfOpenings("");
		}
	};

	const serviceInvasiveNumberOfOpeningsChanged = (event) => {
		setServiceInvasiveNumberOfOpenings(event.target.value);
	};

	// SERVICE HELPER METHODS
	const enableAllServices = () => {
		setEnabledServices({
			visual: true,
			invasive: true,
			design: true,
			bidAssistance: true,
			contractAdministration: true,
			rapidVisual: true,
			claimSupport: true,
			feasibility: true,
			generalConsulting: true,
			engineeringStructural: true,
			drainage: true,
			additionalServices: true,
			windowTesting: true,
			romExercise: true,
		});
	};

	const disableAllServicesBut = (services) => {
		setServiceCheckboxes({
			visual: services.includes("Visual"),
			invasive: services.includes("Invasive"),
			design: services.includes("Design"),
			bidAssistance: services.includes("Bid Assistance"),
			contractAdministration: services.includes(
				"Contract Administration"
			),
			rapidVisual: services.includes("Rapid Visual"),
			claimSupport: services.includes("Claim Support"),
			feasibility: services.includes("Feasibility"),
			generalConsulting: services.includes("General Consulting"),
			engineeringStructural: services.includes(
				"Engineering - Structural Services"
			),
			drainage: services.includes("Drainage"),
			additionalServices: services.includes("Additional Services"),
			windowTesting: services.includes("Window Testing"),
			romExercise: services.includes("ROM Exercise"),
		});

		setEnabledServices({
			visual: services.includes("Visual"),
			invasive: services.includes("Invasive"),
			design: services.includes("Design"),
			bidAssistance: services.includes("Bid Assistance"),
			contractAdministration: services.includes(
				"Contract Administration"
			),
			rapidVisual: services.includes("Rapid Visual"),
			claimSupport: services.includes("Claim Support"),
			feasibility: services.includes("Feasibility"),
			generalConsulting: services.includes("General Consulting"),
			engineeringStructural: services.includes(
				"Engineering - Structural Services"
			),
			drainage: services.includes("Drainage"),
			additionalServices: services.includes("Additional Services"),
			windowTesting: services.includes("Window Testing"),
			romExercise: services.includes("ROM Exercise"),
		});
	};

	// COMPONENT INPUT HANDLER METHODS
	const componentCheckboxChanged = (event) => {
		setComponentCheckboxes((prevInfo) => ({
			...prevInfo,
			[event.target.name]: event.target.checked,
		}));

		event.target.checked
			? setNumComponents((n) => n + 1)
			: setNumComponents((n) => n - 1);
	};

	// COMPONENT HELPER METHODS
	const disableUncheckedComponents = () => {
		setEnabledComponents({
			BE: componentCheckboxes.BE,
			interiorUnit: componentCheckboxes.interiorUnit,
			interiorAttic: componentCheckboxes.interiorAttic,
			interiorCrawlSpace: componentCheckboxes.interiorCrawlSpace,
			roof: componentCheckboxes.roof,
			site: componentCheckboxes.site,
			carportGarage: componentCheckboxes.carportGarage,
			decks: componentCheckboxes.decks,
			windows: componentCheckboxes.windows,
			stairway: componentCheckboxes.stairway,
			plumbing: componentCheckboxes.plumbing,
			HVAC: componentCheckboxes.HVAC,
		});
	};

	const enableAllComponents = () => {
		setEnabledComponents({
			BE: true,
			interiorUnit: true,
			interiorAttic: true,
			interiorCrawlSpace: true,
			roof: true,
			site: true,
			carportGarage: true,
			decks: true,
			windows: true,
			stairway: true,
			plumbing: true,
			HVAC: true,
		});
	};

	// COST BASIS INPUT HANDLER METHODS
	const costBasisChanged = (event) => {
		setServiceCostBases((prevInfo) => ({
			...prevInfo,
			[event.target.name]: event.target.value,
		}));
	};

	// ALERT HELPER FUNCTIONS
	const errorMessageClosed = () => {
		setIsError(false);
		setErrorMessage("");
	};

	const successMessageClosed = () => {
		setIsSuccess(false);
		setSuccessMessage("");
	};

	const serviceMessageClosed = () => {
		setServiceMessageOpen(false);
		setServiceMessageText("");
	};

	const componentMessageClosed = () => {
		setComponentMessageOpen(false);
		setComponentMessageText("");
	};

	const errorMessageOpened = (errorMessage) => {
		setIsError(true);
		setErrorMessage(errorMessage);
		successMessageClosed();
	};

	const successMessageOpened = (successMessage) => {
		setIsSuccess(true);
		setSuccessMessage(successMessage);
		errorMessageClosed();
	};

	const serviceMessageOpened = (serviceMessage) => {
		setServiceMessageOpen(true);
		setServiceMessageText(serviceMessage);
	};

	const componentMessageOpened = (componentMessage) => {
		setComponentMessageOpen(true);
		setComponentMessageText(componentMessage);
	};

	// MISC INPUT HANDLER METHODS
	const proposalSourceChanged = (event) => {
		setProposalSource(event.target.value);
	};

	const proposalSourceReferralChanged = (event) => {
		setProposalSourceReferral(event.target.value);
	};

	const scopeBackgroundChanged = (event) => {
		setScopeBackground(event.target.value);
	};

	const additionalNotesChanged = (event) => {
		setAdditionalNotes(event.target.value);
	};

	const buildingTypeChanged = (event) => {
		setBuildingType(event.target.value);
	};

	const oicChanged = (event) => {
		setOIC(event.target.value);
	};

	const proposalDueDateChanged = (newValue) => {
		setProposalDueDate(newValue || dayjs());
	};

	// HELP DIALOG INPUT HANDLER METHODS
	const clientInformationHelpDialogOpened = () => {
		setClientInformationHelpDialogOpen(true);
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "RFP",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the client information help dialog.",
		});
	};

	const clientInformationHelpDialogClosed = () => {
		setClientInformationHelpDialogOpen(false);
	};

	const propertyInformationHelpDialogOpened = () => {
		setPropertyInformationHelpDialogOpen(true);
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "RFP",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the property information help dialog.",
		});
	};

	const propertyInformationHelpDialogClosed = () => {
		setPropertyInformationHelpDialogOpen(false);
	};

	const proposalSummaryHelpDialogOpened = () => {
		setProposalSummaryHelpDialogOpen(true);
		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "RFP",
			employeeID: employeeID,
			date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
			notes: "Accessed the proposal summary help dialog.",
		});
	};

	const proposalSummaryHelpDialogClosed = () => {
		setProposalSummaryHelpDialogOpen(false);
	};

	// CLIENT API METHODS
	const clientSearchByValueChanged = useMemo(
		() =>
			debounce(async (event) => {
				const textLength = event.target.value.length;

				// if the current text length is less than 3, we will get too many results, so return
				if (textLength < 3) {
					setClientOptions([]);
					return;
				}

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/hubspot/search/contacts",
						{
							searchParameter: clientSearchByParameter,
							value: event.target.value,
						}
					);
					setClientOptions(res.data);
				} catch (error) {
					errorMessageOpened(
						"Error fetching contact records from HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
					);
				}
				// the 500 represents the 500ms delay sent to the debounce function below
			}, 500),
		[clientSearchByParameter]
	); // this [clientSearchByParameter] is the dependency array, which tells the useMemo hook to re-render when clientSearchByParameter is updated

	const createNewClientRecordInHubspot = async () => {
		// makes sure required data is inputted
		if (
			!clientInfo.email ||
			!clientInfo.firstname ||
			!clientInfo.lastname
		) {
			errorMessageOpened(
				"Make sure client first name, client last name, and email are all filled out. If you believe this to be an error, contact your system administrator."
			);
			return;
		}

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hubspot/addContact",
				{
					email: clientInfo.email,
					firstName: clientInfo.firstname,
					lastName: clientInfo.lastname,
				}
			);

			if (res.data?.errorResponse === "Conflict") {
				errorMessageOpened(
					`The email address ${clientInfo.email} is already in use. Please search an existing contact or use a different email address.`
				);

				return null;
			}

			setClientInfo((prevInfo) => ({
				...prevInfo,
				recordID: res.data?.id,
			}));

			return res.data?.id;
		} catch (error) {
			errorMessageOpened(
				"Error creating a contact record in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
			);
		}
	};

	// PROPERTY API METHODS
	const propertySearchByValueChanged = useMemo(
		() =>
			debounce(async (event) => {
				const textLength = event.target.value.length;

				// if the current text length is less than 3, we will get too many results, so return
				if (textLength < 3) {
					setPropertyOptions([]);
					return;
				}

				try {
					const res = await getAPICall(
						instance,
						accounts[0],
						"/api/hubspot/search/properties",
						{
							propertyName: event.target.value,
						}
					);
					setPropertyOptions(res.data);
				} catch (error) {
					errorMessageOpened(
						"Error fetching property records in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
					);
				}
			}, 500),
		[]
	); // 500 represents the 500ms delay sent to the debounce function below

	const createNewPropertyRecordInHubspot = async () => {
		// makes sure required data is inputted
		if (
			!propertyInfo.name ||
			!propertyInfo.address1 ||
			!propertyInfo.city ||
			!propertyInfo.state ||
			!propertyInfo.zip
		) {
			return;
		}

		// make sure that don't have "create new property" enabled
		if (!createNewPropertyEnabled) {
			return;
		}

		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/hubspot/addProperty",
				{
					propertyName: propertyInfo.name,
					propertyAddress1: propertyInfo.address1,
					propertyAddress2: propertyInfo.address2,
					propertyCity: propertyInfo.city,
					propertyState: propertyInfo.state,
					propertyZip: propertyInfo.zip,
				}
			);

			setPropertyInfo((prevInfo) => ({
				...prevInfo,
				recordID: res.data?.id,
			}));

			return res.data?.id;
		} catch (error) {
			errorMessageOpened(
				"Error creating a new property record in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
			);
		}
	};

	// API HELPER FUNCTIONS
	const getHubspotOwner = async (ownerEmail) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/hubspot/getOwnerID",
				{
					email: ownerEmail,
				}
			);
			return res.data[0].id;
		} catch (error) {
			errorMessageOpened(
				`Error retrieving the HubSpot user record for ${ownerEmail}. Refresh your browser and try again. If the problem persists, contact your system administrator.`
			);
			return "error";
		}
	};

	const createDealInHubspot = async (parameters) => {
		const addDealRes = await postAPICall(
			instance,
			accounts[0],
			"/api/hubspot/addDeal",
			{
				...parameters,
			}
		);
		return addDealRes;
	};

	const associateContactToDeal = async (dealID, contactID) => {
		const associateToDeal = await postAPICall(
			instance,
			accounts[0],
			"/api/hubspot/associateToDeal",
			{
				dealID: dealID,
				contactID: contactID,
			}
		);
		return associateToDeal;
	};

	const associatePropertyToDeal = async (dealID, propertyID) => {
		const associateToDeal = await postAPICall(
			instance,
			accounts[0],
			"/api/hubspot/associatePropertyToDeal",
			{
				dealID: dealID,
				propertyID: propertyID,
			}
		);
		return associateToDeal;
	};

	const createTaskInHubspot = async (subject, ownerID) => {
		const createTaskRes = await postAPICall(
			instance,
			accounts[0],
			"/api/hubspot/createTask",
			{
				subject: subject,
				ownerID: ownerID,
				createdTime: dayjs().startOf("day").valueOf(),
			}
		);
		return createTaskRes;
	};

	const associateDealToTask = async (dealID, taskID) => {
		const associateToTask = await postAPICall(
			instance,
			accounts[0],
			"/api/hubspot/associateToTask",
			{
				dealID: dealID,
				taskID: taskID,
			}
		);
		return associateToTask;
	};

	const createDropboxFolder = async (hsDealID, hsPropertyID) => {
		// sends this data to Express server to relay to Make scenario
		sendCreateDropboxFolderWebhook(hsDealID, hsPropertyID);
	};

	// UI HELPER FUNCTIONS
	const clearInputFields = () => {
		// client property search bar states
		searchExistingClientClicked(true);
		searchExistingPropertyClicked(true);

		// service checkboxes
		setServiceCheckboxes({
			visual: false,
			invasive: false,
			design: false,
			bidAssistance: false,
			contractAdministration: false,
			rapidVisual: false,
			claimSupport: false,
			feasibility: false,
			generalConsulting: false,
			engineeringStructural: false,
			drainage: false,
			additionalServices: false,
			windowTesting: false,
			romExercise: false,
		});

		// component checkboxes
		setComponentCheckboxes({
			BE: false,
			interiorUnit: false,
			interiorAttic: false,
			interiorCrawlSpace: false,
			roof: false,
			site: false,
			carportGarage: false,
			decks: false,
			windows: false,
			stairway: false,
			plumbing: false,
			HVAC: false,
		});

		// service cost basis radio buttons
		setServiceCostBases({
			visual: "flatFee",
			invasive: "flatFee",
			design: "flatFee",
			bidAssistance: "flatFee",
			contractAdministration: "timeAndCost",
			rapidVisual: "flatFee",
			claimSupport: "timeAndCost",
			feasibility: "flatFee",
			generalConsulting: "timeAndCost",
			engineeringStructural: "timeAndCost",
			drainage: "timeAndCost",
			additionalServices: "timeAndCost",
			windowTesting: "flatFee",
			romExercise: "flatFee",
		});

		// Enable services and components
		enableAllServices();
		enableAllComponents();

		// Clear Messages
		serviceMessageClosed();
		componentMessageClosed();

		setNumComponents(0);

		// various input fields
		setScopeBackground("");
		setAdditionalNotes("");
		setOIC("");
		setProposalDueDate(dayjs());

		// clear client searchbyvalue autocomplete
		const clientSearchByValueEle =
			clientSearchByValueRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (clientSearchByValueEle) clientSearchByValueEle.click();

		// clear property searchbyvalue autocomplete
		const propertySearchByValueEle =
			propertySearchByValueRef.current.getElementsByClassName(
				"MuiAutocomplete-clearIndicator"
			)[0];
		if (propertySearchByValueEle) propertySearchByValueEle.click();
	};

	// MISC HELPER FUNCTIONS
	// This is used for limiting the amount of api requests, it is called when a user stops changing the input textfield for a given amount of time (wait)
	function debounce(callback, wait) {
		// creates/resets a timeout every time the function is called
		let timeoutId = null;
		return (...args) => {
			// if the function is called again before the timeout is up, clear the timeout and start it over
			window.clearTimeout(timeoutId);
			timeoutId = window.setTimeout(() => {
				// when the timeout is up, call the function with the arguments
				callback.apply(null, args);
			}, wait);
		};
	}

	const validateInputFields = () => {
		// validate client information
		if (
			!clientInfo.email ||
			!clientInfo.firstname ||
			!clientInfo.lastname
		) {
			if (createNewClientEnabled) {
				errorMessageOpened(
					"Please fill out all required fields (first name, last name, email) for the client and try again."
				);
				return false;
			} else {
				if (!clientInfo.recordID) {
					errorMessageOpened(
						"Please select a client from the search bar or create a new client and try again."
					);
					return false;
				}
			}
		}

		if (clientEmailError) {
			errorMessageOpened(
				"Please use a different email or select the existing client from the search bar."
			);
			return false;
		}

		// validate property information
		if (
			!propertyInfo.name ||
			!propertyInfo.address1 ||
			!propertyInfo.city ||
			!propertyInfo.state ||
			!propertyInfo.zip
		) {
			if (createNewPropertyEnabled) {
				errorMessageOpened(
					"Please fill out all required fields for the property (name, address line 1, city, state, zip code) and try again."
				);
				return false;
			} else {
				if (!propertyInfo.recordID) {
					errorMessageOpened(
						"Please select a property from the search bar or create a new property and try again."
					);
					return false;
				}
			}
		}

		if (
			!propertyInfo.name.match(/^[A-Za-z0-9\s]+$/) &&
			createNewPropertyEnabled
		) {
			errorMessageOpened(
				"Property name can only contain letters, numbers, and spaces. Please correct this and try again."
			);
			return false;
		}

		// validate service checkboxes
		var serviceCount = countPhases();
		if (serviceCount > 4) {
			errorMessageOpened(
				"Please select a maximum of 4 services and try again. You have " +
					serviceCount +
					" selected."
			);
			return false;
		}

		// validate component checkboxes
		var componentCount = countComponents();
		if (componentCount > 2) {
			errorMessageOpened(
				"Please select a maximum of 2 components and try again. You have " +
					numComponents +
					" selected."
			);
			return false;
		}

		// service checkboxes
		if (
			!serviceCheckboxes.visual &&
			!serviceCheckboxes.invasive &&
			!serviceCheckboxes.design &&
			!serviceCheckboxes.bidAssistance &&
			!serviceCheckboxes.contractAdministration &&
			!serviceCheckboxes.rapidVisual &&
			!serviceCheckboxes.claimSupport &&
			!serviceCheckboxes.feasibility &&
			!serviceCheckboxes.generalConsulting &&
			!serviceCheckboxes.engineeringStructural &&
			!serviceCheckboxes.drainage &&
			!serviceCheckboxes.additionalServices &&
			!serviceCheckboxes.windowTesting &&
			!serviceCheckboxes.romExercise
		) {
			errorMessageOpened("Please select a service and try again.");
			return false;
		}

		// component checkboxes
		if (
			!componentCheckboxes.BE &&
			!componentCheckboxes.interiorUnit &&
			!componentCheckboxes.interiorAttic &&
			!componentCheckboxes.interiorCrawlSpace &&
			!componentCheckboxes.roof &&
			!componentCheckboxes.site &&
			!componentCheckboxes.carportGarage &&
			!componentCheckboxes.decks &&
			!componentCheckboxes.windows &&
			!componentCheckboxes.stairway &&
			!componentCheckboxes.plumbing &&
			!componentCheckboxes.HVAC
		) {
			errorMessageOpened("Please select a component and try again.");
			return false;
		}

		// proposal source
		if (!proposalSource) {
			errorMessageOpened(
				"Please select a proposal source and try again."
			);
			return false;
		}

		if (proposalSource === "referral" && !proposalSourceReferral) {
			errorMessageOpened(
				"Please input a value for the referral source and try again."
			);
			return false;
		}

		// scope/background text field
		if (!scopeBackground) {
			errorMessageOpened(
				'Please input a value for the "Scope/Background" field and try again.'
			);
			return false;
		}

		// proposal due date datepicker
		if (!proposalDueDate) {
			errorMessageOpened(
				"Please select a proposal due date and try again."
			);
			return false;
		}

		// building type
		if (!buildingType) {
			errorMessageOpened("Please select a building type and try again.");
			return false;
		}

		// OIC select
		if (!oic) {
			errorMessageOpened(
				"Please select the OIC (Office in Charge) and try again."
			);
			return false;
		}

		// if all good, return true
		return true;
	};

	const createDealName = () => {
		let dealName = `${propertyInfo.name}: `;

		// creates an array of all beaut-ified components
		let components = createComponentArray();
		// if it's not empty, add the contents of the array (comma delimited) and a colon to the end of this section of the deal name
		if (components.length > 0) {
			dealName += components.join(", ");
			dealName += ": ";
		}

		// creates an array of all beaut-ified services
		let services = createServiceArray();
		// there won't be a colon at the end of this section so we add the contents (comma delimited)
		dealName += services.join(", ");

		return dealName;
	};

	const createComponentArray = () => {
		var components = [];
		if (componentCheckboxes.BE) components.push("Building Envelope");
		if (componentCheckboxes.interiorUnit)
			components.push("Interior - Unit");
		if (componentCheckboxes.interiorAttic)
			components.push("Interior - Attic");
		if (componentCheckboxes.interiorCrawlSpace)
			components.push("Interior - Crawl Space");
		if (componentCheckboxes.roof) components.push("Roof");
		if (componentCheckboxes.site) components.push("Site");
		if (componentCheckboxes.carportGarage)
			components.push("Carport / Garage");
		if (componentCheckboxes.decks) components.push("Decks");
		if (componentCheckboxes.windows) components.push("Windows");
		if (componentCheckboxes.stairway) components.push("Stairway");
		if (componentCheckboxes.plumbing) components.push("Plumbing / Repipe");
		if (componentCheckboxes.HVAC) components.push("HVAC");

		return components;
	};

	const createServiceArray = () => {
		var services = [];
		if (serviceCheckboxes.visual) services.push("Visual");
		if (serviceCheckboxes.invasive) services.push("Invasive");
		if (serviceCheckboxes.design) services.push("Design");
		if (serviceCheckboxes.bidAssistance) services.push("Bid Assistance");
		if (serviceCheckboxes.contractAdministration)
			services.push("Contract Administration");
		if (serviceCheckboxes.rapidVisual)
			services.push("Rapid Visual (Wellness Exam)");
		if (serviceCheckboxes.claimSupport) services.push("Claim Support");
		if (serviceCheckboxes.feasibility) services.push("Feasibility");
		if (serviceCheckboxes.generalConsulting)
			services.push("General Consulting");
		if (serviceCheckboxes.engineeringStructural)
			services.push("Engineering - Structural Services");
		if (serviceCheckboxes.drainage) services.push("Drainage");
		if (serviceCheckboxes.additionalServices)
			services.push("Additional Services");
		if (serviceCheckboxes.windowTesting) services.push("Window Testing");
		if (serviceCheckboxes.romExercise) services.push("ROM Exercise");

		return services;
	};

	const determinePhaseCostBasises = () => {
		var phaseCostBasises = [];

		// add all cost bases to the array
		for (var key in serviceCostBases) {
			if (serviceCheckboxes[key]) {
				phaseCostBasises.push(serviceCostBases[key]);
			}
		}

		// beautify the cost bases
		for (var i = 0; i < phaseCostBasises.length; i++) {
			if (phaseCostBasises[i] === "timeAndCost") {
				phaseCostBasises[i] = "Time and Cost";
			} else if (phaseCostBasises[i] === "flatFee") {
				phaseCostBasises[i] = "Flat Fee";
			}
		}

		return phaseCostBasises;
	};

	const countPhases = () => {
		var count = 0;

		for (var key in serviceCheckboxes) {
			if (serviceCheckboxes[key]) count++;
		}

		return count;
	};

	const countComponents = () => {
		var count = 0;

		for (var key in componentCheckboxes) {
			if (componentCheckboxes[key]) count++;
		}

		return count;
	};

	// SUBMIT RFP FORM PRESSED
	const fetchSubmitter = async () => {
		const userRes = await getAPICall(
			instance,
			accounts[0],
			"/api/employees/byID",
			{ id: employeeID }
		);

		if (
			userRes.data.length === 0 ||
			userRes.data.error ||
			!userRes.data[0].email
		) {
			postAPICall(instance, accounts[0], "/api/logs/automation/add", {
				employeeID: employeeID,
				date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
				message: "Error retrieving Microsoft user profile",
				success: false,
				tool_name: "RFP",
			});
			errorMessageOpened(
				"Error retrieving user's Microsoft profile. Refresh your browser and try again. If the problem persists, contact your system administrator."
			);

			return null;
		}

		return userRes.data[0].email;
	};

	const submitRFPFormPressed = async () => {
		try {
			// this adds a loading spinner and disables the button
			setIsSubmitting(true);

			// retrieve MSAL user email and handle error if necessary
			const userEmail = await fetchSubmitter();

			if (!userEmail) {
				setIsSubmitting(false);
				return;
			}

			// this just checks to see if all the input fields are valid
			const inputsValid = validateInputFields();
			if (!inputsValid) {
				setIsSubmitting(false);
				return;
			}

			// this checks to see if an existing client is selected, and if not, creates a new client record in HubSpot
			var associatedClientRecordID = clientInfo.recordID;
			if (!clientInfo.recordID) {
				associatedClientRecordID =
					await createNewClientRecordInHubspot();

				// if this returned -2, then the email address is already in use

				// if this returned a nullable value, then add to the automation log and return
				if (!associatedClientRecordID) {
					postAPICall(
						instance,
						accounts[0],
						"/api/logs/automation/add",
						{
							employeeID: employeeID,
							date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
							message: "Error creating client record in Hubspot",
							success: false,
							tool_name: "RFP",
						}
					);
					errorMessageOpened(
						"Error creating client record in Hubspot. Refresh your browser and try again. If the problem persists, contact your system administrator."
					);
					setIsSubmitting(false);
					return;
				}
			}

			// this checks to see if an existing property is selected, and if not, creates a new property record in HubSpot
			var associatedPropertyRecordID = propertyInfo.recordID;
			if (!propertyInfo.recordID) {
				associatedPropertyRecordID =
					await createNewPropertyRecordInHubspot();
				// if this returned a nullable value, then add to the automation log and return
				if (!associatedPropertyRecordID) {
					postAPICall(
						instance,
						accounts[0],
						"/api/logs/automation/add",
						{
							employeeID: employeeID,
							date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
							message:
								"Error creating property record in Hubspot",
							success: false,
							tool_name: "RFP",
						}
					);
					errorMessageOpened(
						"Error creating property record in Hubspot. Refresh your browser and try again. If the problem persists, contact your system administrator."
					);
					setIsSubmitting(false);
					return;
				}
			}

			// simple function to concatenate the deal name (property name: components: services [Property: Project: Phase])
			const dealName = createDealName();

			// gets the hubspot ID from the user's email, this will be used to set the current user (person submitting RFP form) as the owner of the deal in HS
			const ownerID = await getHubspotOwner(userEmail);
			if (ownerID === "error") {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message: "Error retrieving HubSpot owner ID",
					success: false,
					tool_name: "RFP",
				});
				errorMessageOpened(
					"Error retrieving HubSpot owner ID. Refresh your browser and try again. If the problem persists, contact your system administrator."
				);
				setIsSubmitting(false);
				return;
			}

			// this changes the cost basis from the radio buttons to a more human-readable format
			// there will be max four phases in an RFP, so we prepare the cost bases for all four phases, regardless of how many are selected
			const phaseCostBasises = determinePhaseCostBasises();
			const phase1CostBasis = phaseCostBasises[0]
				? phaseCostBasises[0]
				: "";
			const phase2CostBasis = phaseCostBasises[1]
				? phaseCostBasises[1]
				: "";
			const phase3CostBasis = phaseCostBasises[2]
				? phaseCostBasises[2]
				: "";
			const phase4CostBasis = phaseCostBasises[3]
				? phaseCostBasises[3]
				: "";

			// detemine phase name for deal field
			const phases = createServiceArray();
			const phase1Name = phases[0] ? phases[0] : "";
			const phase2Name = phases[1] ? phases[1] : "";
			const phase3Name = phases[2] ? phases[2] : "";
			const phase4Name = phases[3] ? phases[3] : "";

			// this is the project name, which is a list of all the components selected
			var projectName = createComponentArray();
			projectName = projectName.join(", ");

			// this is map of information we send to the hubspot API
			const addDealParams = {
				dealName: dealName ? dealName : "",
				ownerID: ownerID,
				invasiveOpenings: serviceInvasiveNumberOfOpenings
					? serviceInvasiveNumberOfOpenings
					: "",
				phase1Name: phase1Name,
				phase2Name: phase2Name,
				phase3Name: phase3Name,
				phase4Name: phase4Name,
				phase1CostBasis: phase1CostBasis,
				phase2CostBasis: phase2CostBasis,
				phase3CostBasis: phase3CostBasis,
				phase4CostBasis: phase4CostBasis,
				propertyName: propertyInfo.name ? propertyInfo.name : "",
				propertyAddress: `${propertyInfo.address1} ${
					propertyInfo.address2 ? propertyInfo.address2 : ""
				}`,
				propertyCity: propertyInfo.city ? propertyInfo.city : "",
				propertyState: propertyInfo.state ? propertyInfo.state : "",
				propertyZip: propertyInfo.zip ? propertyInfo.zip : "",
				projectName: projectName ? projectName : "",
				scopeBackground: scopeBackground ? scopeBackground : "",
				additionalNotes: additionalNotes ? additionalNotes : "",
				oic: oic ? oic : "",
				proposalDueDate: proposalDueDate
					? proposalDueDate.utc(true).startOf("day").valueOf()
					: "",
				proposalSource: rfpSourceMap[proposalSource]
					? rfpSourceMap[proposalSource]
					: "",
				proposalSourceReferral: proposalSourceReferral
					? proposalSourceReferral
					: "",
				buildingType: buildingType ? buildingType : "",
			};
			// this createDealInHubspot function sends the information to Express server which then relays it to the hubspot API
			const addDealRes = await createDealInHubspot(addDealParams);
			// if the response is null or doesn't have an ID, then we add a failure to the automation log and return
			if (!addDealRes.data || !addDealRes.data.id) {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message: "Error creating deal record in Hubspot",
					success: false,
					tool_name: "RFP",
				});
				errorMessageOpened(
					"Error creating a deal record in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
				);
				setIsSubmitting(false);
				return;
			}

			// this associateContactToDeal function sends the contact ID and deal ID to Express server which relays to the hubspot API
			const contactAssociationRes = await associateContactToDeal(
				addDealRes.data.id,
				associatedClientRecordID
			);
			// if the response is null or doesn't have a results field, then we add a failure to the automation log and return
			if (
				!contactAssociationRes.data ||
				!contactAssociationRes.data.results
			) {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message:
						"Error associating the contact to the deal in HubSpot",
					success: false,
					tool_name: "RFP",
				});
				errorMessageOpened(
					"Error associating the contact to the deal in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
				);
				setIsSubmitting(false);
				return;
			}

			// this associateContactToDeal function sends the contact ID and deal ID to Express server which relays to the hubspot API
			const propertyAssociationRes = await associatePropertyToDeal(
				addDealRes.data.id,
				associatedPropertyRecordID
			);
			// if the response is null or doesn't have a results field, then we add a failure to the automation log and return
			if (
				!propertyAssociationRes.data ||
				!propertyAssociationRes.data.results
			) {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message:
						"Error associating the property to the deal in HubSpot",
					success: false,
					tool_name: "RFP",
				});
				errorMessageOpened(
					"Error associating the property to the deal in HubSpot. Refresh your browser and try again. If the problem persists, contact your system administrator."
				);
				setIsSubmitting(false);
				return;
			} else {
				// if everything is successful, we add a success to the automation log and display a success message to the user
				await postAPICall(
					instance,
					accounts[0],
					"/api/logs/automation/add",
					{
						employeeID: employeeID,
						date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
						message: `RFP form submitted successfully for deal ${dealName}.`,
						success: true,
						tool_name: "RFP",
					}
				);
				successMessageOpened("RFP form submitted successfully.");
				setIsSubmitting(false);
				clearInputFields();
			}

			// this line determines the Client Relations specialist that reviews the deal based on the OIC
			let taskOwnerEmail =
				oic === "OR"
					? "britanyk@j2consultants.com"
					: "kellig@j2consultants.com";
			// this line gets the hubspot ID from the Client Relations specialist's email
			const taskOwnerID = await getHubspotOwner(taskOwnerEmail);
			// this createTaskInHubspot function sends the task information to Express server which relays to the hubspot API.
			// This is a task for the Client Relations specialist to review the deal
			const createTaskRes = await createTaskInHubspot(
				`Create Proposal: ${dealName}`,
				taskOwnerID
			);
			if (!createTaskRes.data || !createTaskRes.data.id) {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message: `Failure to create task for ${taskOwnerEmail} in HubSpot.`,
					success: false,
					tool_name: "RFP",
				});
				return;
			}

			// This is associating the task to the deal in HubSpot
			const taskAssociationRes = await associateDealToTask(
				addDealRes.data.id,
				createTaskRes.data.id
			);
			if (!taskAssociationRes.data || !taskAssociationRes.data.results) {
				postAPICall(instance, accounts[0], "/api/logs/automation/add", {
					employeeID: employeeID,
					date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
					message:
						"Error associating the task to the deal in HubSpot",
					success: false,
					tool_name: "RFP",
				});
				return;
			}

			// This creates the Dropbox folder for the deal via a webhook sent to the Express server which relays it to a Make scenario
			createDropboxFolder(addDealRes.data.id, associatedPropertyRecordID);
		} catch (error) {
			// If there are any unspecified errors, we add a failure to the automation log and display an error message to the user
			postAPICall(instance, accounts[0], "/api/logs/automation/add", {
				employeeID: employeeID,
				date_time: dayjs().format("MMMM D, YYYY h:mm:ss A"),
				message: "Undefined error in submitting the RFP form: " + error,
				success: false,
				tool_name: "RFP",
			});
			errorMessageOpened(
				"Error submitting the RFP form. Refresh your browser and try again. If the problem persists, contact your system administrator."
			);
		}
	};

	// DROPBOX WEBHOOK FUNCTIONS
	const sendCreateDropboxFolderWebhook = async (hsDealID, hsPropertyID) => {
		try {
			var oicID = 1;
			switch (oic) {
				case "WA":
					oicID = 1;
					break;
				case "OR":
					oicID = 2;
					break;
				case "UT":
					oicID = 3;
					break;
				default:
					oicID = 1;
			}

			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/webhooks/createDropboxFolder",
				{
					oicID: oicID,
					hsDealID: hsDealID,
					hsPropertyID: hsPropertyID,
					submittedByID: employeeID,
					additionalNotes: additionalNotes,
					scopeBackground: scopeBackground,
					proposalDueDate: proposalDueDate
						? proposalDueDate.utc(true).startOf("day").valueOf()
						: "",
				}
			);
		} catch (error) {
			console.log("Error sending webhook: ", error);
		}
	};

	// RENDER
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div className="RFP">
				<div className="rfpHeaderContainer">
					<div className="rfpHeader">RFP</div>
					<div className="rfaLink">
						Need an amendment?{" "}
						<a href={`${process.env.REACT_APP_BASE_URL}/rfa`}>
							Click here.
						</a>
					</div>
				</div>
				<div className="rfpCardDialogRow" id="successDialogDiv">
					<Collapse in={isSuccess} id="dialogCollapse">
						<Alert
							sx={{ marginTop: "1em", marginBottom: "1em" }}
							severity="success"
							onClose={successMessageClosed}
						>
							{successMessage}
						</Alert>
					</Collapse>
				</div>
				<div className="rfpCard" id="clientInformationDiv">
					<div className="rfpCardHeader">
						<h2>
							Client Information
							{/* <IconButton
                                onClick={clientInformationHelpDialogOpened} 
                                size='small'
                            >
                                <HelpIcon fontSize='small' />
                            </IconButton> */}
						</h2>
						<ButtonGroup>
							<Button
								sx={{
									"&.Mui-disabled": {
										background: "#184C93",
										color: "#ffffff",
									},
								}}
								variant={
									createNewClientEnabled
										? "contained"
										: "outlined"
								}
								onClick={createNewClientClicked}
								disabled={createNewClientEnabled}
							>
								Create New
							</Button>
							<Button
								sx={{
									"&.Mui-disabled": {
										background: "#184C93",
										color: "#ffffff",
									},
								}}
								variant={
									searchExistingClientEnabled
										? "contained"
										: "outlined"
								}
								onClick={searchExistingClientClicked}
								disabled={searchExistingClientEnabled}
							>
								Search Existing
							</Button>
						</ButtonGroup>
					</div>
					<Collapse in={clientSearchBarOpen} id="searchBarCollapse">
						<div
							className="searchByRFPCardRow"
							id="clientSearchByFields"
						>
							<Autocomplete
								sx={{ flexGrow: 1 }}
								options={clientOptions}
								ref={clientSearchByValueRef}
								key={clientSearchByValueKey}
								getOptionLabel={(option) =>
									option.properties
										? `${
												option.properties.firstname ||
												""
										  } ${
												option.properties.lastname || ""
										  } (${option.properties.email || ""})`
										: option
								}
								filterSelectedOptions
								onChange={clientSearchByValueSelected}
								clearOnBlur={false}
								renderInput={(params) => (
									<TextField
										{...params}
										label={searchByOptionsMap.get(
											clientSearchByParameter
										)}
										placeholder={searchByOptionsMap.get(
											clientSearchByParameter
										)}
										value={clientSearchByValue || ""}
										onKeyUp={clientSearchByValueChanged}
									/>
								)}
							/>
							<FormControl id="searchByFormControl">
								<InputLabel id="clientSearchByLabel">
									Search By
								</InputLabel>
								<Select
									labelId="clientSearchByLabel"
									value={clientSearchByParameter}
									label="Search By"
									onChange={clientSearchByParameterSelected}
								>
									<MenuItem value="lastname">
										Last Name
									</MenuItem>
									<MenuItem value="email">Email</MenuItem>
								</Select>
							</FormControl>
						</div>
					</Collapse>
					<Collapse
						in={clientDetailedViewOpen}
						id="detailedViewCollapse"
					>
						<div className="rfpCardRow">
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewClientEnabled}
								name="email"
								error={clientEmailError}
								onBlur={checkEmail}
								onChange={clientInfoChanged}
								label="Email"
								value={clientInfo.email || ""}
								helperText={
									clientEmailError
										? "Contact with email already exists"
										: ""
								}
							/>
						</div>
						<div className="rfpCardRow">
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewClientEnabled}
								name="firstname"
								onChange={clientInfoChanged}
								label="First Name"
								value={clientInfo.firstname || ""}
							/>
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewClientEnabled}
								name="lastname"
								onChange={clientInfoChanged}
								label="Last Name"
								value={clientInfo.lastname || ""}
							/>
						</div>
					</Collapse>
				</div>
				<div className="rfpCard" id="propertyInformationDiv">
					<div className="rfpCardHeader">
						<h2>
							Property Information
							{/* <IconButton
                                onClick={propertyInformationHelpDialogOpened}
                                size='small'
                            >
                                <HelpIcon fontSize='small' />
                            </IconButton> */}
						</h2>
						<ButtonGroup>
							<Button
								sx={{
									"&.Mui-disabled": {
										background: "#184C93",
										color: "#ffffff",
									},
								}}
								variant={
									createNewPropertyEnabled
										? "contained"
										: "outlined"
								}
								onClick={createNewPropertyClicked}
								disabled={createNewPropertyEnabled}
							>
								Create New
							</Button>
							<Button
								sx={{
									"&.Mui-disabled": {
										background: "#184C93",
										color: "#ffffff",
									},
								}}
								variant={
									searchExistingPropertyEnabled
										? "contained"
										: "outlined"
								}
								onClick={searchExistingPropertyClicked}
								disabled={searchExistingPropertyEnabled}
							>
								Search Existing
							</Button>
						</ButtonGroup>
					</div>
					<Collapse in={propertySearchBarOpen} id="searchBarCollapse">
						<div className="searchByRFPCardRow">
							<Autocomplete
								sx={{ flexGrow: 1 }}
								options={propertyOptions}
								ref={propertySearchByValueRef}
								getOptionLabel={(option) =>
									option.properties
										? option.properties.property_name
										: option
								}
								filterSelectedOptions
								onChange={propertySearchByValueSelected}
								clearOnBlur={false}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Property Name"
										placeholder="Property Name"
										value={propertySearchByValue || ""}
										onKeyUp={propertySearchByValueChanged}
									/>
								)}
							/>
						</div>
					</Collapse>
					<Collapse
						in={propertyDetailedViewOpen}
						id="detailedViewCollapse"
					>
						<div className="rfpCardRow">
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewPropertyEnabled}
								name="name"
								onChange={propertyInfoChanged}
								label="Property Name"
								value={propertyInfo.name || ""}
							/>
						</div>
						<div className="rfpCardRow">
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewPropertyEnabled}
								name="address1"
								onChange={propertyInfoChanged}
								label="Address Line 1"
								value={propertyInfo.address1 || ""}
							/>
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewPropertyEnabled}
								name="address2"
								onChange={propertyInfoChanged}
								label="Address Line 2"
								value={propertyInfo.address2 || ""}
							/>
						</div>
						<div
							className="rfpCardRow"
							id="propertyColumnRFPCardRow"
						>
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewPropertyEnabled}
								name="city"
								onChange={propertyInfoChanged}
								label="City"
								value={propertyInfo.city || ""}
							/>
							<FormControl sx={{ flexGrow: 1 }}>
								<InputLabel id="propertyStateLabel">
									State
								</InputLabel>
								<Select
									labelId="propertyStateLabel"
									value={propertyInfo.state}
									label="State"
									disabled={!createNewPropertyEnabled}
									name="state"
									onChange={propertyInfoChanged}
								>
									<MenuItem value="Washington">
										Washington
									</MenuItem>
									<MenuItem value="Oregon">Oregon</MenuItem>
									<MenuItem value="Utah">Utah</MenuItem>
									<MenuItem value="Idaho">Idaho</MenuItem>
								</Select>
							</FormControl>
							<TextField
								sx={{ flexGrow: 1 }}
								disabled={!createNewPropertyEnabled}
								name="zip"
								onChange={propertyInfoChanged}
								label="ZIP"
								value={propertyInfo.zip || ""}
							/>
						</div>
					</Collapse>
				</div>
				<div className="rfpCard" id="proposalSummaryDiv">
					<div className="rfpCardHeader">
						<h2>
							Proposal Summary
							{/* <IconButton
                                onClick={proposalSummaryHelpDialogOpened}
                                size='small'
                            >
                                <HelpIcon fontSize='small' />
                            </IconButton> */}
						</h2>
					</div>
					<div className="rfpCardGroup" id="serviceDiv">
						<h1 className="formGroupTitle">
							Service: What are we doing there? (select all that
							apply, T&C or Flat Fee options will appear below)
						</h1>
						<div className="rfpCardDialogRow" id="successDialogDiv">
							<Collapse
								in={serviceMessageOpen}
								id="dialogCollapse"
							>
								<Alert
									sx={{
										marginTop: "1em",
										marginBottom: "1em",
									}}
									severity="info"
									onClose={serviceMessageClosed}
								>
									{serviceMessageText}
								</Alert>
							</Collapse>
						</div>
						<FormGroup id="formGroup">
							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.visual}
										onChange={serviceVisualChanged}
										disabled={!enabledServices.visual}
									/>
								}
								label="Visual"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.visual}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="visual"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.invasive}
										onChange={serviceInvasiveChanged}
										disabled={!enabledServices.invasive}
									/>
								}
								label="Invasive"
							/>
							<Collapse
								id="serviceInvasiveCollapse"
								in={serviceCheckboxes.invasive}
							>
								<div id="serviceInvasiveCollapseContainer">
									<TextField
										sx={{ flexGrow: 1 }}
										label="# of Openings"
										value={
											serviceInvasiveNumberOfOpenings ||
											""
										}
										onChange={
											serviceInvasiveNumberOfOpeningsChanged
										}
									/>
								</div>
							</Collapse>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.invasive}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="invasive"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.design}
										name="design"
										onChange={genericServiceChanged}
										disabled={!enabledServices.design}
									/>
								}
								label="Design"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.design}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="design"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											serviceCheckboxes.bidAssistance
										}
										name="bidAssistance"
										onChange={genericServiceChanged}
										disabled={
											!enabledServices.bidAssistance
										}
									/>
								}
								label="Bid Assistance"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.bidAssistance}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="bidAssistance"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											serviceCheckboxes.contractAdministration
										}
										name="contractAdministration"
										onChange={genericServiceChanged}
										disabled={
											!enabledServices.contractAdministration
										}
									/>
								}
								label="Contract Administration"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.contractAdministration}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="timeAndCost"
											id="formGroup"
											name="contractAdministration"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
									<div className="costBasisContainerMessage">
										<mark>
											Please include fee structure
											(percentage, duration, estimate)
											preference in additional notes below
										</mark>
									</div>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.rapidVisual}
										onChange={serviceRapidVisualChanged}
										disabled={!enabledServices.rapidVisual}
									/>
								}
								label="Rapid Visual (Wellness Exam)"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.rapidVisual}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="rapidVisual"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.claimSupport}
										onChange={serviceClaimSupportChanged}
										disabled={!enabledServices.claimSupport}
									/>
								}
								label="Claim Support"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.claimSupport}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="timeAndCost"
											id="formGroup"
											name="claimSupport"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.feasibility}
										onChange={serviceFeasibilityChanged}
										disabled={!enabledServices.feasibility}
									/>
								}
								label="Feasibility"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.feasibility}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="feasibility"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											serviceCheckboxes.generalConsulting
										}
										onChange={
											serviceGeneralConsultingChanged
										}
										disabled={
											!enabledServices.generalConsulting
										}
									/>
								}
								label="General Consulting"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.generalConsulting}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="timeAndCost"
											id="formGroup"
											name="generalConsulting"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											serviceCheckboxes.engineeringStructural
										}
										onChange={
											serviceEngineeringStructuralChanged
										}
										disabled={
											!enabledServices.engineeringStructural
										}
									/>
								}
								label="Engineering - Structural Services"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.engineeringStructural}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="timeAndCost"
											id="formGroup"
											name="engineeringStructural"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.drainage}
										name="drainage"
										onChange={genericServiceChanged}
										disabled={!enabledServices.drainage}
									/>
								}
								label="Drainage"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.drainage}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="timeAndCost"
											id="formGroup"
											name="drainage"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											serviceCheckboxes.additionalServices
										}
										onChange={
											serviceAdditionalServicesChanged
										}
										disabled={
											!enabledServices.additionalServices
										}
									/>
								}
								label="Additional Services (NOT Amendment)"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.additionalServices}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="timeAndCost"
											id="formGroup"
											name="additionalServices"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={
											serviceCheckboxes.windowTesting
										}
										name="windowTesting"
										onChange={genericServiceChanged}
										disabled={
											!enabledServices.windowTesting
										}
									/>
								}
								label="Window Testing"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.windowTesting}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="windowTesting"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>

							<FormControlLabel
								control={
									<Checkbox
										checked={serviceCheckboxes.romExercise}
										name="romExercise"
										onChange={genericServiceChanged}
										disabled={!enabledServices.romExercise}
									/>
								}
								label="ROM Exercise"
							/>
							<Collapse
								id="costBasisCollapse"
								in={serviceCheckboxes.romExercise}
							>
								<div className="costBasisContainer">
									<FormControl>
										<RadioGroup
											defaultValue="flatFee"
											id="formGroup"
											name="romExercise"
											onChange={costBasisChanged}
										>
											<FormControlLabel
												value="timeAndCost"
												control={<Radio />}
												label="Time & Cost"
											/>
											<FormControlLabel
												value="flatFee"
												control={<Radio />}
												label="Flat Fee"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Collapse>
						</FormGroup>
					</div>
					<div className="rfpCardGroup" id="specificComponentDiv">
						<h1 className="formGroupTitle">
							Specific Component (select all that apply)
						</h1>
						<div className="rfpCardDialogRow" id="successDialogDiv">
							<Collapse
								in={componentMessageOpen}
								id="dialogCollapse"
							>
								<Alert
									sx={{
										marginTop: "1em",
										marginBottom: "1em",
									}}
									severity="info"
									onClose={componentMessageClosed}
								>
									{componentMessageText}
								</Alert>
							</Collapse>
						</div>
						<FormGroup id="formGroup">
							<FormControlLabel
								control={
									<Checkbox
										checked={
											componentCheckboxes.interiorUnit
										}
										name="interiorUnit"
										onChange={componentCheckboxChanged}
										disabled={
											!enabledComponents.interiorUnit
										}
									/>
								}
								label="Interior - Unit"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											componentCheckboxes.interiorAttic
										}
										name="interiorAttic"
										onChange={componentCheckboxChanged}
										disabled={
											!enabledComponents.interiorAttic
										}
									/>
								}
								label="Interior - Attic"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											componentCheckboxes.interiorCrawlSpace
										}
										name="interiorCrawlSpace"
										onChange={componentCheckboxChanged}
										disabled={
											!enabledComponents.interiorCrawlSpace
										}
									/>
								}
								label="Interior - Crawl Space"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.roof}
										name="roof"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.roof}
									/>
								}
								label="Roof"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.site}
										name="site"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.site}
									/>
								}
								label="Site"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											componentCheckboxes.carportGarage
										}
										name="carportGarage"
										onChange={componentCheckboxChanged}
										disabled={
											!enabledComponents.carportGarage
										}
									/>
								}
								label="Carport / Garage"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.decks}
										name="decks"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.decks}
									/>
								}
								label="Decks"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.BE}
										name="BE"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.BE}
									/>
								}
								label="Building Envelope (default)"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.windows}
										name="windows"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.windows}
									/>
								}
								label="Windows"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.stairway}
										name="stairway"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.stairway}
									/>
								}
								label="Stairway"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.plumbing}
										name="plumbing"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.plumbing}
									/>
								}
								label="Plumbing / Repipe"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={componentCheckboxes.HVAC}
										name="HVAC"
										onChange={componentCheckboxChanged}
										disabled={!enabledComponents.HVAC}
									/>
								}
								label="HVAC"
							/>
						</FormGroup>
					</div>
					<div className="rfpCardRow" id="proposalSourceDiv">
						<h1 className="proposalSourceTitle">
							RFP Source: Where did this proposal come from?
						</h1>
						<FormControl sx={{ flexGrow: 1 }}>
							<InputLabel id="proposalSourceLabel">
								Proposal Source
							</InputLabel>
							<Select
								labelId="proposalSourceLabel"
								value={proposalSource}
								label="Proposal Source"
								onChange={proposalSourceChanged}
							>
								{Object.keys(rfpSourceMap).map((option) => (
									<MenuItem value={option} key={option}>
										{rfpSourceMap[option]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Collapse
							id="proposalSourceCollapse"
							in={proposalSource === "referral"}
						>
							<div className="proposalSourceCollapseContainer">
								<TextField
									sx={{ flexGrow: 1 }}
									name="proposalSourceReferral"
									onChange={proposalSourceReferralChanged}
									label="Referral Source (Who referred us?)"
									value={proposalSourceReferral || ""}
								/>
							</div>
						</Collapse>
					</div>
					<div className="rfpCardRow" id="scopeBackgroundDiv">
						<J2MultilineTextField
							label="Scope / Background"
							onChange={scopeBackgroundChanged}
							placeholder="Scope / Background (Proposal and building information)"
							value={scopeBackground || ""}
							rows={3}
						/>
					</div>
					<div className="rfpCardRow" id="additionalNotesDiv">
						<J2MultilineTextField
							label="Additional Notes"
							onChange={additionalNotesChanged}
							placeholder="Additional Notes (Budget information, unique requests, etc.)"
							value={additionalNotes || ""}
							rows={2}
						/>
					</div>
					<div className="rfpCardRow" id="bottomRFPCardRow">
						<FormControl sx={{ flexGrow: 1 }}>
							<InputLabel id="buildingTypeLabel">
								Building Type
							</InputLabel>
							<Select
								labelId="buildingTypeLabel"
								value={buildingType}
								label="Building Type"
								onChange={buildingTypeChanged}
							>
								{buildingTypeMap
									.keys()
									.toArray()
									.map((option) => (
										<MenuItem value={option} key={option}>
											{buildingTypeMap.get(option)}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<FormControl sx={{ flexGrow: 1 }}>
							<InputLabel id="oicLabel">OIC</InputLabel>
							<Select
								labelId="oicLabel"
								value={oic}
								label="OIC"
								onChange={oicChanged}
							>
								<MenuItem value="WA">WA</MenuItem>
								<MenuItem value="OR">OR</MenuItem>
								<MenuItem value="UT">UT</MenuItem>
							</Select>
						</FormControl>
						<DatePicker
							label="Proposal Due Date"
							value={proposalDueDate}
							onChange={proposalDueDateChanged}
							slotProps={{ textField: { size: "medium" } }}
						/>
					</div>
					<J2ErrorDialog
						open={isError}
						onClose={errorMessageClosed}
						errorMessage={errorMessage}
					/>
				</div>
				<div className="rfpButtonContainer">
					<div className="rfpSubmitButtonContainer">
						<Button
							color="primary"
							variant="contained"
							onClick={submitRFPFormPressed}
							disabled={isSubmitting}
						>
							Submit
						</Button>
						{isSubmitting && (
							<CircularProgress
								size={24}
								sx={{
									color: "primary",
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</div>
				</div>
			</div>
			<ClientInformationHelpDialog
				open={clientInformationHelpDialogOpen}
				onClose={clientInformationHelpDialogClosed}
			/>
			<PropertyInformationHelpDialog
				open={propertyInformationHelpDialogOpen}
				onClose={propertyInformationHelpDialogClosed}
			/>
			<ProposalSummaryHelpDialog
				open={proposalSummaryHelpDialogOpen}
				onClose={proposalSummaryHelpDialogClosed}
			/>
		</LocalizationProvider>
	);
};

export default RFP;
