// DEPENDENCIES
import React, { useState } from "react";

// CSS
import "./InformationCard.css";

// MUI COMPONENTS
import { Button, Collapse, IconButton } from "@mui/material";

import { Info as InfoIcon, Edit as EditIcon } from "@mui/icons-material";

// CUSTOM COMPONENTS
import DetailComment from "../CommentsCard/DetailComment/DetailComment";

import EditInformationCardDialog from "./EditInformationCardDialog/EditInformationCardDialog";

// CONSTANTS
import { defaultBlue } from "../../../../constants";

// FUNCTIONAL COMPONENT
const InformationCard = (props) => {
	// STATES
	const [editInformationOpen, setEditInformationOpen] = useState(false);

	// INPUT HANDLERS
	const editInformationCardClicked = () => {
		setEditInformationOpen(true);
	};

	// RETURN
	return (
		<div className="detailScreenListCard">
			<div className="detailScreenListCardHeader">
				<div
					className="detailScreenListCardHeaderIconTitleContainer"
					onClick={props.informationDropdownClicked}
				>
					<IconButton sx={{ color: defaultBlue }}>
						<InfoIcon sx={{ fontSize: 32 }} />
					</IconButton>
					<h2 className="detailScreenListCardHeaderTitle">
						Information
					</h2>
				</div>
				{props.informationOpen && (
					<div className="detailScreenListCardHeaderEditButtonContainer">
						<Button
							variant="contained"
							color="primary"
							startIcon={<EditIcon />}
							onClick={editInformationCardClicked}
						>
							Edit
						</Button>
					</div>
				)}
			</div>
			<Collapse
				in={props.informationOpen}
				id="informationDropdownCollapse"
				timeout="auto"
				unmountOnExit
			>
				<div className="informationDropdownContent">
					<div className="informationDropdownContentRow">
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Title
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.detailRecord.title}
							</div>
						</div>
					</div>
					<div className="informationDropdownContentRow">
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								ID
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.detailRecord.id}
							</div>
						</div>
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Uploaded By
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.detailRecord.submitted_by}
							</div>
						</div>
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Downloads
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.detailRecord.download_count}
							</div>
						</div>
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Status
							</div>
							<div className="informationDropdownContentRowItemValue">
								{props.detailRecord.detail_status_name}
							</div>
						</div>
					</div>
					<div className="informationDropdownContentRow">
						<div className="informationDropdownContentRowItem">
							<div className="informationDropdownContentRowItemTitle">
								Recent Comments
							</div>
							<div className="recentCommentsContainer">
								{props.comments.length === 0 && (
									<div className="noCommentsText">
										<i>No comments found</i>
									</div>
								)}
								{props.comments.map((comment, index) => {
									return (
										<DetailComment
											key={index}
											comment={comment}
											showBottomBorder={
												index !==
												props.comments.length - 1
											}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</Collapse>
			<EditInformationCardDialog
				open={editInformationOpen}
				editInformationCardDialogClosed={() =>
					setEditInformationOpen(false)
				}
				recordToEdit={props.detailRecord}
				fetchDetailRecord={props.fetchDetailRecord}
				detailStatuses={props.detailStatuses}
			/>
		</div>
	);
};

export default InformationCard;
