// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

// CSS
import "./PendingChanges.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

import PendingSpecChangePreviewCard from "./PendingSpecChangePreviewCard/PendingSpecChangePreviewCard";

// CONSTANTS

// OTHER

// REACT COMPONENT
const PendingChanges = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [pendingSpecChanges, setPendingSpecChanges] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchPendingSpecChanges();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Pending Changes (Specs)",
			employeeID: employeeID,
			notes: "Accessed the Pending Spec Changes page",
		});
	}, []);

	// INPUT HANDLERS

	// API FUNCTIONS
	const fetchPendingSpecChanges = async () => {
		setIsLoading(true);

		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/pendingChanges/all"
			);

			setPendingSpecChanges(response.data);
		} catch (error) {
			console.log("fetchPendingSpecChanges error: ", error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS

	// RENDER
	return (
		<div className="PendingChanges">
			<div className="pendingChangesHeader">
				<div className="pendingChangesHeaderTitle">
					Pending Spec Changes
				</div>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="pendingChangesPreviewContainer">
					{pendingSpecChanges.length === 0 ? (
						<J2NoResultsFound />
					) : (
						pendingSpecChanges.map((pendingSpecChange) => (
							<RouterLink
								id="pendingSpecCardLink"
								to={`/specs/pending_changes/${pendingSpecChange.id}`}
								underline="none"
							>
								<PendingSpecChangePreviewCard
									pendingSpecChange={pendingSpecChange}
								/>
							</RouterLink>
						))
					)}
				</div>
			)}
		</div>
	);
};

// EXPORT
export default PendingChanges;
