// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

// CSS
import "./SpecDetailScreen.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import {
	deleteAPICall,
	getAPICall,
	postAPICall,
} from "../../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../../context/UserContext";

// MUI COMPONENTS
import { Button, ButtonGroup, CircularProgress } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import InformationCard from "./InformationCard/InformationCard";
import CommentsCard from "./CommentsCard/CommentsCard";
import ContentCard from "./ContentCard/ContentCard";

import AreYouSureDeleteSpecDialog from "./AreYouSureDeleteSpecDialog/AreYouSureDeleteSpecDialog";
import UnableToDeleteSpecDialog from "./UnableToDeleteSpecDialog/UnableToDeleteSpecDialog";

// CONSTANTS

// OTHER

// REACT COMPONENT
const SpecDetailScreen = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// PARAMS
	const { id } = useParams();

	// STATES
	const [specRecord, setSpecRecord] = useState({});
	const [comments, setComments] = useState([]);
	const [statuses, setStatuses] = useState([]);

	// Card States
	const [informationOpen, setInformationOpen] = useState(true);
	const [commentsOpen, setCommentsOpen] = useState(false);
	const [contentOpen, setContentOpen] = useState(true);

	// Dialog States
	const [areYouSureDeleteDialogOpen, setAreYouSureDeleteDialogOpen] =
		useState(false);
	const [jobsUsingSpec, setJobsUsingSpec] = useState([]);

	const [isUnableToDeleteDialogOpen, setIsUnableToDeleteDialogOpen] =
		useState(false);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// USE NAVIGATE
	const navigate = useNavigate();

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchSpecRecord();
	}, []);

	// INPUT HANDLERS
	// Dropdown Handlers
	const expandAllMenus = () => {
		setInformationOpen(true);
		setCommentsOpen(true);
		setContentOpen(true);
	};

	const collapseAllMenus = () => {
		setInformationOpen(false);
		setCommentsOpen(false);
		setContentOpen(false);
	};

	const informationDropdownClicked = () => {
		setInformationOpen(!informationOpen);
	};

	const commentsDropdownClicked = () => {
		setCommentsOpen(!commentsOpen);
	};

	const contentDropdownClicked = () => {
		setContentOpen(!contentOpen);
	};

	// API FUNCTIONS
	const fetchSpecRecord = async () => {
		setIsLoading(true);

		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/byID`,
				{
					id: id,
				}
			);

			if (res.data.length === 0) {
				navigate("/specs/not_found");
			}

			setSpecRecord(res.data[0]);

			await fetchSpecComments();
			await fetchStatuses();
		} catch (error) {
			console.log("Error in fetchSpecRecord: ", error);
		}

		setIsLoading(false);
	};

	const fetchSpecComments = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				`/api/specifications/comments/bySpec`,
				{
					id: id,
				}
			);

			setComments(res.data);
		} catch (error) {
			console.log("Error in fetchSpecComments: ", error);
		}
	};

	const fetchStatuses = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/statuses/all"
			);

			setStatuses(res.data);
		} catch (error) {
			console.log("Error in fetchStatuses: ", error);
		}
	};

	const createNewComment = async (newCommentText) => {
		try {
			const res = await postAPICall(
				instance,
				accounts[0],
				"/api/specifications/comments/add",
				{
					specID: specRecord.id,
					comment: newCommentText,
					createdByID: employeeID,
				}
			);
		} catch (error) {
			console.error("Error creating new comment: ", error);
		}
	};

	const deleteComment = async (commentID, commentContent) => {
		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/specifications/comments/delete",
				{
					commentID: commentID,
					specID: id,
					comment: commentContent,
					deletedByID: employeeID,
				}
			);

			await fetchSpecComments();
		} catch (error) {
			console.error("Error deleting comment: ", error);
		}
	};

	const deleteSpecRecord = async () => {
		setAreYouSureDeleteDialogOpen(false);

		setIsLoading(true);

		try {
			const res = await deleteAPICall(
				instance,
				accounts[0],
				"/api/specifications/removeSpec",
				{
					specID: id,
					specName: specRecord.title,
					isLocal: false,
					deletedByID: employeeID,
				}
			);
		} catch (error) {
			console.error("Error deleting spec record: ", error);
		}

		setIsLoading(false);

		navigate("/specs/library");
	};

	const fetchChildren = async () => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/children",
				{
					id: id,
				}
			);

			return res.data.map((child) => child.id);
		} catch (error) {
			console.error("Error fetching children: ", error);
		}
	};

	const checkAbleToDelete = async (childrenIDs) => {
		try {
			const res = await getAPICall(
				instance,
				accounts[0],
				"/api/specifications/jobsUsingSpec",
				{
					specIDs: [...childrenIDs, id],
				}
			);

			setJobsUsingSpec(res.data);

			return res.data.length === 0;
		} catch (error) {
			console.error("Error checking if deletable: ", error);
		}
	};

	// HELPER FUNCTIONS
	const openAreYouSureDeleteDialog = async () => {
		let childrenIDs = await fetchChildren();

		let canDelete = await checkAbleToDelete(childrenIDs);

		if (canDelete) {
			setAreYouSureDeleteDialogOpen(true);
		} else {
			setIsUnableToDeleteDialogOpen(true);
		}
	};

	// RENDER
	return (
		<div className="SpecDetailScreen">
			<div className="specDetailScreenHeader">
				<div className="specDetailScreenHeaderTitle">
					{specRecord.title ? specRecord.title : "Loading..."}
					<div className="specDetailScreenButtons">
						{Boolean(props.isAdmin) && (
							<Button
								onClick={openAreYouSureDeleteDialog}
								color="error"
								variant="contained"
							>
								Delete Specification
							</Button>
						)}
						<ButtonGroup>
							<Button
								onClick={collapseAllMenus}
								color="secondary"
								variant="contained"
							>
								Collapse All
							</Button>
							<Button
								onClick={expandAllMenus}
								color="primary"
								variant="contained"
							>
								Expand All
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="specDetailScreenBody">
					<InformationCard
						specRecord={specRecord}
						fetchSpecRecord={fetchSpecRecord}
						informationDropdownClicked={informationDropdownClicked}
						informationOpen={informationOpen}
						comments={comments.slice(0, 2)}
						statuses={statuses}
						isAdmin={props.isAdmin}
					/>
					<CommentsCard
						specRecord={specRecord}
						comments={comments}
						commentsDropdownClicked={commentsDropdownClicked}
						commentsOpen={commentsOpen}
						fetchComments={fetchSpecComments}
						isAdmin={props.isAdmin}
						createNewComment={createNewComment}
						deleteComment={deleteComment}
					/>
					<ContentCard
						contentDropdownClicked={contentDropdownClicked}
						contentOpen={contentOpen}
						data={specRecord.content}
						specToEdit={specRecord}
						fetchSpecRecord={fetchSpecRecord}
						createNewComment={createNewComment}
						isAdmin={props.isAdmin}
					/>
				</div>
			)}
			<AreYouSureDeleteSpecDialog
				open={areYouSureDeleteDialogOpen}
				formClosed={() => setAreYouSureDeleteDialogOpen(false)}
				deleteSpecRecord={deleteSpecRecord}
			/>
			<UnableToDeleteSpecDialog
				open={isUnableToDeleteDialogOpen}
				formClosed={() => setIsUnableToDeleteDialogOpen(false)}
				jobsUsingSpec={jobsUsingSpec}
			/>
		</div>
	);
};

// EXPORT
export default SpecDetailScreen;
