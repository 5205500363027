// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./DesignJobChangeLog.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall, postAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { CircularProgress, Pagination } from "@mui/material";

// CUSTOM COMPONENTS
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";

// CONSTANTS
import { convertToPST, RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	employee: "Employee",
	job: "Job",
	description: "Description",
};

const searchDefaultValue = "employee";

// OTHER

// REACT COMPONENT
const DesignJobChangeLog = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [designJobChangeLogs, setDesignJobChangeLogs] = useState([]);
	const [filteredDesignJobChangeLogs, setFilteredDesignJobChangeLogs] =
		useState([]);

	const [designJobChangeLogRows, setDesignJobChangeLogRows] = useState([]);
	const [designJobChangeLogsColumns, setDesignJobChangeLogsColumns] =
		useState([]);

	// Pagination
	const [numberOfPages, setNumberOfPages] = useState(5);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);

	// Feedback
	const [isLoading, setIsLoading] = useState(false);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchLogs();

		postAPICall(instance, accounts[0], "/api/logs/access/add", {
			page_name: "Design Job Change Logs",
			employeeID: employeeID,
			notes: "Accessed the Design Job Change Logs page",
		});
	}, []);

	useEffect(() => {
		createColumnData();
		createRowData();
	}, [filteredDesignJobChangeLogs]);

	// INPUT HANDLERS
	// Pagination
	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	// API FUNCTIONS
	const fetchLogs = async () => {
		setIsLoading(true);

		try {
			const response = await getAPICall(
				instance,
				accounts[0],
				"/api/logs/designJobs/all"
			);

			setDesignJobChangeLogs(response.data);
			setFilteredDesignJobChangeLogs(response.data);
			setNumberOfPages(
				Math.ceil(response.data.length / RECORDS_PER_PAGE)
			);
		} catch (error) {
			console.log(error);
		}

		setIsLoading(false);
	};

	// HELPER FUNCTIONS
	const filterDesignJobChangeLogRecords = (
		searchValue,
		searchByParameter
	) => {
		setIsLoading(true);

		let filteredRecords = designJobChangeLogs.filter((record) => {
			if (searchByParameter === "employee") {
				return (record.employee_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "job") {
				return (record.full_project_name ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			} else if (searchByParameter === "description") {
				return (record.description ?? "")
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			}
		});

		setFilteredDesignJobChangeLogs(filteredRecords);
		setNumberOfPages(Math.ceil(filteredRecords.length / RECORDS_PER_PAGE));

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	const createColumnData = () => {
		setDesignJobChangeLogsColumns([
			{ text: "Employee" },
			{ text: "Job" },
			{ text: "Description" },
			{ text: "Date" },
		]);
	};

	const createRowData = () => {
		setDesignJobChangeLogRows(
			filteredDesignJobChangeLogs.map((log) => [
				{ text: log.employee_name },
				{
					text: (
						// note: using <a> tag instead of link and onClick props so on ctrl+click it opens in new tab
						<a href={`/specs/design_jobs/${log.phase_id}`}>
							{log.full_project_name}
						</a>
					),
				},
				{ text: log.description },
				{ text: convertToPST(log.created) },
			])
		);
	};

	// RENDER
	return (
		<div className="DesignJobChangeLog">
			<div className="designJobChangeLogHeader">
				<div className="designJobChangeLogHeaderTitle">
					Design Job Logs
				</div>
				<SearchBar
					searchDefaultValue={searchDefaultValue}
					searchByParameterMap={searchByParameterMap}
					filterResults={(searchValue, searchByParameter) =>
						filterDesignJobChangeLogRecords(
							searchValue,
							searchByParameter
						)
					}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredDesignJobChangeLogs.length === 0 && (
						<J2NoResultsFound />
					)}
					{filteredDesignJobChangeLogs.length > 0 &&
						designJobChangeLogsColumns.length > 0 &&
						designJobChangeLogRows.length > 0 && (
							<>
								<GenericTable
									columnData={designJobChangeLogsColumns}
									rowData={designJobChangeLogRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
		</div>
	);
};

// EXPORT
export default DesignJobChangeLog;
