// DEPENDENCIES
import React, { useState, useEffect, useContext } from "react";

// CSS
import "./InfoConsole.css";

// MSAL
import { useMsal } from "@azure/msal-react";

// API IMPORTS
import { getAPICall } from "../../../config/apiCalls";

// CONTEXT
import { UserContext } from "../../../context/UserContext";

// MUI COMPONENTS
import { Button, CircularProgress, Pagination } from "@mui/material";

// MUI ICONS

// CUSTOM COMPONENTS
import J2NoResultsFound from "../../Misc/J2NoResultsFound/J2NoResultsFound";
import NewInfoDialog from "./NewInfoDialog/NewInfoDialog";
import EditInfoDialog from "./EditInfoDialog/EditInfoDialog";
import GenericTable from "../../Misc/Table/GenericTable";
import SearchBar from "../../Misc/SearchBar/SearchBar";

// CONSTANTS
import { RECORDS_PER_PAGE } from "../../../constants";

const searchByParameterMap = {
	name: "Name",
	info_category_name: "Category",
};

const searchDefaultValue = "name";

// OTHER

// REACT COMPONENT
const InfoConsole = (props) => {
	// MSAL
	const { instance, accounts } = useMsal();

	// STATES
	const [isLoading, setIsLoading] = useState(false);
	const [newRecordOpen, setNewRecordOpen] = useState(false);
	const [editRecordOpen, setEditRecordOpen] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState(null);

	const [info, setInfo] = useState([]);
	const [infoRows, setInfoRows] = useState([]);
	const [infoColumns, setInfoColumns] = useState([]);
	const [filteredInfo, setFilteredInfo] = useState([]);
	const [categories, setCategories] = useState([]);

	const [paginationStartIndex, setPaginationStartIndex] = useState(0);
	const [paginationEndIndex, setPaginationEndIndex] =
		useState(RECORDS_PER_PAGE);
	const [numberOfPages, setNumberOfPages] = useState(5);

	// CONTEXT
	const employeeID = useContext(UserContext);

	// USE EFFECT
	useEffect(() => {
		fetchInfo();
	}, []);

	useEffect(() => {
		createRowData();
		createColumnData();
	}, [filteredInfo]);

	// INPUT HANDLERS

	const handlePageChange = async (event, value) => {
		const currentPage = value;
		setPaginationStartIndex((currentPage - 1) * RECORDS_PER_PAGE);
		setPaginationEndIndex(currentPage * RECORDS_PER_PAGE);
	};

	const newRecordClicked = () => {
		setNewRecordOpen(!newRecordOpen);
	};

	const editRecordClicked = (record) => {
		setEditRecordOpen(true);
		setRecordToEdit(record);
	};

	const editRecordClosed = () => {
		setEditRecordOpen(false);
		fetchInfo();
	};

	const editRecordClosedNoRefresh = () => {
		setEditRecordOpen(false);
	};

	const newInfoDialogClosed = () => {
		setNewRecordOpen(false);
		fetchInfo();
	};

	const newInfoDialogClosedNoRefresh = () => {
		setNewRecordOpen(false);
	};

	// API FUNCTIONS
	const fetchInfo = async () => {
		try {
			setIsLoading(true);

			const infoRes = await getAPICall(
				instance,
				accounts[0],
				"/api/info/allRecords/"
			);
			const categoriesRes = await getAPICall(
				instance,
				accounts[0],
				"/api/info/allInfoCategories/"
			);
			setCategories(categoriesRes.data);
			setInfo(infoRes.data);
			setFilteredInfo(infoRes.data);
			setNumberOfPages(Math.ceil(infoRes.data.length / RECORDS_PER_PAGE));

			setIsLoading(false);
		} catch (error) {
			console.error(
				"InfoConsole.js - fetchInfo() - Error fetching Info Data: ",
				error
			);
		}
	};

	const filterInfo = async (searchValue, searchByParameter) => {
		setIsLoading(true);

		let filteredInfo = info.filter((infoObj) => {
			return (infoObj[searchByParameter] ?? "")
				.toLowerCase()
				.includes(searchValue.toLowerCase());
		});

		setFilteredInfo(filteredInfo);
		setNumberOfPages(Math.ceil(filteredInfo.length / RECORDS_PER_PAGE));

		// Set current page to 1
		setPaginationStartIndex(0);
		setPaginationEndIndex(RECORDS_PER_PAGE);

		setIsLoading(false);
	};

	// HELPER FUNCTIONS

	const createColumnData = () => {
		setInfoColumns([
			{ text: "Name" },
			{ text: "Data" },
			{ text: "Data Type" },
			{ text: "Info Height" },
			{ text: "Category" },
			{},
		]);
	};

	const createRowData = () => {
		setInfoRows(
			filteredInfo.map((info) => [
				{ text: info.name },
				{
					onClick: () => window.open(info.data, "_blank"),
					icon: "link",
				},
				{ text: info.data_type },
				{
					text:
						info.data_type === "iframe" ? info.info_height : "N/A",
					notApplicable: info.data_type !== "iframe",
				},
				{ text: info.info_category_name },
				{ onClick: () => editRecordClicked(info), icon: "edit" },
			])
		);
	};

	// RENDER
	return (
		<div className="InfoConsole">
			<div className="InfoConsoleHeader">
				<div className="InfoConsoleTitle">
					Info Console
					<Button
						variant="contained"
						color="primary"
						onClick={newRecordClicked}
						disabled={isLoading}
					>
						New Record
					</Button>
				</div>
				<SearchBar
					filterResults={(searchValue, searchByParameter) =>
						filterInfo(searchValue, searchByParameter)
					}
					searchByParameterMap={searchByParameterMap}
					searchDefaultValue={searchDefaultValue}
				/>
			</div>
			{isLoading && (
				<div className="loadingDiv">
					<CircularProgress color="primary" />
				</div>
			)}
			{!isLoading && (
				<div className="tableContainerDiv">
					{filteredInfo.length === 0 && <J2NoResultsFound />}
					{filteredInfo.length > 0 &&
						infoRows.length > 0 &&
						infoColumns.length > 0 && (
							<>
								<GenericTable
									columnData={infoColumns}
									rowData={infoRows.slice(
										paginationStartIndex,
										paginationEndIndex
									)}
								/>
								<div className="paginationDiv">
									<Pagination
										count={numberOfPages}
										color="primary"
										onChange={handlePageChange}
									/>
								</div>
							</>
						)}
				</div>
			)}
			<NewInfoDialog
				open={newRecordOpen}
				categories={categories}
				newInfoDialogClosed={newInfoDialogClosed}
				newInfoDialogClosedNoRefresh={newInfoDialogClosedNoRefresh}
			/>
			<EditInfoDialog
				open={editRecordOpen}
				categories={categories}
				editRecordClosed={editRecordClosed}
				editRecordClosedNoRefresh={editRecordClosedNoRefresh}
				recordToEdit={recordToEdit}
			/>
		</div>
	);
};

// EXPORT
export default InfoConsole;
